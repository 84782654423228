import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { MailForMailTemplateDto } from '../../../../../../../../models/ts/mail-for-mail-template-dto';
import { AsyncPipe, DatePipe, NgTemplateOutlet } from '@angular/common';
import { FilterMenuModule } from '@progress/kendo-angular-grid';
import { IconComponent } from '../../../../../../../shared/components/ui/icon/icon.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate/translate.pipe';
import { NotificationFilterPipe } from '../../../../../../../shared/pipes/filter/notification-filter.pipe';
import { NotificationService } from '../../../../../../../shared/services/filter/notification-service';
import { EditorModule } from '@progress/kendo-angular-editor';
import { CollectionFormApiService } from '../../../../../../../api/bizzmine/collection-form/collection-form-api.service';
import { finalize, lastValueFrom, take, tap } from 'rxjs';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DragFileUploadDirective } from '../../../../../../../shared/directives/drag-file-upload.directive';
import { MailingAttachmentsUploadComponent } from '../mailing-attachments-upload/mailing-attachments-upload.component';
import { ExtensionIconComponent } from '../../../../../../../shared/components/ui/icon/extension-icon/extension-icon.component';
import { FileUploadService } from '../../../../../../../core/services/file-upload/file-upload.service';
import { MediaDto } from '../../../../../../../../models/ts/media-dto.model';
import { DownloadService } from '../../../../../../../core/services/download/download.service';
import { ToggleArrowComponent } from '../../../../../../../shared/components/ui/toggle-arrow/toggle-arrow.component';
import { Align, KENDO_POPUP } from '@progress/kendo-angular-popup';
import { ThemeService } from 'src/app/core/services/theme/theme.service';
import { EditorComponent } from 'src/app/shared/components/editor/editor.component';
import { EMAIL_REGEX } from '../../../../../../../shared/constants/email-regex';

@Component({
  selector: 'bizz-send-mail-from-template',
  standalone: true,
  imports: [
    AsyncPipe,
    FilterMenuModule,
    IconComponent,
    NgTemplateOutlet,
    TooltipModule,
    TranslatePipe,
    NotificationFilterPipe,
    EditorModule,
    ProgressBarModule,
    UploadModule,
    DragFileUploadDirective,
    MailingAttachmentsUploadComponent,
    ExtensionIconComponent,
    ToggleArrowComponent,
    KENDO_POPUP,
    DatePipe,
    EditorComponent,
  ],
  templateUrl: './send-mail-from-template.component.html',
  styleUrl: './send-mail-from-template.component.scss'
})
export class SendMailFromTemplateComponent {
  public template: MailForMailTemplateDto;
  public loading = false;
  public showBcc = false;
  public showCc = false;
  public mailSavedOrSent = false;
  public showPopup = false;
  public popupAnchorAlign: Align = { horizontal: 'left', vertical: 'bottom' };

  public hasSendMail = false;
  public isValid: boolean = true;
  public currentAttachments: Array<{
    id: number,
    fileName: string,
    size: number
  }> = [];

  public constructor(private dialogRef: DialogRef,
                     @Inject(DIALOG_DATA) public dialogModel: {
                       template: MailForMailTemplateDto | null,
                       instanceId: number,
                       collectionId: number,
                       versionId: number,
                       mailIds: Array<number>,
                       viewOnly: boolean
                     },
                     private notificationService: NotificationService,
                     private collectionFormApiService: CollectionFormApiService,
                     private fileUploadService: FileUploadService,
                     private downloadService: DownloadService,
                     private themeService: ThemeService) {
    this.dialogRef.disableClose = true;
    this.template = dialogModel.template ?? {} as MailForMailTemplateDto;
    if (this.template.MediaAttachments && this.template.MediaAttachments.length > 0) {
      this.currentAttachments = this.template.MediaAttachments.map((media) => ({
        id: media.ID,
        fileName: media.OriginalFileName,
        size: media.Size,
      }));
    } else {
      this.template.MediaAttachments = [];
    }

    this.transform();

  }

  public togglePopup(event?: MouseEvent, value?: boolean): void {
    this.showPopup = value != undefined ? value : !this.showPopup;
    if (event)
      event.stopImmediatePropagation();
  }


  public close(): void {
    this.dialogRef.close(this.savedItem);
  }

  public getFileExtension(fileName: string): string {
    return this.fileUploadService.getFileExtension(fileName);
  }

  public formatFileSize(fileSizeInBytes: number): string {
    return this.fileUploadService.formatFileSize(fileSizeInBytes);
  }

  public downloadAttachment(media: MediaDto): void {
    if (this.template.mailId && this.template.mailId > 0) {
      lastValueFrom(
        this.collectionFormApiService.downloadMailTemplateMedia({
          mailId: this.template.mailId,
          mediaId: media.ID,
          name: media.OriginalFileName
        })
      )
        .then(file => {
          this.downloadService.startDownload(file, media.OriginalFileName);
        });
    }

  }


  public sendMail(): void {
    this.template.CollectionsID = this.dialogModel.collectionId;
    this.template.VersionsID = this.dialogModel.versionId;
    this.template.InstancesID = this.dialogModel.instanceId;

    this.isValid = this.validateTemplate(this.template);
    if (!this.isValid) {
      // invalidation -> do not continue
      return;
    }
    this.loading = true;
    this.template.Body = this.themeService.applyThemeToEmailContent(this.template.Body);

    this.collectionFormApiService.sendMailFromTemplate(this.template)
      .pipe(take(1),
        tap(created => this.savedItem = created),
        finalize(() => this.loading = false))
      .subscribe(() => {
        this.hasSendMail = true;
      });
  }

  public savedItem: Array<unknown>;

  public saveDraft(): void {
    this.template.CollectionsID = this.dialogModel.collectionId;
    this.template.VersionsID = this.dialogModel.versionId;
    this.template.InstancesID = this.dialogModel.instanceId;

    this.loading = true;

    this.template.Body = this.themeService.applyThemeToEmailContent(this.template.Body);

    this.collectionFormApiService.draftMailFromTemplate(this.template)
      .pipe(take(1),
        tap(created => this.savedItem = created),
        finalize(() => {this.loading = false;this.mailSavedOrSent = true;this.close();}))
      .subscribe(() => {
        this.hasSendMail = false;
      });
  }

  public toggleBcc(): void {
    this.showBcc = !this.showBcc;
    if (!this.showBcc) {
      this.template.BCC = '';
    }
  }

  public attachmentsChanged(attachments: number[]): void {
    this.template.Attachments = attachments.map(attachment => attachment.toString());
  }

  public toggleCc(): void {
    this.showCc = !this.showCc;
    if (!this.showCc) {
      this.template.CC = '';
    }
  }

  public onBodyChange(value: string):void {
    this.template.Body = value;
  }

  public validateEmail(email: string): boolean {
    const splitEmail = email.split(',');
    let allValid = true;
    if (splitEmail.length > 0) {
      splitEmail.forEach(email => {
        const trimmedEmail = email.trim();
        if (!EMAIL_REGEX.test(trimmedEmail)) {
          allValid = false;
        }
      });
    } else {
      const trimmedEmail = email.trim();
      if (!EMAIL_REGEX.test(trimmedEmail)) {
        allValid = false;
      }
    }
    return allValid;
  }

  private transform(): void {
    this.template.To = this.notificationService.convertNotificationText(this.template.To);
    this.template.CC = this.notificationService.convertNotificationText(this.template.CC);
    this.template.BCC = this.notificationService.convertNotificationText(this.template.BCC);
    this.template.Subject = this.notificationService.convertNotificationText(this.template.Subject);
    this.template.Body = this.notificationService.convertNotificationText(this.template.Body);
  }

  private validateTemplate(template: MailForMailTemplateDto): boolean {
    let valid = true;
    if (!this.validateEmail(template.To)) {
      valid = false;
    }
    if (template.Subject == null || template.Subject.trim() == '') {
      valid = false;
    }

    if (!this.showBcc) {
      template.BCC = '';
    } else if (!this.validateEmail(template.BCC)) {
      valid = false;
    }

    if (!this.showCc) {
      template.CC = '';
    } else if (!this.validateEmail(template.CC)) {
      valid = false;
    }

    return valid;
  }
}


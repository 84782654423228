import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupModule, PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { SafePipe } from 'safe-pipe';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TruncatePipe } from '../../../../pipes/truncate/truncate.pipe';
import { convertHtmlToPlaintext } from '../../../../functions/helpers/convert-html-to-plaintext';

const HIDE_POPUP_DELAY_TIME = 100; //ms
@Component({
  selector: 'bizz-grid-cell-memo',
  standalone: true,
  imports: [CommonModule, IconComponent, PopupModule, SafePipe, TruncatePipe],
  templateUrl: './grid-cell-memo.component.html',
  styleUrls: ['./grid-cell-memo.component.scss']
})
export class GridCellMemoComponent extends GridCellBaseComponent<string> implements OnInit {
  @ViewChild('anchor', { read: ElementRef })
  public anchorEl: ElementRef;

  @ViewChild('readmorePopupTemplateRef', { static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public readmorePopupTemplateRef: TemplateRef<any>;

  /**
   * Html content to show in the popover
   */
  @Input() public previewText: string = '';

  private readmorePopup: PopupRef | null = null;

  private anchorHovered = false;
  private popupHovered = false;
  public showReadMoreIcon: boolean= false;
  public constructor(private popupService: PopupService) {
    super();
  }

  protected override onDataChanged(): void {
    this.previewText = '';
    
    if(this.data['ShowReadMoreIconFor' + this.column.field]){
      this.showReadMoreIcon = this.data['ShowReadMoreIconFor' + this.column.field] as unknown as boolean;
    }

    if (this.data[this.column.field + '_Preview']) {
      this.previewText = this.data[this.column.field+ '_Preview'];
    } else {
      if (this.value) {
        //for fields in editable grid, generate preview text.
        this.previewText = convertHtmlToPlaintext(this.value, this.column.KeepNewLines) ?? this.value;
      }
    }
    if (!this.column.KeepNewLines) {
      this.previewText = this.previewText.replaceAll('<br/>', ' ');
      this.previewText = this.previewText.replaceAll('</br>', ' ');
      this.previewText = this.previewText.replaceAll('<br>', ' ');
      this.previewText = this.previewText.replaceAll('\n', ' ');
    }
  }

  public ngOnInit(): void {
  }

  public onAnchorMouseEnter(): void {
    this.anchorHovered = true;
    this.showPopup();
  }

  public onAnchorMouseLeave(): void {
    this.anchorHovered = false;
    setTimeout(() => {
      if (!this.anchorHovered && !this.popupHovered) this.hidePopup();
    }, HIDE_POPUP_DELAY_TIME);
  }

  public onPopupMouseEnter(): void {
    this.popupHovered = true;
    this.showPopup();
  }

  public onPopupMouseLeave(): void {
    this.popupHovered = false;
    setTimeout(() => {
      if (!this.anchorHovered && !this.popupHovered) this.hidePopup();
    }, HIDE_POPUP_DELAY_TIME);
  }

  public showPopup(): void {
    if (this.readmorePopup != null) return;

    this.readmorePopup = this.popupService.open({
      anchor: this.anchorEl,
      content: this.readmorePopupTemplateRef,
      popupClass:
        'w-svw h-svh sm:w-fit sm:h-auto sm:min-w-[400px] sm:-mt-[0.5rem] sm:rounded-md maxw-sm:!top-0 !z-[9999] maxw-sm:!left-0 maxw-sm:!right-0',
      animate: false,
      anchorAlign: { horizontal: 'center', vertical: 'bottom' },
      popupAlign: { horizontal: 'center', vertical: 'top' },
      collision: { horizontal: 'fit', vertical: 'fit' }
    });
  }

  public hidePopup(): void {
    if (this.readmorePopup) {
      this.readmorePopup.close();
      this.readmorePopup = null;
    }
    event?.preventDefault();
  }
}

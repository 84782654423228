<div class="flex h-full w-full flex-col overflow-hidden">
    <kendo-grid class="h-full min-h-32 overflow-hidden" #kendoGrid [loading]="loading"
        [class.loading]="loading"
        [group]="gridDataInternal.length == 0 ? [] : gridOptions.dataSource.group" [sort]="gridOptions.dataSource.sort"
        [scrollable]="gridOptions.scrollable" [groupable]="gridDataInternal.length == 0 ? false : gridOptions.groupable"
        [sortable]="gridOptions.sortable" [selectable]="gridOptions.selectable" [resizable]="gridOptions.resizable"
        [reorderable]="gridOptions.reorderable" [kendoGridBinding]="gridDataInternal"
        [autoSize]="(!loading && gridDataInternal.length > 0) || loading ? gridOptions.autoSize : false" [trackBy]="trackBy"
        kendoGridSelectBy [rowSelected]="isRowSelected.bind(this)" (groupChange)="onGroupChange($event)"
        (sortChange)="onSortChange($event)" (cellClick)="onCellClick($event)"
        (selectionChange)="onSelectionChange($event)" (columnReorder)="onColumnReorder($event)">
        <ng-template kendoGridLoadingTemplate>
            <div class="relative mt-8 flex h-full min-h-24 w-full items-center justify-center bg-widget/40">
                <bizz-loader [showLoader]="true" backgroundClasses="bg-transparent"></bizz-loader>
            </div>
        </ng-template>
        @if (!loading && gridDataInternal.length > 0) {
        <ng-template kendoGridNoRecordsTemplate>
            <div class="empty-placeholder-image flex h-full w-full flex-col items-center justify-center">
                <bizz-icon class="my-2 h-12 w-12 text-blue-500" iconStyle="duotone"
                    iconName="magnifying-glass"></bizz-icon>
                <p class="h-full text-center font-title text-base font-bold text-widget-header">
                    {{ 'NoResultsFound' | translate }}
                </p>
            </div>
        </ng-template>
        } @else {
        <ng-template kendoGridNoRecordsTemplate></ng-template>
        }
        @if (showMobileColumnView && gridDataInternal.length > 0) {
        <kendo-grid-column [media]="'(max-width: ' + MAX_MOBILE_WIDTH + 'px)'" class="text-xs" headerClass="hidden"
            [width]="0" [reorderable]="false">
            <ng-template kendoGridCellTemplate let-dataItem>
                <bizz-grid-mobile-cell [data]="dataItem" [gridColumns]="gridOptions.columns"
                    [listOptions]="actionCellData.listOptions" [trashFilterActive]="actionCellData.trashFilterActive"
                    [collectionType]="actionCellData.collectionType">
                </bizz-grid-mobile-cell>
            </ng-template>
        </kendo-grid-column>
        }
        @if (!loading && gridOptions.selectable.enabled && gridDataInternal.length > 0) {
        <kendo-grid-checkbox-column [width]="32" [showSelectAll]="gridOptions.selectable.mode === 'multiple'"
            [reorderable]="false">
        </kendo-grid-checkbox-column>
        }
        @if (commandColumnTemplate !== null && gridDataInternal.length > 0) {
        <kendo-grid-command-column title="" [width]="112" [resizable]="false" [reorderable]="false">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-column="column">
                <ng-container [ngTemplateOutlet]="commandColumnTemplate"
                    [ngTemplateOutletContext]="{$implicit: dataItem,rowIndex,column }">
                </ng-container>
            </ng-template>
        </kendo-grid-command-column>
        }

        @for (col of gridOptions.columns; track $index) {
        <kendo-grid-column [field]="col.field" [title]="col.title" [locked]="col.locked" [sortable]="col.sortable"
            [groupable]="col.groupable" [hidden]="col.hidden" [reorderable]="col.reorderable"
            [resizable]="col.resizable" [width]="col.width"
            [minResizableWidth]="col.minResizableWidth > 0 ? col.minResizableWidth : minResizableWidth"
            [media]="showMobileColumnView ? '(min-width: ' + (MAX_MOBILE_WIDTH + 1) + 'px)' : ''" [class]="{ '!text-center' : col.alignmentType == AlignmentType.Center,
                    '!text-end' : col.alignmentType == AlignmentType.Right }">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <ng-container [ngTemplateOutlet]="columnTemplate || defaultColumnTemplate"
                    [ngTemplateOutletContext]="{ $implicit: dataItem, column:col, columnIndex:$index }">
                </ng-container>
                <ng-template #defaultColumnTemplate>
                    @if (col.GridColumnType == gridColumnType.Actions) {
                    <bizz-grid-cell-actions [data]="dataItem" [readOnly]="readOnly" [metaData]="actionCellData"
                        (onCellAction)="cellAction.emit($event)">
                    </bizz-grid-cell-actions>
                    } @else {
                    <bizz-grid-cell [data]="dataItem" [gridColumn]="col" [metaData]="actionCellData"
                        (onCellAction)="cellAction.emit($event)">
                    </bizz-grid-cell>
                    }
                </ng-template>
            </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                <ng-container [ngTemplateOutlet]="editColumnTemplate"
                    [ngTemplateOutletContext]="{$implicit: dataItem, formGroup, column:col, columnIndex: $index }">
                </ng-container>
            </ng-template>

            <ng-template kendoGridGroupHeaderTemplate let-group="group">
                    {{col.title}}: &nbsp;
                    @if(col.FieldType == TableFieldDataType.Memo){
                    <span [innerHTML]="group.value | safe: 'html'"></span>
                    }@else{
                    <span>{{group.value}}</span>
                    }
            </ng-template>
        </kendo-grid-column>
        }
    </kendo-grid>
</div>
@if (showPager && gridDataInternal.length > 0) {
<kendo-datapager [style.width.%]="100" [pageSize]="gridOptions.pageSize"
    [skip]="(currentPageInternal - 1) * gridOptions.pageSize" [total]="totalItems" (pageChange)="onPageChange($event)"
    [size]="'large'">
    <ng-template kendoDataPagerTemplate>
        <div class="flex w-full items-center justify-between">
            <kendo-datapager-info class="cq-widget-btn-hidden hidden md:block"></kendo-datapager-info>
            <span class="cq-widget-btn-flex empty-spacer hidden max-w-0.5 sm:flex"></span>
            <div class="flex w-full flex-wrap items-center justify-between gap-x-0.5 gap-y-2 md:w-auto md:justify-end">
                <kendo-datapager-page-sizes class="ml-auto gap-2" [pageSizes]="pageSizes">
                </kendo-datapager-page-sizes>
                @if (totalItems > gridOptions.pageSize) {
                <div class="k-pager-numbers-wrap">
                    <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
                    <kendo-datapager-numeric-buttons [buttonCount]="gridOptions.pageable.buttonCount!">

                    </kendo-datapager-numeric-buttons>
                    <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
                </div>
                }
            </div>
        </div>
    </ng-template>
</kendo-datapager>
}
@if (formFieldSignal(); as formField) {
<!--    @if (errorState?.Message; as errorMessage) {-->
<!--        <bizz-tooltip class="absolute -top-6 right-4 z-[2] h-full cursor-pointer text-red-400"-->
<!--                      [text]="errorMessage"></bizz-tooltip>-->
<!--    }-->
    <bizz-editor class="min-w-full max-w-full" [value]="formControl.value" (valueChange)="setValue($event)"
                 [id]="'tabID' + formField.Bookmark"
                 [hideToolbar]="!formField.ShowHTMLEditor || readOnlyOverride || formField.IsReadOnly"
                 [readOnly]="readOnlyOverride || formField.IsReadOnly"
                 [minHeight]="formField.ShowHTMLEditor ?
    (formField.Height > MIN_EDITOR_HEIGHT ? formField.Height : MIN_EDITOR_HEIGHT) : 
    (formField.Height > MIN_LIGHT_EDITOR_HEIGHT ? formField.Height : MIN_LIGHT_EDITOR_HEIGHT)"
                 [isError]="errorState != null" [overflowToolbar]="!isInGrid">

    </bizz-editor>
}
@if (formFieldSignal(); as formField) {
    <input #input class="h-10"
           [ngClass]="{'hidden' : formField.IsHidden}"
           [class.error]="errorState != null"
           [readOnly]="readOnlyOverride || formField.IsReadOnly"
           [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
           [formControl]="formControl"
           id="{{formField.Id}}"
           placeholder="{{formField.Placeholder}}"
           type="text">
}
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { map, of, catchError, filter, exhaustMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectForm, selectFormField, selectFormFieldsByAiSourceFields } from '../forms-selectors';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { ReadOnlyPriority } from '../../../../features/bizzmine/form/enums/read-only-priority.enum';
import { AiApiService } from '../../../../api/bizzmine/ai/ai-api.service';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import { CollectionListDataInstance } from '../../../../shared/interfaces/collection-list-data-instance';
@Injectable()
export class FormsAiEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store);
  private aiService = inject(AiApiService);
  public oldGrid: Array<CollectionListDataInstance> = [];


  public fetchAiButtonData$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.fetchAiButtonData),
    filter(({data}) => data != undefined && data.Fields != undefined && data.Fields.length > 0),
    exhaustMap((intent) => {
        return this.aiService.autoComplete(intent.data).pipe(map(response => {
          return formsActions.fetchAiButtonDataSucceeded({
            formId: intent.formId,
            field: intent.field,
            data: response,
          });
        }), catchError(error => {
          return of(formsActions.fetchAiButtonDataFailed({
            formId: intent.formId,
            field: intent.field,
            error: error,
          }));
        }));
    })
  ));

  public getAiButton$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formsActions.getAiButtonData),
      map(({formId,field}) => {

        const aiSourceFields = structuredClone(this.store$.selectSignal(selectFormFieldsByAiSourceFields(
          formId, field.AiSourceFields
        ))());

        const aiTargetFields = structuredClone(this.store$.selectSignal(selectFormFieldsByAiSourceFields(
          formId, field.AiTargetFields
        ))());

        const inputValues: Array<CollectionFormField> = [];
        const outputValues: Array<CollectionFormField> = [];

        if (aiSourceFields && aiSourceFields.length > 0) {
          aiSourceFields.forEach((field) => {
            inputValues.push( field);
            this.store$.dispatch(formsActions.setFieldReadOnly({
              formId: formId,
              formFieldId: field.Id,
              isReadOnly: true,
              readOnlyPriority: ReadOnlyPriority.Protected
            }));
          });
        }

        // do it for aiTargetFields
        if (aiTargetFields && aiTargetFields.length > 0) {
          aiTargetFields.forEach((field) => {
            outputValues.push(field);
            this.store$.dispatch(formsActions.setFieldReadOnly({
              formId: formId,
              formFieldId: field.Id,
              isReadOnly: true,
              readOnlyPriority: ReadOnlyPriority.Protected
            }));
          });
        }

        const form = this.store$.selectSignal(selectForm(formId))();
        return formsActions.fetchAiButtonData({
          formId: formId,
          field: field,
          data: {
            AiLicense: false,
            AiLoading: false,
            FormId: form!.data.CollectionFormId,
            CollectionsId: form!.data.CollectionsID,
            InstancesId: form!.data.InstancesID,
            VersionsId: form!.data.VersionsID,
            AiButtonId: field.CollectionFieldsID ?? 0,
            Fields: inputValues.map(field => {return { [field.Bookmark]: field.Value }}),
          }
        });
      })
    )
  );

  public applyAiToFormFieldForCombobox$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.applyAiToFormField),
    filter(intent => intent != undefined && intent.field != undefined && intent.field.ComponentType == TableFieldDataType.Combobox),
      map((intent) => {
        const displayStyleValue = intent.field.FieldValues.find(field => field.Caption === intent.value);
        return formsActions.updateFormField({
          formId: intent.formId,
          fieldId: intent.field.Id,
          value: displayStyleValue ? displayStyleValue.CollectionFieldValuesID : 0
        });
      })
  ));

  public applyAiToFormFieldForLookup$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.applyAiToFormField),
    filter(intent => intent != undefined && intent.field != undefined && intent.field.ComponentType != TableFieldDataType.Combobox && intent.value?.Text != undefined),
    map((intent) => {
      return formsActions.lookupChanged({
        formId: intent.formId,
        lookupFieldId: intent.field.Id,
        lookupItem: intent.value
      });
    })
  ));

  public applyAiToFormFieldForNormalField$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.applyAiToFormField),
    filter(intent => intent != undefined && intent.field != undefined && intent.field.ComponentType == TableFieldDataType.AlphaNumeric || intent.field.ComponentType == TableFieldDataType.Numeric || intent.field.ComponentType == TableFieldDataType.Email || intent.field.ComponentType == TableFieldDataType.DatePicker || intent.field.ComponentType == TableFieldDataType.TimePicker || intent.field.ComponentType == TableFieldDataType.DateTimePicker || intent.field.ComponentType == TableFieldDataType.Memo || intent.field.ComponentType == TableFieldDataType.HyperLink),
    map((intent) => {
      return formsActions.updateFormField({
        formId: intent.formId,
        fieldId: intent.field.Id,
        value: intent.value
      });
    })
  ));

  public applyAiToFormFieldForLinkedGrids$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.applyAiToFormField),
    filter(intent => intent != undefined && intent.field != undefined && Array.isArray(intent.value)),
    map((intent) => {
      this.store$.dispatch(formsActions.clearGrid({
        formId: intent.formId,
        gridFieldId: intent.field.Id
      }));

      return formsActions.addInstancesToGrid({
        formId: intent.formId,
        gridFieldId: intent.field.Id,
        instances: intent.value
      })
    })
  ));

  public aiButtonDataSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formsActions.fetchAiButtonDataSucceeded),
      tap(value => {
        let aiTargetFields: CollectionFormField[] | null;
        let aiSourceFields: CollectionFormField[] | null;

        const field = value.field;
        if (field !== undefined) {
          aiTargetFields = structuredClone(this.store$.selectSignal(selectFormFieldsByAiSourceFields(
            value.formId, field.AiTargetFields
          ))());
          aiSourceFields = structuredClone(this.store$.selectSignal(selectFormFieldsByAiSourceFields(
            value.formId, field.AiSourceFields
          ))());
        }

        value.data.forEach((item) => {
          const fieldToChange = aiTargetFields?.find(field => field.Id === item.Key);

          // TODO bram -> implement all possible iterations
          const field = this.store$.selectSignal(selectFormField(value.formId, item.Key))();
          if(field){
            this.store$.dispatch(formsActions.applyAiToFormField({
              formId: value.formId,
              field: field,
              value: item.Value
          }));
          }

          //TODO bram all code under this comment should be covered by formsActions.applyAiToFormField
          if (fieldToChange != undefined) {
            //const field = this.store$.selectSignal(selectFormField(value.formId, item.Key))();
            //Check if the field caption contains the AI icon or Exclamation icon
            const cleanString = fieldToChange.Caption.replace('<i class="ml-2 text-sm text-blue-500 fa-solid fa-microchip-ai"></i>','').replace('<i class="ml-2 text-sm text-orange-400 fa-solid fa-circle-exclamation"></i>','');
            this.store$.dispatch(formsActions.updateFormFieldCaption({
              formId: value.formId,
              fieldId: fieldToChange?.Id ?? 0,
              value: cleanString + ' ' + (item.Value ? '<i class="ml-2 text-sm text-blue-500 fa-solid fa-microchip-ai"></i>' : ' <i class="ml-2 text-sm text-orange-400 fa-solid fa-circle-exclamation"></i>')
            }))
          }

          if (aiSourceFields && aiSourceFields.length > 0) {
            aiSourceFields.forEach((field) => {
              this.store$.dispatch(formsActions.setFieldReadOnly({
                formId: value.formId,
                formFieldId: field.Id,
                isReadOnly: false,
                readOnlyPriority: ReadOnlyPriority.Protected
              }));
            });
          }

          // do it for aiTargetFields
          if (aiTargetFields && aiTargetFields.length > 0) {
            aiTargetFields.forEach((field) => {
              this.store$.dispatch(formsActions.setFieldReadOnly({
                formId: value.formId,
                formFieldId: field.Id,
                isReadOnly: false,
                readOnlyPriority: ReadOnlyPriority.Protected
              }));
            });
          }
        })
      })
    )
  , {dispatch: false});
}


import { CollectionFieldDisplayStyleValueDto } from 'src/models/ts/collection-field-display-style-value-dto.model';
import { Component } from '@angular/core';
import { RadioButtonModule } from '@progress/kendo-angular-inputs';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgForOf, NgIf, CommonModule } from '@angular/common';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { getDisplayStyleByFieldId } from 'src/app/shared/functions/helpers/display-style-helpers';
import { hideRemovedOptionsWhenFieldEnabled } from '../../../functions/hide-removed-options';
import { CollectionFormFieldValue } from 'src/models/ts/collection-form-field-value.model';

/**
 * Represents a control that allows the user to select a single option from a group of options.
 * Utilizes kendoRadioButton.
 */
@Component({
  selector: 'bizz-radio-group-control',
  standalone: true,
  imports: [RadioButtonModule, CommonModule, ReactiveFormsModule, NgClass, NgIf, NgForOf, AsyncPipe, TooltipComponent],
  templateUrl: './radio-group-control.component.html',
  styleUrls: ['./radio-group-control.component.scss']
})
export class RadioGroupControlComponent extends BaseFormControlComponent {
  public backgroundColor = '';
  public foregroundColor = '';
  public displayValues:CollectionFieldDisplayStyleValueDto[] = [];
 /**
   * All available options for the radiogroup.
   * @type {CollectionFormFieldValue[]}
   */
 public data: CollectionFormFieldValue[] = [];
 
  public override ngOnInit(): void {
    super.ngOnInit();
    let field = this.formFieldSignal();
    if(field) {
      field.FieldValues.forEach((value) => {
        let displayValue = getDisplayStyleByFieldId(field.DisplayStyleValues, value.CollectionFieldValuesID);
        if(displayValue)
          this.displayValues.push(displayValue);
      });
      this.data = hideRemovedOptionsWhenFieldEnabled(field.FieldValues, field.Value);
    }
  }

  // TODO: implement setvalue in the ngOnInit for radio group (how is radio group value passed?)
   // Implement the abstract member from RadioGroupControlComponent
   protected override focus(): void {
    // Add your implementation here
  }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { FolderInstanceTargetType } from '../../../../../../models/ts/folder-instance-target-type.model';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { GridContextPermissions } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/grid-context-permissions';
import { GridService } from '../../../../../features/bizzmine/widgets/collection-list-widget/services/grid/grid.service';
import { ContextMenuService } from '../../../../services/context-menu/context-menu.service';
import { Store } from '@ngrx/store';
import { trainingActions } from '../../../../../store/features/training/training-actions';
import { Router } from '@angular/router';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { TaskService } from '../../../../services/tasks/task.service';
import { Dialog } from '@angular/cdk/dialog';
import { TrainingApiService } from '../../../../../api/bizzmine/training/training-api.service';
import {
  TrainingSubscribeModalComponent,
  TrainingSubscribeModalComponentMode
} from '../../../../../features/training/modal/training-subscribe-modal/training-subscribe-modal.component';
import { TrainingDto } from '../../../../../../models/ts/training-dto.model';


/**
 * Cell with actions (read, edit, execute, menu,...) for an instance.
 */
@Component({
  selector: 'bizz-grid-cell-actions',
  standalone: true,
  imports: [CommonModule,
    IconComponent,
    TooltipsModule,
    TranslatePipe],
  templateUrl: './grid-cell-actions.component.html',
  styleUrls: ['./grid-cell-actions.component.scss']
})
export class GridCellActionsComponent extends GridCellBaseComponent<unknown>
  implements OnInit {
  @ViewChild('menuIcon') public menuIcon!: ElementRef;
  @Input() public set readOnly(value: boolean) {
    this._readonly = value;
    this.onDataChanged();
  }

  private _readonly: boolean = false;

  protected permissions: GridContextPermissions;

  public constructor(private gridService: GridService, private contextMenuService: ContextMenuService,
                     private store$: Store, private router: Router,
                     private taskService: TaskService,
                     private trainingApiService: TrainingApiService,
                     private dialog: Dialog) {
    super();
  }

  public ngOnInit(): void {
    this.permissions = new GridContextPermissions();
    this.onDataChanged();
  }

  public override onDataChanged(): void {
    if(this.permissions && this.metaData && this.data) {
      this.permissions.loadActionPermissions(this.metaData, this.data, this._readonly);
      this.permissions.loadMenuPermissions(this.data, this._readonly);
    }
  }

  protected onOpenClicked(event: MouseEvent): void {
    const newTab = event && (event.ctrlKey || event.metaKey);
    this.gridService.readInstance(this.data.CollectionsID, this.data.ID, this.data.VersionsID, this.data.GuidChecksum, this.data.DraftsID, this.data.OriginalFoldersID, true, this.metaData.trashFilterActive, newTab);
  }

  protected onActionMenuIconClick(event: MouseEvent): void {
    this.contextMenuService.open(this.permissions, this.data, this.menuIcon, true, this.metaData.widgetId);
  }

  protected onEditClicked(event: MouseEvent): void {
    const newTab = event && (event.ctrlKey || event.metaKey);
    this.gridService.editInstance(this.data.CollectionsID, this.data.ID, this.data.VersionsID, this.data.GuidChecksum, this.data.DraftsID, this.data.OriginalFoldersID, undefined, undefined, newTab);
  }

  protected onActionClicked(event: MouseEvent): void {
    this.taskService.executeTask({
      collectionId: this.data.CollectionsID!,
      instanceId: this.data.ID,
      taskId: this.data.TasksID!, //was TaskID... TODO: Ask Jonas about this
      versionId: this.data.VersionsID,
      draftId: this.data.DraftsID
    });
  }

  protected onRecoverInstance(event: MouseEvent): void {
    this.gridService.restoreInstance(this.data);
  }

  protected onSubscribeToTraining(event: MouseEvent): void {
    this.trainingApiService.getTraining({
      collectionId: this.data.CollectionsID!,
      versionId: this.data.VersionsID!,
      instanceId: this.data.ID!
    }).subscribe(found => {
      this.dialog.open<TrainingDto | undefined>(TrainingSubscribeModalComponent, {
        data: {
          data: found,
          enableNoPreference: true,
          enableCreateNewSession: false,
          mode: TrainingSubscribeModalComponentMode.Subscribe
        }
      })
        .closed
        .subscribe((result: TrainingDto | undefined) => {
          if (result !== undefined) {
            this.addToQueue(found, result?.ID);
          }
        });
    });
  }

  public addToQueue(source: TrainingDto, sessionId: number | null): void {
    this.trainingApiService.subscribeToTraining({
      trainingId: source.ID,
      sessionId: sessionId ?? 0,
      trainingAppId: source.TrainingAppID
    }).subscribe(() => {
    });
  }

  protected onSubscribeToExam(event: MouseEvent): void {

    this.store$.dispatch(trainingActions.fetchExam({
      collectionsId: <number>this.data.CollectionsID,
      versionsId: <number>this.data.VersionsID,
      config: {
        openExamDialog: true,
        redirectUrl: this.router.url
      }
    }));
  }

  protected onForwardTask(event: MouseEvent): void {
    throw new Error('ForwardTask not implemented');
  }

  protected onFolderShortcutClicked($event: MouseEvent) {
    throw new Error('ForwardTask not implemented');
  }

  protected readonly FolderInstanceTargetType = FolderInstanceTargetType;


}

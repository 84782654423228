import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { catchError, combineLatestWith, exhaustMap, map, mergeMap, of, tap } from 'rxjs';
import { selectForm, selectFormField } from '../forms-selectors';
import { Store } from '@ngrx/store';
import { LookupService } from 'src/app/shared/services/lookup/lookup.service';
import { CollectionFormField, Record } from '../../../../../models/ts/collection-form-field.model';
import { LinkedCollectionType } from '../../../../../models/ts/linked-collection-type.model';
import { ViewDataSource } from '../../../../../models/ts/view-data-source.model';
import { CollectionFormLookupApiService } from '../../../../api/bizzmine/collection-form-lookup/collection-form-lookup-api.service';
import { StoreCollectionFormService } from '../../../../features/bizzmine/form/services/store-collection-form.service';
import { CollectionFormFieldGridLookupData } from '../../../../../models/ts/collection-form-field-grid-lookup-data.model';
import { UpdateDeleteState } from '../../../../../models/ts/update-delete-state.model';
import { ViewDataSourcesInstance } from '../../../../../models/ts/view-data-sources-instance.model';
import { concatLatestFrom } from '@ngrx/operators';
import { CollectionFormLinkedService } from '../../../../features/bizzmine/form/services/collection-form-linked.service';

/**
 * Effects specific to 1xN grid controls
 */
@Injectable()
export class FormsGridEffects {
  private actions$ = inject(Actions);
  public emptyRowAddedToGrid$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.addRowsToGrid),
    map(({ formId, gridFieldId }) => ({
      type: formsActions.rowsAddedToGrid.type,
      formId,
      gridFieldId
    }))
  ));
  private store$ = inject(Store);
  private lookupService = inject(LookupService);
  public getEmptyRowForGrid$ = createEffect(() => this.actions$.pipe(
      ofType(formsActions.addEmptyRowToGrid),
      concatLatestFrom((props) => [
        this.store$.select(selectFormField(props.formId, props.gridFieldId)),
        this.store$.select(selectForm(props.formId))
      ]),
      exhaustMap(([props, field, form]) => this.lookupService.getEmptyGridRecord(form!.data.CollectionFormId, structuredClone(field)).pipe(
        tap(record => {
          if (field !== undefined && form !== undefined) {
            let newId = LookupService.getNextNewRecordId(field.Records ?? []);
            record.CrossLinkedInstancesID = newId;
            record.RowDataDesignCrossID = newId;
            record.EditMode = true;
          } else throw new Error(`Field ${props.gridFieldId} and/or form ${props.formId} are undefined`);
        }),
        map(record => ({
          type: formsActions.getEmptyRowForGridSucceeded.type,
          formId: props.formId,
          gridFieldId: props.gridFieldId,
          emptyRow: record
        })),
        catchError((error: Error) => of(formsActions.getEmptyRowForGridFailed({
            formId: props.formId,
            error: error
          }))
        ))
      )
    )
  );
  private addEmptyRowToGrid$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getEmptyRowForGridSucceeded),
    map(props => ({
      type: formsActions.addRowsToGrid.type,
      formId: props.formId,
      gridFieldId: props.gridFieldId,
      records: [props.emptyRow]
    }))
  ));
  private collectionFormLookupApiService = inject(CollectionFormLookupApiService);
  public addViewDataInstanceToGrid$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.addViewDataInstanceToGrid),
    mergeMap((data: {
      formId: string,
      instanceId: number,
      versionId: number,
      viewDataSourceId: number,
      linkedCollectionType: LinkedCollectionType,
      originalChildInstancesId: number,
      crossLinkInstancesId?: number,
      viewDataSources: Array<ViewDataSource>,
      targetGridField: CollectionFormField,
      row: Record | undefined,
      isNewInstanceOnLookup: boolean
    }) => {
      const form = this.store$.selectSignal(selectForm(data.formId))();
      if (form == null) {
        throw new Error(`Form with id ${data.formId} not found`);
      }
      return this.collectionFormLookupApiService.getFormFieldValuesByLookupField(form.data.CollectionFormId, data.viewDataSourceId, data.linkedCollectionType, data.instanceId, data.versionId).pipe(combineLatestWith(of(data)));
    }, 1),
    tap(([lookupData, request]) => {
      const form = this.store$.selectSignal(selectForm(request.formId))();
      if (form == null) {
        throw new Error(`Form with id ${request.formId} not found`);
      }
      const lookupMultiple = lookupData as CollectionFormFieldGridLookupData;
      let rowId = -1;
      const field = this.store$.selectSignal(selectFormField(request.formId, request.targetGridField.Id))();
      if (field?.Records != null && field.Records.length > 0) {
        const lowestIdFound = Math.min(...(field.Records.map(r => r.RowDataDesignCrossID)));
        if (lowestIdFound < 0) {
          rowId = lowestIdFound - 1;
        }
      }
      this.store$.dispatch(formsActions.addRowsToGrid({
        formId: request.formId,
        gridFieldId: request.targetGridField.Id,
        records: [{
          Fields: lookupMultiple.FormFields,
          State: UpdateDeleteState.Update,
          CrossLinkedInstancesID: rowId,
          RowDataDesignCrossID: rowId,
          EditMode: false
        }]
      }));

      const vds = form.data.ViewDataSources.find(vds => vds.ViewDataSourcesID === request.viewDataSourceId);
      if (vds != null) {
        const clonedVds = structuredClone(vds);
        clonedVds.Instances.push({
          OriginalChildInstancesID: request.originalChildInstancesId,
          ChildVersionsID: request.versionId,
          ChildInstancesID: request.instanceId,
          State: UpdateDeleteState.Update,
          CrossLinkedInstancesID: rowId,
          RowDataDesignCrossID: rowId
        } as ViewDataSourcesInstance);
        this.store$.dispatch(formsActions.updateFormViewDataSources({
          formId: request.formId,
          viewDataSources: [clonedVds]
        }));
      }
    })
  ), { dispatch: false });
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private sidebarExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sidebarUnPinned: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public set unpinned(unPinned: boolean) {
    this.sidebarUnPinned.next(unPinned);
  }

  public set expanded(expanded: boolean) {
    this.sidebarExpanded.next(expanded);
  }

  public get expanded(): boolean {
    return this.sidebarExpanded.getValue();
  }

  public get unpinned(): boolean {
    return this.sidebarUnPinned.getValue();
  }

  public toggleSidebar(): void {
    this.expanded = !this.expanded;
    this.unpinned = !this.unpinned;
  }

  public toggleUnPinSidebar(): void {
    this.unpinned = !this.unpinned;
  }

  public collapseSidebar(): void{
    this.expanded = false;
    this.unpinned = true;
  }

  public unCollapseSidebar(): void{
    this.expanded = true;
  }

  public resetSidebar(): void{
    this.expanded = true;
    this.unpinned = false;
  }

  public getObservable(): Observable<boolean> {
    return this.sidebarExpanded.asObservable();
  }
  public getUnpinnedObservable(): Observable<boolean> {
    return this.sidebarUnPinned.asObservable();
  }
}

import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { FormFieldType } from 'src/models/ts/form-field-type.model';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { TrnSkillGradeExpiracyStatus } from '../../../../../../../models/ts/trn-skill-grade-expiracy-status.model';
import { CheckBoxModule } from '@progress/kendo-angular-inputs';
import { ArcGaugeModule, CircularGaugeModule } from '@progress/kendo-angular-gauges';
import { FormTrainingappAssessmentSliderComponent } from '../../form-trainingapp-assessment/form-trainingapp-assessment-slider/form-trainingapp-assessment-slider.component';
import { FormsModule } from '@angular/forms';
import { GridModule, HeaderModule, SharedModule } from '@progress/kendo-angular-grid';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { GridComponent } from '@progress/kendo-angular-grid/grid.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { asyncScheduler, distinctUntilChanged, filter, map, switchMap, take, tap, zip } from 'rxjs';
import { TrnSkillGradeDto } from '../../../../../../../models/ts/trn-skill-grade-dto.model';
import { UserType } from '../../../../../../../models/ts/user-type.model';
import { AuthService } from '../../../../../../core/services/auth/auth.service';
import { TrnFieldProperties } from '../../../../../../../models/ts/trn-field-properties-dto.model';
import { TrainingSubscribedUserDeleteDialogComponent } from './training-subscribed-user-delete-dialog/training-subscribed-user-delete-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import {
  selectForm,
} from '../../../../../../store/features/forms/forms-selectors';
import { ProtectedCollectionType } from '../../../../../../../models/ts/protected-collection-type.model';
import { UpdateDeleteState } from '../../../../../../../models/ts/update-delete-state.model';
import { TrainingApiService } from '../../../../../../api/bizzmine/training/training-api.service';
import { OrgChartItem } from '../../../../org-chart/interfaces/org-chart-item';
import { OrgChartModalComponent } from '../../../../org-chart/org-chart-modal/org-chart-modal.component';
import { Actions, ofType } from '@ngrx/effects';
import { refreshActions } from '../../../../../../store/features/refresh/refresh-actions';
import { OrganizationChartItemType } from '../../../../../../../models/ts/organization-chart-item-type.model';
import { AdditionalModes, OrgChartView } from '../../../../org-chart/org-chart.component';
import { DialogModalModelMode } from '../../../../../../shared/components/modals/dialog-modal/dialog-modal-model';
import { DialogModalComponent } from '../../../../../../shared/components/modals/dialog-modal/dialog-modal.component';
import { formsActions } from '../../../../../../store/features/forms/forms-actions';
import { SafePipe } from 'safe-pipe';

@Component({
  selector: 'bizz-training-subscribed-users-control',
  standalone: true,
  imports: [
    CheckBoxModule,
    CircularGaugeModule,
    FormTrainingappAssessmentSliderComponent,
    FormsModule,
    GridModule,
    HeaderModule,
    IconComponent,
    SharedModule,
    TranslatePipe,
    ArcGaugeModule,
    SafePipe
  ],
  templateUrl: './training-subscribed-users-control.component.html',
  styleUrl: './training-subscribed-users-control.component.scss'
})
export class TrainingSubscribedUsersControlComponent extends BaseFormControlComponent implements OnInit, AfterViewInit {
  public FormFieldType = FormFieldType;
  public data: CollectionFormField;
  public colors = [
    {
      from: 0,
      color: '#3fad10'
    }
  ];
  public UserType = UserType;
  protected readonly ExpiracyStatus = TrnSkillGradeExpiracyStatus;
  @ViewChild('trainingSubscribedUsersKendoGrid', { read: ElementRef })
  private kendoGridElement: ElementRef;
  @ViewChild('trainingSubscribedUsersKendoGrid')
  private kendoGridComponent: GridComponent;
  private totalGridWidth: number;
  private combinedWidthOfOtherColumns: number;
  private initialLastColumnWidth: number;

  public constructor(private authService: AuthService, private dialog: Dialog, private trainingApiService: TrainingApiService, private actions$: Actions) {
    super();
    this.actions$.pipe(
      ofType(refreshActions.refreshSkillGrades),
      distinctUntilChanged(),
      filter(props => props?.trainingAppId != null && props.trainingAppId > 0),
      tap(props => {
        this.data.TrainingAppsID = props.trainingAppId;
      }),
      switchMap(props => trainingApiService.getSkillGrades({trainingAppId: props.trainingAppId})),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((skillgrades) => {
      this.data.TrnSkillGrades = skillgrades;
    });
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.actions$.pipe(ofType(formsActions.updateFormFieldSubscribedUsers), takeUntilDestroyed(this.destroyRef)).subscribe((action) => {
      if (action.fieldId == this.formFieldId()) {
        this.data = structuredClone(this.formFieldSignal()!);
      }
    });

    const field = this.formFieldSignal();
    if (field) {
      this.data = structuredClone(field);
    }
  }

  public ngAfterViewInit(): void {
    asyncScheduler.schedule(() => {
      this.kendoGridComponent.columnResize.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.resizeLastGridColumn();
      });
      this.resizeLastGridColumn();
    });
  }

  public delete(trainee: TrnFieldProperties): void {
    this.dialog.open<boolean | undefined>(TrainingSubscribedUserDeleteDialogComponent, {
      data: { trainee }
    })
      .closed.subscribe((result) => {
      if (result != undefined) {
        if (result) {
          this.data.TrnDeletedUsers = this.data.TrnDeletedUsers ?? [];
          this.data.TrnDeletedUsers.push(trainee);
        }
        this.data.TrnUsers = this.data.TrnUsers.filter(x => x.UsersID != trainee.UsersID);
        this.data.OrgChartUnitSelector = this.data.OrgChartUnitSelector.filter(x => x.VersionId != trainee.UsersID);
        this.emitChanged();
      }
    });
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.resizeLastGridColumn();
  }

  public getScore(skillGradeId: number, defaultValue: boolean): TrnSkillGradeDto | null {
    if (!this.data || !this.data.TrnSkillGrades || this.data.TrnSkillGrades.length == 0) {
      return null;
    }
    const found = this.data.TrnSkillGrades.find(x => x.ID == skillGradeId);
    if (found) {
      return found;
    }
    if (defaultValue) {
      return this.data.TrnSkillGrades[0];
    }
    return null;
  }

  public addUser(): void {
    let hasTraining = false;
    const form = this.store$.selectSignal(selectForm(this.formId))();
    const viewDataSources = form?.data?.ViewDataSources;
    let training: number | undefined = undefined;
    if (viewDataSources) {
      const trainingInstances = viewDataSources.find(x => x.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingTrainingsessions)?.Instances;

      if (trainingInstances == null || trainingInstances.length == 0) {
        hasTraining = false;
      } else {
        const trainingInstance = trainingInstances.find(el => el.State != UpdateDeleteState.Delete);

        if (trainingInstance == null) {
          hasTraining = false;
        } else {
          hasTraining = true;
          training = trainingInstance.ChildVersionsID;
        }
      }

      if (hasTraining) {
        this.dialog.open<Array<OrgChartItem>>(OrgChartModalComponent, { data: {allowedItemTypes: [OrganizationChartItemType.User], additionalModes: [{additionMode: AdditionalModes.TRAINING_QUEUE, data: {trainingId: training!, trainingAppId: this.data.TrainingAppsID}}],activeView: OrgChartView.TRAINEES} })
          .closed.subscribe((result) => {
          if (result && training) {
            const trainees$ = result.filter(r => this.data.TrnUsers.find(t => t.UsersID == r.VersionId) == undefined).map(user => this.trainingApiService.getTrainee({
              userId: user.VersionId,
              trainingAppId: this.data.TrainingAppsID,
              skillVersionId: training!
            }).pipe(take(1), map(found => {return {trainee:found, user}})));
            zip(trainees$).subscribe(found => {
              found.forEach(({trainee, user}) => {
                this.data.TrnUsers.push({
                  UsersID: user.VersionId,
                  Expiracy: trainee?.Expiracy,
                  Grade: trainee?.SkillGradeID,
                  Name: user.Name,
                  Present: true,
                  Remarks: ''
                });

                this.data.OrgChartUnitSelector.push(user);
              });
              this.emitChanged();
            });
          }
        });

      } else {
        this.dialog.open(DialogModalComponent, {data: { mode: DialogModalModelMode.simple, title: 'NoTraining', message: 'NoTrainingFound', confirmationtext: '', showCancelButton: true}});

      }
    }
  }

  private emitChanged(): void {
    const fieldId = this.formFieldId();
    if(fieldId){
      this.store$.dispatch(formsActions.updateFormFieldSubscribedUsers({
        formId: this.formId,
        fieldId: fieldId,
        users: this.data.TrnUsers,
        deletedUsers: this.data.TrnDeletedUsers,
        orgChart: this.data.OrgChartUnitSelector
      }));
    }

  }

  private resizeLastGridColumn(): void {
    if (this.kendoGridElement == undefined) {
      throw new Error('kendoGridElement is undefined');
    }
    this.totalGridWidth = this.kendoGridElement.nativeElement.offsetWidth;
    this.combinedWidthOfOtherColumns = this.kendoGridComponent.columns.toArray().slice(0, -1).reduce(
      (accumulator, currentObject) => {
        return accumulator + currentObject.width;
      }, 0);
    if (this.kendoGridComponent.columns.last != undefined) {
      if (this.initialLastColumnWidth == undefined)
        this.initialLastColumnWidth = this.kendoGridComponent.columns.last.width + 10;

      if (this.totalGridWidth > this.combinedWidthOfOtherColumns)
        this.kendoGridComponent.columns.last.width =
          Math.max(this.totalGridWidth - this.combinedWidthOfOtherColumns, this.initialLastColumnWidth);
    }
  }
   // Implement the abstract member from TrainingSubscribedUsersControlComponent
   protected override focus(): void {
    // Add your implementation here
  }
}

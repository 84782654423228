export enum LicenseKeyType {
	DocumentControl = 1,
	Workflow = 2,
	WorkflowRegAgent = 3,
	WorkflowSyncAgent = 4,
	ADAgent = 5,
	PDFGenerator = 6,
	DocumentImport = 7,
	Scheduler = 8,
	Training = 9,
	Branding = 10,
  AI = 16,
}
import { Component } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { IconComponent } from '../ui/icon/icon.component';

@Component({
  selector: 'bizz-expand-collapse-icon',
  templateUrl: './expand-collapse-icon.html',
  styleUrls: ['./expand-collapse-icon.scss'],
  imports: [
    NgIf,
    AsyncPipe,
    IconComponent,
    NgClass,
  ],
  standalone: true,
})
export class ExpandCollapaseIcon {
  public unPinned = this.sidebarService.getUnpinnedObservable();

  public setPinSidebarState (): void{
    this.sidebarService.toggleUnPinSidebar();
  }

  public constructor(
    private sidebarService: SidebarService,
  ) {
  }
}

<div class="flex gap-2 border-b-2 border-sidebar bg-page-header p-4 sm:px-5 sm:py-2.5 lg:gap-4">
    <div class="button-group" #propertiesAnchor
         [class.hidden]="!buttonStates.showDropdownButton || userType == UserType.AnonymousUser || userType == UserType.ExternalUser">
        <button type="button" class="form-header-button default" (click)="togglePropertiesPopup($event)">
            <bizz-icon class="h-4 w-4" iconName="gear"></bizz-icon>
            <span class="hidden sm:block">{{ 'Properties' | translate }}</span>
            <bizz-toggle-arrow class="ml-1.5 h-4 w-4"
                               [direction]="showPropertiesPopup ? 'up' : 'down'"></bizz-toggle-arrow>
        </button>
    </div>

    <!-- Properties dropdown -->
    @if (showPropertiesPopup) {
        <kendo-popup #propertiesPopup class="z-[1004] w-full sm:w-auto maxw-sm:!left-0"
                     [margin]="{ horizontal: 0, vertical: 5 }" [anchor]="propertiesAnchor" [collision]="popupCollision"
                     [anchorAlign]="popupAnchorAlignProperties">
            <div class="bizz-popup-content">
                <button (click)="copyLinkToRecord()">
                    <bizz-icon [title]="('CopyLinktoInstance' | translate)" class="mr-2 h-5 w-5 sm:h-4 sm:w-4"
                               [hover]="true" iconName="link">
                    </bizz-icon>
                    {{ 'CopyLinktoInstance' | translate }}
                </button>
                <hr class="my-1.5 block w-full"/>

                @if (buttonStates.showPermissionButton) {
                    <button (click)="openPermissions()">
                        <bizz-icon [title]="'Permissions' | translate" class="mr-2 h-5 w-5 sm:h-4 sm:w-4" [hover]="true"
                                   iconName="lock">
                        </bizz-icon>
                        {{ 'Permissions' | translate }}
                    </button>
                }

                @if (buttonStates.showPropertiesButton) {

                    <button (click)="openDocumentSettings()">
                        <bizz-icon [title]="'DocumentSettings' | translate" class="mr-2 h-5 w-5 sm:h-4 sm:w-4"
                                   [hover]="true"
                                   iconName="gear">
                        </bizz-icon>
                        {{ 'DocumentSettings' | translate }}
                    </button>
                    <hr class="my-1.5 block w-full"/>

                }

                @if (buttonStates.showFlowStatusButton) {
                    <button (click)="openFlowStatus()">
                        <bizz-icon [title]="'FlowStatus' | translate" class="mr-2 h-5 w-5 sm:h-4 sm:w-4" [hover]="true"
                                   iconName="chart-line">
                        </bizz-icon>
                        {{ 'FlowStatus' | translate }}
                    </button>
                }
                @if (buttonStates.showMonitorButton) {
                    <button (click)="openMonitor()">
                        <bizz-icon [title]="'Monitor' | translate"
                                   class="mr-2 h-5 w-5 sm:h-4 sm:w-4" [hover]="true"
                                   iconName="display">
                        </bizz-icon>
                        {{ 'Monitor' | translate }}
                    </button>
                }

                @if (buttonStates.showHistoryButton) {
                    <button (click)="openHistory()">
                        <bizz-icon [title]="'History' | translate" class="mr-2 h-5 w-5 sm:h-4 sm:w-4" [hover]="true"
                                   iconName="clock-rotate-left">
                        </bizz-icon>
                        {{ 'History' | translate }}
                    </button>
                }

                @if (buttonStates.showTrackChangesButton) {
                    <button (click)="openTrackChanges()">
                        <bizz-icon [title]="'TrackChanges' | translate" class="mr-2 h-5 w-5 sm:h-4 sm:w-4"
                                   [hover]="true"
                                   iconName="clock-rotate-left">
                        </bizz-icon>
                        {{ 'TrackChanges' | translate }}
                    </button>
                }

                @if (buttonStates.showArchiveButton) {
                    <button (click)="openArchive()">
                        <bizz-icon [title]="'Archive' | translate" class="mr-2 h-5 w-5 sm:h-4 sm:w-4" [hover]="true"
                                   iconName="box-archive">
                        </bizz-icon>
                        {{ 'Archive' | translate }}
                    </button>
                }

                @if (buttonStates.showReportButton) {
                    <hr class="my-1.5 block w-full"/>

                    <button (click)="openReport()">
                        <bizz-icon [title]="'Report' | translate" class="mr-2 h-5 w-5 sm:h-4 sm:w-4" [hover]="true"
                                   iconName="file-pdf">
                        </bizz-icon>
                        {{ 'DownloadReport' | translate }}
                    </button>
                }
            </div>
        </kendo-popup>
    }

    @if ((this.userType != UserType.AnonymousUser && userType != UserType.ExternalUser) || ((this.userType == UserType.AnonymousUser || userType == UserType.ExternalUser) && form.TasksID == 0)) {
        <button class="form-header-button default ml-auto" (click)="cancel()">
            @if (readMode) {
                <bizz-icon class="h-4 w-4" iconName="caret-left"></bizz-icon>
            }
            <span>{{ (readMode ? 'Back' : 'Cancel') | translate }}</span>
        </button>
    } @else {
        <!--empty div to keep the default styling applied-->
        <div class="form-header-button default ml-auto h-9">
        </div>
    }

    @if (buttonStates.showEditButton) {
        <button class="form-header-button default" (click)="editClicked.emit()">
            <bizz-icon class="h-4 w-4" iconName="pen-circle"></bizz-icon>
            {{ 'Edit' | translate }}
        </button>
    }

    @if (buttonStates.showSaveCloseStepButton) {
        <div class="button-group" #saveAnchor>
            <button class="form-header-button save" type="button" (click)="submit(true, true)" [disabled]="formGroup.invalid">
                <bizz-icon class="h-4 w-4" iconName="circle-check"></bizz-icon>
                <span class="leading-none">{{
                        buttonStates.showExecute ? (this.userType == UserType.AnonymousUser || userType == UserType.ExternalUser ? 'Submit' : 'Execute') : (this.userType == UserType.AnonymousUser ? 'Submit' : 'SaveAndCloseStep') | translate
                    }}</span>
            </button>

            @if (userType != UserType.AnonymousUser) {
                <button class="form-header-button save" type="button" (click)="toggleSavePopup($event)" [disabled]="formGroup.invalid">
                    <bizz-toggle-arrow class="h-4 w-4" [direction]="showSavePopup ? 'up' : 'down'"></bizz-toggle-arrow>
                </button>
            }
        </div>
        @if (showSavePopup) {
            <kendo-popup #savePopup class="z-[1004]" [margin]="{ horizontal: 0, vertical: 5 }"
                         (anchorViewportLeave)="toggleSavePopup($event)" [anchor]="saveAnchor"
                         [anchorAlign]="popupAnchorAlignSave"
                         [popupAlign]="popupAlign"
                         >
                <div class="bizz-popup-content text-nowrap bg-green-500 text-white">
                    @if (userType != UserType.AnonymousUser && userType != UserType.ExternalUser) {
                        <button (click)="submit(false, true)" [disabled]="formGroup.invalid">
                            {{ 'SaveAndClose' | translate }}
                        </button>
                    }

                    <button (click)="submit(false, false)" [disabled]="formGroup.invalid">
                        {{ 'Save' | translate }}
                    </button>

                    @if (form.CanForwardTasks && (form.byCrossLinkCollectionsID == undefined || form.byCrossLinkCollectionsID <= 0) && form.TaskInfo != undefined && userType != UserType.AnonymousUser && userType != UserType.ExternalUser) {
                        <button (click)="forwardTask()" [disabled]="formGroup.invalid">
                            {{ 'ForwardTask' | translate }}
                        </button>
                    }
                </div>
            </kendo-popup>
        }
    }

    @if (buttonStates.showSaveButton) {
        <div class="button-group" #saveAnchor>
            <button class="form-header-button save" type="button" [disabled]="formGroup.invalid" (click)="submit(false, true)">
                <bizz-icon class="h-4 w-4" iconName="circle-check"></bizz-icon>
                <span>{{ 'SaveAndClose' | translate }}</span>
            </button>
            @if (userType != UserType.AnonymousUser && dataItem.options?.parentFormId == undefined) {
                <button class="form-header-button save" type="button" (click)="toggleSavePopup($event)" [disabled]="formGroup.invalid">
                    <bizz-toggle-arrow class="h-4 w-4" [direction]="showSavePopup ? 'up' : 'down'"></bizz-toggle-arrow>
                </button>
            }
        </div>
        @if (showSavePopup) {
            <kendo-popup #savePopup class="z-[1004]" [margin]="{ horizontal: 0, vertical: 5 }"
                         (anchorViewportLeave)="toggleSavePopup($event)" [anchor]="saveAnchor"
                         [anchorAlign]="popupAnchorAlignSave"
                         [popupAlign]="popupAlign"
                         >
                <div class="bizz-popup-content bg-green-500 text-white">
                    <button (click)="submit(false, false)" [disabled]="formGroup.invalid">
                        {{ 'Save' | translate }}
                    </button>
                </div>
            </kendo-popup>
        }
    }

</div>
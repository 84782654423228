<div class="flex flex-row justify-between px-4 pb-4 pt-2.5">
    <div class="flex items-center gap-2 text-sm font-semibold">
        <div class="flex items-center" [innerHTML]="element.Caption"></div>
        @if (element && element.IsRequired) {
            <span class="relative -top-1 h-5 text-xl leading-tight text-red-400">*</span>
        }
        @if (element && element.Hint) {
            <bizz-tooltip class="ml-1" [text]="element.Hint"></bizz-tooltip>
        }
        @if (errorState?.Message; as errorMessage) {
            <bizz-tooltip
                    class="relative -top-2 inline-flex h-5 cursor-pointer text-xl leading-4 text-red-400"
                    [text]="errorMessage"></bizz-tooltip>
        }
    </div>
    <button class="default group text-button" (click)="selectMailTemplate()"
            [disabled]="(formData$ | async)?.IsLocked || element.IsReadOnly">
        <bizz-icon
                class="header-popup-icon text-button group-hover:text-blue-500"
                iconName="envelope">
        </bizz-icon>
        <span class="group-hover:text-blue-500">{{ "CreateMail" | translate }}</span>
    </button>
</div>

<div class="flex flex-row justify-between">

    @if (gridOptions$ | async; as go) {
        <bizz-grid class="bizz-grid flex h-full max-w-full flex-col overflow-hidden rounded"
                   [currentPage]="filter$.value.Page"
                   [loading]="isLoading"
                   [gridOptions]="gridOptions"
                   [totalItems]="totalItems"
                   [autoFitColumns]="true"
                   [gridData]="(data$ | async) ?? []"
                   (cellAction)="onCellAction($event)"
                   (pageChange)="onPageChange($event)"
                   [showPager]="true"
                   (sortChange)="onSortChange($event)"
                   (groupChange)="onGroupChange($event)">

        </bizz-grid>
    }
</div>
import { GridParentFilter } from './../../../../../../../models/ts/grid-parent-filter.model';
import { Component, computed, inject, OnInit } from '@angular/core';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { FormCollectionListComponent } from '../../form-collection-list/form-collection-list.component';
import { ViewContainerRefDirective } from 'src/app/shared/directives/view-container-ref.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { TableViewWidgetData } from 'src/models/ts/table-view-widget-data.model';
import { selectForm } from 'src/app/store/features/forms/forms-selectors';
import { LoaderComponent } from '../../../../../../shared/components/ui/loader/loader.component';
import { map, Observable, shareReplay } from 'rxjs';
import { CommonModule } from '@angular/common';
import { concatLatestFrom } from '@ngrx/operators';
import { StrictlyProtectedCollectionTypes } from '../../../../../../shared/constants/strictly-protected-collections';

@Component({
  selector: 'bizz-form-linked-collection-control',
  templateUrl: './form-linked-collection-control.component.html',
  styleUrls: ['./form-linked-collection-control.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ViewContainerRefDirective,
    LoaderComponent,
    FormCollectionListComponent
  ]
})
export class FormLinkedCollectionControlComponent extends BaseFormControlComponent implements OnInit {

  public openLinksInStack = true;
  public parentFilter: GridParentFilter | undefined;
  public readOnly = computed(() => (this.formFieldSignal()?.IsReadOnly ?? false) || this.readOnlyOverride);
  public widgetData$: Observable<TableViewWidgetData> | undefined;

  private collectionListApiService = inject(CollectionListApiService);

  public override ngOnInit(): void {
    super.ngOnInit();
    const field = this.formFieldSignal();
    if (field) {
      this.store$.select(selectForm(this.formId)).pipe(
        takeUntilDestroyed(this.destroyRef),
        map((form) => {
          if (form) {
            this.parentFilter = {
              CollectionsID: field.CollectionsID,
              CrosslinkCollectionsID: field.CrossLinkedCollectionsID,
              ChildInstancesID: form.data.InstancesID, // current instance
              //OriginalChildInstancesID: this.instancesId, // same as child because it's alwasy relational
              ChildVersionsID: form.data.VersionsID //  versionsId
            };
          }
        })
      ).subscribe();

      this.widgetData$ = this.collectionListApiService.getListOptions(
        field.CollectionsID,
        field.ViewsID,
        field.ViewListsID
      ).pipe(
        concatLatestFrom(() => this.store$.select(selectForm(this.formId))),
        takeUntilDestroyed(this.destroyRef),
        map(([widgetData, form]) => {
          widgetData.ShowCaption = true;
          widgetData.Caption = field.Caption;
          widgetData.ListOptions.Records = field.ItemCount;
          widgetData.CanCreate = form !== undefined ? (form.data.InstancesID > 0 && !StrictlyProtectedCollectionTypes.includes(form.data.ProtectedCollectionType)) : false;
          return widgetData;
        }),
        shareReplay(1));
    }
  }

  protected override focus(): void {
    // Add your implementation here
  }
}

<!-- Screen -->
<div class="hidden h-12 w-full justify-between bg-header sm:flex">
    <div class="flex w-64 gap-2 pl-4">
        @if (mode() === HeaderComponentMode.DEFAULT || mode() === HeaderComponentMode.TRAINING) {
            <bizz-brand-logo (click)="brandClicked($event)" class="h-full max-h-12 w-auto cursor-pointer text-header outline-0">
            </bizz-brand-logo>
        } @else {
            <bizz-brand-logo class="h-full max-h-12 w-auto text-header outline-0">
            </bizz-brand-logo>
        }
        @if (environment.debug) {
            <span class="m-2 self-end text-xs font-semibold text-header">{{ environment.appVersion }}</span>
        }
    </div>
    <div class="flex items-center gap-8 pr-5">
        @if (mode() === HeaderComponentMode.DEFAULT) {
            <div class="flex items-center gap-6">
                <bizz-header-tasks></bizz-header-tasks>
                <bizz-header-notifications></bizz-header-notifications>
                <bizz-header-reminders></bizz-header-reminders>
            </div>
        }
        @if (!(mode() === HeaderComponentMode.EXTERNAL_USER) && !(mode() === HeaderComponentMode.ANONYMOUS_USER)) {
            @if (!(mode() === HeaderComponentMode.TRAINING)) {
                <div class="h-7 w-[3px] rounded-full bg-header-text"></div>
            }
            <div class="flex items-center gap-6">
                @if (!(mode() === HeaderComponentMode.TRAINING)) {
                    <bizz-header-help></bizz-header-help>
                }
                <bizz-header-user></bizz-header-user>
                @if (!(mode() === HeaderComponentMode.TRAINING)) {
                    <a class="h-5" href="{{settingsUri}}">
                        <bizz-icon [title]="'Settings' | translate"
                                   class="h-5 w-5 text-header transition-colors hover:text-header-hover" [hover]="true"
                                   iconName="gear"></bizz-icon>
                    </a>
                }
            </div>
        }

        @if (mode() === HeaderComponentMode.ANONYMOUS_USER) {
            @if (languageOptions$ | async; as languages) {
                @if (languages.length > 1) {
                    <kendo-dropdownlist [ngModel]="selectedLanguageDto" class="min-w-16" [data]="languages"
                                        textField="Code"
                                        valueField="ID" (valueChange)="changeLanguageSelection($event)">
                        <ng-template kendoDropDownListItemTemplate let-collectionView>
                            <span>{{ collectionView.Code }}</span>
                        </ng-template>
                    </kendo-dropdownlist>
                }
            }
        }
    </div>
</div>
<!-- Screen -->

<!-- Mobile -->
@if (mode() === HeaderComponentMode.DEFAULT || mode() === HeaderComponentMode.TRAINING) {
    <div class="flex h-12 w-full justify-between bg-header sm:hidden">
        <div class="flex items-center gap-5 pl-4">
            @if (mode() === HeaderComponentMode.DEFAULT) {
                <bizz-icon class="h-7 w-8 text-header" (click)="toggleSidebar()" iconName="bars"></bizz-icon>
            }
            @if (mode() === HeaderComponentMode.TRAINING) {
                <bizz-brand-logo [routerLink]="'/'" [logoType]="BrandLogoType.ICON"
                                 class="h-full max-h-8 w-auto text-header outline-0">
                </bizz-brand-logo>
            }
        </div>

        <div class="flex items-center gap-7 pr-4">
            @if (!(mode() === HeaderComponentMode.TRAINING)) {
                <bizz-header-tasks></bizz-header-tasks>
                <bizz-header-notifications></bizz-header-notifications>
                <bizz-header-reminders></bizz-header-reminders>
                <div class="h-7 w-[3px] rounded-full bg-header-text"></div>
            }
            <bizz-header-user></bizz-header-user>
        </div>
    </div>
}

@if (mode() === HeaderComponentMode.ANONYMOUS_USER) {
    <div class="flex h-12 w-full items-center justify-between bg-header px-4 sm:hidden">
        <bizz-brand-logo class="h-full max-h-12 w-auto text-header outline-0">
        </bizz-brand-logo>
        @if (languageOptions$ | async; as languages) {
            @if (languages.length > 1) {
                <kendo-dropdownlist [ngModel]="selectedLanguageDto" class="h-9 w-16" [data]="languages" textField="Code"
                                    valueField="ID" (valueChange)="changeLanguageSelection($event)">
                    <ng-template kendoDropDownListItemTemplate let-collectionView>
                        <span>{{ collectionView.Code }}</span>
                    </ng-template>
                </kendo-dropdownlist>
            }

        }
    </div>
}
<!-- Mobile -->
@if (hasCheckedAuth) {
    <div class="modal-container modal-md">
        <div class="modal-close-container">
            <bizz-icon iconName="xmark"
                       (click)="authenticated ? cancelEditOnline(true) : cancelAuthentication()"></bizz-icon>
        </div>
        <div class="modal-body gap-4 overflow-y-scroll" style="min-height: 25svh">
            <div class="modal-header">
                <h1>
                    {{ (data.mode == FileUploadEditOnlineModalComponentMode.View ? "DirectEdit_View" : "DirectEdit_Edit") | translate }} {{ "DirectEdit_Title" | translate }}
                </h1>
                @if (authenticated != true) {
                    <p>{{ "DirectEdit_Message1" | translate }}</p>
                }

                <hr class="m-auto mb-5 mt-5 h-0 w-full border-t-2 border-divider">
            </div>
            @if (errorMessage != null && errorMessage.trim() != "") {
                <div class="rounded bg-yellow-200/50 text-default">
                    <div class="m-3 flex items-center gap-2 rounded">
                        <bizz-icon class="size-6 text-yellow-500"
                                   iconName="triangle-exclamation"></bizz-icon>
                        <div class="flex flex-col gap-2">
                            <p>{{ errorMessage | translate }}</p>
                        </div>
                    </div>

                </div>
            }


            <div class="flex w-full flex-col">
                @if (authenticated != true) {
                    <div class="screen-banner">
                        <img class="sm:mx-5" />
                    </div>
                } @else {
                    <p>{{ "DirectEdit_Message3" | translate }}</p>
                }
                @if (data.mode == FileUploadEditOnlineModalComponentMode.Edit && data.docheckoutcheckin && oneDriveKey != null && oneDriveKey.trim() != '') {
                    <div class="form-group gap-4">
                        <label>{{ "ReasonModification" | translate }} <span class="text-red-500">*</span></label>
                        <kendo-editor
                                class="kendo-toolbar_hidden min-h-[calc(100svh_-_25rem)] min-w-full max-w-full sm:min-h-96"
                                [iframe]="false"
                                [resizable]="false"
                                [(ngModel)]="data.modificationreason">
                        </kendo-editor>
                    </div>
                }
            </div>

        </div>
        <div class="modal-buttons mb-5 pt-4">
            @if (!authenticated) {
                <button class="m-auto cursor-pointer gap-2.5 rounded border-2 bg-body px-3 font-sans text-sm hover:shadow-lg" style="border-color: #8C8C8C" (click)="loginAndCheckForResponse()">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0.5H1V9.5H10V0.5Z" fill="#F25022"/>
                        <path d="M20 0.5H11V9.5H20V0.5Z" fill="#7FBA00"/>
                        <path d="M10 10.5H1V19.5H10V10.5Z" fill="#00A4EF"/>
                        <path d="M20 10.5H11V19.5H20V10.5Z" fill="#FFB900"/>
                    </svg>
                    {{ 'DirectEdit_Login' | translate }}
                </button>
            }
            @if (data.mode == FileUploadEditOnlineModalComponentMode.Edit && oneDriveKey != null && oneDriveKey.trim() != '') {
                <button class="save" (click)="finishEditOnline()">{{ "SaveAndClose" | translate }}</button>
            }

        </div>
    </div>
}

import { Injectable } from '@angular/core';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { ViewDataSource } from '../../../../../models/ts/view-data-source.model';
import { CollectionFormService } from './collection-form.service';
import { LinkedCollectionType } from 'src/models/ts/linked-collection-type.model';
import { UpdateDeleteState } from 'src/models/ts/update-delete-state.model';
import { ViewDataSourcesInstance } from 'src/models/ts/view-data-sources-instance.model';
import { LinkedCollectionStorageType } from 'src/models/ts/linked-collection-storage-type.model';
import { Record } from 'src/models/ts/collection-form-field.model';
import { find } from 'lodash';
import { CollectionForm } from '../../../../../models/ts/collection-form.model';

@Injectable({
  providedIn: 'root'
})
export class ViewDataSourceService {

  // public static mapFieldsPerVds(viewDataSources: Array<ViewDataSource>, fields: Array<CollectionFormField>): Map<number, Array<CollectionFormField>> {
  //   const mappedFields = new Map<number, Array<CollectionFormField>>();

  //   viewDataSources.forEach(vds => {
  //     const vdsFields = fields.filter(field => field.ViewDataSourcesID == vds.ViewDataSourcesID);

  //     if (vdsFields.length > 0)
  //       mappedFields.set(vds.ViewDataSourcesID, vdsFields);
  //   });

  //   return mappedFields;
  // }

  // public static organizeVdsIntoTree(viewDataSources: Array<ViewDataSource>): Array<ViewDataSourceTreeNode> {
  //   let vdsTree: Array<ViewDataSourceTreeNode> = [];

  //   const topLevel = viewDataSources.filter(vds => vds.ParentDataSourcesID == 0);

  //   if (topLevel.length > 0) {
  //     topLevel.forEach(vds => {
  //       vdsTree.push({
  //         viewDataSource: vds,
  //         children: ViewDataSourceService.getVdsTreeNodes(vds.ViewDataSourcesID, viewDataSources)
  //       });
  //     });
  //   }

  //   return vdsTree;
  // }

  // public static stringifyViewDataSources(viewDataSources: Array<ViewDataSource>, fields: Array<CollectionFormField>): {
  //   [key: string]: any
  // } {
  //   const vdsTree = ViewDataSourceService.organizeVdsIntoTree(viewDataSources);
  //   const mappedFields = ViewDataSourceService.mapFieldsPerVds(viewDataSources, fields);
  //   let result: { [key: string]: any } = {};

  //   vdsTree.forEach(rootVds => {
  //     // TODO: verify this check is needed => VDS without fields might need to stringified too
  //     if (mappedFields.get(rootVds.viewDataSource.ViewDataSourcesID))
  //       result[rootVds.viewDataSource.Name] = [ViewDataSourceService.stringifyViewDataSource(rootVds, mappedFields)];
  //   });

  //   return result;
  // }

  // public static stringifyViewDataSource(node: ViewDataSourceTreeNode, mappedFields: Map<number, Array<CollectionFormField>>): {
  //   [key: string]: any
  // } {
  //   let result: { [key: string]: any } = {};
  //   const fields = mappedFields.get(node.viewDataSource.ViewDataSourcesID);
  //   if (fields)
  //     fields.forEach(field => {
  //       Object.assign(result, CollectionFormService.stringifyField(field));
  //     });
  //   result['ViewDataSourcesID'] = node.viewDataSource.ViewDataSourcesID;
  //   // TODO: figure out how these fields works with 1x1x... and 1xN
  //   // Note: Instances will be empty in a straight 1x1, but not in a deeper level (1x.x.)

  //   // Deeper level only 1x1
  //   // result['crossLinkedInstancesID'] = node.viewDataSource.Instances[0].CrossLinkedInstancesID;
  //   // result['deleteIfExists'] = false;
  //   // 1x1 => filled in
  //   // result['originalChildInstancesID'] = node.viewDataSource.Instances[0].OriginalChildInstancesID;
  //   // result['previousChildVersionsID'] = node.viewDataSource.Instances[0].PreviousChildVersionsID;
  //   // Deeper level only 1x1
  //   // result['rowDataDesignCrossID'] = node.viewDataSource.Instances[0].RowDataDesignCrossID;
  //   // result['versionsID'] = node.viewDataSource.Instances[0].ParentVersionsID;
  //   node.children.forEach(child => {
  //     result[child.viewDataSource.Name] = [ViewDataSourceService.stringifyViewDataSource(child, mappedFields)];
  //   });

  //   return result;
  // }

  // private static getVdsTreeNodes(parentViewDataSourceId: number, viewDataSources: Array<ViewDataSource>): Array<ViewDataSourceTreeNode> {
  //   return viewDataSources.filter(vds => vds.ParentDataSourcesID == parentViewDataSourceId).map(
  //     child => {
  //       return {
  //         viewDataSource: child,
  //         children: ViewDataSourceService.getVdsTreeNodes(child.ViewDataSourcesID, viewDataSources)
  //       };
  //     }
  //   );
  // }

  public static getViewDataSource(form: CollectionForm, vdsId: number): ViewDataSource | undefined {
    return form.ViewDataSources.find(vds => vds.ViewDataSourcesID == vdsId);
  }

  //Needed because of json serializing we unfortunately need to rely on any quite a bit.
  /* eslint @typescript-eslint/no-explicit-any: 0 */
  /**
   * Recursive function to add linked collection fields form the view data sources to a JSON object
   * @param json The JSON object to add the linked collection fields to
   * @param parentViewDataSourcesId The parent view data sources ID
   * @param nonSnapshotViewDataSources The view data sources excluding snapshot relations
   * @param linkedFields The linked fields
   * @param allViewDataSources All view data sources
   * @param parentGridField The parent grid field
   * @param gridParent Set the property 'gridParent' to true when it's a recursive relation with a parent relation.
   * @param rowDataDesignCrossId The row data design cross ID
   */
  public static addLinkedCollectionFieldsToJson(
    json: { [key: string]: any },
    parentViewDataSourcesId: number,
    nonSnapshotViewDataSources: ViewDataSource[],
    linkedFields: CollectionFormField[],
    allViewDataSources: ViewDataSource[],
    parentGridField: CollectionFormField | undefined = undefined,
    gridParent = false,
    rowDataDesignCrossId = -1): void {
    let pViewdataSources = nonSnapshotViewDataSources.filter(vds => vds.ParentDataSourcesID == parentViewDataSourcesId);

    // sort the viewDataSources, so we first save the recursive relations, in order to be able to set our gridParent property correctly
    if (gridParent && pViewdataSources.length > 0)
      pViewdataSources = pViewdataSources.sort(v => v.IsRecursiveDetailRelation ? 1 : 0);

    pViewdataSources.forEach((vds) => {

      /*
       * ATTENTION: GRID PARENT FOR RECURSIVE RELATIONS
       *
       * COMPANY
       *    -- CONTACTS (1XN)
       *        -- CITY (REL) -- REC
       *        -- CITY (HIST) -- REC
       *
       * Test
       *    -- RD (1XN)
       *       -- PROD -- REC
       *          -- CAT -- REC
       *              -- SUB CAT
       *
       * EX: CITY, PROD, CAT, SUB CAT
       * */
      // (re)set gridParent flag for recursive detail relation(s)
      if (vds.IsRecursiveDetailRelation) {
        if (!gridParent) {
          const parentDataSource = nonSnapshotViewDataSources.find(v => v.ViewDataSourcesID == vds.ParentDataSourcesID);
          if (parentDataSource) {
            gridParent = parentDataSource.SingleOrMany === LinkedCollectionType.GridRecord;
          }
        }
      } else {
        gridParent = false;
      }

      const instances: Array<ViewDataSourcesInstance> = ViewDataSourceService.getViewDataSourceInstances(vds, allViewDataSources, rowDataDesignCrossId, gridParent);
      if (instances.length > 0) {
        let currentJson: any = {};
        if (parentViewDataSourcesId == 0 && json[vds.Name] === undefined)
          json[vds.Name] = [];
        else {
          currentJson =
            ViewDataSourceService.getJsonObjectByRowDataDesignCrossId(json, rowDataDesignCrossId, parentViewDataSourcesId, vds.Name);
        }

        if (vds.SingleOrMany == LinkedCollectionType.GridRecord) {
          const grid = linkedFields.find(lf => lf.ViewDataSourcesID == vds.ViewDataSourcesID);

          // when the grid is undefined, it's possible it's from a reversed relation where the relation is not on the form
          if (grid != undefined) {

            instances.forEach((instance) => {
              rowDataDesignCrossId = instance.CrossLinkedInstancesID;

              const parentDs = allViewDataSources.find(v => v.ViewDataSourcesID == parentViewDataSourcesId);
              const gridInstance = ViewDataSourceService.getRowInstance(grid?.Records, rowDataDesignCrossId, parentDs) as Record;
              let nonRecursiveGridFields = null;
              if (gridInstance != null)
                nonRecursiveGridFields = gridInstance.Fields.filter(f => f.ViewDataSourcesID == vds.ViewDataSourcesID);

              if (instance.CrossLinkedInstancesID > 0) {
                if (parentViewDataSourcesId == 0)
                  ViewDataSourceService.addSingleLinkedInstanceToJson(
                    json[vds.Name], nonRecursiveGridFields, vds.ViewDataSourcesID, rowDataDesignCrossId, instance, true);
                else {
                  if (gridInstance != null && gridInstance.State == UpdateDeleteState.Delete)
                    instance.State = gridInstance.State;
                  ViewDataSourceService.addSingleLinkedInstanceToJson(
                    currentJson[vds.Name], nonRecursiveGridFields, vds.ViewDataSourcesID, rowDataDesignCrossId, instance, true);
                }
              } else {
                if (instance.State != UpdateDeleteState.Delete) {
                  if (parentViewDataSourcesId == 0)
                    ViewDataSourceService.addSingleLinkedInstanceToJson(
                      json[vds.Name], nonRecursiveGridFields, vds.ViewDataSourcesID, rowDataDesignCrossId, instance, false);
                  else
                    ViewDataSourceService.addSingleLinkedInstanceToJson(currentJson[vds.Name], nonRecursiveGridFields, vds.ViewDataSourcesID, rowDataDesignCrossId, instance, false);
                }
              }

              if (parentViewDataSourcesId == 0)
                ViewDataSourceService.addLinkedCollectionFieldsToJson(json[vds.Name], vds.ViewDataSourcesID, nonSnapshotViewDataSources, linkedFields, allViewDataSources, parentGridField);
              else
                ViewDataSourceService.addLinkedCollectionFieldsToJson(currentJson[vds.Name], vds.ViewDataSourcesID, nonSnapshotViewDataSources, linkedFields, allViewDataSources, parentGridField);
            });

            if (vds.LinkedCollectionStorageType == LinkedCollectionStorageType.Historical && vds.MustBeInList === false) {
              let notMustBeInListRecords: Record[] = [];
              if (grid.Records)
                notMustBeInListRecords = grid.Records.filter(gridInstance => {
                  return gridInstance.CrossLinkedInstancesID < 0 && gridInstance.State != UpdateDeleteState.Delete;
                });

              if (notMustBeInListRecords.length > 0) {
                let counter = 0;
                notMustBeInListRecords.forEach((record) => {
                  counter--;
                  rowDataDesignCrossId = counter;
                  record.Fields.forEach((field) => {
                    field.RowDataDesignCrossID = rowDataDesignCrossId;
                  });

                  const nonRecursiveGridFields = record.Fields.filter(f => f.ViewDataSourcesID == vds.ViewDataSourcesID);

                  if (parentViewDataSourcesId == 0)
                    ViewDataSourceService.addSingleNotMustBeInListLinkedInstanceToJson(json[vds.Name], nonRecursiveGridFields, vds.ViewDataSourcesID, rowDataDesignCrossId);
                  else
                    ViewDataSourceService.addSingleNotMustBeInListLinkedInstanceToJson(currentJson[vds.Name], nonRecursiveGridFields, vds.ViewDataSourcesID, rowDataDesignCrossId);


                  if (parentViewDataSourcesId == 0)
                    ViewDataSourceService.addLinkedCollectionFieldsToJson(json[vds.Name], vds.ViewDataSourcesID, nonSnapshotViewDataSources, linkedFields, allViewDataSources, parentGridField);
                  else
                    ViewDataSourceService.addLinkedCollectionFieldsToJson(currentJson[vds.Name], vds.ViewDataSourcesID, nonSnapshotViewDataSources, linkedFields, allViewDataSources, parentGridField);
                });
              }
            }

          } else {
            throw new Error('Impossible scenario detected, contact a developer if you see this message.');
            //TODO: RV if the grid is undefined the legacy would add nonRecursiveGridFields to json... but if the grid is undefined how can there be nonRecursiveGridFields?
            //We need to check if this code actually did anything...

            // add instance data to add as new relation
            // for (var i = 0; i < instances.length; i++) {
            //   CollectionFormService.addSingleLinkedInstanceToJSON(json[vds.Name], nonRecursiveGridFields, viewdataSources, vds.ViewDataSourcesID, rowDataDesignCrossID, instances[i], true);
            // }
          }
        } else {

          let fields: CollectionFormField[] = [];

          if (gridParent) {
            if (parentGridField === undefined)
              parentGridField = linkedFields.find(gridField => gridField.ViewDataSourcesID == vds.ParentDataSourcesID);

            // get fields from record (only if a parentGridField has been found)
            if (parentGridField && parentGridField.Records && parentGridField.Records.length > 0) {
              const recordInstance = parentGridField.Records.find(inst => inst.CrossLinkedInstancesID == rowDataDesignCrossId);
              if (recordInstance)
                fields = recordInstance.Fields.filter(field => field.ViewDataSourcesID == vds.ViewDataSourcesID);
            }
          } else
            fields = linkedFields.filter(field => field.ViewDataSourcesID == vds.ViewDataSourcesID);

          if (!gridParent)
            rowDataDesignCrossId = -1;

          const parentDs = allViewDataSources.find(v => v.ViewDataSourcesID == parentViewDataSourcesId);
          const instance = ViewDataSourceService.getRowInstance(instances, rowDataDesignCrossId, parentDs) as ViewDataSourcesInstance;
          if (parentViewDataSourcesId == 0)
            ViewDataSourceService.addSingleLinkedInstanceToJson(json[vds.Name], fields, vds.ViewDataSourcesID, rowDataDesignCrossId, instance, false);
          else {
            if (currentJson.rowDataDesignCrossID == rowDataDesignCrossId) {
              ViewDataSourceService.addSingleLinkedInstanceToJson(currentJson[vds.Name], fields, vds.ViewDataSourcesID, rowDataDesignCrossId, instance, false);
            }
          }

          if (parentViewDataSourcesId == 0)
            ViewDataSourceService.addLinkedCollectionFieldsToJson(json[vds.Name], vds.ViewDataSourcesID, nonSnapshotViewDataSources, linkedFields, allViewDataSources, parentGridField);
          else
            ViewDataSourceService.addLinkedCollectionFieldsToJson(currentJson[vds.Name], vds.ViewDataSourcesID, nonSnapshotViewDataSources, linkedFields, allViewDataSources, parentGridField);
        }
      } else if (vds.LinkedCollectionStorageType == LinkedCollectionStorageType.Historical && vds.MustBeInList === false) {
        let currentJson: any = {};
        if (parentViewDataSourcesId == 0 && json[vds.Name] === undefined)
          json[vds.Name] = [];
        else {
          currentJson = find(json, {
            rowDataDesignCrossID: rowDataDesignCrossId,
            ViewDataSourcesID: parentViewDataSourcesId
          });
          if (json[vds.Name] === undefined)
            currentJson[vds.Name] = [];
        }

        if (vds.SingleOrMany == LinkedCollectionType.GridRecord) {
          const grid = linkedFields.find(field => field.ViewDataSourcesID == vds.ViewDataSourcesID);

          if (grid !== undefined) {
            if (grid.Records !== undefined) {
              let counter = 0;
              grid.Records.forEach((record) => {
                counter--;
                rowDataDesignCrossId = counter;
                record.Fields.forEach((field) => field.RowDataDesignCrossID = rowDataDesignCrossId);

                const nonRecursiveGridFields = record.Fields.filter(field => field.ViewDataSourcesID == vds.ViewDataSourcesID);

                if (parentViewDataSourcesId == 0)
                  ViewDataSourceService.addSingleNotMustBeInListLinkedInstanceToJson(json[vds.Name], nonRecursiveGridFields, vds.ViewDataSourcesID, rowDataDesignCrossId);
                else
                  ViewDataSourceService.addSingleNotMustBeInListLinkedInstanceToJson(currentJson[vds.Name], nonRecursiveGridFields, vds.ViewDataSourcesID, rowDataDesignCrossId);
              });
            }

            if (parentViewDataSourcesId == 0)
              ViewDataSourceService.addLinkedCollectionFieldsToJson(json[vds.Name], vds.ViewDataSourcesID, nonSnapshotViewDataSources, linkedFields, allViewDataSources, parentGridField);
            else
              ViewDataSourceService.addLinkedCollectionFieldsToJson(currentJson[vds.Name], vds.ViewDataSourcesID, nonSnapshotViewDataSources, linkedFields, allViewDataSources, parentGridField);
          }
        } else {

          const fields = linkedFields.filter(field => field.ViewDataSourcesID == vds.ViewDataSourcesID);

          if (parentViewDataSourcesId == 0)
            ViewDataSourceService.addSingleNotMustBeInListLinkedInstanceToJson(json[vds.Name], fields, vds.ViewDataSourcesID, rowDataDesignCrossId);
          else
            ViewDataSourceService.addSingleNotMustBeInListLinkedInstanceToJson(currentJson[vds.Name], fields, vds.ViewDataSourcesID, rowDataDesignCrossId);

          if (parentViewDataSourcesId == 0)
            ViewDataSourceService.addLinkedCollectionFieldsToJson(json[vds.Name], vds.ViewDataSourcesID, nonSnapshotViewDataSources, linkedFields, allViewDataSources, parentGridField);
          else
            ViewDataSourceService.addLinkedCollectionFieldsToJson(currentJson[vds.Name], vds.ViewDataSourcesID, nonSnapshotViewDataSources, linkedFields, allViewDataSources, parentGridField);
        }
      }
    });
  }

  /**
   * Travels up the VDS tree across parents and returns if all parents have an instance. If any parent lacks an instance, returns false.
   * @param {ViewDataSource[]} viewDataSources
   * @param {number} vdsId
   * @return {boolean}
   */
  public static viewDataSourceHasParentInstances(viewDataSources: ViewDataSource[], vdsId: number): boolean {
    // 1 Get the chain up to the top level vds.
    // 2 Remove the VDS that can't have instances, only the top level VDS and snapshot VDS have instances.
    // 3 Check if any of the remaining VDSs have no instances (Instances.length == 0).
    return ViewDataSourceService.getViewDataSourceChain(viewDataSources, vdsId).filter(vds => vds.ViewDataSourcesID !== vdsId && (vds.ParentDataSourcesID == 0 || (vds.ParentDataSourcesID != 0 && !vds.IsSnapshotRelation))).findIndex(vds => !ViewDataSourceService.viewDataSourceHasActiveInstances(vds)) == -1;
  }

  public static viewDataSourceHasActiveInstances(viewDataSource: ViewDataSource): boolean {
    return viewDataSource.Instances.find(instance => instance.State != UpdateDeleteState.Delete) !== undefined;
  }

  public static getViewDataSourceChain(viewDataSources: ViewDataSource[], vdsId: number): ViewDataSource[] {
    const chain: ViewDataSource[] = [];
    let vds = viewDataSources.find(v => v.ViewDataSourcesID == vdsId);
    while (vds !== undefined) {
      chain.push(vds);
      if (vds.ParentDataSourcesID !== undefined) {
        vds = viewDataSources.find(v => v.ViewDataSourcesID == vds?.ParentDataSourcesID);
      } else break;
    }
    return chain;
  }

  /**
   * Returns a boolean indicating if the given ViewDataSource has an instance.
   * @param {CollectionForm} form
   * @param {number} vdsId
   * @return {boolean}
   */
  public static viewDataSourceHasInstance(viewDataSources: ViewDataSource[], vdsId: number): boolean {
    const vds = viewDataSources.find(v => v.ViewDataSourcesID == vdsId);
    if (vds !== undefined) {
      return ViewDataSourceService.viewDataSourceHasActiveInstances(vds);
    } else return false;
  }

  public static addRecordInstancesToViewDataSource(form: CollectionForm, fieldId: number, records: Record[]): ViewDataSource {
    let vds = form.ViewDataSources.find(vds =>
      vds.ViewDataSourcesID ==
      CollectionFormService.getField(form, field => field.Id == fieldId)?.ViewDataSourcesID
    );
    if (vds) {
      vds.Instances = vds.Instances.concat(records.map(record => ViewDataSourceService.recordToViewDataSourceInstance(form, record)));
      return vds;
    } else throw new Error(`ViewDataSource for field ${fieldId} not present on form.`);
  }

  // TODO: Deeper level case?
  // TODO: Figure out ID sources
  public static recordToViewDataSourceInstance(form: CollectionForm, record: Record): ViewDataSourcesInstance {
    return {
      // CollectionListSummaryItem.InstancesID
      ChildInstancesID: 0,
      // CollectionListSummaryItem.VersionsID
      ChildVersionsID: 0,

      // CollectionForm.VersionsID
      ParentVersionsID: form.VersionsID,
      // CollectionForm.InstancesID
      ParentInstancesID: form.InstancesID,

      // CollectionListSummaryItem.CrossLinkInstancesID
      CrossLinkedInstancesID: record.CrossLinkedInstancesID,
      DataDesignCrossID: 0,
      // CollectionListSummaryItem.OriginalChildInstancesID
      OriginalChildInstancesID: 0,
      State: record.State,
      PreviousChildVersionsID: 0,
      RowDataDesignCrossID: record.RowDataDesignCrossID,
      GuidChecksum: undefined,
      SnapshotCrosslinkInstancesID: 0,
      IsEntityActive: false,
      IsEntityPartOfEntitiesUser: false
    };
  }

  /**
   * Retrieves all revelevant instances for a view data source (also checks snapshot)
   * Code used to be in addLinkedCollectionFieldsToJson in legacy
   * @param vds
   * @param allViewDataSources
   * @param rowDataDesignCrossId
   * @param gridParent
   * @returns
   */
  private static getViewDataSourceInstances(vds: ViewDataSource, allViewDataSources: ViewDataSource[],
                                            rowDataDesignCrossId: number, gridParent: boolean): ViewDataSourcesInstance[] {
    let instances: Array<ViewDataSourcesInstance> = [];
    if (vds.Instances.length == 0) {
      // the instances might be filled in, in the snapshot datasources
      const snapshotDataSource = allViewDataSources.find(v => v.ParentDataSourcesID == vds.ViewDataSourcesID && v.IsSnapshotRelation);
      if (snapshotDataSource !== undefined && snapshotDataSource.Instances.length > 0) {
        if (gridParent) {
          const rowInstances = ViewDataSourceService.getRowInstances(snapshotDataSource.Instances, rowDataDesignCrossId);
          instances = instances.concat(rowInstances);
        } else
          instances = instances.concat(snapshotDataSource.Instances);
      }
    } else {
      // find any replaced instances in the snapshot datasources
      const snapshotDataSource = allViewDataSources.find(v => v.ParentDataSourcesID == vds.ViewDataSourcesID && v.IsSnapshotRelation);
      if (snapshotDataSource && snapshotDataSource.Instances.length > 0) {
        const deletedInstances = vds.Instances.filter(v => v.State == UpdateDeleteState.Delete);
        if (deletedInstances.length > 0) {
          const replacedInstances: Array<ViewDataSourcesInstance> = [];
          deletedInstances.forEach((deletedInstance) => {
            const replacedInstance = snapshotDataSource.Instances.find(inst => {
              inst.RowDataDesignCrossID == deletedInstance.RowDataDesignCrossID;
            });
            if (replacedInstance !== undefined) {
              // the crosslinkinstancesID needs to remain the same or lookup will fail in save
              replacedInstance.CrossLinkedInstancesID = deletedInstance.CrossLinkedInstancesID;
              replacedInstances.push(replacedInstance);
            }
          });
          // add all replaced instances
          instances = instances.concat(replacedInstances);

          // find the instances that have not been replaced and add them to the list
          /*let nonReplacedInstances = _.reject(vds.Instances, (ins) => {
            return _.find(replacedInstances, { RowDataDesignCrossID: ins.RowDataDesignCrossID });
          });*/

          //TODO: RV Double check this does the same as the above
          const nonReplacedInstances = vds.Instances.filter(ins => {
            return !replacedInstances.some(ri => ri.RowDataDesignCrossID === ins.RowDataDesignCrossID);
          });

          instances = instances.concat(nonReplacedInstances);
        } else if (gridParent) {
          const instancesToFilter = rowDataDesignCrossId > 0 ? vds.Instances : snapshotDataSource.Instances;
          const rowInstances = ViewDataSourceService.getRowInstances(instancesToFilter, rowDataDesignCrossId);
          instances = instances.concat(rowInstances);
        } else
          instances = instances.concat(vds.Instances);
      } else if (gridParent && snapshotDataSource !== undefined) {
        const instancesToFilter = rowDataDesignCrossId > 0 ? vds.Instances : snapshotDataSource.Instances;
        const rowInstances = ViewDataSourceService.getRowInstances(instancesToFilter, rowDataDesignCrossId);
        instances = instances.concat(rowInstances);
      } else
        instances = instances.concat(vds.Instances);
    }
    return instances;
  }

  private static getJsonObjectByRowDataDesignCrossId(
    json: { [key: string]: any },
    rowDataDesignCrossId: number,
    parentViewDataSourcesId: number,
    vdsName: string): { [key: string]: any } {
    let currentJson: any = {};
    // On deleted instances - no need to add second levels
    currentJson = find(json, {
      rowDataDesignCrossID: rowDataDesignCrossId,
      ViewDataSourcesID: parentViewDataSourcesId,
      deleteIfExists: false
    });

    if (currentJson === undefined && Object.keys(json).length > 0) {
      currentJson = find(json, (j: any) => (j.rowDataDesignCrossID == 0 || j.rowDataDesignCrossID == -1));
    } else if (currentJson === undefined && Object.keys(json).length == 0) {
      currentJson = [];
    }

    if (currentJson === undefined) {
      currentJson = [];
    }

    if (currentJson != undefined && currentJson[vdsName] === undefined)
      currentJson[vdsName] = [];

    return currentJson;
  }

  private static addSingleLinkedInstanceToJson(
    json: any,
    fields: CollectionFormField[] | null,
    viewDataSourcesId: number,
    rowDataDesignCrossId: number,
    instance: ViewDataSourcesInstance,
    updateIfExists: boolean): void {
    const linkedInstance: any = {};
    let deletedInstance = false;
    if (fields != null) {
      const fieldsWithValue = CollectionFormService.getFieldsWithValue(fields);

      // if deleteInstance and no fields are edited, take the first non CLF and make it have the deleteIfExists property
      if (fieldsWithValue.length == 0 && instance.State == UpdateDeleteState.Delete) {
        const field = fields.find(field => field.IsCrossLinkedField == undefined || !field.IsCrossLinkedField);
        if (field !== undefined) {
          const key = field.Bookmark;
          const value = CollectionFormService.getValueForField(field);
          if (value != null && value != undefined) {
            linkedInstance[key] = value;
            linkedInstance[key].DeleteIfExists = true;
            if (!deletedInstance)
              deletedInstance = true;
          } else
            linkedInstance.DeleteIfExists = true;
        }
      }

      fieldsWithValue.forEach((field) => {
        const key = field.Bookmark;
        const value = CollectionFormService.getValueForField(field);
        if (value != null) {
          linkedInstance[key] = value;
          if (updateIfExists && (field.IsCrossLinkedField === undefined || !field.IsCrossLinkedField))
            linkedInstance[key].updateIfExists = true;
          if (instance.State == UpdateDeleteState.Delete && (field.IsCrossLinkedField === undefined || !field.IsCrossLinkedField)) {
            linkedInstance[key].DeleteIfExists = true;
          }
          if (!deletedInstance)
            deletedInstance = true;
        }
      });
    } else if (instance.State == UpdateDeleteState.Delete) {
      linkedInstance.DeleteIfExists = true;
    }

    linkedInstance.DeleteIfExists = instance.State == UpdateDeleteState.Delete;
    linkedInstance.VersionsID = instance.ChildVersionsID;
    linkedInstance.PreviousChildVersionsID = instance.PreviousChildVersionsID;
    linkedInstance.CrossLinkedInstancesID = instance.CrossLinkedInstancesID;
    linkedInstance.OriginalChildInstancesID = instance.OriginalChildInstancesID;

    //TODO: RV Ask PJ about this. SnapshotCrosslinkInstancesID does not exist on ViewDataSourcesInstance
    //It's filled in in the LookupService.js when adding new viewdatasources (when adding a new instance)
    //This is filled in every time a new instance is added to the form/or grid on the form.
    //See LookupService.js:731 addDataSource (used in mapAddedInstanceToForm)
    //The SnapshotCrosslinkInstancesID is filled in with the OriginalCrosslinkInstancesID of a linkedfield
    linkedInstance.SnapshotCrosslinkInstancesID = instance.SnapshotCrosslinkInstancesID;
    linkedInstance.ViewDataSourcesID = viewDataSourcesId;
    linkedInstance.RowDataDesignCrossID = rowDataDesignCrossId;
    json.push(linkedInstance);
  }

  private static addSingleNotMustBeInListLinkedInstanceToJson(
    json: any,
    fields: CollectionFormField[],
    viewDataSourcesId: number,
    rowDataDesignCrossId: number): void {
    const linkedInstance: any = {};
    const fieldsWithValue = CollectionFormService.getFieldsWithValue(fields);

    if (fieldsWithValue.length > 0) {
      fieldsWithValue.forEach((field) => {
        const value = CollectionFormService.getValueForField(field) as {value: any} | null;
        if (value !== null && !Array.isArray(value) && value['value'] !== null) {
          linkedInstance[field.Bookmark] = value;
        } else if (value !== null && Array.isArray(value) && value.length > 0) {
          linkedInstance[field.Bookmark] = value;
        }
      });

      linkedInstance.RowDataDesignCrossID = rowDataDesignCrossId;
      linkedInstance.ViewDataSourcesID = viewDataSourcesId;
      json.push(linkedInstance);
    }
  }

  private static getRowInstance(instances: Record[] | ViewDataSourcesInstance[] | undefined, rowDataDesignCrossId: number, parentDs: ViewDataSource | undefined): Record | ViewDataSourcesInstance | undefined {
    if (instances === undefined)
      return undefined;

    const rowInstance = structuredClone(instances.find((inst) => {
      if (inst.RowDataDesignCrossID == undefined) {
        if (inst.CrossLinkedInstancesID == rowDataDesignCrossId) {
          return true;
        } else if (parentDs != undefined) {
          const parentInstance = parentDs.Instances.find(pInst => pInst.CrossLinkedInstancesID == inst.CrossLinkedInstancesID);
          return parentInstance != undefined;
        }
        return false;
      } else return inst.RowDataDesignCrossID == rowDataDesignCrossId;
    }));
    if (rowInstance != undefined) {
      if (rowInstance.RowDataDesignCrossID == undefined) {
        if (rowInstance.CrossLinkedInstancesID == rowDataDesignCrossId) {
          rowInstance.RowDataDesignCrossID = rowDataDesignCrossId;
        } else if (parentDs != undefined) {
          const parentInstance = parentDs.Instances.find(pInst => pInst.CrossLinkedInstancesID == rowInstance.CrossLinkedInstancesID);
          if (parentInstance != undefined) {
            rowInstance.RowDataDesignCrossID = parentInstance.RowDataDesignCrossID;
          }
        }
      } else if (rowInstance.RowDataDesignCrossID == 0) {
        rowInstance.RowDataDesignCrossID = -1;
      }
    }

    return rowInstance;
  }

  private static getRowInstances(instances: ViewDataSourcesInstance[], rowDataDesignCrossId: number): ViewDataSourcesInstance[] {
    return instances.filter((inst) => {
      if (inst.RowDataDesignCrossID === undefined) {
        if (inst.CrossLinkedInstancesID == rowDataDesignCrossId) {
          inst.RowDataDesignCrossID = rowDataDesignCrossId;
          return true;
        }
        return false;
      } else return inst.RowDataDesignCrossID == rowDataDesignCrossId;
    });
  }
}

export type ViewDataSourceTreeNode = { viewDataSource: ViewDataSource, children: Array<ViewDataSourceTreeNode> };

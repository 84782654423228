import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { TooltipComponent } from "../../../../../../shared/components/ui/tooltip/tooltip.component";
import {ComboBoxModule} from "@progress/kendo-angular-dropdowns";

/**
 * Represents a control that allows the user to enter alphanumeric characters.
 */
@Component({
  selector: 'bizz-alpha-numeric-control',
  standalone: true,
    imports: [CommonModule, ReactiveFormsModule, IconComponent, TooltipComponent, ComboBoxModule],
  templateUrl: './alpha-numeric-control.component.html',
  styleUrls: ['./alpha-numeric-control.component.scss']
})
export class AlphaNumericControlComponent extends BaseFormControlComponent {
  @ViewChild('input') inputElement: ElementRef;

  protected override focus(): void {
        this.inputElement.nativeElement.focus();
  }
}

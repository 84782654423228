import {
  ApplicationRef,
  Component, DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ItemKeyValuePipe } from '../../../pipes/item-key-value/item-key-value.pipe';
import { IconComponent } from '../icon/icon.component';
import { GridFilterDto } from '../../../../../models/ts/grid-filter-dto.model';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { FormsModule } from '@angular/forms';
import { Dialog } from '@angular/cdk/dialog';
import { FilterModalData } from '../../modals/filter-modal/filter-modal-data';
import { FilterModalComponent } from '../../modals/filter-modal/filter-modal.component';
import { ChartLegendPositionPipe } from '../../../pipes/chart-legend-position/chart-legend-position.pipe';
import { ChartModule } from '@progress/kendo-angular-charts';
import { ChartTypePipe } from '../../../pipes/chart-type/chart-type.pipe';
import { ScrollbarComponent } from '../scrollbar/scrollbar.component';
import { FilterApiService } from '../../../../api/bizzmine/filter/filter-api.service';
import { ToggleArrowComponent } from '../toggle-arrow/toggle-arrow.component';
import { SearchFilterDto } from '../../../../../models/ts/search-filter-dto.model';
import { SearchFilterGroupDto } from 'src/models/ts/search-filter-group-dto.model';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import { FilterItemType } from '../../../../../models/ts/filter-item-type.model';
import { ExtensionIconComponent } from '../icon/extension-icon/extension-icon.component';
import { Align, PopupModule, PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { asyncScheduler, BehaviorSubject, debounceTime, distinctUntilChanged, filter, switchMap,take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SearchFilterOutputDto } from '../../../../../models/ts/search-filter-output-dto.model';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { TaskStateFilterType } from '../../../../../models/ts/task-state-filter-type.model';
import { ItemDto } from '../../../../../models/ts/item-dto.model';
import { SearchComponent } from "../search/search.component";
import { Operators } from '../../../../../models/ts/operators.model';
import { SearchFilterFieldValueBaseDto } from '../../../../../models/ts/search-filter-field-value-base-dto.model';
import { userSettingsFeature } from '../../../../store/features/user-settings/user-settings-feature';
import { Store } from '@ngrx/store';

@Component({
  selector: 'bizz-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  imports: [
    NgForOf,
    ItemKeyValuePipe,
    IconComponent,
    NgIf,
    TranslatePipe,
    NgClass,
    NgSwitch,
    FormsModule,
    NgSwitchCase,
    ChartLegendPositionPipe,
    ChartModule,
    ChartTypePipe,
    ScrollbarComponent,
    ToggleArrowComponent,
    ExtensionIconComponent,
    PopupModule,
    ButtonModule,
    AsyncPipe,
    DropDownListModule,
    SearchComponent
],
  standalone: true
})

export class FilterComponent implements OnInit, OnChanges {
  @Input('filterList') public filterList: SearchFilterDto[];
  @Input('collectionsId') public collectionsId: number;
  @Input('widgetId') public widgetId: number;
  @Input('viewId') public viewId: number | undefined;
  @Input('filterType') public filterType: FilterItemType;
  @Input('userReversedFixedGroupsFilter') public userReversedFixedGroupsFilter: SearchFilterGroupDto[] | undefined = undefined;
  //TODO: Check where dropdown also is not accessible like tasks
  @Input('dropdownAccessible') public dropdownAccessible = true;
  @Input('active') public active = false;
  @Input('searchFieldAccessible') public searchFieldAccessible = false;

  @Input('taskStatusAccessible') public taskStatusAccessible = false;
  @Input('searchedTaskDto') public searchedTaskDto: ItemDto[] | undefined = undefined;
  public taskStateFilterType: TaskStateFilterType = TaskStateFilterType.Open;

  @Input('filterEnabled') public filterEnabled = true;
  @Output() public retrievedFilterData = new EventEmitter<SearchFilterOutputDto>();
  @Output() public retrievedTaskStatus = new EventEmitter<TaskStateFilterType>();

  public model = {} as GridFilterDto;
  public activeFilter: boolean;

  public activeFiltersId: number | undefined;
  public defaultFilterId: number;
  public getDefaultFilter$ = new BehaviorSubject<number | undefined>(undefined);

  public caretVisible = false;
  public filterIsModified: boolean;
  public filterCleared= false;

  public loading = false;

  public searchTerm: string = '';
  public searchTerm$ = new BehaviorSubject<string>('');

  public anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  public popupAlign: Align = { horizontal: 'right', vertical: 'top' };

  @ViewChild("anchor", { read: ElementRef }) public anchor: ElementRef;
  @ViewChild("popupTemplate") public popupTemplate: TemplateRef<{ [Key: string]: unknown }>;

  private popupRef: PopupRef | null = null;

  public constructor(
    private searchFilterApiService: FilterApiService,
    private dialog: Dialog,
    private popupService: PopupService,
    private appRef: ApplicationRef,
    private destroyRef: DestroyRef) {
    this.getDefaultFilter$.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(id => id != undefined),
      distinctUntilChanged(),
      switchMap(id => this.searchFilterApiService.getFilterData(id))
  ).subscribe({
      next: (filters) => {
        this.setFilters(filters, true);
      },
      error: err => {
        throw err;
      }
    });
  }

  public ngOnInit(): void {
    this.loading = true;

    if (this.filterType) {
      this.activeFilter = this.active;
      this.filterCleared = false;
      this.filterIsModified = false;

      if(this.filterType == FilterItemType.InstanceSelector) {
        if(this.viewId == undefined)
          throw new Error('ViewId is required for InstanceSelector filter');
        this.searchFilterApiService.getFilterList(this.viewId).subscribe({
          next: filters => {
            this.filterList = filters;
            this.getDefaultFilter();
          },
          error: err => {
            throw err;
          }
        });
      } else if (this.filterList && this.filterType > 6){
        //Widget filter, tree, user reversed,...
        this.getDefaultFilter();
      } else{
        //Type filter like 'tasks'
        this.getDataByFilterType();
      }
    }else{
      this.loading = false;
        //TODO generic error, no filtertype
    }

    this.searchTerm$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(600)).subscribe(term => {
      this.postFilter(this.model, term);
    });
  }

  public onSearchTermChanged(search: string): void {
    this.searchTerm = search;
    this.searchTerm$.next(this.searchTerm);
  }

  public onTaskStatusChanged(type: TaskStateFilterType): void {
    this.retrievedTaskStatus.emit(type);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.activeFilter = changes['active'].currentValue;
  }

  @HostListener("document:keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.togglePopup(false);
    }
  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    if (this.filterList && this.anchor?.nativeElement) {
      if (event.target && !this.contains(event.target)){
        this.togglePopup(false);
      }
    }
  }

  private contains(target: EventTarget): boolean {
    return (
      this.anchor?.nativeElement?.contains(target) ||
      (this.popupRef?.popup.instance.container.nativeElement.contains(target))
    );
  }
  public togglePopup(show?: boolean): void {
    //Only works if bizz-root has viewContainerRef
    const rootRef = this.appRef.components[0].instance.viewContainerRef;
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = null;
    } else if(show || show === undefined) {
      this.popupRef = this.popupService.open({
        anchor: this.anchor,
        appendTo: rootRef, //add popup to root container
        content: this.popupTemplate,
        positionMode: 'absolute',
        anchorAlign: this.anchorAlign,
        popupAlign: this.popupAlign,
        collision: { horizontal: 'fit', vertical: 'fit' },
        margin: { horizontal: 0, vertical: 5 },
      });
      this.popupRef.popupAnchorViewportLeave.pipe(
        take(1)
      ).subscribe(() => {
        this.togglePopup(false);
        this.popupRef = null;
      });
    }
    this.caretVisible = this.popupRef !== null;
  }

  public getDefaultFilter () : void{
    const defaultFilter = this.filterList.find(obj => obj.IsDefault === true);

    if (defaultFilter) {
      this.defaultFilterId = defaultFilter.ID;
      this.model.currentFilterID = this.defaultFilterId;
      this.getDataByFilterType();
    } else {

      const filter = this.filterList.find(obj => obj.Priority === 1);
      if (filter) {
        this.defaultFilterId = filter.ID;
        this.model.currentFilterID = this.defaultFilterId;
        this.getDataByFilterType();
      }

      this.loading = false;
    }
  }

  public getDataByFilterType (): void{
    switch (this.filterType){
      case FilterItemType.Widget: {
        this.getDefaultFilter$.next(this.defaultFilterId);
        break;
      }
      case FilterItemType.TaskListWidget : {
        this.searchFilterApiService.getTaskListFilter(this.defaultFilterId, this.collectionsId, this.widgetId).subscribe({
          next: (filters) => {
            this.setFilters(filters, true);
            this.model.UseDefaultFilter = true;
          },
          error: err => {
            throw err;
          }
        });
        break;
      }

      case FilterItemType.UserReversedIndex: {
        this.searchFilterApiService.getFilterData(this.defaultFilterId).subscribe({
          next: (filters) => {
            this.model = filters;
            this.model.UseDefaultFilter = false;
            this.model.FilterList = this.filterList;
            this.activeFiltersId = this.defaultFilterId;

            this.searchFilterApiService.getFilterOperators().subscribe({
              next: operators => {
                this.model.OperatorList = operators;
                this.searchFilterApiService.getFiltergrouptype().subscribe({
                  next: filterGroupTypes => {
                    this.model.FilterGroupType = filterGroupTypes;

                    if (!this.model.FilterList.find(obj => obj.Priority == 1)?.IsDefault){
                      this.active = true;
                    }

                    if (this.userReversedFixedGroupsFilter){
                      this.model.UserReversedFixedGroups = this.userReversedFixedGroupsFilter
                      this.model.Groups.forEach(group => {
                        group.Fields.forEach(field => {
                          if(field.FieldType == TableFieldDataType.OrganizationChartUnitSelector && (field.TableFieldID == this.userReversedFixedGroupsFilter![0].Fields[0].TableFieldID)){
                            field.Values[0].SelectedOrganizationChartItems.push(this.userReversedFixedGroupsFilter![0].Fields[0].Values[0].SelectedOrganizationChartItems[0])
                          }
                        });
                      });
                    }
                  }
                });
              },
              error: err => {
                throw err;
              }
            });
          },
          error: err => {
            throw err;
          }
        });
        break;
      }

      case FilterItemType.History: {
        this.searchFilterApiService.getFilterByItemTypeAndCollection(this.filterType, this.collectionsId).subscribe({
          next: filters => {
            this.model = filters;
            this.defaultFilterId = this.filterType;
            this.model.currentFilterID = this.filterType;
            this.activeFiltersId = this.filterType;
          },
          error: err => {
            throw err;
          }
        });
        break;
      }
      case FilterItemType.Tasks:
      case FilterItemType.GeneralLogs:
      case FilterItemType.SecurityLogs:
      case FilterItemType.PermissionHistory:
      case FilterItemType.Collection: {
        this.searchFilterApiService.getFilterByItemType(this.filterType).subscribe({
          next: filters => {
            this.model = filters;
            this.defaultFilterId = this.filterType;
            this.model.currentFilterID = this.filterType;
            this.activeFiltersId = this.filterType;
          },
          error: err => {
            throw err;
          }
        });
        break;
      }
      case FilterItemType.InstanceSelector:
      case FilterItemType.Tree: {
        this.searchFilterApiService.getFilterData(this.defaultFilterId).subscribe({
          next: (filters) => {
            this.setFilters(filters, true);
          },
          error: err => {
            throw err;
          }
        });
        break;
      }
      default:
        throw new Error('FilterType not implemented');
    }
    this.loading = false;
  }

  private setFilters(filters: GridFilterDto, setDefault: boolean): void {
    this.model = filters;
    this.model.FilterList = this.filterList;
    this.model.currentFilterID = this.defaultFilterId;
    this.activeFiltersId = this.defaultFilterId;
    if(setDefault){
      if (!this.model.FilterList.find(obj => obj.Priority == 1)?.IsDefault){
        this.active = true;
      }
    }
  }

  // Handler for setting data for openFilterDialog
  public openFilterModal(): void {
    this.loading = true;

    if (this.caretVisible) {
      this.changeCaretState();
    }

    if (this.filterCleared) {
      this.openFilterDialog();
    }

    // if activefilderID exists, load the previous settings, else get the data
    else if (this.activeFiltersId != undefined) {
      this.openFilterDialog();
    }

    else if (this.userReversedFixedGroupsFilter){
      this.openFilterDialog();
    }

    //ActiveFilter does not exist, get the data from currentfilter

    else {
      this.filterCleared = false;
      this.filterIsModified = false;
      this.openFilterDialog();
    }

    this.loading = false;
  }

  // Handler for opening filter-modal.component
  public openFilterDialog(): void {
    this.loading = true;

    if (!this.activeFiltersId){
      this.getDataByFilterType()
    }

    if (!this.model.OperatorList || !this.model.FilterGroupType){
      this.getOperatersAndFilterLists();
    }

    const dialogRef =
      this.dialog.open<GridFilterDto>(FilterModalComponent, {
        data: new FilterModalData(this.model, this.filterCleared, this.collectionsId, this.filterType)
      });

    dialogRef.closed.subscribe({
      next: (value) => {
        if (value) {
          if (value.UseDefaultFilter){
            this.resetFilterDefault();
          }else{
            this.model = value;
            this.activeFiltersId = value.currentFilterID;
            this.filterIsModified = true;
            this.filterCleared = false;
            this.active = true;
            this.postFilter(value)
          }
        }
      },
      error: err => {
        throw err;
      }
    });

    // Explicitly specify the type of dialogRef.componentInstance
    if (dialogRef.componentInstance instanceof FilterModalComponent) {
      dialogRef.componentInstance.filterCleared.subscribe(() => {
        // Handle the filter cleared event here without closing the dialog
        this.clearFilter();
        this.dialog.closeAll()
        this.openFilterModal();
      });
    }

    this.loading = false;
  }

  // Clears all fields & reopens modal
  public clearFilter(): void {
    this.loading = true;
    this.togglePopup(false);
    this.model.Groups.forEach(item => {
      item.Fields.forEach(field => {
        field.Values.forEach(value => {
          if (value.Operator == 7 || value.Operator == 8 || value.Operator == 19) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value.Operator = null;
          }
          value.DateTimePickerValue = null;
          value.StringValue = '';
          value.DateTimeValue = null;
          value.DoubleValue = null;
          value.SelectedOrganizationChartItems = [];
          value.SelectedTypeaheadItems = [];

          if (field.FieldType == 8) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value.Operator = 0;
            value.DoubleValue = 0;
          }
        })
      })
    })
    this.filterCleared = true;
    this.postFilter(this.model);
    this.loading = false;
  }

  // Resets filter to default
  public resetFilterDefault(): void{
    this.loading = true;
    this.togglePopup(false);
    this.model.UseDefaultFilter = true;
    this.activeFiltersId = undefined;
    this.active = false;
    this.filterCleared = false;
    this.filterIsModified = false;
    this.postFilter(this.model);
    this.getDefaultFilter();
    this.loading = false;
  }

  // Handler for filter/clear/default from carret dropdown
  public loadCaretFilter(filterId: number): void {
    this.loading = true;
    // set filter active
    // do not retrieve the same searchfilter again;
    //this.filterType = FilterItemType.Widget;
      this.searchFilterApiService.getFilterData(filterId).subscribe({
        next: filterData => {
          this.model = filterData;
          this.model.FilterList = this.filterList;
          this.model.currentFilterID = filterId;
          this.activeFiltersId = filterId;

          if (!this.model.FilterList.find(obj => obj.Priority == 1)?.IsDefault){
            this.active = true;
          }
          this.postFilter(this.model);
        },
        error: err => {
          throw err;
        }
      });
    this.togglePopup(false);
    this.loading = false;
  }

  // changes caret state caretVisible
  public changeCaretState(): void {
    this.caretVisible = !this.caretVisible;
  }

  // Gets operators & filterGroupTypes for filter modal
  public getOperatersAndFilterLists ():void{
    this.loading = true;
    this.searchFilterApiService.getFilterOperators().subscribe({
      next: operators => {
        this.model.OperatorList = operators;
        this.model.OperatorList.StringOperatorsWithEntities = this.model.OperatorList.StringOperators;
        this.model.OperatorList.StringOperators = this.model.OperatorList.StringOperators.filter((operator) => operator.Value !== Operators.MemberOfMyEntities);
      },
      error: err => {
        throw err;
      }
    });

    this.searchFilterApiService.getFiltergrouptype().subscribe({
      next: filterGroupTypes => {
        this.model.FilterGroupType = filterGroupTypes;
      },
      error: err => {
        throw err;
      }
    });
    this.loading = false;
  }

  // Last step post searched/Filtered data back to parent component
  public postFilter(filter: GridFilterDto, search: string | undefined = undefined): void {
    const tempOutput: SearchFilterOutputDto = {
      Search: this.searchTerm,
      SearchFilters: filter
    }
    this.retrievedFilterData.emit(tempOutput);
  }

  protected readonly document = document;
  protected readonly window = window;
  protected readonly FilterItemType = FilterItemType;
}
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { JsonPipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {ItemKeyValuePipe} from '../../../pipes/item-key-value/item-key-value.pipe';
import {GridFilterDto} from '../../../../../models/ts/grid-filter-dto.model';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {FormsModule} from '@angular/forms';
import {IconComponent} from '../../ui/icon/icon.component';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {FilterModalData} from './filter-modal-data';
import { Subject } from 'rxjs';
import {DatePickerModule, DateTimePickerModule, TimePickerModule} from '@progress/kendo-angular-dateinputs';
import {FilterGroupType} from '../../../../../models/ts/filter-group-type.model';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {FilterModalItemComponent} from "./filter-modal-item/filter-modal-item.component";
import { FilterApiService } from '../../../../api/bizzmine/filter/filter-api.service';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import {ToggleArrowComponent} from "../../ui/toggle-arrow/toggle-arrow.component";
import { TypeaheadSearchDto } from '../../../../../models/ts/typeahead-search-dto.model';
import { SearchFilterFieldType } from '../../../../../models/ts/search-filter-field-type.model';

@Component({
  selector: 'bizz-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
  imports: [
    NgForOf,
    ItemKeyValuePipe,
    IconComponent,
    NgIf,
    TranslatePipe,
    NgClass,
    NgSwitch,
    FormsModule,
    NgSwitchCase,
    DatePickerModule,
    DateTimePickerModule,
    TimePickerModule,
    NgScrollbarModule,
    FilterModalItemComponent,
    DropDownsModule,
    ToggleArrowComponent,
    JsonPipe
  ],
  standalone: true
})

export class FilterModalComponent implements OnInit, OnDestroy {
  @Input() public loading: boolean;
  @Output() public filterCleared = new EventEmitter<void>();

  public model: GridFilterDto;
  private destroy = new Subject<boolean>();

  public constructor(@Inject(DIALOG_DATA) public data: FilterModalData, public dialogRef: DialogRef<GridFilterDto>, private searchFilterApiService: FilterApiService,) {
  }

  public ngOnInit(): void {
    this.model = this.data.gridFilterDto;
    // If filter got cleared from caret also cleanup filter modal
    if (this.data.cleared) {
      this.clearFilter();
    }
    this.getTypeaHeads();
  }

  public getTypeaHeads(): void {
    this.loading = true;
    this.model.Groups.forEach((group) => {
      group.Fields.forEach((field) => {
        if (field.SearchFilterFieldType == SearchFilterFieldType.Typeahead) {
          const typeaheadSearchDto: TypeaheadSearchDto = {
            Text: '',
            FieldItemType: field.SearchFilterFieldItemType,
            CollectionsID: this.data.collectionsId
          };

          this.searchFilterApiService.getTypeAheadFilter(this.data.filterItemType, typeaheadSearchDto).subscribe({
            next: (result) => {
              field.TypeAheadValues = structuredClone(result);
            }
          })
        }
      })
    })
    this.loading = false;
  }

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  //Function to close dialog and send model back to main component with boolean to set if filter component must search
  public closeDialog(filterSearchClicked: boolean): void {
    if (filterSearchClicked) {
      this.model.UseDefaultFilter = false;
      this.dialogRef.close(this.model);
    }else {
      this.dialogRef.close();
    }
  }

  // Function to reset filter to default filter
  public resetFilterToDefault(): void {
    this.loading = true;
    this.model.UseDefaultFilter = true;
    this.dialogRef.close(this.model);
  }

  // Function that clears all filter group values on filter-modal itself.
  public clearFilter(): void {
    this.model.Groups.forEach(item => {
      item.Fields.forEach(field => {
        field.Values.forEach(value => {
          if (value.Operator == 7 || value.Operator == 8 || value.Operator == 19) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value.Operator = null;
          }
          value.DateTimePickerValue = null;
          value.StringValue = '';
          value.DateTimeValue = null;
          value.DoubleValue = null;
          value.SelectedOrganizationChartItems = [];
          value.SelectedTypeaheadItems = [];

          if (field.FieldType == 8) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value.Operator = 0;
            value.DoubleValue = 0;
          }
        })
      })
    })
  }

  // Function to detect dropdownlist change and get new fields/groups
  public filterChange(id: any): void {
    this.searchFilterApiService.getFilterData(id.ID).subscribe({
      next: (filters) => {
        this.model.Groups = filters.Groups;

        if (this.model.UserReversedFixedGroups){
          this.model.Groups.forEach(group => {
            group.Fields.forEach(field => {
              if(field.FieldType == TableFieldDataType.OrganizationChartUnitSelector && (field.TableFieldID == this.model.UserReversedFixedGroups![0].Fields[0].TableFieldID)){
                field.Values[0].SelectedOrganizationChartItems.push(this.model.UserReversedFixedGroups![0].Fields[0].Values[0].SelectedOrganizationChartItems[0])
              }
            });
          });
        }
      },
      error: (error) => {
        console.error('An error occurred:', error);
      }
    });
  }


  protected readonly FilterGroupType = FilterGroupType;
  protected readonly parent = parent;
}
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { ModalComponent } from '../modal/modal.component';
import { StepperStep } from '../../ui/stepper/interfaces/stepper-step';
import { StepperComponent } from '../../ui/stepper/stepper.component';
import { IconLibrary } from 'src/models/ts/icon-library.model';
import { NgTemplateOutlet } from '@angular/common';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconComponent } from '../../ui/icon/icon.component';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { take } from 'rxjs';
import { LinkingService } from 'src/app/core/services/linking/linking.service';
import { PdfPermission } from 'src/models/ts/pdf-permission.model';
import { PdfPermissionDto } from 'src/models/ts/pdf-permission-dto.model';
import { DocumentSettingsTabs } from 'src/app/shared/enums/document-settings-tabs';
import { DocumentSettingsParams } from './interfaces/document-settings-params';



@Component({
  selector: 'bizz-document-settings',
  standalone: true,
  imports: [FormsModule,
    TranslatePipe,
    ModalComponent,
    StepperComponent,
    NgTemplateOutlet,
    DropDownsModule,
    IconComponent,
    ReactiveFormsModule,

  ],
  templateUrl: './document-settings.component.html',
  styleUrl: './document-settings.component.scss'
})
export class DocumentSettingsComponent implements OnInit {
  public propertiesForm: FormGroup;

  public steps: StepperStep[] = [{
    label: 'Bookmarks', disabled: false, showLabelOnMobile: true, icon: { name: 'file-word', library: IconLibrary.FontAwesome, },
  }, {
    label: 'DirectLink', disabled: false, showLabelOnMobile: true, icon: { name: 'link-simple', library: IconLibrary.FontAwesome, },
  }, {
    label: 'PDFConverter', disabled: false, showLabelOnMobile: true, icon: { name: 'file-pdf', library: IconLibrary.FontAwesome, },
  }, {
    label: 'AssessmentAndDistribution', disabled: false, showLabelOnMobile: true, icon: { name: 'clipboard-list', library: IconLibrary.FontAwesome, },
  },
  ];
  @ViewChild('bookmarksTabTemplateRef', { static: true }) private bookmarksTabTemplateRef: TemplateRef<any>;
  @ViewChild('directLinkTabTemplateRef', { static: true }) private directLinkTabTemplateRef: TemplateRef<any>;
  @ViewChild('pdfConverterTabTemplateRef', { static: true }) private pdfConverterTabTemplateRef: TemplateRef<any>;
  @ViewChild('assessmentAndDistributionTabTemplateRef', { static: true }) private assessmentAndDistributionTabTemplateRef: TemplateRef<any>;
  public activeTabTemplateRef: TemplateRef<any>;

  public activeTab: DocumentSettingsTabs;

  protected readonly PdfPermission = PdfPermission;

  public disableAssembly: boolean = false;
  public constructor(public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: DocumentSettingsParams,
    private collectionListService: CollectionListApiService,
    private linkingService: LinkingService,
    private fb: FormBuilder,
  ) {

  }

  public setDisableAssembly(): void {
    // if AllowChangeDocument is false, then AllowDocumentAssembly should be false as well
    const item = this.data.properties.PdfPermissions.find(x => x.Permission == PdfPermission.AllowChangeDocument);
    if (item) {
      this.disableAssembly = item.Allow;
    }
  }

  public ngOnInit(): void {
    this.setDisableAssembly();
    this.setTemplateRef(0);
    this.propertiesForm = this.buildForm();
  }

  private buildForm(): FormGroup {
    return this.fb.group({
      UseWordExcelInteraction: new FormControl(this.data.properties.UseWordExcelInteraction),
      EnableSearchIndexation: new FormControl(this.data.properties.EnableSearchIndexation),
      RetrDocAccessType: new FormControl(this.data.properties.RetrDocAccessType),
      DirectLink: new FormControl(this.data.properties.DirectLink),
      UsePDFConverter: new FormControl(this.data.properties.UsePDFConverter),
      SetPdfACompliant: new FormControl(this.data.properties.SetPdfACompliant),
      EnableWordHeaderConversionToPdfBookmarks: new FormControl(this.data.properties.EnableWordHeaderConversionToPdfBookmarks),
      DisplayFieldsInheritType: new FormControl(this.data.properties.DisplayFieldsInheritType),
    });

  }

  public setTemplateRef(step: number): void {
    this.activeTab = DocumentSettingsTabs[DocumentSettingsTabs[step] as keyof typeof DocumentSettingsTabs];
    switch (this.activeTab) {
      case DocumentSettingsTabs.BOOKMARKS:
        this.activeTabTemplateRef = this.bookmarksTabTemplateRef;
        break;
      case DocumentSettingsTabs.DIRECTLINK:
        this.activeTabTemplateRef = this.directLinkTabTemplateRef;
        break;
      case DocumentSettingsTabs.PDFCONVERTER:
        this.activeTabTemplateRef = this.pdfConverterTabTemplateRef;
        break;
      case DocumentSettingsTabs.ASSESSMENTANDDISTRIBUTION:
        this.activeTabTemplateRef = this.assessmentAndDistributionTabTemplateRef;
        break;
    }
  }
  public copyToClipboard(): void {
    this.linkingService.copyToClipboard(this.data.properties.DirectLink);
  }

  public togglePermission(item: PdfPermissionDto): void {
    item.Allow = !item.Allow;
    if (item.Permission == PdfPermission.AllowChangeDocument) {
      const permission = this.data.properties.PdfPermissions.find(x => x.Permission == PdfPermission.AllowDocumentAssembly);
      if (permission) {
        permission.Allow = item.Allow;
      }
      this.disableAssembly = item.Allow;
    }
  }
  public getDirectLink(accessType: number): void {
    this.collectionListService.getDirectLinkFromInstance(this.data.collectionId, this.data.instanceId, this.data.versionId, accessType)
      .pipe(take(1))
      .subscribe({
        next: (value: string) => {
          this.propertiesForm.value.DirectLink = value;
        }
      });
  }

  public saveProperties(): void {
    this.data.properties.UseWordExcelInteraction = this.propertiesForm.get('UseWordExcelInteraction')?.value;
    this.data.properties.EnableSearchIndexation = this.propertiesForm.get('EnableSearchIndexation')?.value;
    this.data.properties.RetrDocAccessType = this.propertiesForm.get('RetrDocAccessType')?.value;
    this.data.properties.DirectLink = this.propertiesForm.get('DirectLink')?.value;
    this.data.properties.UsePDFConverter = this.propertiesForm.get('UsePDFConverter')?.value;
    this.data.properties.SetPdfACompliant = this.propertiesForm.get('SetPdfACompliant')?.value;
    this.data.properties.EnableWordHeaderConversionToPdfBookmarks = this.propertiesForm.get('EnableWordHeaderConversionToPdfBookmarks')?.value;
    this.data.properties.DisplayFieldsInheritType = this.propertiesForm.get('DisplayFieldsInheritType')?.value;
    this.collectionListService.savePropertiesFromInstance(this.data.collectionId, this.data.instanceId, this.data.versionId, this.data.properties)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        }
      });
  }



}

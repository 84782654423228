import { Component } from '@angular/core';
import { LinkedFormControlComponent } from '../linked-form-control.component';
import { selectForm, selectFormFieldByPredicate } from '../../../../../../../store/features/forms/forms-selectors';
import { ProtectedFieldType } from '../../../../../../../../models/ts/protected-field-type.model';
import { LookupData } from '../../../../../../../shared/services/lookup/lookup.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LinkedFormControlButtonsComponent } from '../../linked-form-control-buttons/linked-form-control-buttons.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TooltipComponent } from '../../../../../../../shared/components/ui/tooltip/tooltip.component';
import { IconComponent } from '../../../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate/translate.pipe';
import { Observable, take } from 'rxjs';
import { formsActions } from '../../../../../../../store/features/forms/forms-actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'bizz-entity-linked-form-control',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, LinkedFormControlButtonsComponent, FormsModule, DropDownsModule, TooltipComponent, TooltipModule,  IconComponent, TranslatePipe],
  templateUrl: '../linked-form-control.component.html',
  styleUrl: '../linked-form-control.component.scss'
})
export class EntityLinkedFormControlComponent extends LinkedFormControlComponent {

  public override ngOnInit(): void {
    super.ngOnInit();
    const formData = this.form?.data;
    if (this.fieldViewDataSource !== undefined && this.fieldViewDataSource.Instances.length > 0 && this.fieldViewDataSource.AutocompleteEntity && formData !== undefined && formData.InstancesID <= 0) {
      const instance = this.fieldViewDataSource.Instances[0];
      this.formField$.pipe(takeUntilDestroyed(this.destroyRef), take(1)).subscribe({
        next: field => {
          if (field !== undefined) {
            this.store$.dispatch(formsActions.lookupChanged({
                lookupItem: {
                  InstancesID: instance.ChildInstancesID,
                  OriginalChildInstancesID: 0,
                  Text: '',
                  VersionsID: instance.ChildVersionsID,
                  CrossLinkInstancesID: instance.CrossLinkedInstancesID,
                  LinkedToParentVersions: [],
                  GuidChecksum: instance.GuidChecksum
                },
                formId: this.formId,
                lookupFieldId: field.Id
              })
            );
          }
        }
      });
    }
  }

  // TODO: change with different endpoint for entity
  public override performLookup(search: string): void {
    const field = this.formFieldSignal();
    const fieldId = field?.Id;
    if (search !== '' && search !== undefined && fieldId && field) {
      const baseCollectionsId = this.viewDataSources[0].ParentCollectionsID;
      // this must be recalculated because the parentrelation can have new values
      const lookupData = this.getLookupData(field.ViewDataSourcesID);
      if (lookupData !== undefined && lookupData.registeredBy !== undefined && lookupData.registredOnBehalfOf !== undefined) {
        let request: Observable<any>;
        if (lookupData.linkedLevel === 1) {
          request = this.collectionFormLookupApiService.getEntitySearchByTypeAhead(baseCollectionsId, fieldId, lookupData.viewsId, field.ViewDataSourcesID, search, lookupData.registeredBy, lookupData.registredOnBehalfOf, lookupData.versionsId ?? -1);
        } else {
          // TODO: deeper level if possible?
          request = this.collectionFormLookupApiService.getSearchByTypeAheadDeeperLevel(baseCollectionsId, fieldId, field.ViewDataSourcesID, lookupData.childCollectionsId, lookupData.childOriginalCollectionsId, lookupData.instancesId, search);
        }
        request.subscribe({
          next: (items) => {
            this.searchResults = items;
          }
        });
      }
    } else {
      this.searchResults = [];
    }
  }

  protected override getLookupData(vdsId: number): LookupData | undefined {
    const lookupData = super.getLookupData(vdsId);
    if (lookupData !== undefined) {
      const registeredBy = this.store$.selectSignal(selectFormFieldByPredicate(this.formId, field => field.ProtectedFieldType == ProtectedFieldType.RegisteredBy))();
      const registeredOnBehalfOf = this.store$.selectSignal(selectFormFieldByPredicate(this.formId, field => field.ProtectedFieldType == ProtectedFieldType.RegisteredOnBehalfOf))();
      const versionsId = this.store$.selectSignal(selectForm(this.formId))()?.data.VersionsID ?? -1;

      lookupData.registeredBy = registeredBy?.OrgChartUnitSelector[0]?.VersionId;
      lookupData.registredOnBehalfOf = registeredOnBehalfOf?.OrgChartUnitSelector[0]?.VersionId;
      lookupData.versionsId = versionsId;
      return lookupData;
    } else return lookupData;
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MacroButtonApiService {

  private http = inject(HttpClient);

  /**
   * Returns the url for given macro button. URL parameter is filled by API if empty.
   * Could be used to partially filly the URL and then get the rest from API but this is not implemented in legacy code.
   * @param {number} collectionsId
   * @param {number} instancesId
   * @param {number} versionsId
   * @param {number} collectionFieldsId
   * @param {string} url
   * @return {Observable<string>}
   */
  public getMacroButtonUrl(collectionsId: number, instancesId: number, versionsId: number, collectionFieldsId: number, url: string): Observable<string> {
    return this.http.post(`api/collection/${collectionsId}/instance/${instancesId}/version/${versionsId}/field/${collectionFieldsId}/macrobutton/url`, isEmpty(url) ? {} : {Url: url}, { responseType: "text"});
  }

  /**
   * Returns the url for given macro button in a linked grid. URL parameter is filled by API if empty.
   * @param {number} collectionsId
   * @param {number} instancesId
   * @param {number} versionsId
   * @param {number} collectionFieldsId
   * @param {number} viewDataSourcesId
   * @param {number} crossLinkedInstancesId
   * @param {number} childInstancesId
   * @param {string} url
   * @return {Observable<string>}
   */
  public getGridMacroButtonUrl(collectionsId: number, instancesId: number, versionsId: number, collectionFieldsId: number, viewDataSourcesId: number, crossLinkedInstancesId: number, childInstancesId: number, url: string): Observable<string> {
    return this.http.post(`api/collection/${collectionsId}/instance/${instancesId}/version/${versionsId}/field/${collectionFieldsId}/vds/${viewDataSourcesId}/crosslink/${crossLinkedInstancesId}/child/${childInstancesId}/macrobutton/url`, isEmpty(url) ? {} : {Url: url},{ responseType: "text"});
  }
}

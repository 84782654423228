import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CldrIntlService, localeData } from '@progress/kendo-angular-intl';
import { UserSettingsDto } from '../../../../models/ts/user-settings-dto.model';
import { LANGUAGE_LOCALE_IDS } from '../../../shared/constants/language-locale-ids';
import { Languages } from '../../../shared/enums/languages';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    @Inject(CldrIntlService) private intlService: CldrIntlService
  ) {
  }

  public updateLocaleWithUserSettings(userSettings: UserSettingsDto): void {
    const languageId = LANGUAGE_LOCALE_IDS[userSettings.UserLanguagesID];

    if (!languageId){
      console.error('local user language ID is undefined', userSettings.UserLanguagesID)
      return;
    }

    this.intlService.localeId = LANGUAGE_LOCALE_IDS[userSettings.UserLanguagesID];

    let locale = localeData(this.localeId);
    if (locale.numbers?.symbols) {
      locale.numbers.symbols.decimal = userSettings.DecimalSeparatorString;
      locale.numbers.symbols.group = userSettings.ThousandSeparatorString;
      if (locale.calendar?.dateFormats?.full)
        locale.calendar.dateFormats.full = userSettings.DateTimeFormatTypeString;
      if (locale.calendar?.dateTimeFormats?.full)
        locale.calendar.dateTimeFormats.full = userSettings.DateFormatTypeString;
      if (locale.calendar?.timeFormats?.full)
        locale.calendar.timeFormats.full = userSettings.TimeFormatType;
    }
  }
}

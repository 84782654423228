import { Component, DestroyRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatestWith, filter, startWith } from 'rxjs';
import { StateType } from '../../../../../models/ts/state-type.model';
import { CollectionMethodType } from '../../../../../models/ts/collection-method-type.model';
import { TaskApiService } from '../../../../api/bizzmine/task/task-api.service';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'bizz-form-landing-page',
  standalone: true,
  imports: [],
  templateUrl: './form-landing-page.component.html',
  styleUrl: './form-landing-page.component.scss'
})
export class FormLandingPageComponent {
  private collectionIdRouteParam = 'collectionId';
  private instanceIdRouteParam = 'instanceId';
  private versionIdRouteParam = 'versionId';
  private checkSumRouteParam = 'checksum';
  private readonlyUrlSegment = 'readonly';
  private stateRouteParam = 'state';
  private crossLinkCollectionIdRouteParam = 'crosslinkCollectionsId';
  private childInstanceIdRouteParam = 'childInstancesId';
  private childVersionIdRouteParam = 'childVersionsId';
  private originalChildInstanceIdRouteParam = 'originalChildInstancesId';
  private widgetIdRouteParam = 'widgetId';
  private workspaceItemsIdRouteParam = 'workspaceItemsId';
  private taskIdRouteParam = 'taskId';
  private revisionIdRouteParam = 'revisionId';

  public collectionId : number | undefined = undefined;
  public instanceId : number | undefined = undefined;
  public versionId : number | undefined = undefined;
  public checkSum : string | undefined = undefined;
  public readonly : boolean | undefined = undefined;
  public state : StateType | undefined = undefined;
  public crossLinkCollectionId : number | undefined = undefined;
  public childInstanceId : number | undefined = undefined;
  public childVersionId : number | undefined = undefined;
  public originalChildInstanceId : number | undefined = undefined;
  public widgetId : number | undefined = undefined;
  public collectionMethodType : CollectionMethodType | undefined = undefined;
  public workspaceItemsId : number | undefined = undefined;
  public revisionId : number | undefined = undefined;
  public taskId : number | undefined = undefined;
  public constructor(public route: ActivatedRoute,
                     public store$: Store,
                     private destroyRef: DestroyRef,
                     public router: Router,
                     public taskApiService: TaskApiService,
                     public dialog: Dialog) {
    //routerNavigated$ provided for when we hit the same url mutliple times -> gets form removed needs to refresh data (e.g. same record from notification-viewdetail hit)
    const routerNavigated$ = this.router.events.pipe(takeUntilDestroyed(this.destroyRef), filter((e): e is NavigationEnd => e instanceof NavigationEnd),startWith(undefined));
    this.route.params.pipe(combineLatestWith(this.route.url,this.route.queryParams,routerNavigated$), takeUntilDestroyed(this.destroyRef))
      .subscribe(([params, urlSegments, queryParam]) => {
        this.applyRoute(params, urlSegments, queryParam);
      });
  }


  public init() : void{
    this.collectionId = undefined;
    this.instanceId = undefined;
    this.versionId = undefined;
    this.readonly = undefined;
    this.state = undefined;
    this.crossLinkCollectionId = undefined;
    this.childInstanceId = undefined;
    this.childVersionId = undefined;
    this.originalChildInstanceId = undefined;
    this.widgetId = undefined;
    this.collectionMethodType = undefined;
    this.workspaceItemsId = undefined;
    this.revisionId = undefined;
    this.taskId = undefined;
  }

  public applyRoute(params: Params, urlSegments: Array<UrlSegment>,queryParams: Params): void {
    this.init();
    this.collectionId = params[this.collectionIdRouteParam];
    this.instanceId = params[this.instanceIdRouteParam];
    this.versionId = params[this.versionIdRouteParam];
    this.checkSum = queryParams != undefined ? queryParams[this.checkSumRouteParam] : undefined;
    this.readonly = urlSegments.find(segment => segment.path == this.readonlyUrlSegment) != null;
    this.state = params[this.stateRouteParam];
    this.crossLinkCollectionId = params[this.crossLinkCollectionIdRouteParam];
    this.childInstanceId = params[this.childInstanceIdRouteParam];
    this.childVersionId = params[this.childVersionIdRouteParam];
    this.originalChildInstanceId = params[this.originalChildInstanceIdRouteParam];
    this.widgetId = params[this.widgetIdRouteParam];
    this.collectionMethodType = this.getCollectionMethodType(urlSegments);
    this.workspaceItemsId = params[this.workspaceItemsIdRouteParam];
    this.taskId = params[this.taskIdRouteParam];
    this.revisionId = params[this.revisionIdRouteParam];

    this.routeApplied();
  }

  private getCollectionMethodType(urlSegments: Array<UrlSegment>): CollectionMethodType | undefined {
    let collectionMethodType = urlSegments.find(segment => segment.path.toLowerCase() == 'major');
    if (collectionMethodType != null) {
      return CollectionMethodType.RevisionWithMajorVersionChange;
    }
    collectionMethodType = urlSegments.find(segment => segment.path.toLowerCase() == 'minor');
    if (collectionMethodType != null) {
      return CollectionMethodType.RevisionWithMinorVersionChange;
    }
    return undefined;
  }

  public routeApplied() : void {}

}

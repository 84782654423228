@if (formFieldSignal(); as formField) {
    <div class="flex items-center gap-2">
        <input [ngClass]="{'hidden' : formField.IsHidden}" [class.error]="errorState != null"
               [formControl]="formControl" id="{{formField.Id}}" type="checkbox" kendoCheckBox>
        <label class="flex items-center justify-center gap-1 text-sm font-semibold" [for]="formField.Id">
            <span class="flex items-center gap-2" [innerHTML]="formField.Caption">
            </span>
            @if (formField.IsRequired) {
                <span class="-top-0.5 h-5 text-xl leading-4 text-red-400">*</span>
            }
            @if (errorState?.Message; as errorMessage) {
                <div class="z-[1] flex h-full items-center justify-center">
                    <bizz-tooltip class="relative -top-2 cursor-pointer text-red-400"
                                  [text]="errorMessage"></bizz-tooltip>
                </div>
            }
        </label>
    </div>
}

@if (text) {
    <div kendoTooltip>
        <bizz-icon
                class="h-3 w-3"
                title="{{text}}"
                [hover]="false"
                iconName="circle-info">
        </bizz-icon>
    </div>
}

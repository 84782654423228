import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { formsActions } from '../forms-actions';
import { catchError, combineLatestWith, concatMap, exhaustMap, filter, map, of, tap } from 'rxjs';
import { StoreCollectionFormService } from '../../../../features/bizzmine/form/services/store-collection-form.service';
import { CollectionFormService } from '../../../../features/bizzmine/form/services/collection-form.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CollectionFormEditApiService } from '../../../../api/bizzmine/collection-form-edit/collection-form-edit-api.service';
import { CollectionFormLookupApiService } from '../../../../api/bizzmine/collection-form-lookup/collection-form-lookup-api.service';
import { StoreCollectionForm } from '../forms-state';
import { CollectionFormApiService } from '../../../../api/bizzmine/collection-form/collection-form-api.service';
import { selectForm, selectFormField, selectFormFieldByPredicate, selectGridRecordField } from '../forms-selectors';
import { Store } from '@ngrx/store';
import { CollectionFormFieldSingleLookupData } from '../../../../../models/ts/collection-form-field-single-lookup-data.model';
import { ProtectedFieldType } from '../../../../../models/ts/protected-field-type.model';
import { ControlledDocumentUploadIntent } from '../../../../features/bizzmine/form/components/controls/controlled-document-control/controlled-document-upload-intent';
import { ControlledDocumentTemplateIntent } from '../../../../features/bizzmine/form/components/controls/controlled-document-control/controlled-document-template-intent';
import { viewStackActions } from '../view-stack-actions';
import { LookupService } from '../../../../shared/services/lookup/lookup.service';
import { CollectionFormFieldGridLookupData } from '../../../../../models/ts/collection-form-field-grid-lookup-data.model';
import { CollectionFormJsonService } from '../../../../features/bizzmine/form/services/collection-form-json.service';
import { CollectionFormLinkedService } from '../../../../features/bizzmine/form/services/collection-form-linked.service';
import { LookupItem } from '../../../../../models/ts/collection-list-summary-item.model';
import { FormFieldType } from '../../../../../models/ts/form-field-type.model';
import { LinkedCollectionType } from '../../../../../models/ts/linked-collection-type.model';

/**
 * Effects for linked collection(forms) interactions and lookups
 */
@Injectable()
export class FormsLinkedEffects {
  private actions$ = inject(Actions);
  //region OLD EFFECTS
  public closeFormAfterSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createLinkedFormInstanceSucceeded, formsActions.saveLinkedFormInstanceSucceeded, formsActions.createGridLinkedFormInstanceSucceeded, formsActions.saveGridLinkedFormInstanceSucceeded),
    map(({ formId }) => formsActions.formClosed({
        formId,
        unsavedChanges: false
      })
    )
  ));
  public changeLookupAfterLinkedFormSave$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createLinkedFormInstanceSucceeded, formsActions.saveLinkedFormInstanceSucceeded),
    map((params) => formsActions.lookupChanged({
        formId: params.parentFormId,
        lookupFieldId: params.parentFormFieldId,
        lookupItem: {
          InstancesID: params.instance.InstancesID,
          OriginalChildInstancesID: 0,
          Text: '',
          VersionsID: params.instance.VersionsID,
          CrossLinkInstancesID: 0,
          LinkedToParentVersions: []
        }
      }
    ))
  ));
  public gridRecordUpdated$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateGridRecord),
    map(({ formId, viewDataSourceId, lookupData, recordId, newRecordId }) => formsActions.gridRecordUpdated({
      formId,
      viewDataSourceId,
      recordId: newRecordId ?? recordId
    }))
  ));
  public addNmbilLookup$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lookupCreated),
    // Get lookup from API
    map(params => formsActions.lookupFetched({
      formId: params.formId,
      lookupFieldId: params.lookupFieldId,
      lookupItem: params.lookupItem,
      lookupData: undefined
    }))
  ));
  public addNmbilGridLookup$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.gridLookupCreated),
    // Get lookup from API
    map(params => formsActions.gridLookupFetched({
      formId: params.formId,
      gridFieldId: params.gridFieldId,
      recordId: params.recordId,
      lookupFieldId: params.lookupFieldId,
      lookupItem: params.lookupItem,
      lookupData: undefined
    }))
  ));
  private linkedService = inject(CollectionFormLinkedService);
  public getGridLookupByIdsForNewRecords$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.fetchGridLookupNewRecords),
    tap(intent => {
      this.linkedService.convertLookupsToRecords({
        formId: intent.formId,
        gridFieldId: intent.gridFieldId,
        lookupItems: intent.summaryItems
      });
    })
  ), { dispatch: false });
  /**
   * Calls a service that will get the required empty record, instance data, combine them and dispatch actions to add new records to the grid
   * @type {Observable<{readonly formId?: any, readonly form?: any, readonly field?: any, readonly instances?: any, readonly gridFieldId?: any}> & CreateEffectMetadata}
   * @private
   */
  public fetchMultipleGridLookUps = createEffect(() => this.actions$.pipe(
    ofType(formsActions.addInstancesToGrid),
    tap(({
           formId,
           gridFieldId,
           instances
         }) => this.linkedService.addInstancesToGrid(formId, gridFieldId, instances))
  ), { dispatch: false });
  private store$ = inject(Store);
  public fetchLookupDataFromApi$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lookupChanged),
    concatLatestFrom(({ formId, lookupFieldId }) => [
      this.store$.select(selectForm(formId)),
      this.store$.select(selectFormField(formId, lookupFieldId))
    ]),
    // Check for undefined and map to object
    map(([params, form, field]) => {
      if (form !== undefined && field !== undefined) return { params, form: form.data, field };
      else throw new Error(`Form ${params.formId} and/or field ${params.lookupFieldId} not found.`);
    }),
    // Get lookup from API
    concatMap(({ params, form, field }) =>
      this.linkedService.getSingleRecordLookup(form, field.ViewDataSourcesID, params.lookupItem.InstancesID, params.lookupItem.VersionsID, params.lookupItem.CrossLinkInstancesID).pipe(
        map(lookupData => formsActions.lookupFetched({
          formId: params.formId,
          lookupFieldId: params.lookupFieldId,
          lookupItem: params.lookupItem,
          lookupData: lookupData
        })),
        catchError(error => of(formsActions.lookupFailed({
          formId: params.formId
        })))
      )
    )
  ));
  public clearLookup$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lookupCleared),
    concatLatestFrom(({ formId }) => [
      this.store$.select(selectForm(formId))
    ]),
    map(([props, form]) => {
      if (form?.data !== undefined) {
        this.linkedService.clearLookup(props.formId, structuredClone(form.data), props.viewDataSourceId);
      } else throw new Error(`Form ${props.formId} not found.`);
    })
  ), { dispatch: false });
  public clearLookupOnChange$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lookupChanged),
    concatLatestFrom((props) => [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectFormField(props.formId, props.lookupFieldId))
    ]),
    map(([props, form, field]) => {
      if (form !== undefined && field !== undefined) {
        this.linkedService.clearLookup(props.formId, structuredClone(form.data), field.ViewDataSourcesID);
      } else throw new Error(`Form ${props.formId} not found.`);
    })
  ), { dispatch: false });
  public clearGridLookup$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.gridLookupCleared),
    concatLatestFrom(({ formId }) => [
      this.store$.select(selectForm(formId))
    ]),
    map(([props, form]) => {
      if (form?.data !== undefined) {
        this.linkedService.clearGridLookup(props.formId, structuredClone(form.data), props.viewDataSourceId, props.gridFieldId, props.recordId);
      } else throw new Error(`Form ${props.formId} not found.`);
    })
  ), { dispatch: false });
  public clearGridLookupOnChange$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.gridLookupChanged),
    concatLatestFrom((props) => [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectGridRecordField(props.formId, props.gridFieldId, props.recordId, f => f.CollectionFieldsID == props.lookupFieldId))
    ]),
    map(([props, form, field]) => {
      if (form !== undefined && field !== undefined) {
        this.linkedService.clearGridLookup(props.formId, structuredClone(form.data), field.ViewDataSourcesID, props.gridFieldId, props.recordId);
      } else throw new Error(`Form ${props.formId} not found.`);
    })
  ), { dispatch: false });
  public linkNewlyCreatedInstanceToGrid$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createGridLinkedFormInstanceSucceeded),
    tap(({
           parentFormId,
           parentGridFieldId,
           parentRecordId,
           instance
         }) => {
      const lookupItem: LookupItem = {
        InstancesID: instance.InstancesID,
        OriginalChildInstancesID: instance.InstancesID,
        Text: '',
        VersionsID: instance.VersionsID,
        CrossLinkInstancesID: parentRecordId ?? 0,
        LinkedToParentVersions: []
      };

      if (parentRecordId != undefined) {
        this.store$.dispatch(formsActions.gridLookupChanged({
          formId: parentFormId,
          gridFieldId: parentGridFieldId,
          recordId: parentRecordId,
          lookupFieldId: parentGridFieldId,
          lookupItem: lookupItem
        }));
      } else {
        this.linkedService.convertLookupsToRecords({
          formId: parentFormId,
          gridFieldId: parentGridFieldId,
          lookupItems: [lookupItem]
        });
      }

    })
  ), { dispatch: false });
  public fetchGridLookupDataFromApi$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.gridLookupChanged),
    concatLatestFrom((data) => [
      this.store$.select(selectForm(data.formId)),
      this.store$.select(selectFormField(data.formId, data.gridFieldId))
    ]),
    // Check for undefined and map to object
    map(([params, form, field]) => {
      if (form !== undefined && field !== undefined) return { params, form: form.data, field };
      else throw new Error(`Form ${params.formId} and/or field ${params.lookupFieldId} not found.`);
    }),
    // Get lookup from API
    exhaustMap(({ params, form, field }) =>
      this.linkedService.getGridRecordLookup(form, field.ViewDataSourcesID, params.lookupItem.InstancesID, params.lookupItem.VersionsID, params.lookupItem.CrossLinkInstancesID).pipe(
        map(lookupData => formsActions.gridLookupFetched({
          formId: params.formId,
          gridFieldId: params.gridFieldId,
          recordId: params.recordId,
          lookupFieldId: params.lookupFieldId,
          lookupItem: params.lookupItem,
          lookupData: lookupData
        })),
        catchError(error => of(formsActions.gridLookupFailed({
          formId: params.formId
        })))
      )
    )
  ));
  public updateLookupFields$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lookupFetched),
    concatLatestFrom(props => [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectFormField(props.formId, props.lookupFieldId))
    ]),
    tap(([props, form, lookupField]) => {
      if (form?.data !== undefined && lookupField !== undefined) {
        // clear child lookups except for TRN cases
        if ((props.lookupData !== undefined && props.lookupData.TrainingViewDataSources?.length == 0) || props.lookupData == undefined)
          this.linkedService.clearChildLookupFields(props.formId, form.data, lookupField.ViewDataSourcesID);
        if (props.lookupData !== undefined) {
          // TODO: Jonas check against racing conditions
          this.linkedService.updateTrainingLookupFields(props.formId, props.lookupData);
          this.linkedService.updateLookupFields(props.formId, form.data, props.lookupData);
        }
      } else throw new Error();
    })
  ), { dispatch: false });
  public updateLookupViewDataSources$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lookupFetched),
    concatLatestFrom(props => [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectFormField(props.formId, props.lookupFieldId))
    ]),
    tap(([props, form, lookupField]) => {
      if (form?.data !== undefined && lookupField !== undefined) {
        this.linkedService.updateViewDataSourceInstance(props.formId, form.data, lookupField.ViewDataSourcesID, props.lookupItem, props.lookupData);
        // TODO: Jonas check against racing conditions
        if (props.lookupData !== undefined)
          this.linkedService.updateTrainingViewDataSources(props.formId, props.lookupData, lookupField);
      } else throw new Error();
    })
  ), { dispatch: false });
  // ));
  public formFetchedWithFiles$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formFetchedWithFiles),
    filter(({ files }) => files != null && files.length > 0),
    tap(({
           form,
           files
         }) => {
        const fileField = CollectionFormService.getField(form.data, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.File && f.ViewDataSourcesID == 0;
        });
        if (fileField) {
          this.store$.dispatch(formsActions.updateFormFieldValue({
            formId: form.id,
            fieldId: fileField.Id,
            value: new ControlledDocumentUploadIntent(files)
          }));
        }

      }
    )
  ), { dispatch: false });
  public formFetchedWithTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formFetchedWithTemplate),
    filter(({ selectedTemplate }) => selectedTemplate != null),
    tap(({
           form,
           selectedTemplate
         }) => {
        const fileField = CollectionFormService.getField(form.data, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.File && f.ViewDataSourcesID == 0;
        });
        if (fileField) {
          this.store$.dispatch(formsActions.updateFormFieldValue({
            formId: form.id,
            fieldId: fileField.Id,
            value: new ControlledDocumentTemplateIntent(selectedTemplate)
          }));
        }

      }
    )
  ), { dispatch: false });
  public t$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.gridLookupFetched),
    concatLatestFrom((props) => [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectGridRecordField(props.formId, props.gridFieldId, props.recordId, f => f.CollectionFieldsID == props.lookupFieldId))
    ]),
    tap(([props, form, lookupField]) => {
      if (form !== undefined && lookupField !== undefined)
        this.linkedService.updateViewDataSourceInstanceForGrid(props.formId, form.data, lookupField.ViewDataSourcesID, props.lookupItem, props.lookupData, props.recordId);
      else throw new Error('Missing form/field data, cannot update VDS.');
    })
  ), { dispatch: false });
  public formByWidgetIdWithCategory$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formFetchedByWidgetIdWithCategory),
    concatLatestFrom((props) => [
      this.store$.select(selectFormFieldByPredicate(props.form.id, field => field.IsLookupField && field.SourceCollectionsID == props.skillCategoryCollectionId))
    ]),
    map(([props, field]) => {
      if (field !== undefined && field !== null) {
        this.store$.dispatch(formsActions.lookupChanged({
          formId: props.form.id,
          lookupItem: props.lookupItem,
          lookupFieldId: field.Id
        }));
      } else throw new Error(`Skill Category ${props.skillCategoryCollectionId} lookup not found on form ${props.form.id}`);
    })
  ), { dispatch: false });
  public clearLinkedFieldValues$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.clearLinkedFieldValues),
    concatLatestFrom(({ formId }) => this.store$.select(selectForm(formId))),
    tap(([{ formId, field }, form]) => {
      if (form && form.data)
        this.linkedService.clearLookup(formId, structuredClone(form.data), field.ViewDataSourcesID);
    })
  ), { dispatch: false });
  private collectionFormApiService = inject(CollectionFormApiService);
  public getLinkedInstanceRead$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getLinkedInstance),
    filter(action => action.read),
    exhaustMap(({
                  collectionId, instanceId, versionId, read, formFieldId, formId, relationType, guidChecksum
                }) => this.collectionFormApiService.getFormByInstanceRead(collectionId, instanceId, versionId, guidChecksum)
      .pipe(
        catchError(err => {

          if (err.status === 403) {
            this.store$.dispatch(formsActions.unAuthorizedFetch({ formId: formId }));
          }
          throw err;
        }),
        map(
          data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: formFieldId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      ))
  ));
  public getGridLinkedInstanceRead$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getGridLinkedInstance),
    filter(action => action.read),
    exhaustMap(({
                  collectionId, instanceId, versionId, read, formFieldId, formId, relationType, guidChecksum
                }) => this.collectionFormApiService.getFormByInstanceRead(collectionId, instanceId, versionId, guidChecksum)
      .pipe(
        catchError(err => {
          if (err.status === 403) {
            this.store$.dispatch(formsActions.unAuthorizedFetch({ formId: formId }));
          }
          throw err;
        }),
        map(
          data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: formFieldId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      ))
  ));
  public getLinkedInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getLinkedInstance),
    filter(action => !action.read),
    exhaustMap(({
                  collectionId, instanceId, versionId, read, formFieldId, formId, relationType, guidChecksum
                }) => this.collectionFormApiService.getFormByInstance(collectionId, instanceId, versionId, guidChecksum)
      .pipe(
        catchError(err => {
          if (err.status === 403) {
            this.store$.dispatch(formsActions.unAuthorizedFetch({}));
          }
          throw err;
        }),
        map(
          data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: formFieldId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      ))
  ));
  public getGridLinkedInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getGridLinkedInstance),
    filter(action => !action.read),
    exhaustMap(({
                  collectionId,
                  instanceId,
                  versionId,
                  read,
                  formFieldId,
                  formId,
                  gridFieldId,
                  recordId,
                  relationType,
                  guidChecksum
                }) => this.collectionFormApiService.getFormByInstance(collectionId, instanceId, versionId, guidChecksum)
      .pipe(
        catchError(err => {
          if (err.status === 403) {
            this.store$.dispatch(formsActions.unAuthorizedFetch({}));
          }
          throw err;
        }),
        map(
          data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: formFieldId,
              parentGridFieldId: gridFieldId,
              parentRecordId: recordId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      ))
  ));
  private collectionFormEditApiService = inject(CollectionFormEditApiService);
  public getLinkedForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getLinkedForm),
    exhaustMap(({
                  formFieldId,
                  formId,
                  relationType
                }) => this.collectionFormEditApiService.getFormByCollectionFormField(formFieldId)
      .pipe(
        map(data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: formFieldId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      )
    )
  ));
  public getExternalLinkedForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getExternalLinkedForm),
    exhaustMap(({
                  externalAccess,
                  formFieldId,
                  formId,
                  relationType
                }) => this.collectionFormEditApiService.getFormByParentAndDataSource({
        parentFormId: externalAccess.FormID,
        viewDataSourceId: externalAccess.ViewDataSourcesID
      })
        .pipe(
          map(data => ({
              type: formsActions.formFetched.type,
              form: new StoreCollectionForm(data, {
                parentFormId: formId,
                parentFormFieldId: formFieldId,
                linkType: relationType
              }),
              addToViewStack: true
            })
          )
        )
    )
  ));
  public getGridLinkedForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getGridLinkedForm),
    exhaustMap(({
                  formId,
                  collectionFieldId,
                  gridFieldId,
                  recordId,
                  relationType
                }) => this.collectionFormEditApiService.getFormByCollectionFormFieldFromGrid(gridFieldId)
      .pipe(
        map(data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: collectionFieldId,
              parentGridFieldId: gridFieldId,
              parentRecordId: recordId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      )
    )
  ));
  public getExternalForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getExternalGridLinkedForm),
    exhaustMap(({
                  externalAccess,
                  formFieldId,
                  formId,
                  gridFieldId,
                  recordId,
                  relationType
                }) => this.collectionFormEditApiService.getFormByParentAndDataSource({
        parentFormId: externalAccess.FormID,
        viewDataSourceId: externalAccess.ViewDataSourcesID
      })
        .pipe(
          map(data => ({
              type: formsActions.formFetched.type,
              form: new StoreCollectionForm(data, {
                parentFormId: formId,
                parentFormFieldId: formFieldId,
                parentGridFieldId: gridFieldId,
                parentRecordId: recordId,
                linkType: relationType
              }),
              addToViewStack: true
            })
          )
        )
    )
  ));
  public getGridLinkedFormWithFile$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getGridLinkedFormWithFile),
    exhaustMap(intent => {
        if (intent.externalAccess != null) {
          return this.collectionFormEditApiService.getFormByParentAndDataSource({
            parentFormId: intent.externalAccess.FormID,
            viewDataSourceId: intent.externalAccess.ViewDataSourcesID
          }).pipe(combineLatestWith(of(intent)));
        }
        return this.collectionFormEditApiService.getFormByCollectionFormFieldFromGrid(intent.gridFieldId).pipe(combineLatestWith(of(intent)));
      }
    ),
    map(([data, intent]) => ({
        type: formsActions.formFetchedWithFiles.type,
        form: new StoreCollectionForm(data, {
          parentFormId: intent.formId,
          parentFormFieldId: intent.formFieldId,
          parentGridFieldId: intent.gridFieldId,
          parentRecordId: intent.recordId,
          linkType: intent.relationType
        }),
        addToViewStack: true,
        files: intent.files
      })
    )
  ));
  public getGridLinkedFormWithTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getGridLinkedFormWithTemplate),
    exhaustMap(intent => {
        if (intent.externalAccess != null) {
          return this.collectionFormEditApiService.getFormByParentAndDataSource({
            parentFormId: intent.externalAccess.FormID,
            viewDataSourceId: intent.externalAccess.ViewDataSourcesID
          }).pipe(combineLatestWith(of(intent)));
        }
        return this.collectionFormEditApiService.getFormByCollectionFormFieldFromGrid(intent.gridFieldId).pipe(combineLatestWith(of(intent)));
      }
    ), map(([data, intent]) => ({
        type: formsActions.formFetchedWithTemplate.type,
        form: new StoreCollectionForm(data, {
          parentFormId: intent.formId,
          parentFormFieldId: intent.formFieldId,
          parentGridFieldId: intent.gridFieldId,
          parentRecordId: intent.recordId,
          linkType: intent.relationType
        }),
        addToViewStack: true,
        selectedTemplate: intent.selectedTemplate
      })
    )
  ));
  public preValidateCreateLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createLinkedFormInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      false,
      form
    ).pipe(
      map(data => ({
        type: formsActions.createLinkedFormInstance.type,
        parentFormId,
        parentFormFieldId,
        formId,
        form, preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public createLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createLinkedFormInstance),
    filter(({ preValidate }) => !preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.createInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)), false)
      .pipe(
        map(data => ({
          type: formsActions.createLinkedFormInstanceSucceeded.type,
          parentFormId,
          parentFormFieldId,
          formId,
          instance: data
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.createLinkedFormInstanceFailed({
            formId,
            response
          }))
        )
      )
    )
  ));
  public preValidateCreateGridLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createGridLinkedFormInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  parentGridFieldId,
                  parentRecordId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      false,
      form
    ).pipe(
      map(data => ({
        type: formsActions.createGridLinkedFormInstance.type,
        parentFormId,
        parentFormFieldId,
        parentGridFieldId,
        parentRecordId,
        formId,
        form,
        preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public createGridLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createGridLinkedFormInstance),
    filter(({ preValidate, parentRecordId }) => !preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  parentGridFieldId,
                  parentRecordId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.createInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)), false).pipe(
        map(data => ({
          type: formsActions.createGridLinkedFormInstanceSucceeded.type,
          parentFormId,
          parentFormFieldId,
          parentGridFieldId,
          parentRecordId,
          formId,
          instance: data
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.createLinkedFormInstanceFailed({
            formId,
            response
          }))
        )
      )
    )
  ));
  public preValidateSaveLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveLinkedFormInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      false,
      form
    ).pipe(
      map(data => ({
        type: formsActions.saveLinkedFormInstance.type,
        parentFormId,
        parentFormFieldId,
        formId,
        form, preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public saveLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveLinkedFormInstance),
    filter(({ preValidate }) => !preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.saveInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)))
      .pipe(
        map(data => ({
          type: formsActions.saveLinkedFormInstanceSucceeded.type,
          parentFormId,
          parentFormFieldId,
          formId,
          instance: data
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.saveLinkedFormInstanceFailed({
            formId,
            response
          }))
        )
      )
    )
  ));
  public preValidateSaveGridLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveGridLinkedFormInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  parentGridFieldId,
                  parentRecordId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      false,
      form
    ).pipe(
      map(data => ({
        type: formsActions.saveGridLinkedFormInstance.type,
        parentFormId,
        parentFormFieldId,
        parentGridFieldId,
        parentRecordId,
        formId,
        form,
        preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public saveGridLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveGridLinkedFormInstance),
    filter(({ preValidate }) => !preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  parentGridFieldId,
                  parentRecordId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.saveInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)))
      .pipe(
        map(data => ({
          type: formsActions.saveGridLinkedFormInstanceSucceeded.type,
          formId: formId,
          collectionId: form.CollectionsID,
          instanceId: data.InstancesID,
          versionId: data.VersionsID
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.saveLinkedFormInstanceFailed({
            formId,
            response
          }))
        )
      )
    )
  ));
  private collectionFormLookupApiService = inject(CollectionFormLookupApiService);
  // TODO: rework
  private lookupService = inject(LookupService);
  public getGridLookupByIdsForNewRecord$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.fetchGridLookupNewRecord),
    concatLatestFrom(props => [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectFormField(props.formId, props.gridFieldId))
    ]),
    concatMap(([props, form, field]) => this.lookupService.getEmptyGridRecord(form!.data.CollectionFormId, field!).pipe(
        concatLatestFrom(() => [
          this.store$.select(selectForm(props.formId)),
          this.store$.select(selectFormField(props.formId, props.gridFieldId))
        ]),
        concatMap(([newRecord, form, field]) => of({
          props,
          form,
          field,
          newRecord,
          vds: CollectionFormService.getViewDataSourceForFieldById(form!.data, props.gridFieldId)
        })),
        concatMap(({ props, form, field, newRecord, vds }) =>
          this.collectionFormLookupApiService.getFormFieldValuesByLookupField(form!.data.CollectionFormId, vds!.ViewDataSourcesID, vds!.SingleOrMany, props.summaryItem.InstancesID, props.summaryItem.VersionsID).pipe(
            map((response) => ({
              type: formsActions.addRowsToGrid.type,
              formId: props.formId,
              gridFieldId: props.gridFieldId,
              records: [CollectionFormService.fillRecordWithLookupData(structuredClone(newRecord), response as CollectionFormFieldGridLookupData, LookupService.getNextNewRecordId(field?.Records ?? []))]
            }))
          )
        )
      )
    )
  ));
  private storeCollectionFormService = inject(StoreCollectionFormService);
  public updateReadOnlyOnViewDataSourceUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateFormViewDataSource),
    concatLatestFrom(({ formId }) => this.store$.select(selectForm(formId))),
    tap(([{ formId, viewDataSource }, form]) => {
      if (form?.data !== undefined)
        this.storeCollectionFormService.updateViewDataSourceFieldsReadOnly(formId, form.data, viewDataSource.ViewDataSourcesID);
      else throw new Error(`No form with id ${formId} found.`);
    })
  ), { dispatch: false });
  public clearGridLinkedFieldValues$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.clearGridLinkedFieldValues),
    concatLatestFrom(({ formId }) => this.store$.select(selectForm(formId))),
    tap(([{ formId, fieldId, recordId }, form]) => {
      this.storeCollectionFormService.clearGridLinkedFields(formId, form!.data, fieldId, recordId);
    })
  ), { dispatch: false });
  /**
   * Fetches the lookup values for a crosslink field
   * Usually occurs because a childform is opened from a grid and we need to fill in the parentinfo
   * in the lookup fields of the childform
   * E.g: We add an employee to company form in the employees grid, in the new employee form
   * the company info needs to be filled in automatically.
   */
  public getLookupByCrossLinkId$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.fetchLookupByCrossLinkId),
    exhaustMap(({ formId, storeForm, gridParentFilter }) => {
        const form = storeForm.data;
        const vds = CollectionFormService.getViewDataSourceByCrossLinkId(form, gridParentFilter.CrosslinkCollectionsID);
        return this.collectionFormLookupApiService.getFormFieldValuesByLookupField(form.CollectionFormId, vds!.ViewDataSourcesID, vds!.SingleOrMany, gridParentFilter.ChildInstancesID, gridParentFilter.ChildVersionsID)
          .pipe(
            tap(lookupData => {
              if (vds?.SingleOrMany == LinkedCollectionType.GridRecord) {
                const field = CollectionFormService.getField(form, f => f.ViewDataSourcesID == vds!.ViewDataSourcesID && f.FormFieldType == FormFieldType.List);
                if (field) {
                  this.linkedService.convertLookupsToRecords({
                    formId: formId, gridFieldId: field!.Id, lookupItems: [{
                      InstancesID: gridParentFilter.ChildInstancesID,
                      OriginalChildInstancesID: gridParentFilter.ChildInstancesID,
                      Text: '',
                      VersionsID: gridParentFilter.ChildInstancesID,
                      CrossLinkInstancesID: gridParentFilter.CrosslinkCollectionsID,
                      LinkedToParentVersions: [],
                      GuidChecksum: ''
                    } as LookupItem]
                  });
                }
              } else {
                this.storeCollectionFormService.updateLinkedFields(formId, form, lookupData as CollectionFormFieldSingleLookupData);
              }

            }),
            map(() => ({
              type: viewStackActions.addFormToViewStack.type,
              data: storeForm
            }))
          );
      }
    )
  ));
  public updateGridLinkedFieldsIndividually$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateGridLinkedFieldValues),
    concatLatestFrom(({ formId, fieldId }) => [
      this.store$.select(selectForm(formId)),
      this.store$.select(selectFormField(formId, fieldId))
    ]),
    tap(([props, form, field]) => {
      if (form == undefined || field == undefined) throw new Error(`Form ${props.formId} and/or field ${props.fieldId} not found.`);
    }),
    map(([{
        formId,
        fieldId,
        recordId,
        summaryItem,
        value
      }, form, field]) =>
        this.storeCollectionFormService.updateFormWithGridLookup(formId, structuredClone(form!.data), fieldId, recordId, field!, value, summaryItem)
    )
  ), { dispatch: false });
  //endregion
}
import { AfterViewInit, Component, DestroyRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Dialog } from '@angular/cdk/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { Country, getAllCountries } from 'countries-and-timezones';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate, Location, NgIf } from '@angular/common';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { WorkspaceIndexDto } from '../../../../models/ts/workspace-index-dto.model';
import { selectUserSettingsFeature } from '../../../store/root-state-selectors';
import { UserSettingsApiService } from '../../../api/bizzmine/user-settings/user-settings-api.service';
import { ThemeService } from '../../../core/services/theme/theme.service';
import { ThousandSeparatorType } from '../../../../models/ts/thousand-separator-type.model';
import { UserSettingsIndexDto } from '../../../../models/ts/user-settings-index-dto.model';
import { UserSettingsDto } from '../../../../models/ts/user-settings-dto.model';
import { DecimalSeparatorType } from '../../../../models/ts/decimal-separator-type.model';
import { DelimiterType } from '../../../../models/ts/delimiter-type.model';
import { IconLibrary } from '../../../../models/ts/icon-library.model';
import { mapTimezonesDtoToMapped } from '../../../shared/functions/helpers/map-timezones';
import { mapToNewDelimiterTypes } from '../../../shared/functions/helpers/map-delimiters';
import { TimezonesDto } from '../../../../models/ts/timezones-dto.model';
import { TimezoneDto } from '../../../../models/ts/timezone-dto.model';
import { UserSettingsPasswordResetModalComponent } from '../../../shared/components/modals/user-settings-modal/Password/user-settings-password-reset-modal.component';
import { UserSettings2faResetModalComponent } from '../../../shared/components/modals/user-settings-modal/2FA/user-settings-2fa-reset-modal.component';
import { UserSettings2faSetupModalComponent } from '../../../shared/components/modals/user-settings-modal/2FA/Setup/user-settings-2fa-setup-modal.component';
import { UserSettings2faSetupModalData } from '../../../shared/components/modals/user-settings-modal/2FA/Setup/user-settings-2fa-setup-modal-data';
import { DropDownListModule, SharedModule } from '@progress/kendo-angular-dropdowns';
import { TranslatePipe } from '../../../shared/pipes/translate/translate.pipe';
import { WorkspaceSelectorComponent } from '../../../features/bizzmine/workspace-selector/components/workspace-selector/workspace-selector.component';
import { TwoFactorPipe } from '../../../shared/pipes/twofa/two-factor.pipe';
import { IconComponent } from '../../../shared/components/ui/icon/icon.component';
import { ThemeDto } from '../../../../models/ts/theme-dto.model';
import { ThemeSelectorModalComponent } from 'src/app/shared/components/modals/user-settings-modal/theme-selector-modal/theme-selector-modal.component';
import { ThemeSelectorButtonComponent } from '../../../shared/components/theme-selector-button/theme-selector-button.component';
import { map, switchMap, take, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '../../../core/services/auth/auth.service';
import { BizzMineSessionStorageService } from '../../../shared/services/localStorage/bizzmine-session-storage.service';
import { TokenResponse } from 'angular-oauth2-oidc';
import { userSettingsActions } from '../../../store/features/user-settings/user-settings-actions';
import { Operators } from '../../../../models/ts/operators.model';
import { DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { DateFilterService } from '../../../shared/services/filter/date-filter-service';
import { DatetimePipe } from '../../../shared/pipes/dates/datetime.pipe';
import { LANGUAGE_LOCALE_IDS } from '../../../shared/constants/language-locale-ids';
import { AggregateResult } from '@progress/kendo-data-query/dist/npm/transducers';
import { GroupedTimezone } from '../../../../models/ts/timezones-grouped';

export type ScrollbarVisibility = 'hover' | 'always' | 'native';

@Component({
  selector: 'bizz-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  standalone: true,
  imports: [
    DropDownListModule,
    TranslatePipe,
    NgIf,
    SharedModule,
    WorkspaceSelectorComponent,
    TwoFactorPipe,
    IconComponent,
    ThemeSelectorButtonComponent,
    DateTimePickerComponent,
    DatetimePipe
  ]
})
export class UserSettingsComponent implements OnInit{
  //TODO: Bv: 'noticed es lint errors' Check full component for code issues (or unused code).
  public index: UserSettingsIndexDto;
  public userSettings: UserSettingsDto;
  public userForm: FormGroup;
  public workspaces: WorkspaceIndexDto;
  public today: string;
  public timezones = UserSettingsComponent.getTimezones();
  public passwordReset = false;
  public ThousandSeparatorType = ThousandSeparatorType;
  public DecimalSeparatorType = DecimalSeparatorType;
  public DelimiterType = DelimiterType;
  public userSettingsSignal = this.store$.selectSignal(selectUserSettingsFeature);
  public hiddenElement: string;
  public error: string[] = [];
  public readonly IconLibrary = IconLibrary;
  public scrollBarVisible: ScrollbarVisibility = 'hover';
  public groupedData: GroupedTimezone[];
  public filteredTimeZones: GroupedTimezone[];
  public virtual: any = { itemHeight: 28 };
  public currentTheme: ThemeDto | null;

  public constructor(
    private destroyRef: DestroyRef,
    private fb: FormBuilder,
    private dialog: Dialog,
    private userSettingsApiService: UserSettingsApiService,
    private themeService: ThemeService,
    private authService: AuthService,
    private sessionStorage: BizzMineSessionStorageService,
    private store$: Store,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    ) {
  }

  /**
   * Builds a timezonesdto object from countries using moment-timezone.
   */
  public static getTimezones(): TimezonesDto[] {
    const countries = getAllCountries() as Record<string, Country>;
    const timezones: TimezonesDto[] = [];

    for (const key in countries) {
      const timezonesDto = new TimezonesDto();

      timezonesDto.CountryName = countries[key].name;
      timezonesDto.ID = countries[key].id;

      countries[key].timezones.forEach(function (country) {
        timezonesDto.Timezone.push({
            DisplayName: country,
            BaseUtcOffset: (moment.tz(moment.utc(), country)).format('Z')
          } as TimezoneDto
        );
      });
      timezones.push(timezonesDto);
    }
    return timezones;
  }

  public ngOnInit(): void {
    this.getRouteData();
    if (this.index) {
      this.userForm = this.buildForm(this.index);
    }

    const newItems = mapTimezonesDtoToMapped(this.timezones);
    this.groupedData = groupBy(newItems, [{ field: 'CountryName' }]) as GroupedTimezone[];
    this.filteredTimeZones = this.groupedData;

    this.index.DelimiterTypes = mapToNewDelimiterTypes(this.index.DelimiterTypes);
    this.index.DecimalSeparatorTypes = mapToNewDelimiterTypes(this.index.DecimalSeparatorTypes);
    this.index.ThousandSeparatorTypes = mapToNewDelimiterTypes(this.index.ThousandSeparatorTypes);


    this.themeService.currentTheme$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map((theme) => this.currentTheme = theme))
      .subscribe();

  }

  public filterByDisplayName(searchTerm: string): void {
    const searchTermLower = searchTerm.toLowerCase();

    this.filteredTimeZones = this.groupedData.map(group => {
      // Filter the items of each group (explicitly typing 'item' as MappedTimezone)
      const filteredItems = group.items.filter((item) =>
        item.Displayname.toLowerCase().includes(searchTermLower)
      );

      // Return the group if it has matching items
      if (filteredItems.length > 0) {
        return { ...group, items: filteredItems };
      } else {
        return null;
      }
    }).filter(group => group !== null); // Remove any groups with no matches
  }

  //function to get time zone offset of this.index.TimezoneName
  public getTimezoneOffset(): string {
    const ct = this.index.TimezoneName?.split(':').pop();
    return ct ? (moment.tz(moment.utc(), ct.trim())).format('Z') : '';
  }

  public get currentDateTime(): string {
    const format = this.userSettings.DateTimeFormatTypeString.replace('DD', 'dd');
    this.today = formatDate(new Date(), format, LANGUAGE_LOCALE_IDS[this.userSettings.UserLanguagesID], this.index.TimezoneOffset).toString();
    return this.today;
  }

  /** Gets the current URL and
   * removes the '/user-settings' part from it.
   * And navigates to the modified URL.
   */
  public goBack(): void {
    this.router.navigateByUrl(this.location.path().replace('/user-settings', '')).then(
      () => {
        window.location.reload();//needed for theme refresh.
      }
    );
  }

  public openResetPasswordModal(): void {
    const dialogRef = this.dialog.open<boolean>(UserSettingsPasswordResetModalComponent);

    dialogRef.closed.subscribe({
      next: (value) => {
        if (value)
          this.passwordReset = value;
      }
    });
  }

  /*  TODO: BV check if works (think ok)*/
  public checkActiveSperator(type: string): void {
    let secondType = 'Thousand';

    if (type == 'Thousand') {
      secondType = 'Decimal';
    }

    const seperatorId = this.userForm.get(`${type}SeparatorType`)?.value;
    const secondSeperatorId = this.userForm.get(`${secondType}SeparatorType`)?.value;
    const secondElement = document.getElementById(`${secondType}${seperatorId}`);

    if (this.hiddenElement != 'none' && document.getElementById(this.hiddenElement)?.classList.contains('hidden')) {
      document.getElementById(this.hiddenElement)?.classList.remove('hidden');
    }

    if (seperatorId == 3) {
      this.hiddenElement = (`none`);
    } else {
      this.hiddenElement = (`${secondType}${seperatorId}`);
      secondElement?.classList.add('hidden');

      if (seperatorId == secondSeperatorId) {
        this.userForm.get(`${secondType}SeparatorType`)?.setValue(0);
      }
    }
  }

  public openReset2faModal(): void {
    const dialogRef = this.dialog.open<boolean>(UserSettings2faResetModalComponent);

    dialogRef.closed.subscribe({
      next: (value) => {
        if (value)
          window.location.reload();
        //this.twofaReset = value
      }
    });
  }

  public openSetup2faModal(): void {

    let name = this.userSettings.UserFirstName + '.' + this.userSettings.UserLastName;

    if (this.userSettings.UserEmail) {
      name = this.userSettings.UserEmail;
    }

    const dialogRef = this.dialog.open<boolean>(UserSettings2faSetupModalComponent, { data: new UserSettings2faSetupModalData(this.index, name) });

    dialogRef.closed.subscribe({
      next: (value) => {
        if (value)
          window.location.reload();
        //this.twofaReset = value
      }
    });
  }

  public getId(): number {
    return this.userForm.get('DefaultWorkspacesID')?.value;
  }

  public handleOutputFromSelector(result: number): void {
    this.userForm.get('DefaultWorkspacesID')?.patchValue(result);
  }

  public openThemeSelectorModal(): void {
    const dialogRef = this.dialog.open<ThemeDto>(ThemeSelectorModalComponent,
      {
        data: {
          currentTheme: this.themeService.currentTheme$.value,
          themes: this.index.Themes,
          showFullPreviewIcons: true,
          applyStyle: true
        }
      });

    dialogRef.closed.subscribe({
      next: (chosenTheme: ThemeDto | undefined) => {
        if (chosenTheme) {
          this.themeService.currentTheme$.next(chosenTheme);
          this.currentTheme = chosenTheme;
        }
      }
    });
  }

  public submitForm(): void {
    let reloadTheme = false;
    this.index.DefaultWorkspacesID = this.userForm.get('DefaultWorkspacesID')?.value;
    this.index.UserLanguagesID = this.userForm.get('UserLanguagesID')?.value;
    this.index.TimezoneName = this.userForm.get('TimezoneName')?.value;
    this.index.TimezoneOffset = this.getTimezoneOffset();
    this.index.DateFormatType = this.userForm.get('DateFormatType')?.value;
    this.index.TimeFormatType = this.userForm.get('TimeFormatType')?.value;
    this.index.DelimiterType = this.userForm.get('DelimiterType')?.value;
    this.index.DecimalSeparatorType = this.userForm.get('DecimalSeparatorType')?.value;
    this.index.ThousandSeparatorType = this.userForm.get('ThousandSeparatorType')?.value;
    this.index.PageSizeType = this.userForm.get('PageSizeType')?.value;
    this.index.UnitType = this.userForm.get('UnitType')?.value;
    this.index.UserTwoFactorAuthenticationMethod = this.userForm.get('UserTwoFactorAuthenticationMethod')?.value;
    if (this.currentTheme) {
      reloadTheme = this.index.ThemesID !== this.currentTheme.ID;
      this.index.ThemesID = this.currentTheme.ID;
    }

    this.userSettingsApiService.validateUserSettings(this.index).pipe(
      take(1),
      switchMap(() => this.userSettingsApiService.postUserSettingsIndex(this.index))
    ).subscribe({
      error: (error) => {
        error.error.Errors.forEach((item: any) => {
          this.error.push(item.Message);
        });
      },
      complete: () => {
        if (reloadTheme) {
          this.themeService.getTheme(this.index.ThemesID!).pipe(
            take(1),
            tap((theme) => this.themeService.applyCssThemeAndBranding(theme))
          ).subscribe();
        }
        this.authService.refreshTokenViaIdentityServer().then(
          (response: TokenResponse) => {
            this.sessionStorage.setItem('access_token', response.access_token);
            this.store$.dispatch(userSettingsActions.fetchUserSettings());
            //We need to reload the page because the theme is not reloaded properly
            //Unfortunately this is needed because kendo's 'chart.reloadTheme() doesn't work properly.

            window.location.pathname = `/workspace/${this.index.DefaultWorkspacesID}/user-settings`;
            window.location.replace(window.location.host + `/workspace/${this.index.DefaultWorkspacesID}/user-settings`);
          });
      }
    });
  }

  private getRouteData(): void {
    const data = this.route.snapshot.data;
    if (data) {
      this.userSettings = data['settings'];
      this.index = data['index'];
      this.workspaces = data['workspaces'];
    }
  }

  private buildForm(index: UserSettingsIndexDto): FormGroup {
    return this.fb.group({
      DefaultWorkspacesID: [index.DefaultWorkspacesID],
      ThemesID: [index.ThemesID],
      UserLanguagesID: [index.UserLanguagesID],
      TimezoneName: [index.TimezoneName],
      TimezoneOffset: [index.TimezoneOffset],
      DateFormatType: [index.DateFormatType],
      TimeFormatType: [index.TimeFormatType],
      DelimiterType: [index.DelimiterType],
      DecimalSeparatorType: [index.DecimalSeparatorType],
      ThousandSeparatorType: [index.ThousandSeparatorType],
      PageSizeType: [index.PageSizeType],
      UnitType: [index.UnitType],
      UserTwoFactorAuthenticationMethod: [index.UserTwoFactorAuthenticationMethod]
    });
  }

  protected readonly operators = Operators;
}
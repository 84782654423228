import { Component, inject } from '@angular/core';
import { FormLandingPageComponent } from '../form-landing-page/form-landing-page.component';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs';
import { DownloadService } from '../../../../core/services/download/download.service';

@Component({
  selector: 'bizz-direct-link-page',
  standalone: true,
  imports: [],
  templateUrl: './direct-link-page.component.html',
  styleUrl: './direct-link-page.component.scss'
})
export class DirectLinkPageComponent extends FormLandingPageComponent {
  private http = inject(HttpClient);
  private downloadService = inject(DownloadService);

  public override routeApplied(): void {

    let request = `api/directlink/challenge?collectionsID=${this.collectionId}&instancesID=${this.instanceId}`;
    if(this.checkSum != undefined && this.checkSum.trim() != ''){
      request += `&checksum=${this.checkSum}`;
    }
    const tenant = window.location.hostname.split('.')[0];
    this.http.get(
      request, { responseType: 'arraybuffer', observe: 'response', headers: { 'x-tenant': tenant } })
      .pipe(take(1))
      .subscribe(
        {
          error: (error) => {
            if(error.status == 401) {
              const path = window.location.pathname?.replace('#', '') + window.location.search;
              this.router.navigate(['/direct'+ path]);
            }
          },
          next: (response) => {
            if(response.body != null && response.body.byteLength > 0) {
              const headers = response.headers;
              const fileNameFromHeader = headers.get('x-filename');
              const fileName = DownloadService.cleanupFileName(fileNameFromHeader);
              this.downloadService.startDownload(response.body, fileName);
            }
          }
        }
      );
  }
}

import { Component, OnDestroy, OnInit, Signal } from '@angular/core';
import { Subject, take } from 'rxjs';
import { ViewStackService } from 'src/app/shared/services/view-stack/view-stack.service';
import { PermissionsParams } from '../../interfaces/permissions-params.interface';
import { ViewStackLoadedComponent } from 'src/app/shared/interfaces/view-stack-loaded-component';
import { PermissionsComponent } from '../permissions/permissions.component';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { StoreCollectionForm } from 'src/app/store/features/forms/forms-state';
import { selectForm } from 'src/app/store/features/forms/forms-selectors';
import { formsActions } from 'src/app/store/features/forms/forms-actions';
import { Store } from '@ngrx/store';
import { InstancePermissionsDto } from 'src/models/ts/instance-permissions-dto.model';

@Component({
  selector: 'bizz-view-stack-permissions',
  standalone: true,
  imports: [PermissionsComponent],
  templateUrl: './view-stack-permissions.component.html',
  styleUrl: './view-stack-permissions.component.scss'
})
export class ViewStackPermissionsComponent implements ViewStackLoadedComponent<PermissionsParams>, OnInit, OnDestroy {

  public id: string;
  public data: PermissionsParams;
  private destroy = new Subject<void>();
  public form: Signal<StoreCollectionForm | undefined>;

  public constructor(private viewStackService: ViewStackService, private collectionListService: CollectionListApiService, private store: Store) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public hasUnsavedChanges(): boolean {
    return false;
  }

  public removeFromViewStack(permissions: InstancePermissionsDto | undefined): void {
    if (permissions) {
      if (this.data.instanceId == 0 && this.data.versionId == 0) {
          this.store.dispatch(formsActions.updateFormPermissions({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            formId: this.data.formId!,
            permissions: permissions
          }))
          this.viewStackService.removeLastItemFromStack();
      } else {
        this.collectionListService.savePermissions(this.data.collectionId, this.data.instanceId, this.data.versionId, this.data.folderId, permissions)
          .pipe(take(1)).subscribe(() => {
            this.viewStackService.removeLastItemFromStack();
          })
      }
    }else{
      this.viewStackService.removeLastItemFromStack();
    }

  }
}


import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CollectionFormLookupApiService } from '../../../../api/bizzmine/collection-form-lookup/collection-form-lookup-api.service';
import { LinkedCollectionType } from '../../../../../models/ts/linked-collection-type.model';
import { Observable } from 'rxjs/internal/Observable';
import { CollectionFormFieldSingleLookupData } from '../../../../../models/ts/collection-form-field-single-lookup-data.model';
import { CollectionFormFieldGridLookupData } from '../../../../../models/ts/collection-form-field-grid-lookup-data.model';
import { LookupService } from '../../../../shared/services/lookup/lookup.service';
import { StoreCollectionFormService } from './store-collection-form.service';
import { CollectionForm } from '../../../../../models/ts/collection-form.model';
import { formsActions } from '../../../../store/features/forms/forms-actions';
import { UpdateDeleteState } from '../../../../../models/ts/update-delete-state.model';
import { LookupItem } from '../../../../../models/ts/collection-list-summary-item.model';
import { ViewDataSource } from '../../../../../models/ts/view-data-source.model';
import { CollectionFormService } from './collection-form.service';
import { selectForm, selectFormField } from '../../../../store/features/forms/forms-selectors';
import { combineLatestWith, map, take, zip } from 'rxjs';
import { CollectionListDataInstance } from '../../../../shared/interfaces/collection-list-data-instance';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';

/**
 * Service with a number of functions frequently used in forms with linked fields & grids.
 */
@Injectable({
  providedIn: 'root'
})
export class CollectionFormLinkedService {
  private store$ = inject(Store);
  private collectionFormLookupApiService = inject(CollectionFormLookupApiService);
  private storeCollectionFormService = inject(StoreCollectionFormService);

  public static getParentGridViewDataSource(form: CollectionForm, viewDataSource: ViewDataSource): ViewDataSource | undefined {
    if (viewDataSource.ParentDataSourcesID == 0) return undefined;
    let currentViewDataSource: ViewDataSource = viewDataSource;
    while (true) {
      if (currentViewDataSource.ParentDataSourcesID !== 0) {
        let nextViewDataSource = form.ViewDataSources.find(vds => vds.ViewDataSourcesID == currentViewDataSource.ParentDataSourcesID);
        if (nextViewDataSource !== undefined) {
          const gridField = CollectionFormService.getField(form, field => field.ViewDataSourcesID == nextViewDataSource.ViewDataSourcesID && CollectionFormService.fieldIsGrid(field))
          if (!nextViewDataSource.IsSnapshotRelation && nextViewDataSource.SingleOrMany == LinkedCollectionType.GridRecord && gridField !== undefined) {
            return nextViewDataSource;
          } else {
            currentViewDataSource = nextViewDataSource;
          }
        } else throw new Error(`Could not find VDS ${currentViewDataSource.ParentDataSourcesID}`);
      } else break;
    }
    return undefined;
  }

  //region Get LookupData

  // Identical to the grid record logic but returns a type specific to single records
  public getSingleRecordLookup(form: CollectionForm, viewDataSourceId: number, instanceId: number, versionId: number, crossLinkedInstanceId: number): Observable<CollectionFormFieldSingleLookupData> {
    const viewDataSource = form.ViewDataSources.find(vds => vds.ViewDataSourcesID == viewDataSourceId);
    if (viewDataSource !== undefined) {
      if (viewDataSource.ParentDataSourcesID != 0) {
        const snapshotViewDataSource = form.ViewDataSources.find(vds => vds.ParentDataSourcesID == viewDataSource.ViewDataSourcesID && vds.IsSnapshotRelation);
        if (snapshotViewDataSource !== undefined) {
          return this.getDeeperLevelLookupForInstance(form.CollectionFormId, viewDataSourceId, snapshotViewDataSource.CrossLinkCollectionsID, crossLinkedInstanceId, instanceId, versionId);
        } else throw new Error(`SnapshotViewDataSource not found.`);
      } else return this.getLookupForInstance(form.CollectionFormId, viewDataSourceId, instanceId, versionId);
    } else throw new Error(`ViewDataSource ${viewDataSourceId} not found.`);
  }

  // Identical to the single record logic but returns a type specific to grid records
  public getGridRecordLookup(form: CollectionForm, viewDataSourceId: number, instanceId: number, versionId: number, crossLinkedInstanceId: number): Observable<CollectionFormFieldGridLookupData> {
    const viewDataSource = form.ViewDataSources.find(vds => vds.ViewDataSourcesID == viewDataSourceId);
    if (viewDataSource !== undefined) {
      if (viewDataSource.ParentDataSourcesID != 0) {
        const snapshotViewDataSource = form.ViewDataSources.find(vds => vds.ParentDataSourcesID == viewDataSource.ViewDataSourcesID && vds.IsSnapshotRelation);
        if (snapshotViewDataSource !== undefined) {
          return this.getDeeperLevelGridLookupForInstance(form.CollectionFormId, viewDataSourceId, snapshotViewDataSource.CrossLinkCollectionsID, crossLinkedInstanceId, instanceId, versionId);
        } else throw new Error(`SnapshotViewDataSource not found.`);
      } else return this.getGridLookupForInstance(form.CollectionFormId, viewDataSourceId, instanceId, versionId);
    } else throw new Error(`ViewDataSource ${viewDataSourceId} not found.`);
  }

  /**
   * Returns an array of observables fetching the lookup data for provided lookups.
   * @param {CollectionForm} form
   * @param {number} viewDataSourceId
   * @param {LookupItem[]} lookupItems
   * @return {Observable<{lookupData: CollectionFormFieldGridLookupData, lookupItem: LookupItem}>[]}
   */
  public getGridRecordLookups(form: CollectionForm, viewDataSourceId: number, lookupItems: LookupItem[]): Observable<{
    lookupData: CollectionFormFieldGridLookupData,
    lookupItem: LookupItem
  }>[] {
    return lookupItems.map(lookupItem => {
      return this.getGridRecordLookup(form, viewDataSourceId, lookupItem.InstancesID, lookupItem.VersionsID, lookupItem.CrossLinkInstancesID).pipe(map(lookupData => {
        return {
          lookupData: lookupData,
          lookupItem: lookupItem
        };
      }), take(1));
    });
  }

  public getLookupForInstance(formId: number, viewDataSourceId: number, instanceId: number, versionId: number): Observable<CollectionFormFieldSingleLookupData> {
    return this.collectionFormLookupApiService.getFormFieldValuesByLookupField(formId, viewDataSourceId, LinkedCollectionType.SingleRecord, instanceId, versionId) as Observable<CollectionFormFieldSingleLookupData>;
  }

  public getDeeperLevelLookupForInstance(formId: number, viewDataSourceId: number, crossLinkCollectionId: number, crossLinkInstanceId: number, instanceId: number, versionId: number): Observable<CollectionFormFieldSingleLookupData> {
    return this.collectionFormLookupApiService.getFormFieldValuesByDeepLookupField(formId, viewDataSourceId, LinkedCollectionType.SingleRecord, crossLinkCollectionId, crossLinkInstanceId, instanceId, versionId) as Observable<CollectionFormFieldSingleLookupData>;
  }

  public getGridLookupForInstance(formId: number, viewDataSourceId: number, instanceId: number, versionId: number): Observable<CollectionFormFieldGridLookupData> {
    return this.collectionFormLookupApiService.getFormFieldValuesByLookupField(formId, viewDataSourceId, LinkedCollectionType.GridRecord, instanceId, versionId) as Observable<CollectionFormFieldGridLookupData>;
  }

  public getDeeperLevelGridLookupForInstance(formId: number, viewDataSourceId: number, crossLinkCollectionId: number, crossLinkInstanceId: number, instanceId: number, versionId: number): Observable<CollectionFormFieldGridLookupData> {
    return this.collectionFormLookupApiService.getFormFieldValuesByDeepLookupField(formId, viewDataSourceId, LinkedCollectionType.GridRecord, crossLinkCollectionId, crossLinkInstanceId, instanceId, versionId) as Observable<CollectionFormFieldGridLookupData>;
  }

  //endregion

  public updateLookupFields(formId: string, form: CollectionForm, lookupData: CollectionFormFieldSingleLookupData): void {
    this.storeCollectionFormService.updateLookupFields(formId, form, lookupData);
  }

  public updateTrainingLookupFields(formId: string, lookupData: CollectionFormFieldSingleLookupData): void {
    this.storeCollectionFormService.linkExamTrainingInfo(formId, lookupData);
  }

  public updateTrainingViewDataSources(formId: string, lookupData: CollectionFormFieldSingleLookupData, lookupField: CollectionFormField): void {
    this.storeCollectionFormService.linkTrainingViewDataSource(formId, lookupData, lookupField);
    this.storeCollectionFormService.linkExamViewDataSources(formId, lookupData);
  }

  public clearChildLookupFields(formId: string, form: CollectionForm, viewDataSourceId: number): void {
    const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID == viewDataSourceId);
    if (vds) {
      const childViewDataSources = form.ViewDataSources.filter(vds => vds.ParentDataSourcesID == viewDataSourceId);
      childViewDataSources.forEach(vds => {
        this.clearViewDataSourceFieldsValue(formId, form, vds.ViewDataSourcesID);
      });
    } else throw new Error(`Could not find VDS ${viewDataSourceId}`);
  }

  public clearLookup(formId: string, form: CollectionForm, viewDataSourceId: number): void {
    this.clearViewDataSourceFieldsValue(formId, form, viewDataSourceId);
    this.removeInstanceFromViewDataSource(formId, form, viewDataSourceId);
  }

  public clearGridLookup(formId: string, form: CollectionForm, viewDataSourceId: number, gridFieldId: number, recordId: number): void {
    this.clearViewDataSourceRecordValue(formId, form, viewDataSourceId, gridFieldId, recordId);
    this.removeInstanceFromViewDataSource(formId, form, viewDataSourceId, recordId);
  }

  /**
   * Updates VDS with provided lookup data for given grid record.
   * @param {string} formId
   * @param {CollectionForm} form
   * @param {number} viewDataSourceId
   * @param {LookupItem} lookupItem
   * @param {CollectionFormFieldGridLookupData} lookupData
   * @param {number} recordId
   */
  public updateViewDataSourceInstanceForGrid(formId: string, form: CollectionForm, viewDataSourceId: number, lookupItem: LookupItem, lookupData: CollectionFormFieldGridLookupData | undefined, recordId: number): void {
    const viewDataSource = form.ViewDataSources.find(vds => vds.ViewDataSourcesID == viewDataSourceId);
    if (viewDataSource !== undefined) {
      // Clone the ViewDataSource to update since the stored is immutable.
      const updatedViewDataSource = structuredClone(viewDataSource);
      // Find any existing instance to update
      let instance = updatedViewDataSource.Instances.find(i => i.CrossLinkedInstancesID == recordId);
      // If the recordId is 0, it's a new record created outside of a grid row and needs a new ID.
      if (recordId == 0) recordId = LookupService.getLowestCrossLinkId(updatedViewDataSource.Instances);
      let newCrossLinkId = recordId;
      if (instance != undefined) {
        // Update the instance with provided ID's and update state.
        const index = updatedViewDataSource.Instances.indexOf(instance);
        if (instance.CrossLinkedInstancesID > 0) {
          newCrossLinkId = LookupService.getLowestCrossLinkId(viewDataSource.Instances);
          updatedViewDataSource.Instances[index].State = UpdateDeleteState.Delete;
          updatedViewDataSource.Instances.push({
            ChildInstancesID: lookupItem.InstancesID,
            ChildVersionsID: lookupItem.VersionsID,
            ParentVersionsID: 0,
            ParentInstancesID: 0,
            CrossLinkedInstancesID: newCrossLinkId,
            DataDesignCrossID: 0,
            OriginalChildInstancesID: lookupItem.OriginalChildInstancesID,
            State: UpdateDeleteState.Update,
            PreviousChildVersionsID: instance.ChildVersionsID ?? 0,
            RowDataDesignCrossID: newCrossLinkId,
            SnapshotCrosslinkInstancesID: lookupItem.CrossLinkInstancesID,
            GuidChecksum: lookupData?.GuidChecksum ?? lookupItem.GuidChecksum,
            IsEntityActive: instance.IsEntityActive,
            IsEntityPartOfEntitiesUser: instance.IsEntityPartOfEntitiesUser
          });
        } else {
          updatedViewDataSource.Instances[index] = {
            ChildInstancesID: lookupItem.InstancesID,
            ChildVersionsID: lookupItem.VersionsID,
            ParentVersionsID: 0,
            ParentInstancesID: 0,
            CrossLinkedInstancesID: recordId,
            DataDesignCrossID: 0,
            OriginalChildInstancesID: lookupItem.OriginalChildInstancesID,
            State: UpdateDeleteState.Update,
            PreviousChildVersionsID: instance.ChildVersionsID ?? 0,
            RowDataDesignCrossID: recordId,
            SnapshotCrosslinkInstancesID: lookupItem.CrossLinkInstancesID,
            GuidChecksum: lookupData?.GuidChecksum ?? lookupItem.GuidChecksum,
            IsEntityActive: instance.IsEntityActive,
            IsEntityPartOfEntitiesUser: instance.IsEntityPartOfEntitiesUser
          };
        }
      } else {
        // Push new instance with provided ID's and update state.
        updatedViewDataSource.Instances.push({
          ChildInstancesID: lookupItem.InstancesID,
          ChildVersionsID: lookupItem.VersionsID,
          ParentVersionsID: 0,
          ParentInstancesID: 0,
          CrossLinkedInstancesID: recordId,
          DataDesignCrossID: 0,
          OriginalChildInstancesID: lookupItem.OriginalChildInstancesID,
          State: UpdateDeleteState.Update,
          PreviousChildVersionsID: 0,
          RowDataDesignCrossID: recordId,
          SnapshotCrosslinkInstancesID: lookupItem.CrossLinkInstancesID,
          GuidChecksum: lookupData?.GuidChecksum ?? lookupItem.GuidChecksum,
          IsEntityActive: false,
          IsEntityPartOfEntitiesUser: false
        });
      }

      // Update store with updated VDS
      this.store$.dispatch(formsActions.updateFormViewDataSource({
        formId,
        viewDataSource: updatedViewDataSource
      }));

      if (lookupData !== undefined) {
        this.store$.dispatch(formsActions.updateGridRecord({
          formId,
          lookupData,
          newRecordId: newCrossLinkId,
          recordId,
          viewDataSourceId
        }));

        // Check for child VDS to update
        if (lookupData.ViewDataSources.length > 0)
          this.updateGridChildViewDataSources(formId, form, structuredClone(lookupData), recordId);
      }
    } else throw new Error(`ViewDataSource ${viewDataSourceId} not found.`);
  }

  /**
   * Updates any child view datasources with data passed from the lookup.
   * Checks for existing instances to update and corrects RowDataDesignCrossID's.
   * @param {string} formId
   * @param {CollectionForm} form
   * @param {number} viewDataSourceId
   * @param {LookupItem} lookupItem
   * @param {CollectionFormFieldGridLookupData} lookupData
   * @param {number} recordId
   */
  public updateGridChildViewDataSources(formId: string, form: CollectionForm, lookupData: CollectionFormFieldGridLookupData, recordId: number): void {
    lookupData.ViewDataSources.filter(vds => vds.Instances.length > 0).forEach(lookupVds => {
      // Retrieve current VDS state
      let updatedViewDataSource = structuredClone(form.ViewDataSources.find(formVds => formVds.ViewDataSourcesID == lookupVds.ViewDataSourcesID));

      if (updatedViewDataSource !== undefined) {
        // Use form.ViewDataSources to check for existing instances then
        // update the passed VDS Instance with the correct RowDataDesignCrossID to prevent
        // overwrite of data or loss of link between Grid Records/rows and deeper level linked instances.
        lookupVds.Instances.forEach(instance => {
          instance.SnapshotCrosslinkInstancesID = instance.CrossLinkedInstancesID;
          if (updatedViewDataSource?.IsSnapshotRelation) {
            instance.RowDataDesignCrossID = recordId;
            instance.CrossLinkedInstancesID = recordId;
          }


          let existingInstance = structuredClone(updatedViewDataSource.Instances.find(instance => instance.RowDataDesignCrossID == recordId));
          if (existingInstance !== undefined) {
            existingInstance = instance;
            existingInstance.SnapshotCrosslinkInstancesID = existingInstance.CrossLinkedInstancesID;
            if (updatedViewDataSource?.IsSnapshotRelation) {
              existingInstance.RowDataDesignCrossID = recordId;
            }

          } else {
            updatedViewDataSource.Instances.push(instance);
          }
        });
        this.store$.dispatch(formsActions.updateFormViewDataSource({
          formId,
          viewDataSource: updatedViewDataSource
        }));
      } else throw new Error(`ViewDataSource ${lookupVds.ViewDataSourcesID} not found on form ${formId}`);
    });
  }

  public updateViewDataSourceInstance(formId: string, form: CollectionForm, viewDataSourceId: number, lookupItem: LookupItem, lookupData: CollectionFormFieldSingleLookupData | undefined): void {
    const viewDataSource = form.ViewDataSources.find(vds => vds.ViewDataSourcesID == viewDataSourceId);
    if (viewDataSource !== undefined) {
      // Clone the ViewDataSource to update since the stored is immutable.
      const updatedViewDataSource = structuredClone(viewDataSource);
      // Replace the first (and only) instance in the viewDataSource
      // Due to how the API expects ID's and state to be handled, existing instances are partially overwritten instead of fully replaced.
      updatedViewDataSource.Instances = [{
        ChildInstancesID: lookupItem.InstancesID,
        ChildVersionsID: lookupItem.VersionsID,
        ParentVersionsID: 0,
        ParentInstancesID: 0,
        // If existing CrossLinkInstancesID you reuse, else use the one from the lookup, or if that one is 0, get the next lowest negative id.
        CrossLinkedInstancesID: updatedViewDataSource.Instances[0]?.CrossLinkedInstancesID > 0 ? updatedViewDataSource.Instances[0].CrossLinkedInstancesID : lookupItem.CrossLinkInstancesID == 0 ? LookupService.getLowestCrossLinkId(viewDataSource.Instances) : lookupItem.CrossLinkInstancesID,
        DataDesignCrossID: 0,
        OriginalChildInstancesID: lookupItem.OriginalChildInstancesID,
        State: updatedViewDataSource.Instances[0]?.CrossLinkedInstancesID > 0 ? UpdateDeleteState.Default : UpdateDeleteState.Update,
        PreviousChildVersionsID: updatedViewDataSource.Instances[0]?.ChildVersionsID ?? 0,
        RowDataDesignCrossID: updatedViewDataSource.Instances[0]?.RowDataDesignCrossID ?? -1,
        SnapshotCrosslinkInstancesID: 0,
        GuidChecksum: lookupData?.GuidChecksum ?? lookupItem.GuidChecksum,
        IsEntityActive: updatedViewDataSource.Instances[0]?.IsEntityActive ?? true,
        IsEntityPartOfEntitiesUser: updatedViewDataSource.Instances[0]?.IsEntityPartOfEntitiesUser ?? true
      }];
      this.store$.dispatch(formsActions.updateFormViewDataSource({
        formId,
        viewDataSource: updatedViewDataSource
      }));

      if (lookupData !== undefined)
        // Update Lookup VDS (Deeper level instances)
        lookupData.ViewDataSources.filter(vds => vds.Instances.length > 0).forEach(vds => {
          this.store$.dispatch(formsActions.updateFormViewDataSource({
            formId,
            viewDataSource: vds
          }));
        });

    } else throw new Error(`ViewDataSource ${viewDataSourceId} not found.`);
  }

  public addInstancesToGrid(formId: string,
                            gridFieldId: number,
                            instances: CollectionListDataInstance[]): void {
    // iterate over each instance
    const lookupItems = instances.map(instance => {
      return {
        InstancesID: instance.ID,
        OriginalChildInstancesID: instance['OriginalChildInstancesID'] as number,
        Text: '',
        VersionsID: instance.VersionsID,
        CrossLinkInstancesID: instance['CrossLinkInstancesID'] as number,
        LinkedToParentVersions: [],
        GuidChecksum: instance['GuidChecksum'] as string
      } as LookupItem;
    });
    this.convertLookupsToRecords({
      formId: formId,
      gridFieldId: gridFieldId,
      lookupItems: lookupItems
    });
  }

  public convertLookupsToRecords(intent: {
    formId: string,
    gridFieldId: number,
    lookupItems: Array<LookupItem>
  }): void {
    if (intent != null) {
      // Get required form & field data.
      let form = this.store$.selectSignal(selectForm(intent.formId))()?.data;
      const gridField = this.store$.selectSignal(selectFormField(intent.formId, intent.gridFieldId))();
      if (form !== undefined && gridField !== undefined) {
        // Get the empty record for the grid.
        const emptyRecord = this.collectionFormLookupApiService.getEmptyRow(form.CollectionFormId, gridField.ViewDataSourcesID).pipe(take(1),
          map(fields => {
            return LookupService.buildRecordFromFields(fields, gridField);
          }));
        // Get the Grid's VDS
        const vds = CollectionFormService.getViewDataSourceForFieldById(form, intent.gridFieldId)!;
        if (vds !== undefined) {
          // Build the observables to fetch lookup data.
          const lookups = this.getGridRecordLookups(form, vds.ViewDataSourcesID, intent.lookupItems);
          if (lookups != undefined && lookups.length > 0) {
            // Fetch the data and combine with empty record.
            emptyRecord.pipe(combineLatestWith(zip(lookups))).subscribe(([emptyRecord, foundFieldValues]) => {
              foundFieldValues.forEach(found => {
                const data = found.lookupData;
                const item = found.lookupItem;
                const record = CollectionFormService.fillRecordWithLookupData(structuredClone(emptyRecord), data, LookupService.getNextNewRecordId(this.store$.selectSignal(selectFormField(intent.formId, intent.gridFieldId))()?.Records ?? []));

                this.store$.dispatch(formsActions.addRowsToGrid({
                  formId: intent.formId,
                  records: [record],
                  gridFieldId: intent.gridFieldId
                }));

                form = this.store$.selectSignal(selectForm(intent.formId))()?.data;
                if (form !== undefined)
                  this.updateViewDataSourceInstanceForGrid(intent.formId, form, vds.ViewDataSourcesID, item, data, 0);
              });
            });
          } else {
            // just add an empty row
            emptyRecord.subscribe((foundEmptyRecord) => {
              const newId = LookupService.getNextNewRecordId(this.store$.selectSignal(selectFormField(intent.formId, intent.gridFieldId))()?.Records ?? []);
              foundEmptyRecord.CrossLinkedInstancesID = newId;
              foundEmptyRecord.RowDataDesignCrossID = newId;
              foundEmptyRecord.EditMode = true;
              this.store$.dispatch(formsActions.addRowsToGrid({
                formId: intent.formId,
                records: [foundEmptyRecord],
                gridFieldId: intent.gridFieldId
              }));
            });
          }
        } else throw new Error(`VDS ${gridField.ViewDataSourcesID} not found.`);
      } else throw new Error('Missing required store data to convert selection instances to records.');
    } else throw new Error('Invalid intent.');
  }

  /**
   * Removes an instance from a ViewDataSource. If no recordId is provided, will perform removal on first instance.
   * @param {string} formId
   * @param {CollectionForm} form
   * @param {number} vdsId
   * @param recordId
   */
  public removeInstanceFromViewDataSource(formId: string, form: CollectionForm, vdsId: number, recordId?: number): void {
    const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID == vdsId);
    if (vds !== undefined) {
      if (vds.SingleOrMany == LinkedCollectionType.GridRecord && recordId == undefined)
        throw new Error(`Attempted to remove instance from Grid VDS without recordId`);
      if (vds.SingleOrMany == LinkedCollectionType.GridRecord) {
        // 1xN, instance with matching CrossLinkedInstancesID to record
        let instanceIndex = vds.Instances.findIndex(instance => instance.CrossLinkedInstancesID == recordId);
        if (instanceIndex != -1) {
          if (vds.Instances[instanceIndex].CrossLinkedInstancesID < 0) {
            vds.Instances.splice(instanceIndex, 1);
          } else
            vds.Instances[instanceIndex].State = UpdateDeleteState.Delete;
          this.store$.dispatch(formsActions.updateFormViewDataSource({
            formId,
            viewDataSource: vds
          }));
        }
      } else {
        // 1x1, first instance in array
        let instance = vds.Instances[0];
        if (instance !== undefined) {
          if (instance.CrossLinkedInstancesID < 0)
            vds.Instances = [];
          else
            instance.State = UpdateDeleteState.Delete;

          this.store$.dispatch(formsActions.updateFormViewDataSource({
            formId,
            viewDataSource: vds
          }));
        }
      }

      this.removeChildInstancesFromViewDataSource(formId, form, vdsId, recordId);
    } else throw new Error(`Could not find VDS ${vdsId}`);
  }

  /**
   * Removes all child instances from a ViewDataSource.
   * @param {string} formId
   * @param {CollectionForm} form
   * @param {number} vdsId
   * @param instanceId
   */
  public removeChildInstancesFromViewDataSource(formId: string, form: CollectionForm, vdsId: number, recordId?: number): void {
    const childViewDataSources = form.ViewDataSources.filter(viewDataSource => viewDataSource.ParentDataSourcesID == vdsId);
    if (childViewDataSources.length > 0) {
      childViewDataSources.forEach(childViewDataSource => {
        childViewDataSource.Instances.forEach(instance => {
          this.removeInstanceFromViewDataSource(formId, form, childViewDataSource.ViewDataSourcesID, instance.CrossLinkedInstancesID);
        });
        if (childViewDataSource.SingleOrMany == LinkedCollectionType.GridRecord) {
          let vdsGridField = CollectionFormService.getField(
            form,
            field => field.ViewDataSourcesID == childViewDataSource.ViewDataSourcesID && CollectionFormService.fieldIsGrid(field)
          );

          if (vdsGridField !== undefined)
            this.store$.dispatch(formsActions.clearGrid({
              formId,
              gridFieldId: vdsGridField.Id
            }));
        }
      });
    }
  }


  public clearChildViewDataSourcesFieldsValue(formId: string, form: CollectionForm, vdsId: number): void {
    const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID == vdsId);
    if (vds) {
      const childViewDataSources = form.ViewDataSources.filter(vds => vds.ParentDataSourcesID == vdsId);
      childViewDataSources.forEach(vds => {
        this.clearViewDataSourceFieldsValue(formId, form, vds.ViewDataSourcesID);
      });
    } else throw new Error(`Could not find VDS ${vdsId}`);
  }

  /**
   * Clears the value of all controls belonging to the provided ViewDataSources.
   * Also clears child ViewDataSources.
   * @param {string} formId
   * @param {CollectionForm} form
   * @param {number} vdsId
   * @param {boolean} updateChildren
   */
  public clearViewDataSourceFieldsValue(formId: string, form: CollectionForm, vdsId: number, updateChildren: boolean = true): void {
    const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID == vdsId);
    if (vds) {
      const vdsFields = CollectionFormService.extractFieldsFromForm(form).filter(field => field.ViewDataSourcesID == vdsId);
      vdsFields.forEach(field => {
        this.store$.dispatch(formsActions.updateFormField({
          formId,
          fieldId: field.Id,
          value: undefined
        }));
      });

      if (updateChildren) {
        // Repeat for all Child ViewDataSources
        this.clearChildViewDataSourcesFieldsValue(formId, form, vdsId);
      }
    } else throw new Error(`Could not find VDS ${vdsId}`);
  }

  /**
   * Clears the value of all controls belonging to the provided ViewDataSources.
   * Also clears child ViewDataSources.
   * @param {string} formId
   * @param {CollectionForm} form
   * @param {number} vdsId
   * @param {boolean} updateChildren
   */
  public clearViewDataSourceRecordValue(formId: string, form: CollectionForm, vdsId: number, gridFieldId: number, recordId: number): void {
    const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID == vdsId);
    if (vds) {
      const gridField = CollectionFormService.getField(form, field => field.Id == gridFieldId);

      if (gridField !== undefined && gridField.Records !== undefined) {
        const record = gridField.Records.find(record => record.CrossLinkedInstancesID == recordId);
        if (record !== undefined) {
          const recordFields = record.Fields.filter(field => !field.IsCrossLinkedField);
          recordFields.forEach(recordField => {
            this.store$.dispatch(formsActions.updateGridFormField({
              formId,
              gridFieldId: gridFieldId,
              recordId: recordId,
              recordFieldId: recordField.CollectionFieldsID,
              value: undefined
            }));
          });
        }
      }
    } else throw new Error(`Could not find VDS ${vdsId}`);
  }
}

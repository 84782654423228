import { Component, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { MacroButtonComponent } from '../../../../../../shared/components/ui/macro-button/macro-button.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { Store } from '@ngrx/store';
import { formsActions } from '../../../../../../store/features/forms/forms-actions';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { StoreCollectionForm } from '../../../../../../store/features/forms/forms-state';
import { selectForm } from '../../../../../../store/features/forms/forms-selectors';
import { AiApiService } from '../../../../../../api/bizzmine/ai/ai-api.service';
import { take } from 'rxjs';
import {MultiSelectComponent} from "@progress/kendo-angular-dropdowns";
/**
 * Represents a button that the user can click to go to a predefined url.
 */
@Component({
  selector: 'bizz-ai-button-control',
  standalone: true,
    imports: [CommonModule, IconComponent, MacroButtonComponent, EditorModule, TooltipComponent, TooltipDirective, MultiSelectComponent],
  templateUrl: './ai-button-control.component.html',
  styleUrls: ['./ai-button-control-component.scss']
})
export class AiButtonControlComponent extends BaseFormControlComponent {
  public form: Signal<StoreCollectionForm | undefined> = this.store.selectSignal(selectForm(this.formId));
  public hasAiLicense$ = this.aiService.hasAiLicense().pipe(take(1));
  public btnLoading: boolean = false;

  public constructor(public store: Store, private aiService: AiApiService) {super();}

  protected override focus(): void {}

  public aiButton(): void {
    //TODO: Add check if validLicense ?
    //TODO field can be replaced by formField$ ?
    const field = this.formFieldSignal();
    if (field !== undefined) {
      this.btnLoading = true;
      this.store$.dispatch(formsActions.getAiButtonData({
        formId: this.formId,
        field: field
      }));
      this.btnLoading = false;
    }
  }
}
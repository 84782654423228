import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DragFileUploadDirective } from '../../../../../../../shared/directives/drag-file-upload.directive';
import { IconComponent } from '../../../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate/translate.pipe';
import { MediaApiService } from '../../../../../../../api/bizzmine/media/media-api.service';
import {
  asyncScheduler,
  BehaviorSubject,
  filter,
  lastValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap
} from 'rxjs';
import { AsyncPipe, DatePipe, JsonPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { ExtensionIconComponent } from '../../../../../../../shared/components/ui/icon/extension-icon/extension-icon.component';
import { PopoverModule } from '@progress/kendo-angular-tooltip';
import { PopupModule } from '@progress/kendo-angular-popup';
import { userSettingsFeature } from '../../../../../../../store/features/user-settings/user-settings-feature';
import { Store } from '@ngrx/store';
import { MediaDto } from '../../../../../../../../models/ts/media-dto.model';
import {
  FileChunkUpload,
  FileUpload,
  FileUploadService,
  FormDataWithMeta
} from '../../../../../../../core/services/file-upload/file-upload.service';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { CellClickEvent, GridModule, GroupModule } from '@progress/kendo-angular-grid';
import { GridMobileCellComponent } from '../../../../../../../shared/components/grid/cells/grid-mobile-cell/grid-mobile-cell.component';
import { MediaCommitDto } from '../../../../../../../../models/ts/media-commit-dto.model';
import { FileSelectMultiComponent } from '../../../../../../../shared/components/file-select-multi/file-select-multi.component';

@Component({
  selector: 'bizz-mailing-attachments-upload',
  standalone: true,
  imports: [
    DragFileUploadDirective,
    IconComponent,
    TranslatePipe,
    AsyncPipe,
    JsonPipe,
    ExtensionIconComponent,
    NgStyle,
    PopoverModule,
    PopupModule,
    DatePipe,
    GridModule,
    GridMobileCellComponent,
    GroupModule,
    NgIf,
    FileSelectMultiComponent,
    NgClass
  ],
  templateUrl: './mailing-attachments-upload.component.html',
  styleUrl: './mailing-attachments-upload.component.scss'
})
export class MailingAttachmentsUploadComponent implements OnInit {

  public isHoveringDragDrop = false;
  public fileUploads: Array<FileUpload> = [];
  @Input() public mediaId: Array<number> = [];
  @Input() public currentAttachments: Array<{
    id: number,
    fileName: string,
    size: number
  }> = [];
  @Output() public attachmentsChanged = new EventEmitter<Array<number>>();
  public attachments: Array<{
    id: number,
    fileName: string,
    size: number
  }> = [];
  public sizeError = false;
  public emptyExtensionError = false;
  public dateTimeFormatType: string;
  public showPopup = false;
  public selectedMedia: Array<MediaDto> = [];
  public gridView: GroupResult[] | MediaDto[];
  public groups = [{ field: 'CollectionName' }];
  public attachmentWidth: number | string = 0;
  @ViewChild('attachmentSelector', { static: true }) public attachmentSelector: ElementRef;
  @ViewChild('MailingAttachmentsUploadComponentFileSelect', { static: true }) public fileSelectMultiComponent: FileSelectMultiComponent;
  public readonly MAX_MOBILE_WIDTH = 1024;
  @ViewChild('popup', { read: ElementRef }) public popup: ElementRef;
  @ViewChild('anchorParent', { read: ElementRef }) public anchorParent: ElementRef;
  private mediaId$ = new BehaviorSubject<Array<number>>([]);
  public media$ = this.mediaId$
    .pipe(
      filter(mediaId => mediaId.length > 0),
      switchMap(mediaId => this.mediaApi.getMediaWithMeta({ mediaIds: mediaId })),
      map(found => {
        found.forEach(media => {
          media.FileSize = this.formatFileSize(media.Size);
        });
        return found;
      }),
      tap(mediaList => {

        this.gridView = groupBy(mediaList, this.groups);
      }),
      shareReplay(1)
    );
  private MAX_ATTACHMENT_FILE_SIZE_LIMIT = 23000000;
  private chunkSize = 1024 * 1024;
  private currentTotalSize = 0;
  private uploadMeta: Array<{
    fileId: string,
    size: number
  }> = [];

  public constructor(private mediaApi: MediaApiService,
                     private store$: Store,
                     private fileUploadService: FileUploadService) {
    this.dateTimeFormatType = this.store$.selectSignal(
      userSettingsFeature.selectDateTimeFormatTypeString
    )();
  }

  public ngOnInit(): void {
    this.mediaId$.next(this.mediaId);
    if(this.currentAttachments && this.currentAttachments.length > 0){
      this.attachments = this.attachments.concat(this.currentAttachments);
    }
    asyncScheduler.schedule(() => this.onResize());
  }

  public getFileExtension(fileName: string): string {
    return this.fileUploadService.getFileExtension(fileName);
  }

  public containsMedia(media: MediaDto): boolean {
    return this.selectedMedia.find(item => item.ID == media.ID) != null;
  }

  public toggleMediaAttachment(media: MediaDto,$event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.selectedMedia.find(item => item.ID == media.ID)) {
      this.selectedMedia = this.selectedMedia.filter(item => item.ID != media.ID);
    } else {
      this.selectedMedia.push(media);
    }
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.attachmentWidth = this.attachmentSelector.nativeElement.clientWidth + 'px';
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target as EventTarget)) {
      this.showPopup = false;
    }
  }

  public onCellClick(event: CellClickEvent): void {
    this.toggleMediaAttachment(event.dataItem, event.originalEvent);
  }

  public applySelection(medias: Array<MediaDto>): void {
    this.sizeError = false;
    medias.forEach(media => {
      const found = this.attachments.find(_ => _.id == media.ID);
      if (found) {
        this.currentTotalSize = this.currentTotalSize - found.size;
      }
      this.attachments = this.attachments.filter(_ => _.id != media.ID);
    });
    for (let i = 0; i < this.selectedMedia.length; i++) {
      const media = this.selectedMedia[i];
      if (media.Size + this.currentTotalSize < this.MAX_ATTACHMENT_FILE_SIZE_LIMIT) {
        this.currentTotalSize = this.currentTotalSize + media.Size;
        this.attachments.push({
          id: media.ID,
          fileName: media.OriginalFileName,
          size: media.Size
        });
      } else {
        this.sizeError = true;
        setTimeout(() => this.sizeError = false, 15000);
      }
    }
    this.attachmentsChanged.emit(this.attachments.map(attachment => attachment.id));
    this.showPopup = false;
  }

  public formatFileSize(fileSizeInBytes: number): string {
    return this.fileUploadService.formatFileSize(fileSizeInBytes);
  }

  public openFileSelect(): void {
    this.fileSelectMultiComponent.openFileSelect();
  }

  public validateFile(formData: FormDataWithMeta): Observable<FormDataWithMeta> {
    if (this.uploadMeta.find(meta => meta.fileId == formData.fileId) == null) {
      this.uploadMeta.push({ fileId: formData.fileId, size: formData.meta.size });
    }
    this.sizeError = false;
    this.emptyExtensionError = false;
    this.currentTotalSize = this.currentTotalSize + formData.meta.size;
    if (this.currentTotalSize > this.MAX_ATTACHMENT_FILE_SIZE_LIMIT) {
      this.sizeError = true;
      setTimeout(() => this.sizeError = false, 15000);
      throw new Error('De bestandsgrootte(s) overschrijden de maximumgrootte van 23mb.');
    }
    if (this.getFileExtension(formData.meta.fileName).trim() == '') {
      this.emptyExtensionError = true;
      setTimeout(() => this.emptyExtensionError = false, 15000);
      throw new Error('Het bestand heeft een ongeldige extensie.');
    }

    return of(formData);
  }

  public filesDropped(files: Array<File>): void {
    const fileUploads = this.fileUploadService.uploadFiles({
      upload$: (formData) => this.mediaApi.uploadFileFromFormData(formData),
      files: files,
      chunkSize: this.chunkSize,
      maxConcurrentUploads: null,
      preValidation$: (data) => this.validateFile(data)
    });

    fileUploads.forEach(fileUpload => {
      fileUpload.onError = (): void => {
        console.error('onError', fileUpload);
        this.cancelUpload(fileUpload.fileId);
      };
      fileUpload.onCompleted = (): void => {
        if (fileUpload.fileChunkUpload) {
          const mediaCommitDto: MediaCommitDto = {
            FileName: fileUpload.fileChunkUpload.fileName,
            LastModifiedDate: fileUpload.fileChunkUpload.lastModifiedDate.toString(),
            Identifier: fileUpload.fileId,
            Type: fileUpload.fileChunkUpload.type,
            Size: fileUpload.fileChunkUpload.size,
            ChunkIDs: fileUpload.fileChunkUpload.chunkIds,
            MediasID: 0,
            CollectionsID: 0,
            ContentType: '',
            MimeValidation: '',
            VersionsID: 0
          };
          lastValueFrom(this.mediaApi.commitMailAttachment(mediaCommitDto))
            .then((response) => {
              this.finishUpload(fileUpload.fileId, response, fileUpload.fileChunkUpload as FileChunkUpload);
            });
        }
      };
    });

    this.fileUploads = this.fileUploads.concat(fileUploads);
  }

  public finishUpload(fileId: string, id: number, item: FileChunkUpload): void {
    this.fileUploads = this.fileUploads.filter(file => file.fileId != fileId);
    this.attachments.push({
      id: id,
      size: item.size,
      fileName: item.fileName
    });
    this.attachmentsChanged.emit(this.attachments.map(attachment => attachment.id));
  }

  public removeAttachment(id: number): void {
    const found = this.attachments.find(attachment => attachment.id == id);
    if (found) this.currentTotalSize = this.currentTotalSize - found.size;
    this.attachments = this.attachments.filter(attachment => attachment.id != id);
    this.selectedMedia = this.selectedMedia.filter(media => media.ID != id);
    this.attachmentsChanged.emit(this.attachments.map(attachment => attachment.id));
  }

  public cancelUpload(fileId: string): void {
    const foundMeta = this.uploadMeta.find(meta => meta.fileId == fileId);
    if (foundMeta) this.currentTotalSize = this.currentTotalSize - foundMeta.size;
    this.uploadMeta = this.uploadMeta.filter(meta => meta.fileId != fileId);
    this.fileUploads = this.fileUploads.filter(fileUpload => fileUpload.fileId != fileId);
  }

  private contains(target: EventTarget): boolean {
    return (
      (this.attachmentSelector ? this.attachmentSelector.nativeElement.contains(target) : false ) ||
      (this.anchorParent ? this.anchorParent.nativeElement.contains(target) : false ) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }

}

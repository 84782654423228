import { Component, DestroyRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectableSettings, TreeItem, TreeViewComponent, TreeViewModule } from '@progress/kendo-angular-treeview';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { OrgChartItem } from '../interfaces/org-chart-item';
import { OrganizationChartApiService } from '../../../../api/bizzmine/organization-chart/organization-chart-api.service';
import { IconComponent } from '../../../../shared/components/ui/icon/icon.component';
import { ORG_CHART_ITEM_TYPE_ICONS } from '../constants/org-chart-item-type-icons';
import { OrganizationChartItemType } from '../../../../../models/ts/organization-chart-item-type.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DepartmentType } from '../../../../../models/ts/department-type';

/**
 * Displays the Organization Chart as a Tree.
 * Utilizes the Kendo TreeView component.
 */
@Component({
  selector: 'bizz-org-chart-tree',
  standalone: true,
  imports: [CommonModule, TreeViewModule, IconComponent],
  templateUrl: './org-chart-tree.component.html',
  styleUrls: ['./org-chart-tree.component.scss']
})
export class OrgChartTreeComponent implements OnInit {
  @ViewChild('treeview')
  public treeview: TreeViewComponent;
  @Input() public selectableSettings: SelectableSettings = {
    mode: 'multiple',
    enabled: true
  };
  @Input() public tree: OrgChartItem;
  public nodes: OrgChartItem[];
  @Input() public selectedItems$ = new BehaviorSubject<OrgChartItem[]>([]);
  @Input() public maxSelectedItems = 0;
  @Input() public minSelectedItems = 0;
  @Input() public allowedItemTypes: Array<OrganizationChartItemType> = [OrganizationChartItemType.User, OrganizationChartItemType.Department, OrganizationChartItemType.Function];
  @Input() public excludedUserIds: Array<number> = [];
  @Input() public replaceDepartmentsAndFunctionsWithUsers = false;
  public selectedKeys: Array<string> = [];
  public disabledKeys: Array<string> = [];

  // Dev notes: use isSelected from Kendo to make sure the selected items are highlighted when they are loaded in when their parent is expanded => selectedkeys might not work => CHECK
  protected readonly OrganizationChartItemType = OrganizationChartItemType;
  private allNodes: Array<OrgChartItem> = [];

  public constructor(public organizationChartApiService: OrganizationChartApiService, private destroyRef: DestroyRef) {
  }

  public ngOnInit(): void {
    this.organizationChartApiService.getOrgChartTreeRootNode().pipe(take(1)).subscribe({
      next: (root: OrgChartItem) => {
        this.tree = root;
        this.nodes = [this.tree];
        this.allNodes.push(this.tree);
        this.disabledKeys = this.allNodes.filter(node => !node.HasChildren && this.allowedItemTypes.includes(node.ItemType) == false).map(node => node.Name);
        this.treeview.expandNode(this.nodes[0], '0'); //expand the root node
      }
    });

    this.selectedItems$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.selectedKeys = value?.map(item => item.UniqueID) ?? [];
    });
  }

  public getIconForData(dataItem: OrgChartItem): string{
    if(dataItem.ItemType != OrganizationChartItemType.Department){
      return ORG_CHART_ITEM_TYPE_ICONS[dataItem.ItemType];
    }

    return dataItem.DepartmentType == DepartmentType.Department ? ORG_CHART_ITEM_TYPE_ICONS[OrganizationChartItemType.Department] : 'building-columns';
  }

  public selectInnerValues(dataItem: OrgChartItem): void {
    this.organizationChartApiService.getUsersForFunctionDepartment([dataItem.UniqueID]).subscribe({
      next: users => {
        const current = this.selectedItems$.value ?? [];
        this.selectedKeys = this.selectedKeys ?? [];
        users.forEach(u => {
          if (current.find(o => o.UniqueID == u.UniqueID) == null) {
            current.push(u);
          }
        })
        this.selectedItems$.next(current);
        this.onSelectionChange(current.map(_ => _.UniqueID));
      }
    });
  }

  public onSelectionChange(event: string[]): void {
    if (this.maxSelectedItems > 0 && event.length > this.maxSelectedItems) {
      return;
    }

    //TODO: add extra check all nodes -> 
    let mapped = <Array<OrgChartItem>>event.map(item =>
      this.selectedItems$.value?.find(selectedItem => selectedItem.UniqueID == item) ??
      this.allNodes.find(node => node.UniqueID == item))
      .filter(item => item != null);
    if (!mapped.find(orgChartItem => this.excludedUserIds?.includes(orgChartItem.ObjectID))) {
      if (this.allowedItemTypes != null && this.allowedItemTypes.length > 0) {
        mapped = mapped.filter(item => this.allowedItemTypes.includes(item.ItemType));
      }
      if (mapped.length) {
        this.selectedItems$.next(mapped);
      }
    }
  }

  public selectBy(item: TreeItem): any {
    return item.dataItem['UniqueID'];
  }

  /**
   * Required by Kendo to have an expandable tree.
   * @param node
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchChildren = (node: any): Observable<Array<OrgChartItem>> =>
    this.organizationChartApiService.getOrgChartTreeNodeChildren(node.VersionId, node.ItemType).pipe(tap(values => {
      this.allNodes = this.allNodes.concat(values);
      this.disabledKeys = this.allNodes.filter(node => !node.HasChildren && this.allowedItemTypes.includes(node.ItemType) == false).map(node => node.Name);
    }));

  /**
   * Required by Kendo to have an expandable tree.
   * @param node
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public hasChildren(node: any): boolean {
    return node.HasChildren;
  }

  public isSelected(data: OrgChartItem): boolean {
    return this.selectedKeys?.includes(data.UniqueID) ?? false;
  }

  public isEnabledForSelection(itemType: OrganizationChartItemType): boolean {
    return this.allowedItemTypes?.includes(itemType) ?? false;
  }

  public isExcludedUser(orgChartItem: OrgChartItem): boolean {
    return this.excludedUserIds?.includes(orgChartItem.ObjectID) && orgChartItem.ItemType == OrganizationChartItemType.User;
  }
}

<div class="flex h-full w-full flex-col gap-1 p-2 sm:gap-2">
    <div class="flex flex-wrap justify-between gap-1">
        <span class="widget-header">{{ data.Caption }}</span>
        <div class="ml-auto flex items-center justify-end gap-2 sm:flex-wrap">
            <!-- TODO: replace with generic search -->
            <bizz-filter [active]="(gridResultData$ | async)?.activeFilter ?? false" [filterList]="data.FilterList"
                         [filterType]="filterItemType" [viewId]="data.ListOptions.ViewID" [widgetId]="data.ID"
                         [collectionsId]="data.CollectionsID" [searchFieldAccessible]="true"
                         (retrievedFilterData)="refreshFilterGrid($event)">
            </bizz-filter>
            <div class="flex justify-end">
                <button type="button" class="cq-widget-btn-hidden hidden px-2 py-1 sm:flex" (click)="refreshGrid()">
                    <bizz-icon class="icon-button size-4" iconName="rotate-right"></bizz-icon>
                </button>
                <button (click)="changeDownloadPopUpState()" class="cq-widget-btn-hidden hidden px-2 py-1 sm:flex"
                        [ngClass]="{'pointer-events-none': isDownloading, 'sm:hidden': !data.CanExport}" #anchor>
                    @if (isDownloading) {
                        <div
                                class="text-surface inline-block h-4 w-4 animate-spin rounded-full border-3 border-solid border-current border-e-transparent align-[-0.125em] text-widget-button motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white">
                        </div>
                    } @else {
                        <bizz-icon class="icon-button size-4 hover:text-widget-button-hover"
                                   iconName="download"></bizz-icon>
                    }
                </button>
                @if (toggleDownloadPopUp) {
                    <kendo-popup [anchor]="anchor" [animate]="true"
                                 [popupClass]="'flex w-screen sm:w-auto h-auto sm:justify-center sm:rounded'"
                                 class="z-[1004] mt-12 hidden sm:mt-0 sm:block sm:rounded maxw-sm:!top-0"
                                 [positionMode]="'absolute'"
                                 [anchorAlign]="anchorAlign" [popupAlign]="popupAlign"
                                 (anchorViewportLeave)="changeDownloadPopUpState(false)" #popup>
                        <div class="bizz-popup-content">
                            <button type="button" class="hidden gap-3 text-nowrap px-2 py-1.5 sm:flex"
                                    [ngClass]="{ 'sm:hidden' : !data.CanExport }" (click)="exportCsv()">
                                <bizz-extension-icon size="extraSmall" class="flex min-w-5 items-center"
                                                     extension="csv"></bizz-extension-icon>
                                {{ 'CSV' | translate }}
                            </button>

                            <button type="button" class="hidden gap-3 text-nowrap px-2 py-1.5 sm:flex"
                                    [ngClass]="{ 'sm:hidden' : !data.CanExport }" (click)="exportXlsx()">
                                <bizz-extension-icon size="extraSmall" class="-ml-[1px] flex min-w-5 items-center"
                                                     extension="xlsx"></bizz-extension-icon>
                                {{ 'XLSX' | translate }}
                            </button>
                            @if (data.CanExport) {
                                <button type="button" class="hidden gap-3 text-nowrap px-2 py-1.5 sm:flex"
                                        [ngClass]="{ 'sm:hidden' : !reportsAvailableForUser }" (click)="exportPdf()">
                                    <bizz-extension-icon size="extraSmall" class="flex min-w-5 items-center"
                                                         extension="pdf"></bizz-extension-icon>
                                    {{ 'PDF' | translate }}
                                </button>
                            }
                        </div>
                    </kendo-popup>
                }
                @if (data.CanAccessTrashCan) {
                    <button type="button" class="cq-widget-btn-hidden hidden px-2 py-1 sm:flex"
                            (click)="toggleTrashView()">
                        <bizz-icon class="icon-button size-4 hover:cursor-pointer hover:text-red-400 focus:text-red-400"
                                   iconName="trash-can" [class]="this.isTrashView ? '!text-red-500' : ''"></bizz-icon>
                    </button>
                }
                @if (data.CanCreate && !readOnly) {
                    <button type="button" class="save round mx-1 size-8 drop-shadow-sm sm:flex" (click)="newRecord()">
                        <bizz-icon class="size-4" iconName="plus"></bizz-icon>
                    </button>
                }
                <button type="button"
                        class="cq-widget-btn-flex px-0 py-1 hover:text-blue-600 focus:text-blue-600 sm:hidden"
                        (click)="toggleMobileActionsPopup($event)" #mobileActionsAnchor>
                    <bizz-icon
                            class="inline-block size-5 text-widget-button hover:cursor-pointer hover:text-blue-600 focus:text-blue-600"
                            iconName="ellipsis-vertical"></bizz-icon>
                </button>
            </div>
        </div>
    </div>
    @if (showMobileActionsPopup) {
        <kendo-popup #propertiesPopup class="z-[1004] w-auto" [anchor]="mobileAnchor" [animate]="true"
                     [margin]="{ horizontal: 0, vertical: 10 }" [collision]="{ horizontal: 'fit', vertical: 'fit' }"
                     [popupAlign]="{ horizontal: 'right', vertical: 'top' }"
                     [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }" [positionMode]="'absolute'"
                     (anchorViewportLeave)="toggleMobileActionsPopup(undefined, false)">
            <div class="bizz-popup-content">
                <button type="button" class="flex gap-4 text-nowrap px-2 py-1.5" (click)="refreshGrid()">
                    <bizz-icon class="size-5 min-w-5 sm:size-3 sm:min-w-3" iconName="rotate-right"></bizz-icon>
                    <span class="text-sm sm:text-xs">{{ 'Refresh' | translate }}</span>
                </button>
                @if (data.CanAccessTrashCan) {
                    <button type="button" class="flex gap-4 text-nowrap px-2 py-1.5" (click)="toggleTrashView()">
                        <bizz-icon class="size-5 min-w-5 sm:size-3 sm:min-w-3" iconName="trash-can"
                                   [class]="this.isTrashView ? '!text-red-400' : ''"></bizz-icon>
                        <span class="text-sm sm:text-xs">{{ 'Trashcan' | translate }}</span>
                    </button>
                }
                @if (data.CanExport) {
                    <hr class="my-1.5 h-[1px] border-t border-divider px-2"/>
                    <button type="button" class="flex gap-4 text-nowrap px-2 py-1.5" (click)="exportCsv()">
                        <bizz-icon class="size-5 min-w-5 text-sidebar-icon sm:size-3 sm:min-w-3"
                                   iconName="file-spreadsheet"></bizz-icon>
                        <span class="text-sm sm:text-xs">{{ 'Export' | translate }} {{ 'CSV' | translate }}</span>
                    </button>

                    <button type="button" class="flex gap-4 text-nowrap px-2 py-1.5" (click)="exportXlsx()">
                        <bizz-icon class="size-5 min-w-5 text-green-700 sm:size-3 sm:min-w-3"
                                   iconName="file-excel"></bizz-icon>
                        <span class="text-sm sm:text-xs">{{ 'Export' | translate }} {{ 'XLSX' | translate }}</span>
                    </button>
                    @if (reportsAvailableForUser) {
                        <button type="button" class="flex gap-4 text-nowrap px-2 py-1.5" (click)="exportPdf()">
                            <bizz-icon class="size-5 min-w-5 text-red-400 sm:size-3 sm:min-w-3"
                                       iconName="file-pdf"></bizz-icon>
                            <span class="text-sm sm:text-xs">{{ 'Export' | translate }} {{ 'PDF' | translate }}</span>
                        </button>
                    }
                }
            </div>
        </kendo-popup>
    }
    <bizz-grid class="bizz-grid flex h-full flex-col overflow-hidden" [currentPage]="currentPage"
               [gridOptions]="gridOptions" [gridData]="(gridData | async)!" [readOnly]="readOnly" [loading]="loading"
               [totalItems]="((gridResultData$ | async)?.totalItems ?? 0)"
               [gridCellMetadata]="{ trashFilterActive: isTrashView, collectionType, listOptions, isDocumentTreeGrid: false,
        userId, userType, managerTrainingQueue: false, widgetId: data.ID }"
               [autoFitColumns]="listOptions.GridColumnsAutoWidth" [commandColumnTemplate]="commandColumnTemplate"
               (selectionChange)="selectionChange.emit($event)" (cellAction)="onCellAction($event)"
               (pageChange)="onPageChange($event)" (groupChange)="onGroupChange($event)"
               (sortChange)="onSortChange($event)"></bizz-grid>
</div>
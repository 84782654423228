@if (formFieldSignal(); as formField) {
    <div class="flex flex-col gap-2">
        @if(!isInGrid) {
            <label class="flex gap-1 text-sm font-semibold" [for]="formField.Id">
                <div class="flex items-center" [innerHTML]="formField.Caption"></div>
                @if (formField.IsRequired) {
                    <span class="relative -top-1 h-5 text-xl leading-tight text-red-400">*</span>
                }
                @if (formField && formField.Hint) {
                    <bizz-tooltip class="ml-1" [text]="formField.Hint"></bizz-tooltip>
                }
                @if (errorState?.Message; as errorMessage) {
                    <bizz-tooltip
                            class="relative -top-2 inline-flex h-5 cursor-pointer text-xl leading-4 text-red-400"
                            [text]="errorMessage"></bizz-tooltip>
                }
                @else if(formControl.invalid){
                    <bizz-tooltip class="relative -top-2 inline-flex h-5 cursor-pointer text-xl leading-4 text-red-400"  [text]="'Info_ProvideEmail' | translate"></bizz-tooltip>
                }
            </label>
        }
        <div class="input-button-group flex" [ngClass]="{'hidden' : formField.IsHidden}">
            <input #input [readOnly]="readOnlyOverride || formField.IsReadOnly"
                [ngClass]="{'border-red-400': formControl.dirty && formControl.invalid}"
                [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
                [formControl]="formControl"
                [class.error]="errorState != null"
                id="{{formField.Id}}"
                placeholder="{{formField.Placeholder}}"
                type="email">
            <div class="button-group">
                <button type="button"
                        class="default !px-2 !py-1"
                        [class.error]="errorState != null"
                        [disabled]="formControl.invalid"
                        (click)="openMailClient()">
                    <bizz-icon class="h-4 w-4" iconName="envelope"></bizz-icon>
                </button>
            </div>
        </div>
    </div>
}

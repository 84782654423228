@if (value !== undefined) {
    @if (columnHasDisplayStyle) {
        @if (hasDisplayStyle) {
            <span class="truncate rounded-full px-3 py-1 text-sm font-medium text-widget sm:text-xs"
                  [style]="{'background-color' : backgroundColor, 'color': foregroundColor }">
            {{ value | decimal: column.MaxDecimals }}
        </span>
        } @else {
            <span class="truncate px-3 text-sm font-normal text-widget sm:text-xs">
            {{ value | decimal: column.MaxDecimals }}
            </span>
        }
    } @else {
        <span class="truncate text-sm font-normal text-widget sm:text-xs">
            {{ value | decimal: column.MaxDecimals }}
        </span>
    }
}

<div class="flex max-w-full flex-grow flex-wrap-reverse gap-2 sm:flex-nowrap" [ngClass]="{
    'flex-row-reverse justify-start' : taskStatusAccessible,
    'justify-end' : !taskStatusAccessible }">
    @if(searchFieldAccessible){
        <bizz-search [searchTerm]="searchTerm" (searchTermChange)="onSearchTermChanged($event)">
        </bizz-search>
    }
    @if (taskStatusAccessible){
        <kendo-dropdownlist
                class="h-full w-28 sm:w-48"
                [data]="searchedTaskDto"
                [value]="taskStateFilterType"
                textField="Text"
                valueField="Value"
                [valuePrimitive]="true"
                (valueChange)="onTaskStatusChanged($event)">
        </kendo-dropdownlist>
    }
    @if(filterEnabled) {
        <div class="flex flex-row rounded">
            <button [disabled]="loading" (click)="openFilterModal()" class="p-1">
                <bizz-icon class="icon-button size-4" [ngClass]="activeFilter ? '!text-blue-500' : ''" iconName="filter"></bizz-icon>
            </button>
            @if (dropdownAccessible) {
                <button class="p-1" type="button" #anchor (click)="togglePopup()">
                    <bizz-toggle-arrow class="icon-button pointer-events-none size-4" [ngClass]="activeFilter ? '!text-blue-500' : ''" [direction]="caretVisible ? 'up' : 'down'"></bizz-toggle-arrow>
                </button>
            }
        </div>
    }
</div>
<ng-template #popupTemplate>
    <div class="bizz-popup-content flex max-h-[50svh] flex-col overflow-y-auto text-sm sm:text-xs">
        <button type="button"  class="flex gap-4 text-nowrap px-2 py-1.5" (click)="resetFilterDefault()">
            <bizz-icon class="size-5 min-w-5 sm:size-3 sm:min-w-3" [hover]="true" iconName="rotate-left"></bizz-icon>
            <span class="text-sm sm:text-xs">{{"ResetToDefaultFilter" | translate}}</span>
        </button>
        @if (filterList.length > 1) {
            <hr class="my-1.5 h-[1px] border-t border-divider px-2"/>
        }
        @for (filter of filterList; track $index){
            @if (filter.Priority > 1){
                <button type="button"  class="flex gap-4 text-nowrap px-2 py-1.5" (click)="loadCaretFilter(filter.ID)" 
                [ngClass]="filter.ID == activeFiltersId ? 'text-blue-500' : ''">
                    <bizz-icon class="size-5 min-w-5 sm:size-3 sm:min-w-3" 
                    [hover]="true" iconName="filter" 
                    [ngClass]="filter.ID == activeFiltersId ? 'text-blue-500' : ''"></bizz-icon>
                    <span class="text-sm sm:text-xs">{{filter.Name}}</span>
                </button>
            }
        }
        <hr class="my-1.5 h-[1px] border-t border-divider px-2"/>
        <button type="button" class="flex gap-4 text-nowrap px-2 py-1.5" (click)="clearFilter()">
            <bizz-icon class="size-5 min-w-5 sm:size-3 sm:min-w-3" 
            [hover]="true" iconName="eraser"></bizz-icon>
            <span class="text-sm sm:text-xs">{{"ClearAllFilters" | translate}}</span>
        </button>
    </div>
</ng-template>
@if (formFieldSignal(); as formField) {
    <div class="flex flex-col gap-2" [ngClass]="{'hidden' : formField.IsHidden}"
         [tabIndex]="(readOnlyOverride || formField.IsReadOnly) ? -1  : 0">
        <div class="flex justify-between gap-2">
            <label class="flex gap-1 text-sm font-semibold" [for]="formField.Id">
                <div class="flex items-center" [innerHTML]="formField.Caption"></div>
                @if (formField.IsRequired) {
                    <span class="relative -top-1 h-5 text-xl leading-tight text-red-400">*</span>
                }
                @if (formField && formField.Hint) {
                    <bizz-tooltip class="ml-1" [text]="formField.Hint"></bizz-tooltip>
                }
                @if (errorState?.Message; as errorMessage) {
                    <bizz-tooltip
                            class="relative -top-2 inline-flex h-5 cursor-pointer text-xl leading-4 text-red-400"
                            [text]="errorMessage"></bizz-tooltip>
                }
            </label>
            @if (!formField.IsReadOnly && !readOnlyOverride && !isProtectedCollectionField()) {
                <div class="flex items-center justify-center gap-2">
                    @if (showCreate || showLookup) {
                        <div class="button-group" #createMenuAnchor>
                            <ng-container
                                    [ngTemplateOutlet]="showCreate ? createNewInstanceButton : lookupButton"></ng-container>
                            @if ((showCreate || showLookup) && showNewItem) {
                                <button class="form-header-button save" type="button"
                                        (click)="toggleCreateMenuPopup($event)">
                                    <bizz-toggle-arrow class="size-4" [direction]="showCreateMenuPopup ? 'up' : 'down'">
                                    </bizz-toggle-arrow>
                                </button>
                            }
                        </div>
                    }
                    @if (showCreateMenuPopup) {
                        <kendo-popup #createMenuPopup
                                     class="z-[1004] w-full sm:w-auto maxw-sm:!left-0"
                                     [margin]="{ horizontal: 0, vertical: 10 }"
                                     [anchor]="createMenuAnchor"
                                     [collision]="{ horizontal: 'flip', vertical: 'fit' }"
                                     [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }"
                                     [popupAlign]="{ horizontal: 'right', vertical: 'top' }"
                                     (anchorViewportLeave)="toggleCreateMenuPopup(undefined,false)">
                            <div class="bizz-popup-content">
                                @if (showLookup && showCreate) {
                                    <ng-container [ngTemplateOutlet]="lookupButton"></ng-container>
                                }
                                @if (showNewItem) {
                                    <ng-container [ngTemplateOutlet]="addItemButton"></ng-container>
                                }
                            </div>
                        </kendo-popup>
                    }
                </div>
            }
        </div>
        @if (pagedRecords(); as records) {
            <bizz-grid #bizzGridComponent
                       [trackBy]="trackBy"
                       [loading]="true"
                       [currentPage]="currentPage"
                       [totalItems]="activeRecords().length"
                       [gridData]="records"
                       [tabIndex]="(readOnlyOverride || formField.IsReadOnly) ? -1  : 0"
                       [gridOptions]="gridOptions"
                       [columnTemplate]="formColumnTemplate"
                       [commandColumnTemplate]="commandColumnTemplate"
                       (pageChange)="pageChange($event)"
                       (cellAction)="onCellAction($event)"
                       [autoFitColumns]="false">
            </bizz-grid>
            <ng-template #commandColumnTemplate let-dataItem let-rowIndex="rowIndex" let-column="column">
                <bizz-from-field-grid-action-cell [record]="dataItem"
                                                  [formSignal]="form"
                                                  [actionsDisabled]="(readOnlyOverride || formField.IsReadOnly)"
                                                  [tabIndex]="(readOnlyOverride || formField.IsReadOnly) ? -1  : 0"
                                                  [recordsSignal]="activeRecords"
                                                  [gridFieldSignal]="formFieldSignal"
                                                  [externalAccess]="externalAccess"
                                                  [formType]="formType()"
                                                  (openClicked)="onOpenRecordClicked($event)"
                                                  (editInNewWindowClicked)="onEditInNewWindowClicked($event)"
                                                  (editInlineClicked)="onEditInlineClicked(dataItem, rowIndex)"
                                                  (deleteClicked)="onDeleteClicked(dataItem, rowIndex)"
                                                  (saveClicked)="onSaveClicked(dataItem, rowIndex)"
                                                  (editDocumentClicked)="onEditDocumentClicked($event)">
                </bizz-from-field-grid-action-cell>
            </ng-template>
            <ng-template #formColumnTemplate let-data let-column="column" let-columnIndex="columnIndex">
                @if (data.EditMode) {
                    <bizz-form-control [isGridControl]="true"
                                       [formId]="formId"
                                       [tabIndex]="(readOnlyOverride || formField.IsReadOnly) ? -1  : 0"
                                       [fieldId]="column.CollectionFieldsID"
                                       [recordId]="data.CrossLinkedInstancesID"
                                       [gridFieldId]="formField.Id"
                                       [group]="formControl">
                    </bizz-form-control>
                } @else {
                    <bizz-form-field-grid-cell [data]="data" [fieldId]="column.CollectionFieldsID"
                                               [tabIndex]="(readOnlyOverride || formField.IsReadOnly) ? -1  : 0"
                                               [gridColumn]="column"
                                               (onCellAction)="onCellAction($event)">
                    </bizz-form-field-grid-cell>
                }
            </ng-template>
        }
    </div>
}

<ng-template #createNewInstanceButton>
    <button [ngClass]="{'form-header-button !sm:py-[7px] save !px-2': showCreate}" type="button"
            (click)="createNewInstance(); setCreateMenuPopup(false)">
        <bizz-icon class="size-4" iconName="circle-plus"></bizz-icon>
        <span class="hidden md:flex">{{ 'CreateNewInstance' | translate }}</span>
    </button>
</ng-template>
<ng-template #lookupButton>
    <button [ngClass]="{'form-header-button !sm:py-[7px] save !px-2': !showCreate}"
            (click)="searchInList(); setCreateMenuPopup(false)" type="button">
        <bizz-icon [title]="('SearchInList' | translate)"
                   class="mr-2 h-5 w-5 hover:text-green-500 sm:h-4 sm:w-4"
                   [hover]="true"
                   iconName="magnifying-glass">
        </bizz-icon>
        {{ 'SearchInList' | translate }}
    </button>
</ng-template>
<ng-template #addItemButton>
    <button (click)="createNewRowInstance(); setCreateMenuPopup(false)" type="button">
        <bizz-icon [title]="('AddItem' | translate)"
                   class="mr-2 h-5 w-5 hover:text-green-500 sm:h-4 sm:w-4"
                   [hover]="true"
                   iconName="circle-plus">
        </bizz-icon>
        {{ 'AddItem' | translate }}
    </button>
</ng-template>
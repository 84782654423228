import { Component, EventEmitter, HostListener, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { TooltipComponent } from 'src/app/shared/components/ui/tooltip/tooltip.component';
import {
  EditorColorPickerComponent,
  EditorComponent as KendoEditorComponent,
  EditorModule,
  EditorView,
  Plugin,
  PluginKey
} from '@progress/kendo-angular-editor';
import { TextSelection } from 'prosemirror-state';
import { EditorFontSizeComponent } from './custom-tools/editor-font-size/editor-font-size.component';
import {
  alignCenterIcon,
  alignJustifyIcon,
  alignLeftIcon,
  alignRightIcon,
  chevronDownIcon,
  clearCssIcon,
  codeSnippetIcon,
  dropletIcon,
  foregroundColorIcon,
  strikethroughIcon,
  subscriptIcon,
  supscriptIcon,
  tableAddIcon,
  unlinkIcon
} from '@progress/kendo-svg-icons';
import { FormatItem } from '@progress/kendo-angular-editor/common/format-item.interface';
import { ThemeService } from 'src/app/core/services/theme/theme.service';

@Component({
  selector: 'bizz-editor',
  standalone: true,
  imports: [CommonModule, EditorModule, IconComponent, ButtonModule, TooltipComponent, EditorFontSizeComponent],
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

  @ViewChild('editor') public editor: KendoEditorComponent;
  @ViewChild('foreColorPicker') public foreColorPicker: EditorColorPickerComponent;
  @ViewChild('backColorPicker') public backColorPicker: EditorColorPickerComponent;

  @ViewChild('editorFontSizeTool') public editorFontSizeTool: EditorFontSizeComponent;

  public isMobileView = false;
  public chevronDownIcon = chevronDownIcon;

  @Input() public value: string;
  @Output() public valueChange = new EventEmitter<string>();
  @Input() public hideToolbar: boolean = false;
  @Input() public overflowToolbar: boolean = true;
  @Input() public readOnly: boolean = false;
  @Input() public minHeight: number = 250;
  @Input() public id: string = '';
  @Input() public isError: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public iframe: boolean = false;
  @Input() public editorClass: string = '';

  public data = [
    {
      text: 'Clean formatting',
      svgIcon: clearCssIcon,
      click: (): void => this.editor.exec('cleanFormatting')
    },
    {
      text: 'Strikethrough',
      svgIcon: strikethroughIcon,
      click: (): void => this.editor.exec('strikethrough')
    },
    {
      text: 'Subscript',
      svgIcon: subscriptIcon,
      click: (): void => this.editor.exec('subscript')
    },
    {
      text: 'Superscript',
      svgIcon: supscriptIcon,
      click: (): void => this.editor.exec('superscript')
    },
    {
      text: 'Foreground color',
      svgIcon: foregroundColorIcon,
      click: (): void => this.foreColorPicker.openDialog()
    },
    {
      text: 'Background color',
      svgIcon: dropletIcon,
      click: (): void => this.backColorPicker.openDialog()
    },
    {
      text: 'Align text left',
      svgIcon: alignLeftIcon,
      click: (): void => this.editor.exec('alignLeft')
    },
    {
      text: 'Align text center',
      svgIcon: alignCenterIcon,
      click: (): void => this.editor.exec('alignCenter')
    },
    {
      text: 'Align text right',
      svgIcon: alignRightIcon,
      click: (): void => this.editor.exec('alignRight')
    },
    {
      text: 'Align text justify',
      svgIcon: alignJustifyIcon,
      click: (): void => this.editor.exec('alignJustify')
    },
    {
      text: 'Unlink',
      svgIcon: unlinkIcon,
      click: (): void => this.editor.exec('unlink')
    },
    {
      text: 'Insert Table',
      svgIcon: tableAddIcon,
      click: (): void => this.editor.exec('insertTable', { rows: 1, cols: 1 })
    },
    {
      text: 'View source',
      svgIcon: codeSnippetIcon,
      click: (): void => this.editor.openDialog('viewSource')
    }
  ];

  // CSS imports (iframe mode)
  public embeddedCss = `
    @font-face {
      font-family: 'Noto Sans';
      font-weight: 100 900;
      font-stretch: 62.5% 100%; // 62.5% is the minimum value for the font-stretch property
      font-style: normal;
      src: url(/assets/fonts/Noto-Sans/NotoSans-Variable.woff2) format("woff2");
    }

    @font-face {
      font-family: Montserrat;
      font-weight: 100 900;
      font-style: normal;
      src: url(/assets/fonts/Montserrat/Montserrat-Variable.woff2) format("woff2");
    }

    .k-content {
      font-family: 'Noto Sans', sans-serif;
    }

    .k-content p {
      font-size: 12px;
    }

    .k-content h1, .k-content h2, .k-content h3, .k-content h4, .k-content h5, .k-content h6 {
      font-family: Montserrat, sans-serif;
    }
  `;

  private fontSizes: { [key: string]: string } | null = null;

  public constructor(private ngZone: NgZone, private themeService: ThemeService) {
      this.themeService.currentTheme$.subscribe((theme) => {
        this.fontSizes = this.themeService.getThemeFontSizes();
      });
  }

  public editorPlugins = (args: Plugin[]): Plugin[] => [
    ...args,
    this.detectFontSizePlugin
  ];

  // @ts-ignore
  private detectFontSizePlugin = new Plugin({
    key: new PluginKey('detect-font-size'),
    view: () => ({
      update: (view: EditorView): void => {
        // Details about the API and usage of the EditorView object are available in the
        // ProseMirror documentation - https://prosemirror.net/docs/ref/#view
        const state = view.state;
        const selection = state.selection as TextSelection;
        if (selection && selection.$cursor) {
          const $cursor = selection.$cursor;
          const node = this.getDeepestChild(view.domAtPos($cursor.pos).node);
          if (node) {
            const fontSize = this.getNodeFontSize(node);
            if (fontSize) {
              this.ngZone.run(() => {
                if(this.editorFontSizeTool)
                  this.editorFontSizeTool.value = fontSize;
              });
            }
          }
        }
      }
    }),

  });

  public getDeepestChild(node: Node | null): Node | null {
    if (node == null)
      return null;
    let child = node;
    while (child.firstChild != null && child.firstChild instanceof HTMLElement) {
      child = child.firstChild;
    }
    return child;
  }

  public getNodeFontSize(node: Node | HTMLElement | null, nrOfIterations = 5): string {
    if (nrOfIterations == 0)
      return '';
    if (node && node instanceof HTMLElement) {
      return window.getComputedStyle(node).getPropertyValue('font-size');
    } else if (node && (node as Node).parentNode != null) {
      return this.getNodeFontSize((node as Node).parentNode, nrOfIterations - 1);
    }
    return '';
  }

  public setSelection(): void {
    this.editor.view.state.selection;
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.isMobileView = window.innerWidth <= 640;
  }

  public ngOnInit(): void {
    //640px is the tailwindcss sm breakpoint (maybe make a class for this)
    this.isMobileView = window.innerWidth <= 640;
  }

  public focus(): void {
    this.editor.focus();
  }

  public onValueChange(value: string): void {
    this.valueChange.emit(value);
  }

  public onFormatChange(newFormat: FormatItem): void {
    if(this.editorFontSizeTool == null || this.fontSizes == null)
      return;
    const { tag } = newFormat;
    const fontSize = this.fontSizes[tag];
    if(fontSize) { 
      this.editorFontSizeTool.value = fontSize;
      this.editor.focus();
      this.editor.exec('cleanFormatting', {
        blocksInSelection: false,
      });
    }
  }
}

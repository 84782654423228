<kendo-editor #editor class="h-auto max-h-[800px] min-w-full max-w-full"
              [plugins]="editorPlugins"
              [ngClass]="editorClass"
              [value]="value"
              (valueChange)="onValueChange($event)"
              [style]="{'min-height': minHeight + 'px' }"
              [id]="'tabID' + id"
              [readonly]="readOnly" tabIndex="-1"
              [resizable]="{ minHeight: (minHeight ? minHeight : 64) }"
              [class.error]="isError"
              [disabled]="disabled"
              [iframe]="iframe"
              [iframeCss]="{ content: embeddedCss }"
              [applyToWord]="true">
    <kendo-toolbar 
    [ngClass]="{'hidden' : hideToolbar, 'mobile': isMobileView}" 
    [overflow]="overflowToolbar">
        @if (!hideToolbar) {
            @if (!isMobileView) {
                <kendo-toolbar-dropdownlist kendoEditorFormat
                                            (valueChange)="onFormatChange($event)"></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist bizzEditorFontSize #editorFontSizeTool></kendo-toolbar-dropdownlist>
                <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-colorpicker kendoEditorForeColor view="gradient"></kendo-toolbar-colorpicker>
                <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                <kendo-toolbar-buttongroup>
                <kendo-toolbar-button 
                kendoEditorAddColumnBeforeButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button 
                kendoEditorAddColumnAfterButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button 
                kendoEditorAddRowBeforeButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button 
                kendoEditorAddRowAfterButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button 
              kendoEditorDeleteColumnButton
            ></kendo-toolbar-button>
            <kendo-toolbar-button 
              kendoEditorDeleteRowButton
            ></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
            } @else {
                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-spacer></kendo-toolbar-spacer>
                <kendo-toolbar-dropdownbutton [svgIcon]="chevronDownIcon" [data]="data"></kendo-toolbar-dropdownbutton>
                <kendo-toolbar-colorpicker #foreColorPicker kendoEditorForeColor
                                           view="gradient"></kendo-toolbar-colorpicker>
                <kendo-toolbar-colorpicker #backColorPicker kendoEditorBackColor
                                           view="gradient"></kendo-toolbar-colorpicker>
            }
        }
    </kendo-toolbar>
</kendo-editor>
@if (formFieldSignal(); as formField) {
<!--    @if (errorState?.Message; as errorMessage) {-->
<!--        <div class="absolute right-12 top-0 z-[1] flex h-full items-center justify-center">-->
<!--            <bizz-tooltip class="cursor-pointer text-red-400" [text]="errorMessage"></bizz-tooltip>-->
<!--        </div>-->
<!--    }-->
    <kendo-datepicker
            #input
            [class.error]="errorState != null"
            [formControl]="formControl"
            [ngClass]="{'hidden' : formField.IsHidden}"
            [placeholder]="''"
            [readonly]="readOnlyOverride || formField.IsReadOnly"
            [tabIndex]="-1"
            [id]="'TabIDPicker'+ formField.Bookmark"
            [readOnlyInput]="readOnlyOverride || formField.IsReadOnly"
            [inputAttributes]="{'tabindex': (readOnlyOverride || formField.IsReadOnly ? '-1' : '0')}"
            [format]="dateFormat" (blur)="hidePopup()" (focus)="setCurrentDate()">
    </kendo-datepicker>
}
<!-- TODO: suggest incomplete date validation in front end => [incompleteDateValidation] built into kendo -->
<!-- DO NOT CONVERT TO LOCAL -->
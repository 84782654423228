import { DestroyRef, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class NavigationHistoryService {

  public static history: string[] = [];
  public static root = '/';

  public constructor(private router: Router) {

  }

  public init(destroyRef: DestroyRef):void {
    this.router.events.pipe(
      takeUntilDestroyed(destroyRef),
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if(event.urlAfterRedirects.toLowerCase().includes('auth')){
        this.clearHistory();
      } else {
        NavigationHistoryService.history.push(event.urlAfterRedirects);
      }

    });
  }


  public getHistory(): string[] {
    return NavigationHistoryService.history;
  }

  public clearHistory(): void {
    NavigationHistoryService.history = [];
  }
}

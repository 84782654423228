import { Component, computed, ElementRef, inject, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { BytesPipe } from 'src/app/shared/pipes/bytes/bytes.pipe';
import { ProtectedFieldType } from 'src/models/ts/protected-field-type.model';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { FORMAT_EXCEPTION_FIELD_TYPES } from '../../../constants/form-constants';
import { getDisplayStyleForNumber } from 'src/app/shared/functions/helpers/display-style-helpers';
import { DecimalPipe } from '../../../../../../shared/pipes/decimal/decimal.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CollectionFieldDisplayStyleValueDto } from 'src/models/ts/collection-field-display-style-value-dto.model';
import { toNumber } from 'lodash';
import { TranslatePipe } from "../../../../../../shared/pipes/translate/translate.pipe";
import { TranslationService } from 'src/app/core/services/translation/translation.service';

/**
 * Represents a control that allows the user to enter a numeric value.
 * Decimal and thousand separators are automatically added.
 * Amount of decimal places is restricted according to configuration.
 */
@Component({
  selector: 'bizz-numeric-control',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, NgStyle, NumericTextBoxModule, AsyncPipe, BytesPipe, TooltipComponent, DecimalPipe, TranslatePipe],
  templateUrl: './numeric-control.component.html',
  styleUrls: ['./numeric-control.component.scss']
})
export class NumericControlComponent extends BaseFormControlComponent {
  @ViewChild('input') inputElement: ElementRef;
  @ViewChild('inputKendo') inputKendoElement: NumericControlComponent;
  public backgroundColor = '';
  public foregroundColor = '';
  public hasDisplayStyle = false;
  public inputHasFocus = false;
  protected ProtectedFieldType = ProtectedFieldType;

  private translationService = inject(TranslationService);

  /** 
 * Bug reproduction: type 25.5 quickly
 * Value is set to 25
 * Debouncer starts ticking down
 * User types a dot/comma (decimal sign)
 * 25 & 25. are the same value => no change emitted
 * Debouncer lapses => Value is set in store
 * Store event updates formControl value to 25
 * Trailing comma vanishes
 */
  protected override changeDelay = 0;

  public override ngOnInit(): void {
    super.ngOnInit();

    const field = this.formFieldSignal();
    if (field) {
      this.setThemeColors(field.DisplayStyleValues, field[this.fieldValueProperty]);
    }

    this.formControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: value => this.setThemeColors(this.formFieldSignal()!.DisplayStyleValues, value)
    });
  }

  // TODO: Once unit tests are in place, test this formatting method
  /**
   * Creates the correct format for numeric inputs.
   * Is an empty String in case of a limited set of protected field types.
   * @param {CollectionFormField} field
   * @returns {string}
   */
  public format(field: CollectionFormField): string {
    if (!FORMAT_EXCEPTION_FIELD_TYPES.includes(field.ProtectedFieldType)) {
      return `n${field.MaxDecimals}`;
    }

    return '';
  }

  public onFocus(): void {
    if (!this.formFieldSignal()?.IsReadOnly) {
      this.inputHasFocus = true;
    }
  }

  public onBlur(): void {
    this.inputHasFocus = false;
  }

  public getTranslatedValidationError(): string {
    const f = this.formFieldSignal(); 
    if(!f) return '';
    return this.translationService.translate("ProvideAValueLowerThan {0} OrHigherThen {1} For {2}") //weird key
    .replace('{0}', f.MinValue.toString())
    .replace('{1}', f.MaxValue.toString())
    .replace('{2}', f.Caption);
  }

  protected override valueSetter(field: CollectionFormField): void {
    let fieldValue = field[this.fieldValueProperty];
    let numberValue = fieldValue != null ? toNumber(fieldValue) : fieldValue;
      this.formControl.setValue(numberValue, { emitEvent: false });
      this.setThemeColors(field.DisplayStyleValues, numberValue);
  }

  protected override focus(): void {
    if (this.formFieldSignal()?.ProtectedFieldType == ProtectedFieldType.Size) {
      this.inputElement.nativeElement.focus();
    } else {
      this.inputKendoElement.focus();
    }
  }

  private setThemeColors(styles: CollectionFieldDisplayStyleValueDto[], value: number): void {
    const displayValue = getDisplayStyleForNumber(styles, value);
    if (displayValue) {
      this.backgroundColor = displayValue.BackgroundColor;
      this.foregroundColor = displayValue.ForegroundColor;
      this.hasDisplayStyle = true;
    } else {
      this.hasDisplayStyle = false;
    }
  }
}

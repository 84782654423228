import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DiagramComponent, GojsAngularModule } from 'gojs-angular';
import { FlowDiagram, FlowDiagramConfig } from '../../class/flow-diagram';
import { FlowMapperService } from '../../services/flow-mapper.service';
import { FlowDiagramState } from '../../interfaces/flow-diagram-state';
import * as go from 'gojs';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { FlowDto } from '../../../../../../models/ts/flow-dto.model';
import { FlowStatusTaskDto } from '../../../../../../models/ts/flow-status-task-dto.model';
import * as Sentry from '@sentry/angular';
import { FlowDiagramColorService } from '../../services/flow-diagram-color-service';

@Component({
  selector: 'bizz-flow-status-diagram',
  standalone: true,
  imports: [CommonModule, IconComponent, PopupModule, TranslatePipe, GojsAngularModule, NgScrollbarModule],
  templateUrl: './flow-status-diagram.component.html',
  styleUrls: ['./flow-status-diagram.component.scss']
  //encapsulation: ViewEncapsulation.None,
})
@Sentry.TraceClass({ name: 'FlowStatusDiagramComponent' })
export class FlowStatusDiagramComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('bizzDiagram', { static: true }) public diagramComponent: DiagramComponent;


  @Input({ required: true }) public flow: FlowDto;
  @Input({ required: true }) public tasks: FlowStatusTaskDto[];

  private flowDiagram: FlowDiagram;
  private flowState: FlowDiagramState;

  public constructor(private flowMapper: FlowMapperService,
    private flowDiagramColorService: FlowDiagramColorService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  @Sentry.TraceMethod({ name: 'ngOnInit' })
  public ngOnInit(): void {
    if (this.flow == undefined)
      throw new Error('Flow is undefined');
    this.flowState = this.flowMapper.mapToFlowState(this.flow, this.tasks);
    this.initDiagram();
  }

  @Sentry.TraceMethod({ name: 'ngOnDestroy' })
  public ngOnDestroy(): void {
  }

  public initDiagram(): void {
    if (this.flowState == undefined)
      throw new Error('Flow state is undefined');

    this.flowDiagram = new FlowDiagram();
    const config: FlowDiagramConfig = {
      draft: false,
      flowStatus: true,
      colors: this.flowDiagramColorService.Colors,
    };

    this.flowDiagram.init(config);
    this.diagramComponent.initDiagram = (): go.Diagram => {
      const diagram = this.flowDiagram.initDiagram();
      this.flowDiagram.load(this.flowState);
      return diagram;
    };
  }

  public zoomToFit(): void {
    this.flowDiagram.zoomToFit();
  }

  public zoomIn(): void {
    this.flowDiagram.zoomIn();
  }

  public zoomOut(): void {
    this.flowDiagram.zoomOut();
  }

  public setDiagramScale(scale: number = 1): void {
    this.flowDiagram.setDiagramScale(scale);
  }
}

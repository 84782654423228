<bizz-modal modalSize="xs" [title]="'CurrentlyLoggedOn' | translate" [contentTemplate]="contentTemplateRef" [footerTemplate]="footerTemplateRef"></bizz-modal>

<ng-template #contentTemplateRef>
    <div class="modal-content">
        {{ 'ProceedReplaceTokenWithAnonUser' | translate}}
    </div>
</ng-template>

<ng-template #footerTemplateRef>
    <div class="modal-buttons">
        <button class="default" (click)="dialogRef.close(false)">
            {{ 'Cancel' | translate }}
        </button>
        <button (click)="dialogRef.close(true)">
            {{ 'Ok' | translate }}
        </button>
    </div>
</ng-template>
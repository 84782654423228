import { Pipe, PipeTransform } from '@angular/core';
import { DateFilterService } from '../../services/filter/date-filter-service';

@Pipe({
  name: 'datetime',
  standalone: true,
})
export class DatetimePipe implements PipeTransform {
  private _dateFilterService: DateFilterService;

  public constructor(dateFilterService: DateFilterService) {
    this._dateFilterService = dateFilterService;
  }

  public transform(value: unknown): string {
    if (!value) {
      return '';
    } else if (typeof value === 'string') {
      return this._dateFilterService.convertDateTime(value);
    }
    else {
      throw new Error('DateTimePipe: Invalid value type');
    }
  }
}

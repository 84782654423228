/**
 * Enum for read only level.
 * A higher priority level cannot be overridden a lower priority level.
 */
export enum ReadOnlyPriority {
  // Form Designer
  Design = 1,
  // Relational and Historical Must be in list fields that are not lookup fields or deeper level lookup fields
  Relational = 2,
  // Specific to Historical Not must be in list, where fields are always editable
  Historical = 3,
  // Only affects the field if it's a result field, which is always read-only
  Formula = 4,
  // Field cannot be edited due to permissions (form/instance/folder permissions)
  Permissions = 5,
  // Form is in read/view mode (nothing can be edited)
  ReadMode = 6,
  // Field is a protected field (System fields, TRN/Exam fields, etc.)
  Protected = 7,
  // Future Functionality
  Scripting = 8,
  // Hardcoded Functionality
  HardCoded = 9
}

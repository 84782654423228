import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule } from '@progress/kendo-angular-editor';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { EditorComponent as BizzEditorComponent } from '../../../../../../shared/components/editor/editor.component';

/**
 * Represents a control with a rich text editor.
 * Can be simple or contain formatting options and HTML.
 */
@Component({
  selector: 'bizz-memo-control',
  standalone: true,
  imports: [CommonModule, EditorModule, IconComponent, ButtonModule, TooltipComponent, BizzEditorComponent],
  templateUrl: './memo-control.component.html',
  styleUrls: ['./memo-control.component.scss']
})
export class MemoControlComponent extends BaseFormControlComponent implements AfterViewInit {

  @ViewChild('editor') public editor: BizzEditorComponent;

  public readonly MIN_EDITOR_HEIGHT = 250; //min of legacy bizzmine 
  //light editor didn't have a min height in legacy so it's about two lines now.
  public readonly MIN_LIGHT_EDITOR_HEIGHT = 64; 

  public ngAfterViewInit(): void{
    const objFrame = document.getElementById('tabID' + this.formFieldSignal()?.Bookmark);
    const iframeObj = objFrame ? objFrame.getElementsByTagName('iframe') : undefined;

    if (iframeObj) {
      iframeObj[0].setAttribute('tabindex', (this.readOnlyOverride || this.formFieldSignal()?.IsReadOnly ? '-1' : '0'))
    }
  }

  public setValue(value: string): void {
    this.formControl.markAsDirty();
    this.formControl.setValue(value);
  }
   // Implement the abstract member from MemoControlComponent
   protected override focus(): void {
     setTimeout(() => {
        this.editor.focus();
      }, 10);
    }

}
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { WorkspaceSidebarDto } from '../../../../../models/ts/workspace-sidebar-dto.model';
import { SeparatorListDto } from '../../../../../models/ts/separator-list-dto.model';
import { WorkspaceSidebarItemDto } from '../../../../../models/ts/workspace-sidebar-item-dto.model';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DialogModule } from '@angular/cdk/dialog';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SidebarService } from '../../../services/sidebar/sidebar.service';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { ScrollbarComponent } from '../scrollbar/scrollbar.component';
import { slideInOut } from '../../../animations/in-out.trigger';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { WorkspaceSelectorComponent } from '../../../../features/bizzmine/workspace-selector/components/workspace-selector/workspace-selector.component';
import { SidebarBlockComponent } from './sidebar-block/sidebar-block.component';
import { ExpandCollapaseIcon } from '../../expand-collapse-icon/expand-collapase-icon';
import * as Sentry from '@sentry/angular';
import { SkeletonModule } from '@progress/kendo-angular-indicators';
import { ToggleArrowComponent } from '../toggle-arrow/toggle-arrow.component';
import { SidebarBlockSkeletonComponent } from './sidebar-block-skeleton/sidebar-block-skeleton.component';

export type ScrollbarVisibility = 'hover' | 'always' | 'native';

@Component({
  selector: 'bizz-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [
    NgClass,
    IconComponent,
    NgIf,
    NgForOf,
    RouterLink,
    DialogModule,
    ScrollbarComponent,
    NgScrollbarModule,
    DropDownListModule,
    FormsModule,
    PopupModule,
    TranslatePipe,
    AsyncPipe,
    WorkspaceSelectorComponent,
    SidebarBlockComponent,
    ExpandCollapaseIcon,
    RouterLinkActive,
    SkeletonModule,
    SidebarBlockSkeletonComponent,
    ToggleArrowComponent
  ],
  standalone: true,
  animations: [slideInOut]
})
@Sentry.TraceClass({ name: 'SidebarComponent' })
export class SidebarComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * Property representing the sidebar. Cloned from workspaceSidebar with added collapsed property.
   * @see workspaceSidebar
   */
  public sidebar: Sidebar;
  public selectedWorkspace: number;
  public sidebarState: 'in' | 'out' = 'out';
  public ScrollbarVisibility: ScrollbarVisibility;
  public newItemEvent = new EventEmitter<boolean>();
  public expanded = this.sidebarService.getObservable();
  public collapsed = false;
  public sidebarHoverEnabled = false;
  public loading = false;

  /**
   * Sidebar data in the form of an observable.
   */
  @Input({ required: true }) public workspaceSidebar: Observable<WorkspaceSidebarDto>;
  @Input() public sidebarHoverState: boolean;
  @Output() public workspaceChanged = new EventEmitter<number>();

  /**
   * Subject used to unsubscribe from all subscriptions when the component is destroyed.
   */
  private destroy: Subject<void> = new Subject<void>();

  public constructor(
    private sidebarService: SidebarService
  ) {
    this.expanded.pipe(takeUntil(this.destroy)).subscribe({
      next: (expanded) => {
        this.sidebarState = expanded ? 'out' : 'in';
        this.toggleContent(!expanded);
      }
    });
  }

  @Sentry.TraceMethod({ name: 'ngOnChanges' })
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['sidebarHoverState']) {
      if (this.sidebarService.unpinned && this.sidebarHoverEnabled) {
        this.toggleExpanded();
      }
      this.sidebarHoverEnabled = true;
    }
  }

  /**
   * Dispatches a store action which will set the selected workspace in the store
   * @param id
   * @see WorkspaceSelectorComponent, workspaceActions
   */
  public selectedWorkspaceChanged(id: number): void {
    this.loading = true;
    this.workspaceChanged.emit(id);
  }

  @Sentry.TraceMethod({ name: 'ngOnInit' })
  public ngOnInit(): void {
    this.subscribeToSidebar();
    this.checkViewportWidth();
    if (window.innerWidth > 700) {
      window.addEventListener('resize', this.checkViewportWidth.bind(this));
    }
  }

  public checkViewportWidth(): void {
    if (window.innerWidth < 700 && this.sidebarService.expanded) {
      this.sidebarService.toggleSidebar();
    }
    if (window.innerWidth > 700 && !this.sidebarService.expanded) {
      this.sidebarService.toggleSidebar();
    }
  }

  @Sentry.TraceMethod({ name: 'ngOnDestroy' })
  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.unsubscribe();
  }

  // Sets timers to control transitions in sidebar expand/collapse
  public toggleExpanded(): void {
    this.sidebarService.toggleSidebar();
  }

  public onMouseEvent(event: MouseEvent): void {
    if (this.sidebarService.unpinned && event.type == 'mouseleave') {
      this.sidebarService.collapseSidebar();
    } else if (this.sidebarService.unpinned && event.type == 'mouseenter') {
      this.sidebarService.unCollapseSidebar();
    }
  }

  /**
   * Sets the collapsed state of all sidebar items.
   * @param collapsed
   */
  public toggleContent(collapsed: boolean): void {
    this.collapsed = collapsed;
    if (this.sidebar?.Parents)
      for (const item of this.sidebar.Parents) {
        item.Parent.collapsed = collapsed;
      }
  }

  /**
   * Subscribes to the sidebar observable and sets the sidebar and selectedWorkspace properties on change.
   * @private
   */
  private subscribeToSidebar(): void {
    if (this.workspaceSidebar) {
      this.workspaceSidebar.pipe(takeUntil(this.destroy)).subscribe({
        next: (sidebar: WorkspaceSidebarDto) => {
          this.sidebar = structuredClone(sidebar) as Sidebar;
          this.toggleContent(!this.expanded);
          this.selectedWorkspace = sidebar.ID;
          this.loading = false;
        }
      });
    }
  }
}

// Interfaces that extend back end DTOs to add UI properties where needed (collapsed)
export interface Sidebar extends WorkspaceSidebarDto {
  Parents: SidebarList[];
}

export interface SidebarList extends SeparatorListDto {
  Parent: SidebarListItem;
}

export interface SidebarListItem extends WorkspaceSidebarItemDto {
  collapsed: boolean;
}

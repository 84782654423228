<div class="input-button-group relative flex h-full rounded">
<!--    @if(errorState?.Message; as errorMessage){-->
    <!--        <div class="absolute right-12 top-0 z-[2] flex h-full items-center justify-center">-->
    <!--            <bizz-tooltip class="cursor-pointer text-red-400" [text]="errorMessage"></bizz-tooltip>-->
    <!--        </div>-->
    <!--    }-->
        <kendo-multiselect  [id]="inputId" rounded="none" class="h-auto !rounded-l rounded-r-none"
                            [class.error]="errorState != undefined"
            [class.focused]="isFocused" [filterable]="true" (filterChange)="lookup$.next($event)" [data]="lookupResults"
            [value]="selection" (valueChange)="selectionChange($event)" textField="Name" valueField="Name"
            [valuePrimitive]="false" [readonly]="readonly" [tabIndex]="readonly ? -1  : 0">
            <ng-template kendoMultiSelectTagTemplate kendoComboBoxItemTemplate let-dataItem tabIndex="readonly ? -1  : 0">
                <span class="flex items-center gap-1">
                    @switch(dataItem.ObjectType){
                        @case(OrganizationChartItemType.User){
                            <bizz-icon class="h-3 w-3 text-blue-500" iconName="user"></bizz-icon>
                        }
                        @case(OrganizationChartItemType.Function){
                            <bizz-icon class="h-3 w-3 text-blue-500" iconName="users"></bizz-icon>
                        }
                        @case(OrganizationChartItemType.Department){
                            <bizz-icon class="h-3 w-3 text-blue-500" [iconName]="dataItem.DepartmentType == DepartmentType.Entity ? 'building-columns': 'building'"></bizz-icon>
                        }
                        @case(OrganizationChartItemType.CurrentUser){
                            <bizz-icon class="h-3 w-3 text-blue-500" iconName="circle-user"></bizz-icon>
                        }
                    }
                    {{ dataItem.text }}
                </span>
            </ng-template>

        </kendo-multiselect>

        @if(!readonly){
            <div class="button-group">
                <button (click)="openOrgChart()" [disabled]="readonly" [class.error]="errorState != undefined" class="default rounded-l-none !px-2 !py-1" type="button">
                    <bizz-icon class="relative -left-[1px] size-3.5" iconName="user-group">
                    </bizz-icon>
                </button>
            </div>
        }
</div>
<div class="max-w-svw h-svh max-h-svh w-svw bg-body">
    <bizz-header [mode]="4">
    </bizz-header>
    <div class="font-title text-lg font-bold text-form-header w-full text-center justify-between">
                        {{ title }}
                    </div>
    <div class="sm:p-auto m-auto block h-[calc(100%_-_48px)] w-full items-center justify-center justify-items-center gap-5 p-2 sm:flex">
        @if (forms$ | async; as forms) {
            @if (!loading) {
                <div class="m-auto flex flex-row flex-wrap justify-items-center gap-5 sm:w-4/6">
                    @for (form of forms; track $index) {

                        <div [style]="{'width': (form.Width == WidgetAnonymousRegistrationButtonSize.small ? 48 : 98) + '%', 'min-height' :  (form.Height == WidgetAnonymousRegistrationButtonHeight.regular ? 75 : 150) +'px'}"
                             class="m-auto flex cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-button p-2 text-center hover:bg-blue-500/10"
                             (click)="openForm(form)">

                            <div class="flex items-center justify-center gap-4"
                                 [class.flex-col]="form.Width == WidgetAnonymousRegistrationButtonSize.small">
                                @if (form.Icon) {
                                    <bizz-icon
                                            class="mt-0.5 size-12 min-w-[1.25rem] text-sidebar-icon"
                                            [iconLibrary]="form.Icon.Library"
                                            [iconName]="form.Icon.Name">
                                    </bizz-icon>
                                }
                                <p class="text-2xl">{{ translate(form, 'Name') }}</p>
                            </div>
                        </div>
                    }
                </div>
            } @else {
                <bizz-loader [fullscreen]="true" [showLoader]="true"></bizz-loader>
            }


        }
    </div>
</div>


import { Routes } from '@angular/router';
import { LoginComponent } from './views/auth/login/login.component';
import { loginGuard } from './core/guards/login/login.guard';
import { authGuard } from './core/guards/auth/auth.guard';
import { LayoutComponent } from './shared/components/ui/layout/layout.component';
import { DashboardComponent } from './views/workspace/dashboard/dashboard.component';
import { UserSettingsComponent } from './views/workspace/user-settings/user-settings.component';
import { NotificationsComponent } from './views/workspace/notifications/notifications.component';
import { TasksComponent } from './views/workspace/tasks/tasks.component';
import { RemindersComponent } from './views/workspace/reminders/reminders.component';
import { FlowStatusComponent } from './views/workspace/flow-status/flow-status.component';
import { workspaceGuard } from './views/workspace/guards/workspace.guard';
import { TrainingExamPageComponent } from './views/training/training-exam-page/training-exam-page.component';
import { dashboardResolver } from './api/bizzmine/dashboard/resolvers/dashboard.resolver';
import { UserSettingsResolver } from './api/bizzmine/user-settings/resolvers/user-settings.resolver';
import { UserSettingsIndexResolver } from './api/bizzmine/user-settings/resolvers/user-settings-index.resolver';
import { WorkspacesResolver } from './api/bizzmine/workspace/resolvers/workspaces/workspaces.resolver';
import { NotificationsResolver } from './api/bizzmine/notification/resolvers/notifications.resolver';
import { PlaygroundComponent } from './views/workspace/playground/playground.component';
import { LogoutComponent } from './views/auth/logout/logout.component';
import { settingsGuard } from './core/guards/settings/settings.guard';
import { SettingsComponent } from './views/settings/settings.component';
import { HistoryComponent } from './views/workspace/history/history.component';
import { formRoutes } from './views/workspace/forms/form-landing-routes';
import { FormReadonlyPageComponent } from './views/workspace/forms/form-readonly-page/form-readonly-page.component';
import {
  dispatchReadOnlyGuard,
  dispatchRevisionGuard,
  dispatchTaskGuard
} from './views/workspace/guards/dispatch-read-only-guard';
import { unsavedChangesGuard } from './features/bizzmine/form/guards/unsaved-changes.guard';
import { ExternalUserTokenComponent } from './views/workspace/external-user/external-user-token/external-user-token.component';
import { AnonymousUserComponent } from './views/workspace/external-user/anonymous-user/anonymous-user.component';
import { AnonymousPortalComponent } from './views/workspace/external-user/anonymous-portal/anonymous-portal.component';
import { TaskLandingPageComponent } from './views/workspace/forms/task-landing-page/task-landing-page.component';
import { RevisionLandingPageComponent } from './views/workspace/forms/revision-landing-page/revision-landing-page.component';
import { DirectLinkPageComponent } from './views/workspace/forms/direct-link-page/direct-link-page.component';


export const routes: Routes = [
  {
    path: 'settings',
    canActivate: [settingsGuard],
    component: SettingsComponent
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [
          loginGuard
        ]
      },
      {
        path: 'logout',
        component: LogoutComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
      }
    ]
  },
  {
    path: 'workspace',
    canActivate: [authGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: ':workspaceId',
        component: LayoutComponent,
        data: {
          settings: false
        },
        children: [
          {
            path: 'form',
            canDeactivate: [unsavedChangesGuard],
            loadChildren: () => formRoutes,
          },
          {
            path: 'dashboard',
            runGuardsAndResolvers: 'always',
            children: [
              {
                path: ':workspaceItemId',
                component: DashboardComponent,
                canDeactivate: [unsavedChangesGuard],
                resolve: {
                  dashboard: dashboardResolver
                }
              }
            ]
          },
          {
            path: 'playground',
            component: PlaygroundComponent
          },
          {
            path: 'user-settings',
            component: UserSettingsComponent,
            resolve: {
              settings: UserSettingsResolver,
              index: UserSettingsIndexResolver,
              workspaces: WorkspacesResolver
            }
          },
          {
            path: 'notifications',
            component: NotificationsComponent,
            resolve: {
              notifications: NotificationsResolver
            }
          },
          {
            path: 'tasks',
            component: TasksComponent
          },
          {
            path: 'reminders',
            component: RemindersComponent
          },
          {
            path: 'flow-status/collectionId/:collectionId/instanceId/:instanceId/versionId/:versionId/methodType/:methodType',
            component: FlowStatusComponent
          },
          {
            path: 'flowstatus/collectionsid/:collectionId/instancesid/:instanceId/versionsid/:versionId/methodtype/:methodType',
            component: FlowStatusComponent
          },
          {
            path: 'history/collectionId/:collectionId/instanceId/:instanceId/versionId/:versionId',
            component: HistoryComponent
          }
        ]
      },
      {
        path: '',
        pathMatch: 'full',
        component: LayoutComponent,
        canActivate: [
          workspaceGuard
        ]
      }
    ]
  },
  {
    path: 'trn',
    children: [
      {
        path: ':trainingAppID/exam/:examSessionVersionsID',
        component: TrainingExamPageComponent
      }
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth'
  },
  {
    canActivate: [authGuard, dispatchReadOnlyGuard],
    path: 'collection/:collectionId/instance/:instanceId/version/:versionId/readonly',
    component: FormReadonlyPageComponent
  },
  {
    canActivate: [authGuard, dispatchTaskGuard],
    path: 'task/:taskId',
    component: TaskLandingPageComponent
  },
  {
    canActivate: [authGuard, dispatchRevisionGuard],
    path: 'revisiontask/:taskId',
    component: RevisionLandingPageComponent
  },
  {
    canActivate: [authGuard],
    path: 'direct/collection/:collectionId/instance/:instanceId',
    component: DirectLinkPageComponent
  },
  {
    path: 'Account/ExternalUser',
    component: ExternalUserTokenComponent
  },
  {
    path: 'Account/Form',
    component: AnonymousUserComponent
  },
  {
    path: ':languageCode/Account/Forms/:caption/:guid',
    component: AnonymousPortalComponent
  },
  {
    path: 'Account/Forms/:caption/:guid',
    component: AnonymousPortalComponent
  },
  {
    path: 'portal/form',
    component: AnonymousUserComponent
  },
  {
    path: ':languageCode/portal/:caption/:guid',
    component: AnonymousPortalComponent
  },
  {
    path: 'portal/:caption/:guid',
    component: AnonymousPortalComponent
  },
  {
    path: 'collection/:collectionId/instance/:instanceId',
    component: DirectLinkPageComponent
  },
  { //redirect invalid urls to the default workspace
    path: '**',
    redirectTo: 'workspace'
  }
];
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';

export const BuiltInValidationControls: Array<TableFieldDataType> = [
  TableFieldDataType.AlphaNumeric,
  TableFieldDataType.Numeric,
  TableFieldDataType.Combobox,
  TableFieldDataType.DatePicker,
  TableFieldDataType.DateTimePicker,
  TableFieldDataType.TimePicker,
  TableFieldDataType.Memo,
  TableFieldDataType.OrganizationChartUnitSelector,
  TableFieldDataType.OrganizationChartUnitSelectorForTrnApp
]
@if(data){

<h2 class="mb-4 font-bold text-default">{{'Inheritance' | translate}}</h2>

<div class="flex items-center gap-3">
    <label class="shrink-0">{{'SetPermissionsAs' | translate}}</label>
    <kendo-dropdownlist class="h-8 w-full" [data]="data.permissions.InheritTypes" [(ngModel)]="data.permissions.InheritType"
        (valueChange)="setProperties($event)" textField="Text" valueField="Value" [valuePrimitive]="true">
        <ng-template kendoDropDownListItemTemplate let-type>
            <span>{{ type.Text }}</span>
        </ng-template>
    </kendo-dropdownlist>
</div>

<hr class="my-5" />

<h2 class="mb-4 font-bold text-default">{{'Properties' | translate}}</h2>

<div class="relative">


<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanSetPermissions" [currentUser]="true"
    [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanReadProperties" [currentUser]="true"
    [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanReadDocPDF" [currentUser]="true"
    [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanEditProperties" [currentUser]="true"
    [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanRevisionWithMinorVersionChange"
    [currentUser]="true" [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanRevisionWithMajorVersionChange"
    [currentUser]="true" [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanAccessHistory" [currentUser]="true"
    [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanAccessTrackChanges"
    [currentUser]="true" [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanAccessArchives" [currentUser]="true"
    [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanDeleteToTrashCan" [currentUser]="true"
    [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanExecuteOnBehalfOf" [currentUser]="true"
    [currentUserItem]="currentUserItem"></bizz-access-permission>
<bizz-access-permission [disabled]="disabled" [item]="data.permissions.CanForwardTasks" [currentUser]="true"
    [currentUserItem]="currentUserItem"></bizz-access-permission>

    <bizz-loader [showLoader]="isLoading" backgroundClasses="bg-widget/40"></bizz-loader>
</div>
}
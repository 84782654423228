import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { catchError, combineLatestWith, debounceTime, exhaustMap, filter, map, of, retry, take, tap } from 'rxjs';
import { StoreCollectionForm } from '../forms-state';
import { CollectionFormApiService } from '../../../../api/bizzmine/collection-form/collection-form-api.service';
import { CollectionFormEditApiService } from '../../../../api/bizzmine/collection-form-edit/collection-form-edit-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UnsavedChangesService } from '../../../../shared/services/unsaved-changes/unsaved-changes.service';
import { SAVED_ALERT } from '../../../../features/bizzmine/alerts/constants/preset-alerts';
import { DocumentCheckinType } from '../../../../../models/ts/document-checkin-type.model';
import { userSettingsFeature } from '../../user-settings/user-settings-feature';
import { Store } from '@ngrx/store';
import { Dialog } from '@angular/cdk/dialog';
import { FileUploadCancelCheckoutModalComponent } from '../../../../shared/components/file-upload/file-upload/file-upload-cancel-checkout-modal/file-upload-cancel-checkout-modal.component';
import { CollectionMethodType } from '../../../../../models/ts/collection-method-type.model';
import { TaskApiService } from '../../../../api/bizzmine/task/task-api.service';
import { TaskInstanceDialogComponent } from '../../../../features/bizzmine/widgets/task-list-widget/task-instance-dialog/task-instance-dialog.component';
import { DialogModalComponent } from '../../../../shared/components/modals/dialog-modal/dialog-modal.component';
import { DialogModalModelMode } from '../../../../shared/components/modals/dialog-modal/dialog-modal-model';
import { DialogModalButtons } from '../../../../shared/enums/dialog-modal-buttons';
import { AlertService } from '../../../../features/bizzmine/alerts/alert.service';
import { refreshActions } from '../../refresh/refresh-actions';
import { viewStackActions } from '../view-stack-actions';
import { selectForm, selectFormField } from '../forms-selectors';
import { concatLatestFrom } from '@ngrx/operators';
import { CollectionFormJsonService } from '../../../../features/bizzmine/form/services/collection-form-json.service';
import { UpdateDeleteState } from '../../../../../models/ts/update-delete-state.model';
import { TranslationService } from '../../../../core/services/translation/translation.service';
import { LinkedCollectionStorageType } from '../../../../../models/ts/linked-collection-storage-type.model';
import { StoreCollectionFormOptions } from '../interfaces/store-collection-form-options';
import { CollectionFormLookupApiService } from '../../../../api/bizzmine/collection-form-lookup/collection-form-lookup-api.service';
import { LinkedCollectionType } from '../../../../../models/ts/linked-collection-type.model';
import { CollectionFormFieldGridLookupData } from '../../../../../models/ts/collection-form-field-grid-lookup-data.model';
import { formsFeature } from '../forms-feature';
import { CollectionLockType } from '../../../../../models/ts/collection-lock-type.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserType } from '../../../../../models/ts/user-type.model';
import { NavigationHistoryService } from '../../../../core/history/navigation-history.service';
import { ProtectedCollectionType } from '../../../../../models/ts/protected-collection-type.model';

/**
 * Effects for basic form functionality such as fetching, saving, view stack and unsaved changes.
 */
@Injectable()
export class FormsEffects {
  private actions$ = inject(Actions);
  public formFetchedAndAddedToViewStack$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formFetched, formsActions.formFetchedWithFiles, formsActions.formFetchedWithTemplate, formsActions.formFetchedByWidgetIdWithCategory),
    filter(({ addToViewStack }) => addToViewStack),
    map(({ form }) => ({
      type: viewStackActions.addFormToViewStack.type,
      data: form
    }))
  ));
  public formFetched$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formFetched, formsActions.formFetchedWithFiles, formsActions.formFetchedWithTemplate, formsActions.formFetchedByWidgetIdWithCategory),
    filter(({ addToViewStack }) => addToViewStack == false),
    map(({ form }) => ({
        type: formsActions.fetchLookupByCrossLinkId.type,
        formId: form.id,
        storeForm: form,
        gridParentFilter: form.options?.formGridParentFilter
      })
    )
  ));
  public formClosed$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formClosed),
    map(({ formId, unsavedChanges }) => ({
          type: viewStackActions.removeFormFromViewStack.type,
          formId: formId,
          wasReadMode: ((this.store$.selectSignal(selectForm(formId))()?.data)?.ReadMode ?? false)
        })

    )
  ));
  public editReadOnlyFormFetched$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.editReadOnlyFormFetched),
    // tap(() => {
    //   this.alertService.setAlert(FORM_INFO_ALERT);
    // }),
    map(({ formId, form }) => ({
        type: formsActions.updateForm.type,
        update: { id: formId, changes: { data: form } }
      })
    )
  ));
  public formRefreshedUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formRefreshed),
    map(({ formId, form }) => ({
      type: formsActions.updateForm.type,
      update: { id: formId, changes: { data: form, reset: true } }
    }))
  ));
  private store$ = inject(Store);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private navigationHistoryService = inject(NavigationHistoryService);


  public rerouteOnCancel$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formCanceled),
    filter(data => data?.ignoreReroute != true),
    map(() => formsActions.performGoBack())));

  public checkIfNeededRerouteOnCancel$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.performGoBack),
    debounceTime((100)),
    tap(() => {
        // pop first history (this is the current url)
       const current = NavigationHistoryService.history[NavigationHistoryService.history.length - 1].toLowerCase();
       if(current.includes('workspace') && current.includes('dashboard')){
         return;
       }
       NavigationHistoryService.history.pop();
       if(NavigationHistoryService.history.length > 0){
         const backUrl = NavigationHistoryService.history[NavigationHistoryService.history.length - 1];
         if(backUrl != undefined){
           NavigationHistoryService.history.pop();
           this.router.navigateByUrl(backUrl);
         }
       } else {
         const userType = this.store$.selectSignal(userSettingsFeature.selectUserType)();
         if(userType != UserType.AnonymousUser && userType != UserType.ExternalUser){
           this.router.navigate([NavigationHistoryService.root]);
         }
       }
    })
  ), { dispatch: false });
  /**
   * Form cancel/back used => Check for changed if needed, then check if lock needs to be removed (not the case in read mode)
   */
  public formCanceled$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formCanceled),
    map(({ formId, form, unsavedChanges, performFileCheckoutValidation }) => {
      if (performFileCheckoutValidation && form.DocumentProperties.OriginalDocumentCheckinStatus == DocumentCheckinType.CheckedIn && form.DocumentProperties.DocumentCheckinStatus == DocumentCheckinType.CheckedOut && form.DocumentProperties.CheckedOutByID == this.store$.selectSignal(userSettingsFeature.selectUserID)()) {
        return ({
          type: formsActions.formCanceledFileCheckoutDialogValidation.type,
          form: form,
          formId: formId,
          unsavedChanges: unsavedChanges
        });
      }
      if(form.ReadMode || (form.LockType == CollectionLockType.Locked && !form.IsManager)) {
        return ({
          type: formsActions.formClosed.type,
          formId: formId
        });
      }
       return ({
        type: formsActions.deleteSelfLock.type,
        form: form,
        formId: formId,
        close: true
      });
    })
  ));
  public executePendingSteps$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.executePendingSteps),
    filter(data => data?.pendingSteps != null),
    map(data => {
      const currentUser = this.store$.selectSignal(userSettingsFeature.selectUserID)();
      if (data.pendingSteps.length == 1 && (data.pendingSteps[0].AssignedToID == currentUser || data.executorSelected == true)) {
        const pendingStep = data.pendingSteps[0];
        if (pendingStep.IsExecuteOnBehalfOf) {
          return ({
            type: formsActions.getTaskOnBehalfOf.type,
            taskId: pendingStep.TasksID,
            collectionsId: data.collectionId,
            assignedToId: pendingStep.AssignedToID,
            assignedToType: pendingStep.AssignedToType,
            formId: data.formId
          });
        }
        return ({
          type: formsActions.getTaskForm.type,
          taskId: pendingStep.TasksID,
          formId: data.formId
        });
      } else {
        return ({
          type: formsActions.openTaskInstanceDialogComponent.type,
          pendingSteps: data?.pendingSteps,
          collectionId: data?.collectionId,
          formId: data.formId

        });
      }
    })
  ));
  private dialog = inject(Dialog);
  public formCanceledFileCheckoutDialogValidation$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formCanceledFileCheckoutDialogValidation),
    exhaustMap(({ formId, form, unsavedChanges }) => {
      return this.dialog.open<boolean>(FileUploadCancelCheckoutModalComponent, {
        data: { form: form },
        disableClose: true
      }).closed.pipe(map(performCancel => {
        return {
          performCancel: performCancel,
          formId: formId,
          form: form,
          unsavedChanges: unsavedChanges
        };
      }));
    }),
    filter(data => data != null && data.performCancel == true),
    map((data) => {
      return {
        type: formsActions.formCanceled.type,
        form: data.form,
        formId: data.formId,
        unsavedChanges: data.unsavedChanges,
        performFileCheckoutValidation: false
      };
    })
  ));
  public widgetRefreshOnLastFormClosed$ = createEffect(() => this.actions$.pipe(
    ofType(viewStackActions.removeFormFromViewStack),
    concatLatestFrom(action => this.store$.select(formsFeature.selectIds)),
    filter(([action, forms]) => forms.length == 0 && action.wasReadMode != true),
    map(() => refreshActions.refreshData({all: true}))
  ));
  public openTaskInstanceDialogComponent$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.openTaskInstanceDialogComponent),
    tap(data => {
      if(data.pendingSteps.length>0){
      this.dialog.open(TaskInstanceDialogComponent, {
        data: {
          data: data.pendingSteps,
          collectionId: data.collectionId,
          formId: data.formId
        }
      });
    }else{
      this.dialog.open(DialogModalComponent, {data: {
        mode: DialogModalModelMode.simple,
        title: 'NoAssignedTasksFound',
        message: 'NoAssignedTasksFoundBody',
        confirmationtext: '',
        buttons: DialogModalButtons.Ok,
        showCancelButton: false } }
      )
    }
    })
  ), { dispatch: false });
  private unsavedChangesService = inject(UnsavedChangesService);
  public checkForUnsavedChanges = createEffect(() => this.actions$.pipe(
    ofType(formsActions.checkForUnsavedChanges),
    exhaustMap(({ formId, form }) => this.unsavedChangesService.openUnsavedChangesDialog().pipe(
      filter(closeForm => !!closeForm),
      map(() => ({
          type: formsActions.deleteSelfLock.type,
          formId: formId,
          form: form,
          close: true
        })
      )
    ))
  ));
  private collectionFormApiService = inject(CollectionFormApiService);
  public getFormByWidget$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormByWidgetId),
    exhaustMap(({ collectionId, widgetId }) => this.collectionFormApiService.getFormByWidget(collectionId, widgetId)
      .pipe(
        map(data => ({
          type: formsActions.formFetched.type,
          form: new StoreCollectionForm(data),
          addToViewStack: true
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: undefined,
            response: response
          }))
        )
      )
    )
  ));

  public getFormByWidgetIdWithCategory$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormByWidgetIdWithCategory),
    exhaustMap(props => {
      return this.collectionFormApiService.getFormByWidget(props.collectionId, props.widgetId).pipe(
        map((data) =>
          formsActions.formFetchedByWidgetIdWithCategory({
            form: new StoreCollectionForm(data),
            addToViewStack: true,
            lookupItem: props.lookupItem,
            skillCategoryCollectionId: props.skillCategoryCollectionId
          })
        ),
        catchError(error => of(formsActions.fetchFromFailed({error})))
      );
    }),
  ));

  public getFormByCollectionMethodType$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormByCollectionMethodType),
    exhaustMap(({
                  collectionId,
                  instanceId,
                  versionId,
                  collectionMethodType
                }) => this.collectionFormApiService.getFormByCollectionMethodType({
        collectionId,
        instanceId,
        versionId,
        collectionMethodType
      })
        .pipe(
          map(data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, { startRevision: true }),
            addToViewStack: true
          })),
          catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
              formId: undefined,
              response: response
            }))
          )
        )
    )
  ));
  public getFormWorkspace$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormWorkspace),
    exhaustMap(({
                  collectionId,
                  workspaceItemId
                }) => this.collectionFormApiService.getFormByWorkspaceId({ collectionId, workspaceItemId })
      .pipe(
        map(data => ({
          type: formsActions.formFetched.type,
          form: new StoreCollectionForm(data),
          addToViewStack: true
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: undefined,
            response: response
          }))
        )
      )
    )
  ));
  /**
   * Updates a form from Read mode to editable by performing the same request as a edit actionfrom grid (BE correctly sets readonly states)
   */
  public editReadOnlyForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.editReadOnlyForm),
    exhaustMap(({
                  formId,
                  collectionId,
                  instanceId,
                  versionId,
                  guidChecksum
                }) => this.collectionFormApiService.getFormByInstance(collectionId, instanceId, versionId,guidChecksum)
      .pipe(
        catchError(err => {
          if(err.status === 403){
            this.store$.dispatch(formsActions.unAuthorizedFetch({formId: formId}));
          }
          throw err;
        }),
        map(data => ({
          type: formsActions.editReadOnlyFormFetched.type,
          formId: formId,
          form: data
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: formId,
            response: response
          }))
        )
      )
    )
  ));
  public getFormEdit$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormEdit),
    exhaustMap(({
                  collectionId,
                  instanceId,
                  versionId,
                  guidChecksum
                }) => this.collectionFormApiService.getFormByInstance(collectionId, instanceId, versionId,guidChecksum)
      .pipe(
        catchError(err => {
          if(err.status === 403){
            this.store$.dispatch(formsActions.unAuthorizedFetch({}));
          }
          throw err;
        }),
        map(data => ({
          type: formsActions.formFetched.type,
          form: new StoreCollectionForm(data),
          addToViewStack: true
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: undefined,
            response: response
          }))
        )
      )
    )
  ));
  public getFormCreateForWidget$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormCreate),
    filter(action => action.widgetId != undefined),
    exhaustMap(({
                  collectionId,
                  widgetId
                }) => this.collectionFormApiService.getFormByWidget(collectionId, widgetId!)
      .pipe(
        map(data => ({
          type: formsActions.formFetched.type,
          form: new StoreCollectionForm(data),
          addToViewStack: true
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: undefined,
            response: response
          }))
        )
      )
    )
  ));
  public getFormCreate$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormCreate),
    filter(action => action.widgetId == undefined),
    exhaustMap(({
                  collectionId,
                  crossLinkCollectionId,
                  childInstanceId,
                  childVersionId,
                  originalChildInstanceId
                }) => this.collectionFormApiService.getFormByCollection(collectionId)
      .pipe(
        map(data => ({
          type: formsActions.formFetched.type,
          form: new StoreCollectionForm(data),
          addToViewStack: true
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: undefined,
            response: response
          }))
        )
        // TODO -> other params to use for legacy *processData(data, params)* - work in progress by René
      )
    )
  ));
  public unAuthorizedFetch$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.unAuthorizedFetch),
    tap(({formId}) => {

      this.alertService.setAlert({
        title: 'UnAuthorized',
        type: 'error',
        icon: 'triangle-exclamation',
        content: [
          'Form could not be fetched due to unauthorized access.',
          'Please contact your administrator.'
        ],
        timed: true,
        timer: 5000,
        dismissable: true
      });
    })
  ), {dispatch: false});
  public getFormReadOnly$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormReadOnly),
    filter(action => action.state == null),
    exhaustMap(({
                  collectionId,
                  instanceId,
                  versionId,
                  guidChecksum
                }) => this.collectionFormApiService.getFormByInstanceRead(collectionId, instanceId, versionId,guidChecksum)
      .pipe(
        catchError(err => {
          if(err.status === 403){
            this.store$.dispatch(formsActions.unAuthorizedFetch({}));
          }
          throw err;
        }),
        map(data => ({
          type: formsActions.formFetched.type,
          form: new StoreCollectionForm(data),
          addToViewStack: true
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: undefined,
            response: response
          }))
        )
      )
    )
  ));
  public getFormReadOnlyWithState$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormReadOnly),
    filter(action => action.state != null),
    exhaustMap(({
                  collectionId,
                  instanceId,
                  versionId,
                  guidChecksum,
                  state
                }) => this.collectionFormApiService.getFormByInstanceReadWithState(collectionId, instanceId, versionId, state!,guidChecksum)
      .pipe(
        map(data => ({
          type: formsActions.formFetched.type,
          form: new StoreCollectionForm(data),
          addToViewStack: true
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: undefined,
            response: response
          }))
        )
      )
    )
  ));
  public getFormByCollection$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormByCollectionId),
    exhaustMap(({
                  collectionId,
                  formGridParentFilter,
                  addToViewStack
                }) => this.collectionFormApiService.getFormByCollection(collectionId)
      .pipe(
        map(data => formsActions.formFetched({form: new StoreCollectionForm(data, { formGridParentFilter }),addToViewStack: addToViewStack}))
      )
    )
  ));
  public getFormByCollectionFormGridField$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormByCollectionGridField),
    exhaustMap(({
                  formFieldId,
                  addToViewStack
                }) => this.collectionFormApiService.getFormByCollectionFormGridField(formFieldId)
      .pipe(
        map(data => ({
          type: formsActions.formFetched.type,
          form: new StoreCollectionForm(data),
          addToViewStack: addToViewStack
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: undefined,
            response: response
          }))
        )
      )
    )
  ));
  public getFormByCollectionFormField$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormByCollectionField),
    exhaustMap(({
                  formFieldId,
                  addToViewStack
                }) => this.collectionFormApiService.getFormByCollectionFormField(formFieldId)
      .pipe(
        map(data => ({
          type: formsActions.formFetched.type,
          form: new StoreCollectionForm(data),
          addToViewStack: addToViewStack
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: undefined,
            response: response
          }))
        )
      ),
    )
  ));
  public getFormByFolder$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormByFolder),
    exhaustMap(({
                  collectionId,
                  folderId
                }) => this.collectionFormApiService.getFormByFolder(collectionId, folderId)
      .pipe(
        map(
          data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data),
            addToViewStack: true
          })
        ),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: undefined,
            response: response
          }))
        )
      ))
  ));
  public refreshForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.refreshForm),
    exhaustMap(({
                  formId,
                  collectionId,
                  instanceId,
                  versionId,
                  guidChecksum
                }) => this.collectionFormApiService.getFormByInstance(collectionId, instanceId, versionId,guidChecksum)
      .pipe(
        catchError(err => {
          if(err.status === 403){
            this.store$.dispatch(formsActions.unAuthorizedFetch({}));
          }          throw err;
        }),
        map(
          data => ({
            type: formsActions.formRefreshed.type,
            formId: formId,
            form: data
          })
        ),
        catchError((response: HttpErrorResponse) => of(formsActions.getFormFailed({
            formId: formId,
            response: response
          }))
        )
      ))
  ));
  public getFormInstanceForGrid$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormInstanceForGrid),
    concatLatestFrom(data => this.store$.select(selectForm(data.parentFormId)).pipe(take(1))),
    map(([data, form]) => {
      const viewDataSource = form?.data?.ViewDataSources.find(_ => _.ViewDataSourcesID == data.viewDataSourceId);
      const recordData = viewDataSource?.Instances.find(_ => (_.RowDataDesignCrossID ?? _.CrossLinkedInstancesID) == data.crossLinkedInstancesId && _.State != UpdateDeleteState.Delete);
      if(viewDataSource == undefined){
        throw new Error('ViewDataSource not found');
      }
      if(recordData == undefined){
        throw new Error('RecordData not found');
      }
      return formsActions.getFormInstanceById({
        collectionId: viewDataSource.ChildCollectionsID,
        instanceId: recordData.ChildInstancesID,
        versionId: recordData.ChildVersionsID,
        read: data.read,
        parentData: data,
        guidChecksum: recordData?.GuidChecksum
      });

    })
  ));

  public invalidReadGetFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormInstanceById),
    filter(action => !action.read && (action.versionId == undefined || action.instanceId == undefined || action.collectionId == undefined)),
    tap(() => {
      this.alertService.setAlert({
        title: '',
        type: 'error',
        content: [this.translationService.translate('PleaseSelectACollectionItem')],
        icon: 'triangle-exclamation',
        timed: true,
        timer: 10000,
      })
    }),
    map(() => {
      return viewStackActions.removeLoadingState();
    })
  ));

  private translationService = inject(TranslationService);

  public updateGridRecordEditModeWithPreValidation$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateGridRecordEditModeWithPreValidation),
    filter(record => record != undefined),
    map(({
           formId,
           gridFieldId,
           recordId,
           editMode
         }) => {
      if (editMode) {
        return formsActions.updateGridRecordEditMode({
          formId,
          gridFieldId,
          recordId,
          editMode
        });
      } else {
        // perform additional validation
        const form = this.store$.selectSignal(selectForm(formId))();
        const field = this.store$.selectSignal(selectFormField(formId,gridFieldId))();
        const vds = form?.data?.ViewDataSources.find(vds => vds.ViewDataSourcesID == field?.ViewDataSourcesID);
        if(vds?.LinkedCollectionStorageType == LinkedCollectionStorageType.Relational){
          const rec = field?.Records?.find(r => r.CrossLinkedInstancesID == recordId);
          const instance = vds?.Instances.find(i => (i.RowDataDesignCrossID ?? i.CrossLinkedInstancesID) == recordId);
          if(rec == undefined || instance == undefined) {
            return formsActions.invalidGridRecordEditModeAction();
          }
        }
        return formsActions.updateGridRecordEditMode({
          formId,
          gridFieldId,
          recordId,
          editMode
        });
      }
    })
  ));

  public removeRecordFromGridPreConfirmation$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.removeRecordFromGridPreConfirmation),
    exhaustMap(({ formId, gridFieldId, recordId }) => {
      return this.dialog.open<DialogModalButtons>(
        DialogModalComponent,
        {
          data: {
            title: 'DeleteItem',
            message: 'AreYouSureYouWantToDeleteThisItem',
            buttons: DialogModalButtons.Delete,
            showCancelButton: true
          }
        }).closed.pipe(take(1), combineLatestWith(of({ formId, gridFieldId, recordId })))
    }),
    filter(([action,intent]) => action == DialogModalButtons.Delete),
    map(([action,intent]) => {
      return formsActions.removeRecordFromGrid({
        formId: intent.formId,
        gridFieldId: intent.gridFieldId,
        recordId: intent.recordId
      });
    })
  ));

  public invalidGridRecordEditModeAction$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.invalidGridRecordEditModeAction),
    tap(() => {
      this.alertService.setAlert({
        title: '',
        type: 'warning',
        content: [this.translationService.translate('SelectAnInstanceBeforeEditingInFullEditor')],
        icon: 'triangle-exclamation',
        timed: true,
        timer: 10000,
      })
    })
  ), { dispatch: false });

  public invalidEditGetFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormInstanceById),
    filter(action => action.read && (action.versionId == undefined || action.instanceId == undefined || action.collectionId == undefined)),
    tap(() => {
      this.alertService.setAlert({
        title: '',
        type: 'warning',
        content: [this.translationService.translate('SelectAnInstanceBeforeEditingInFullEditor')],
        icon: 'triangle-exclamation',
        timed: true,
        timer: 10000,
      })
    }),
    map(() => {
      return viewStackActions.removeLoadingState();
    })
  ));

  public getFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormInstanceById),
    filter(action => !action.read && action.versionId != undefined && action.instanceId != undefined && action.collectionId != undefined),
    exhaustMap(action => this.collectionFormApiService.getFormByInstance(action.collectionId, action.instanceId, action.versionId, action.guidChecksum)
      .pipe(
        catchError(error => {
          if(error.status === 403){
            this.store$.dispatch(formsActions.unAuthorizedFetch({}));
          } else {
            this.store$.dispatch(viewStackActions.removeLoadingState());
          }
          throw error;
        }),
        map(
          data => {
            let options: StoreCollectionFormOptions | undefined = undefined;
            if(action.parentData != undefined){
              options = {
                parentFormId: action.parentData.parentFormId,
                parentFormFieldId: action.parentData.formFieldId,
                parentGridFieldId: action.parentData.gridFieldId,
                parentRecordId: action.parentData.crossLinkedInstancesId
              } as StoreCollectionFormOptions
            }
            if(action.checkForWorkFlow == true){
              options = options ?? {} as StoreCollectionFormOptions;
              options = {
                ...options,
                checkForWorkFlow: true
              }
            }
            const form = new StoreCollectionForm(data, options, action.schedulerData);
            return formsActions.formFetched({
            form,
            addToViewStack: true
          })}
        )
      )
    )
  ));

  public updateTrainingDocumentViewDataSources$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formFetched),
    concatLatestFrom(data => this.store$.select(selectForm(data.form.id)).pipe(take(1))),
    filter(([data, storeForm]) => storeForm?.data != undefined && storeForm.data.ViewDataSources != undefined && storeForm.data.ViewDataSources.find(vds => vds.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingDocument) != undefined),
    map(([data, storeForm]) => formsActions.updateTrainingDocumentFields({formId: storeForm!.id})
    )));

  public getFormByInstanceRead$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormInstanceById),
    filter(action => action.read && action.versionId != undefined && action.instanceId != undefined && action.collectionId != undefined),
    exhaustMap(action=> this.collectionFormApiService.getFormByInstanceRead(action.collectionId, action.instanceId, action.versionId, action.guidChecksum)
      .pipe(
        catchError(error => {
          if(error.status === 403){
            this.store$.dispatch(formsActions.unAuthorizedFetch({}));
          } else {
            this.store$.dispatch(viewStackActions.removeLoadingState());
          }
          throw error;
        }),
        map(data => {
          let options: StoreCollectionFormOptions | undefined = undefined;
          if(action.parentData != undefined){
            options = {
              parentFormId: action.parentData.parentFormId,
              parentFormFieldId: action.parentData.formFieldId,
              parentGridFieldId: action.parentData.gridFieldId,
              parentRecordId: action.parentData.crossLinkedInstancesId
            } as StoreCollectionFormOptions
          }
          if(action.checkForWorkFlow == true){
            options = options ?? {} as StoreCollectionFormOptions;
            options = {
              ...options,
              checkForWorkFlow: true
            }
          }
          const form = new StoreCollectionForm(data, options);
          return  formsActions.formFetched({
          form,
          addToViewStack: true
        })}
        )
      )
    )
  ));

  public saveFormInstanceSucceeded$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveFormInstanceSucceeded),
    filter(data => data.collectionId != null),
    map(data =>
      ({
        type: refreshActions.refreshData.type,
        collectionId: data.collectionId,
      })
    )));

  private collectionFormLookupApiService = inject(CollectionFormLookupApiService);
  public refreshGridRecords$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveFormInstanceSucceeded),
    concatLatestFrom(data => this.store$.select(selectForm(data.formId)).pipe(take(1))),
    filter(([data,form]) => form != undefined && form.options != undefined && form.options.parentFormId != undefined && (form.options.parentFormFieldId ?? 0) > 0 && (form.options.parentRecordId ?? 0) > 0),
    map(([data, form]) => {return {data: data!, form: form!}}),
    exhaustMap((data) => {
      const options = data.form.options!;
      const parentForm = this.store$.selectSignal(selectForm(options.parentFormId!))()!.data;
      const parentFormField = this.store$.selectSignal(selectFormField(options.parentFormId!, options.parentFormFieldId!))()!;
      return this.collectionFormLookupApiService.getFormFieldValuesByLookupField(parentForm.CollectionFormId, parentFormField.ViewDataSourcesID, LinkedCollectionType.GridRecord, data.data.instanceId, data.data.versionId).pipe(combineLatestWith(of(options)));
    }),
    map(([formFields, options]) => {
      return formsActions.gridRecordDataLookupUpdated({
        recordId: options.parentRecordId!,
        formId: options.parentFormId!,
        lookupFieldId: options.parentFormFieldId!,
        lookupData: formFields as CollectionFormFieldGridLookupData
      })
    })
    ));

  public refreshGridRecordsAgain$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveGridLinkedFormInstanceSucceeded),
    concatLatestFrom(data => this.store$.select(selectForm(data.formId)).pipe(take(1))),
    filter(([data,form]) => form != undefined && form.options != undefined && form.options.parentFormId != undefined && (form.options.parentFormFieldId ?? 0) > 0 && (form.options.parentRecordId ?? 0) > 0),
    map(([data, form]) => {return {data: data!, form: form!}}),
    exhaustMap((data) => {
      const options = data.form.options!;
      const parentForm = this.store$.selectSignal(selectForm(options.parentFormId!))()!.data;
      const parentFormField = this.store$.selectSignal(selectFormField(options.parentFormId!, options.parentGridFieldId!))()!;
      return this.collectionFormLookupApiService.getFormFieldValuesByLookupField(parentForm.CollectionFormId, parentFormField.ViewDataSourcesID, LinkedCollectionType.GridRecord, data.data.instanceId, data.data.versionId).pipe(combineLatestWith(of(options)));
    }),
    map(([formFields, options]) => {
      return formsActions.gridRecordDataLookupUpdated({
        recordId: options.parentRecordId!,
        formId: options.parentFormId!,
        lookupFieldId: options.parentFormFieldId!,
        lookupData: formFields as CollectionFormFieldGridLookupData
      })
    })
  ));

  public saveMinorRevision$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveRevision),
    filter(({ form }) => form.MethodType === CollectionMethodType.RevisionWithMinorVersionChange),
    exhaustMap(({
                  formId,
                  form,
                  closeStep,
                  closeForm
                }) => this.collectionFormApiService.startMinorRevision(form.CollectionsID, form.CollectionFormId, closeStep, JSON.stringify(CollectionFormJsonService.formToJson(form)))
      .pipe(
        map(data => ({
          type: formsActions.saveFormInstanceSucceeded.type,
          formId: formId,
          collectionId: form.CollectionsID,
          instanceId: data.InstancesID,
          versionId: data.VersionsID,
          close: closeForm
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.saveFormInstanceFailed({
            formId: formId,
            response: response
          }))
        )
      )
    )
  ));
  public saveMajorRevision$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveRevision),
    filter(({ form }) => form.MethodType === CollectionMethodType.RevisionWithMajorVersionChange),
    exhaustMap(({
                  formId,
                  form,
                  closeStep,
                  closeForm
                }) => this.collectionFormApiService.startMajorRevision(form.CollectionsID, form.CollectionFormId, closeStep, JSON.stringify(CollectionFormJsonService.formToJson(form)))
      .pipe(
        map(data => ({
          type: formsActions.saveFormInstanceSucceeded.type,
          formId: formId,
          collectionId: form.CollectionsID,
          instanceId: data.InstancesID,
          versionId: data.VersionsID,
          close: closeForm
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.saveFormInstanceFailed({
            formId: formId,
            response: response
          }))
        )
      )
    )
  ));
  private taskApiService = inject(TaskApiService);
  public getPendingStep$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getPendingSteps),
    exhaustMap(value => {
      let version = value.versionId;
      if (value.draftId != null && value.draftId > 0) {
        version = value.draftId;
      }
      let instance$ = this.taskApiService.getInstanceOpenSteps(value.collectionId, value.instanceId, version);
      if (value.retryCount != null && value.retryCount > 0) {
        instance$ = instance$.pipe(retry({
          delay: 1000,
          resetOnSuccess: true,
          count: value.retryCount
        }));
      }
      return instance$.pipe(catchError(err => {
        this.dialog.open(DialogModalComponent, {
          data: {
            mode: DialogModalModelMode.simple,
            title: 'NoPendingTasksFound',
            message: 'NoPendingTasksFound',
            confirmationtext: '',
            buttons: DialogModalButtons.Ok,
            showCancelButton: false
          }
        });
        throw err;
      }), combineLatestWith(of(value)));
    }),
    map(([data, params]) => {
      return ({
          type: formsActions.executePendingSteps.type,
          pendingSteps: data,
          collectionId: params.collectionId,
          formId: params.formId
        }
      );
    })
  ));
  private collectionFormEditApiService = inject(CollectionFormEditApiService);
  public preValidateCreateFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createFormInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({ formId, form, closeStep, closeForm }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      closeForm,
      form
    ).pipe(
      map(data => ({
        type: formsActions.createFormInstance.type,
        formId,
        form,
        closeStep,
        closeForm,
        preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public createFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createFormInstance),
    filter(({preValidate}) => !preValidate),
    exhaustMap(({
                  formId,
                  form,
                  closeStep,
                  closeForm
                }) => {
        return this.collectionFormEditApiService.createInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)), closeStep)
          .pipe(
            tap(() => {
              this.store$.dispatch(refreshActions.refreshData({ collectionId: form.CollectionsID, formId: formId }));
            }),
            map(data => {
              if (closeForm) {
                return ({
                  type: formsActions.formClosed.type,
                  formId: formId,
                  unsavedChanges: false
                });
              }
              if (form.StepVersionsID > 0) {
                return ({
                  type: formsActions.getPendingSteps.type,
                  collectionId: form.CollectionsID,
                  instanceId: data.InstancesID,
                  versionId: data.VersionsID,
                  draftId: null,
                  retryCount: 120,
                  formId: formId
                });
              }

              return ({
                type: formsActions.refreshForm.type,
                formId: formId,
                instanceId: data.InstancesID,
                versionId: data.VersionsID,
                collectionId: form.CollectionsID
              });

            }),
            catchError((response: HttpErrorResponse) => of(formsActions.createFormInstanceFailed({
                formId: formId,
                response: response
              }))
            )
          );
      }
    )
  ));
  public preValidateCreateDocumentInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createDocumentInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({ formId, form, closeStep, closeForm }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      closeForm,
      form
    ).pipe(
      map(data => ({
        type: formsActions.createDocumentInstance.type,
        formId,
        form,
        closeStep,
        closeForm,
        preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public createDocumentInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createDocumentInstance),
    filter(({ preValidate }) => !preValidate),
    exhaustMap(({
                  formId: formId,
                  form,
                  closeStep,
                  closeForm
                }) => this.collectionFormEditApiService.createDocumentInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)), closeStep)
      .pipe(
        tap(() => {
          this.store$.dispatch(refreshActions.refreshData({ collectionId: form.CollectionsID, formId: formId }));
        }),
        map(data => {
          if(closeForm || form.StepVersionsID == undefined || form.StepVersionsID <= 0) return({
          type: formsActions.saveFormInstanceSucceeded.type,
          formId: formId,
          collectionId: form.CollectionsID,
          instanceId: data.InstancesID,
          versionId: data.VersionsID,
          close: closeForm
        });
            return ({
              type: formsActions.getPendingSteps.type,
              collectionId: form.CollectionsID,
              instanceId: data.InstancesID,
              versionId: data.VersionsID,
              draftId: null,
              retryCount: 120,
              formId: formId
            });
          }),
        catchError((response: HttpErrorResponse) => of(formsActions.saveFormInstanceFailed({
            formId: formId,
            response: response
          }))
        )
      )
    )
  ));
  public preValidateSaveFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveFormInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({ formId, form, closeStep, closeForm }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      closeForm,
      form
    ).pipe(
      map(data => ({
        type: formsActions.saveFormInstance.type,
        formId,
        form,
        closeStep,
        closeForm,
        preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public saveFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveFormInstance),
    filter(({ preValidate }) => !preValidate),
    exhaustMap(({
                  formId: formId,
                  form,
                  closeStep,
                  closeForm
                }) => this.collectionFormEditApiService.saveInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)))
      .pipe(
        map(data => ({
          type: formsActions.saveFormInstanceSucceeded.type,
          formId: formId,
          collectionId: form.CollectionsID,
          instanceId: data.InstancesID,
          versionId: data.VersionsID,
          close: closeForm
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.saveFormInstanceFailed({
            formId: formId,
            response: response
          }))
        )
      )
    )
  ));
  private alertService = inject(AlertService);
  public saveSucceeded$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createFormInstanceSucceeded, formsActions.saveFormInstanceSucceeded),
    tap(() => this.alertService.setAlert(SAVED_ALERT)),
    map(data => data.close ? ({
      type: formsActions.formClosed.type,
      formId: data.formId,
      unsavedChanges: false
    }) : ({
      type: formsActions.refreshForm.type,
      formId: data.formId,
      instanceId: data.instanceId,
      versionId: data.versionId,
      collectionId: data.collectionId
    }))
  ));
}


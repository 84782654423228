@if (wizard) {
<kendo-stepper class="mb-3 grid w-full md:mb-0" [class]="
  {'cursor-not-allowed': disabled,
  'opacity-50': disabled,
  'menu-bar': !wizard }" [steps]="steps" [stepType]="stepType" [(currentStep)]="currentStep" [linear]="linear"
    (activate)="onStepActivate($event)">
    <ng-template  kendoStepperStepTemplate let-step>
        <span
        class="k-step-indicator" kendoTooltip [title]="step.label | translate">
            <bizz-icon  [iconLibrary]="step.icon.library  ?? iconLibrary.FontAwesome"
                [iconName]="step.icon.name" [class]="{'cursor-not-allowed': disabled, 'opacity-50': disabled}"
                class="size-5">
            </bizz-icon>
        </span>
        <span kendoTooltip [title]="step.label | translate"
            class="mt-1 hidden max-w-full truncate font-sans text-xs font-semibold leading-tight text-default md:inline"
            [class]="{'cursor-not-allowed': disabled,
        'opacity-50': disabled}">
            {{ step.label | translate }}
        </span>
    </ng-template>
</kendo-stepper>
} @else {
<kendo-tabstrip (tabSelect)="onTabSelect($event)" class="h-full" [scrollable]="scrollable">
    <kendo-tabstrip-tab *ngFor="let step of steps; let i = index" [selected]="i === currentStep"
        [disabled]="step.disabled" [closable]="false"
        [cssStyle]="{ 'max-width': scrollable ? 'auto': (100 / this.steps.length + '%')}">
        <ng-template kendoTabTitle>
            @if(step.icon){
            <bizz-icon [iconLibrary]="step.icon.library ?? iconLibrary.FontAwesome" [iconName]="step.icon.name"
                [class]="{'cursor-not-allowed': disabled, 'opacity-50': disabled}"
                class="mr-3 size-5 min-w-min shrink-0">
            </bizz-icon>
            }
            <h4 kendoTooltip [title]="step.label | translate"
                class="hidden font-sans text-xs font-semibold leading-tight sm:inline lg:text-sm"
                [class]="{'cursor-not-allowed': disabled, 'opacity-50': disabled, '!inline': step.showLabelOnMobile ?? false }">
                {{ step.label | translate }}
            </h4>

        </ng-template>
        <ng-template kendoTabContent>
            <div [ngClass]="'kendoTabContent'"></div>
        </ng-template>
    </kendo-tabstrip-tab>
</kendo-tabstrip>
}

export interface Webmanifest {
  name: string;
  short_name: string;
  scope: string;
  start_url: string;
  background_color: string;
  theme_color: string;
  display: string;
  icons: { src: string, sizes: string, type: string, purpose: string }[];
}

export class webmanifestConfig {
  public name: string;
  public short_name: string;
  public scope: string;
  public start_url: string;
  public background_color: string;
  public theme_color: string;
  public display: string;
  public icons: Array<{ src: string, sizes: string, type: string, purpose: string }>;

  constructor(webmanifest: Partial<Webmanifest>) {
    this.name = webmanifest.name ?? "Bizzmine";
    this.short_name = webmanifest.short_name ?? "Bizzmine";
    this.scope = webmanifest.scope ?? window.location.href;
    this.start_url = webmanifest.start_url ?? window.location.href;
    this.background_color = webmanifest.background_color ?? "#000";
    this.theme_color = webmanifest.theme_color ?? "#000";
    this.display = webmanifest.display ?? "standalone";
    this.icons = webmanifest.icons ?? [{
      src: window.location.origin + "/assets/icons/favicon/apple-touch-icon-60x60.png",
      sizes: "60x60",
      type: "image/png",
      purpose: "maskable any"
    },
    {
      src: window.location.origin + "/assets/icons/favicon/apple-touch-icon-76x76.png",
      sizes: "76x76",
      type: "image/png",
      purpose: "maskable any"
    },
    {
      src: window.location.origin + "/assets/icons/favicon/apple-touch-icon-120x120.png",
      sizes: "120x120",
      type: "image/png",
      purpose: "maskable any"
    },
    {
      src: window.location.origin + "/assets/icons/favicon/apple-touch-icon-152x152.png",
      sizes: "152x152",
      type: "image/png",
      purpose: "maskable any"
    },
    {
      src: window.location.origin + "/assets/icons/favicon/apple-touch-icon-180x180.png",
      sizes: "180x180",
      type: "image/png",
      purpose: "maskable any"
    },
    {
      src: window.location.origin + "/assets/icons/favicon/android-chrome-192x192.png",
      sizes: "192x192",
      type: "image/png",
      purpose: "maskable any"
    },
    {
      src: window.location.origin + "/assets/icons/favicon/android-chrome-256x256.png",
      sizes: "256x256",
      type: "image/png",
      purpose: "maskable any"
    },
    {
      src: window.location.origin + "/assets/icons/favicon/android-chrome-512x512.png",
      sizes: "512x512",
      type: "image/png",
      purpose: "maskable any"
    }]
  }
}

export function setManifestFile(manifestConfig: Webmanifest) {
  const stringManifest = JSON.stringify(manifestConfig);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector('#manifestFile')?.setAttribute('href', manifestURL);
  console.info('Manifest file created');
}
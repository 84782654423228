import { AfterViewInit, Component, HostListener, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboBoxComponent, MultiSelectComponent, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { ToggleArrowComponent } from '../../../../../../shared/components/ui/toggle-arrow/toggle-arrow.component';
import { CollectionFormFieldValue } from 'src/models/ts/collection-form-field-value.model';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { formsActions } from '../../../../../../store/features/forms/forms-actions';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isEmpty } from 'lodash';
import { filter, ReplaySubject, take } from 'rxjs';
import { hideRemovedOptionsWhenFieldEnabled } from '../../../functions/hide-removed-options';

/**
 * Represents a control that allows the user to select a value from a list of options.
 */
@Component({
  selector: 'bizz-combobox-control',
  standalone: true,
  imports: [CommonModule, MultiSelectModule, ReactiveFormsModule, TooltipComponent, ToggleArrowComponent, ComboBoxComponent, TranslatePipe],
  templateUrl: './combobox-control.component.html',
  styleUrls: ['./combobox-control.component.scss'],
  //Needed to dynamically style the kendo multiselect tags
  encapsulation: ViewEncapsulation.Emulated
})
export class ComboboxControlComponent extends BaseFormControlComponent implements AfterViewInit {
  @ViewChild('vcr', { read: ViewContainerRef })
  public multiSelectGroupRef: ViewContainerRef;

  @ViewChild('multiSelectInput') public inputElement: MultiSelectComponent;

  /**
   * Indicates if any of the options in the data array have custom colors.
   * @type {boolean}
   */
  public hasCustomColors: boolean = false;

  /**
   * All available options for the combobox.
   * @type {CollectionFormFieldValue[]}
   */
  public data: CollectionFormFieldValue[] = [];
  public filteredData: CollectionFormFieldValue[] = [];
  public isFocused: boolean = false;
  public inputId: string = 'multiselect_' + crypto.randomUUID();
  public valueInitialized = new ReplaySubject<boolean>();

  public override ngOnInit(): void {
    // Setup background color for tags on value change
    this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: value => {
        if (Array.isArray(value) && value[0] !== undefined)
          this.setTagBackgroundColor(value[0]);
      }
    });

    const field = this.formFieldSignal();
    if (field) {
      // Setup data and filtered data arrays
      this.setupData(field);
      this.data = hideRemovedOptionsWhenFieldEnabled(this.data, field.Value);
      this.filteredData = this.data.slice();
      // Set initial value
      this.formControl.setValue(this.getSelectedValue(field));
    }

    // Setup value initialization on first value set
    this.formField$.pipe(takeUntilDestroyed(this.destroyRef), take(1)).subscribe({
      next: field => {
        if (field !== undefined) {
          const fieldValue = this.getSelectedValue(field);
          if (Array.isArray(fieldValue) && fieldValue[0] !== undefined) {
            this.valueInitialized.next(true);
          }
        }
      }
    });

    // Call parent ngOnInit
    super.ngOnInit();
  }

  public ngAfterViewInit(): void {
    // On value initialization, set the background color of the selected tag.
    this.valueInitialized.pipe(takeUntilDestroyed(this.destroyRef), filter(initialized => initialized), take(1)).subscribe({
      next: () => {
        const field = this.formFieldSignal();
        if (field !== undefined && this.getSelectedValue(field).length > 0)
          this.setTagBackgroundColor(this.getSelectedValue(field)[0]);
      }
    });
  }

  public onFilterChange(filter: string): void {
    const searchTerm = filter.toLowerCase();
    this.filteredData = this.data.filter(value => value.Caption.toLowerCase().includes(searchTerm));
  }

  /**
   * @param {KeyboardEvent} event
   */
  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.formFieldSignal()?.IsReadOnly) {
      if (!this.inputElement.hostElement.nativeElement.contains(event.target as Node)) {
        this.isFocused = false;
      } else {
        this.isFocused = true;
        setTimeout(() => {
          this.inputElement.hostElement.nativeElement.getElementsByTagName('input')[0].focus();
        }, 1);
        if (event && event.type != 'click') event.stopImmediatePropagation();
      }
    }

    if(event && event.type == 'click') {
      if(!this.multiSelectGroupRef.element.nativeElement.contains(event.target as Node)) {
        this.inputElement.toggle(false);
      }
    }
  }

  /**
   * Handles the MultiSelect value change event and sets the formControl value. Disables multiple selection by slicing arrays longer than 1 to the last value.
   * @param value
   */
  public onValueChange(value: any): void {
    // Disables multiple selection
    if (Array.isArray(value) && value.length > 1) {
      value = value.slice(-1);
    }
    this.formControl.setValue(value);
  }

  protected override focus(): void {
    // Add your implementation here
    this.inputElement.focus();
  }

  protected override valueSetter(field: CollectionFormField) {
    // If the provided value is not the same as the current formControl value, the value was set via the store and the background color of the tag needs to be set.
    if (this.getSelectedValue(field) !== this.formControl.getRawValue() && this.formControl.getRawValue() !== null)
      this.setTagBackgroundColor(this.getSelectedValue(field)[0]);
    this.formControl.setValue(this.getSelectedValue(field), { emitEvent: false });
  }

  protected override valueChangeDispatcher(value: any) {
    const fieldId = this.formFieldSignal()?.Id;
    if (fieldId)
      this.store$.dispatch(formsActions.updateFormFieldValue({
        formId: this.formId,
        fieldId: fieldId,
        value: value[0] ? value[0].CollectionFieldValuesID : undefined
        // ComboBox: value?.CollectionFieldValuesID ?? undefined
      }));
  }

  protected override gridValueChangeDispatcher(value: any) {
    const field = this.formFieldSignal();
    if (field)
      this.store$.dispatch(formsActions.updateGridFormFieldValue({
        formId: this.formId,
        gridFieldId: this.gridFieldId,
        recordId: this.recordId,
        recordFieldId: field.CollectionFieldsID,
        value: value[0] ? value[0].CollectionFieldValuesID : undefined
        // ComboBox: value?.CollectionFieldValuesID ?? undefined
      }));
  }

  /**
   * Sets the background color of the selected tag.
   * @param {CollectionFormFieldValue} value
   * @private
   * @see inputElement
   */
  private setTagBackgroundColor(value: CollectionFormFieldValue): void {
    if (value !== undefined && this.inputElement !== undefined) {
      this.inputElement.hostElement.nativeElement.style.setProperty(
        '--kendo-tag-background-color',
        value.BackgroundColor
      );
    }
  }

  /**
   * Returns the selected value from the data array or throws an error if the value is not present.
   * @param {CollectionFormField} field
   * @return {CollectionFormFieldValue[]}
   * @private
   */
  private getSelectedValue(field: CollectionFormField): CollectionFormFieldValue[] {
    if (!field.Value) {
      return [];
    } else {
      const selectedValue = this.data.find(val => val.CollectionFieldValuesID == field.Value);
      if (selectedValue !== undefined)
        return [selectedValue];
      else throw new Error(`Combobox received invalid value: ${field.Value}. Not present in FieldValues Array.`);
    }
  }

  /**
   * Sets up the data array with the correct colors.
   * @param {CollectionFormField} field
   * @private
   */
  private setupData(field: CollectionFormField): void {
    this.data = field.FieldValues.map(fieldValue => {
      if (field.DisplayStyleValues !== null) {
        const coloredValue = field.DisplayStyleValues.find(display => display.CollectionFieldValuesID == fieldValue.CollectionFieldValuesID);
        if (coloredValue !== undefined) {
          this.hasCustomColors = true;
          let coloredFieldValue = structuredClone(fieldValue);
          coloredFieldValue.ForegroundColor = isEmpty(coloredValue.ForegroundColor) ? null : coloredValue.ForegroundColor;
          coloredFieldValue.BackgroundColor = isEmpty(coloredValue.BackgroundColor) ? null : coloredValue.BackgroundColor;
          return coloredFieldValue;
        } else return fieldValue;
      } else return fieldValue;
    });
  }
}
import { LookupService } from 'src/app/shared/services/lookup/lookup.service';
import {
  Component,
  computed,
  ElementRef,
  forwardRef,
  HostListener,
  inject,
  OnInit,
  signal,
  Signal,
  ViewChild
} from '@angular/core';
import { BaseControlComponent } from '../../../classes/base-form-control.component';
import { GridComponent } from '../../../../../../shared/components/grid/grid.component';
import { GridOptions } from 'src/app/shared/classes/list/grid-options';
import { FormFieldGridCellComponent } from './components/form-field-grid-cell/form-field-grid-cell.component';
import { GridModule } from '@progress/kendo-angular-grid';
import {
  FromFieldGridActionCellComponent,
  SelectedGridData
} from './components/from-field-grid-action-cell/from-field-grid-action-cell.component';
import { CollectionFormField, Record } from 'src/models/ts/collection-form-field.model';
import {
  selectForm,
  selectFormField,
  selectFormFields, selectFormFieldsByAiSourceFields,
  selectFormLockState, selectFormViewDataSource
} from 'src/app/store/features/forms/forms-selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { formsActions } from 'src/app/store/features/forms/forms-actions';
import { UpdateDeleteState } from 'src/models/ts/update-delete-state.model';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { ToggleArrowComponent } from 'src/app/shared/components/ui/toggle-arrow/toggle-arrow.component';
import { TooltipComponent } from 'src/app/shared/components/ui/tooltip/tooltip.component';
import { KendoPopupContains } from 'src/app/shared/functions/kendo/kendo-popup-contains';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { CollectionFormGroupService } from 'src/app/features/bizzmine/form/services/collection-form-group.service';
import { FormGroup } from '@angular/forms';
import { exhaustMap, filter, of, switchMap, take } from 'rxjs';
import { getLinkedCollectionType } from '../../../../../../shared/functions/helpers/grid-helpers';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { Dialog } from '@angular/cdk/dialog';
import { CollectionListDataInstance } from '../../../../../../shared/interfaces/collection-list-data-instance';
import { StoreCollectionForm } from '../../../../../../store/features/forms/forms-state';
import { Actions, ofType } from '@ngrx/effects';
import { FormControlComponent } from '../form-control/form-control.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { CollectionFormService } from '../../../services/collection-form.service';
import { LookupModalData, LookupSearchModalComponent } from '../../lookup-search-modal/lookup-search-modal.component';
import { LinkedCollectionType } from '../../../../../../../models/ts/linked-collection-type.model';
import { CellActionType } from '../../../../widgets/collection-list-widget/classes/cell-action-type';
import { CellActionData } from '../../../../widgets/collection-list-widget/interfaces/cell-action-data';
import { GridService } from '../../../../widgets/collection-list-widget/services/grid/grid.service';
import { FormGridActionPermissions } from './interfaces/form-grid-action-permissions';
import { ProtectedCollectionType } from '../../../../../../../models/ts/protected-collection-type.model';
import { AiButtonModel } from '../../../../../../../models/ts/ai-button-model';
import { TableFieldDataType } from '../../../../../../../models/ts/table-field-data-type.model';
import { ReadOnlyPriority } from '../../../enums/read-only-priority.enum';
import { GridColumnBase } from '../../../../../../shared/classes/list/grid-column-base';
import { AiApiService } from '../../../../../../api/bizzmine/ai/ai-api.service';
import { LinkedBaseFormControlComponent } from '../../../classes/linked-base-form-control.component';
import { StrictlyProtectedCollectionTypes } from '../../../../../../shared/constants/strictly-protected-collections';

@Component({
  selector: 'bizz-form-list-control',
  templateUrl: './form-list-control.component.html',
  styleUrls: ['./form-list-control.component.scss'],
  standalone: true,
  imports: [
    GridComponent,
    GridModule,
    FormFieldGridCellComponent,
    FromFieldGridActionCellComponent,
    // ForwardRef required as this is a circular reference
    // (FormList is referenced in FormControlComponent through resolveComponentType function
    forwardRef(() => FormControlComponent),
    IconComponent,
    ToggleArrowComponent,
    TooltipComponent,
    PopupModule,
    TranslatePipe,
    FormControlComponent,
    NgClass,
    NgTemplateOutlet
  ]
})
export class FormListControlComponent extends LinkedBaseFormControlComponent<FormGroup> implements OnInit {

  @ViewChild('bizzGridComponent')
  public gridComponent: GridComponent;

  @ViewChild('createMenuAnchor')
  public createMenuAnchor: ElementRef;

  @ViewChild('createMenuPopup', { read: ElementRef })
  public createMenuPopup: ElementRef;
  public gridOptions: GridOptions;
  public currentPage: number = 1;
  public pageRange = signal<{ skip: number, take: number }>({ skip: 0, take: 10 });
  public disabled: boolean = false; //TODO: RV check when backend changes are implemented
  public showCreateMenuPopup = false;

  // Signals
  public form: Signal<StoreCollectionForm | undefined>;
  public formType = computed(() => this.form()?.data?.FormType);
  public records = computed(() => this.formFieldSignal()?.Records ?? []);
  public activeRecords = computed(() => this.records().filter((record, index) => record.State != UpdateDeleteState.Delete));
  public pagedRecords = computed(() => this.activeRecords().slice(this.pageRange().skip, this.pageRange().skip + this.pageRange().take));
  public isProtectedCollectionField = computed(() => {
    let form = this.form();
    let field = this.formFieldSignal();
    if (form !== undefined && field !== undefined) {
      let viewDataSource = form.data.ViewDataSources.find(vds => vds.ViewDataSourcesID == field.ViewDataSourcesID);
      return viewDataSource !== undefined ? StrictlyProtectedCollectionTypes.includes(viewDataSource.ProtectedCollectionType) : true;
    } else return false;
  });
  // Overrides
  protected override fieldValueProperty: keyof CollectionFormField = 'Records';
  protected readonly ProtectedCollectionType = ProtectedCollectionType;
  // DI
  private formGroupService: CollectionFormGroupService = inject(CollectionFormGroupService);
  private collectionListApiService: CollectionListApiService = inject(CollectionListApiService);
  private dialog: Dialog = inject(Dialog);
  private lookupService: LookupService = inject(LookupService);
  private actions$ = inject(Actions);
  private gridService = inject(GridService);
  public oldGrid: Array<CollectionListDataInstance> = [];

  public pageChange(event: { skip: number, take: number }): void {
    this.pageRange.set(event);
    this.currentPage = (event.skip / event.take) + 1;
    this.gridOptions.pageSize = event.take;
  }

  public toggleCreateMenuPopup(event?: MouseEvent, state?: boolean): void {
    this.showCreateMenuPopup = state !== undefined ? state : !this.showCreateMenuPopup;
    if (event)
      event.stopImmediatePropagation();
  }

  public setCreateMenuPopup(showPopup: boolean): void {
    this.showCreateMenuPopup = showPopup;
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (this.showCreateMenuPopup && event.target &&
      !KendoPopupContains(event.target, this.createMenuAnchor, this.createMenuPopup)) {
      this.showCreateMenuPopup = false;
    }
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.store$.select(selectFormLockState(this.formId)).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: locked => {
        this.readOnlyOverride = locked;
      }
    });

    const gridOptions = this.formFieldSignal()?.GridOptions;
    if (gridOptions) {
      const adjustedGridOptions = structuredClone(gridOptions);
      this.gridOptions = new GridOptions(adjustedGridOptions);
      // Form grids are not groupable or sortable
      this.gridOptions.groupable = { enabled: false, showFooter: false };
      this.gridOptions.sortable = false;
    } else {
      throw new Error('GridOptions are required for FormListControlComponent');
    }

    this.form = this.store$.selectSignal(selectForm(this.formId));

    // Update form state and group when record is updated
    this.actions$.pipe(
      ofType(formsActions.gridRecordUpdated),
      filter(action => action.formId == this.formId && action.viewDataSourceId == this.formFieldSignal()?.ViewDataSourcesID),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: action => {
        this.formControl.markAsDirty();
        this.updateFormGroup();
      }
    });

    // Update form state and group on rows/records being added or removed
    this.actions$.pipe(
      ofType(formsActions.rowsAddedToGrid, formsActions.removeRecordFromGrid),
      filter(action => action.formId == this.formId && action.gridFieldId == this.formFieldId()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: action => {
        this.formControl.markAsDirty();
        this.updateFormGroup();
      }
    });

    // Update form group on form update (example: refresh after save)
    this.actions$.pipe(
      ofType(formsActions.updateForm),
      filter(action => action.update.id == this.formId),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: action => {
        this.updateFormGroup();
      }
    });

    this.updateFormGroup();
  }

  public createNewInstance(): void {
    const formFieldId = this.formFieldId();
    if (formFieldId) {
      if (this.externalAccess != null) {
        this.store$.dispatch(formsActions.getExternalGridLinkedForm({
          externalAccess: this.externalAccess,
          formFieldId: this.formFieldSignal()!.CollectionFieldsID,
          formId: this.formId,
          gridFieldId: this.gridFieldId,
          recordId: this.recordId,
          relationType: LinkedCollectionType.GridRecord
        }));
      } else {
        this.store$.dispatch(formsActions.getGridLinkedForm({
          formId: this.formId,
          collectionFieldId: undefined,
          recordId: undefined,
          gridFieldId: formFieldId,
          relationType: LinkedCollectionType.GridRecord
        }));
      }
    }
  }

  public createNewRowInstance(): void {
    const field = this.formFieldSignal();
    const collectionFormId = this.form()?.data.CollectionFormId;
    if (field !== undefined && collectionFormId !== undefined) {
      this.store$.dispatch(formsActions.addInstancesToGrid({
        formId: this.formId,
        gridFieldId: field.Id,
        instances: []
      }));
    }
  }

  public trackBy(index: number, record: any): number {
    return record.CrossLinkedInstancesID;
  }

  public searchInList(): void {
    this.openLookupSearchModal();
  }

  public constructor(
    public aiService: AiApiService,
  ) {
    super();
  }


  public aiButtonFields(event: { action: CellActionType, data: CellActionData }): void {
    const field: GridColumnBase = event.data.data.column;
    const fields: Record = event.data.data.data;
    const aiField = fields.Fields.find(obj => obj.CollectionFieldsID === field.CollectionFieldsID)

    if (aiField !== undefined){
      this.store$.dispatch(formsActions.getAiButtonData({
        formId: this.formId,
        field: aiField
      }));
    }
  }

  public onCellAction(event: { action: CellActionType, data: CellActionData }): void {
    if (event.action == CellActionType.AI_BUTTON) {
      this.aiButtonFields(event);
    }
    const field = this.formFieldSignal();
    if (field == null) return;
    const vds = this.form()?.data.ViewDataSources.find(vds => vds.ViewDataSourcesID == field.ViewDataSourcesID);
    if (vds == null) return;
    const record = vds.Instances?.find(i => i.CrossLinkedInstancesID == event.data.data.CrossLinkedInstancesID);
    if (record == null) return;

    switch (event.action) {
      case CellActionType.DOWNLOAD_FILE: {
        this.gridService.fetchPermissionAndDownloadFromInstance({
          VersionsID: record.ChildVersionsID,
          CollectionsID: vds.ChildCollectionsID
        });
        break;
      }

      default: {
        break;
      }
    }
  }

  public onLookupInstancesSelected(selectedRows: CollectionListDataInstance[]): void {
    const form = structuredClone(this.form());
    if (form == undefined)
      throw new Error('FormListControlComponent form is undefined');

    const field = this.formFieldSignal();
    if (field) {
      const instances = [];
      selectedRows.forEach(row => {
        if (row && row.LinkedToParentVersions && row.LinkedToParentVersions?.length > 0) {
          //TODO: handle scenario where instance is already linked
          /*const promise = AlreadyLinkedInstanceService.openModalForAlreadyLinked(selectedRows[i]);
          promise.then(function (result) {
            if (result.status) {
              var lookupFieldData = LookupService.createLookupObject(result.selectedItem.ID, result.selectedItem.VersionsID, $scope.field.DataDesignViewDataSourcesID, Enums.LinkedCollectionType.GridRecord, result.selectedItem.ID);
              LookupService.addInstanceToGrid(lookupFieldData, $scope.formsId, $scope.viewDataSources, $scope.field, null, false);
            }
          });*/
        } else {
          instances.push({
            id: row.ID,
            versionId: row.VersionsID
          });
        }
      });
      this.store$.dispatch(formsActions.addInstancesToGrid({
        formId: this.formId,
        gridFieldId: this.formFieldId()!,
        instances: selectedRows
      }));
    } else {
      throw new Error('FormListControlComponent field is undefined');
    }
  }

  public onOpenRecordClicked($event: SelectedGridData): void {
    this.store$.dispatch(formsActions.getFormInstanceForGrid({
      read: true,
      parentFormId: this.formId,
      crossLinkedInstancesId: $event.crossLinkedInstancesId,
      viewDataSourceId: $event.viewDataSourceId,
      gridFieldId: this.gridFieldId,
      formFieldId: this.formFieldId() ?? 0
    }));
  }

  public onEditInNewWindowClicked($event: SelectedGridData): void {
    this.store$.dispatch(formsActions.getFormInstanceForGrid({
      read: false,
      parentFormId: this.formId,
      crossLinkedInstancesId: $event.crossLinkedInstancesId,
      viewDataSourceId: $event.viewDataSourceId,
      gridFieldId: this.gridFieldId,
      formFieldId: this.formFieldId() ?? 0
    }));
  }

  /**
   * Handles inline editing by updating the ActionPermissions of the record in the store.
   * @param {Record} record
   * @param {number} index
   */
  public onEditInlineClicked(record: Record, index: number): void {
    if (this.formFieldId()) {
      this.store$.dispatch(formsActions.updateGridRecordEditModeWithPreValidation({
        formId: this.formId,
        gridFieldId: this.formFieldId()!,
        recordId: record.CrossLinkedInstancesID,
        editMode: true
      }));
    }
  }

  /**
   * Handles deletion of a record through the store.
   * @param {Record} record
   * @param {number} rowIndex
   */
  public onDeleteClicked(record: Record, rowIndex: number): void {
    const field = this.formFieldSignal();
    const form = this.form();
    if (form && field) {
      this.store$.dispatch(formsActions.removeRecordFromGridPreConfirmation({
        formId: form.id,
        gridFieldId: field.Id,
        recordId: record.CrossLinkedInstancesID
      }));
    }
  }

  /**
   * Handles 'saving' by updating the ActionPermissions of the record in the store.
   * Actual saving of the record is done on form submission.
   * @param {Record} record
   * @param {number} rowIndex
   */
  public onSaveClicked(record: Record, rowIndex: number): void {
    if (this.formFieldId()) {
      this.store$.dispatch(formsActions.updateGridRecordEditModeWithPreValidation({
        formId: this.formId,
        gridFieldId: this.formFieldId()!,
        recordId: record.CrossLinkedInstancesID,
        editMode: false
      }));
    }
  }

  public onEditDocumentClicked($event: Event): void {
    // TODO: JONAS Edit Online
    throw new Error('Method not implemented.');
  }

  protected override focus(): void {
    // Add your implementation here
  }

  private openLookupSearchModal(): void {
    const form = this.form();
    if (!form)
      throw new Error('FormListControlComponent form is undefined');
    const formField = this.formFieldSignal();
    if (!formField)
      throw new Error('FormListControlComponent field is undefined');

    const lookupData = this.lookupService.getHigherLookupData(form.data.ViewDataSources, formField.ViewDataSourcesID, this.externalAccess);
    let listId = 0;
    if (lookupData) {
      (this.externalAccess?.ChildListID == null ? this.collectionListApiService.getListIdByViewsId(lookupData.viewsId) : of(this.externalAccess.ChildListID)).pipe(
        takeUntilDestroyed(this.destroyRef),
        exhaustMap((id) => {
          listId = id;
          return this.collectionListApiService.getListOptionsByListId(this.externalAccess?.ChildListID ?? listId);
        }),
        switchMap(listOptions => {
            lookupData.parentFormType = form.data.FormType;
            lookupData.parentFormsId = form.data.CollectionFormId;
            const fieldVds = this.store$.selectSignal(selectFormViewDataSource(this.formId, formField.ViewDataSourcesID))();
            const data: LookupModalData = {
              listId: listId,
              lookupData: lookupData,
              singleOrMany: getLinkedCollectionType(formField),
              canCreate: (this.externalAccess?.AllowCreateRecord ?? true) && !StrictlyProtectedCollectionTypes.includes(formField.SourceProtectedCollectionType),
              canViewHiddenDocuments: false,
              listOptions: listOptions,
              formFieldId: formField.Id,
              isGridField: CollectionFormService.fieldIsGrid(formField),
              formFieldSourceCollectionId: fieldVds?.ChildOriginalCollectionsID ?? formField.SourceCollectionFieldsID
            };
            return this.dialog.open<CollectionListDataInstance[]>(LookupSearchModalComponent, {
              data: data
            }).closed;
          }
        )
      ).subscribe({
        next: (selection: CollectionListDataInstance[] | undefined) => {
          if (selection)
            this.onLookupInstancesSelected(selection);
        }
      });
    }
  }

  /**
   * Updates the formControl (in this case a FormGroup) to reflect the store state.
   * Will use activeRecords signal and compares it against the controls in the formGroup.
   * Adds a FormGroup when records contains an id not found in controls.
   * Removes a FormGroup when controls contains an id not found in records.
   * @private
   */
  private updateFormGroup(): void {
    // Get store records
    const records = this.activeRecords();
    // Find differences between formGroup controls and records
    const formControlIds = new Set(Object.keys(this.formControl.controls).map(key => {
      return Number(key);
    }));
    const recordIds = new Set(records.map(record => {
      return record.CrossLinkedInstancesID;
    }));

    const newRecordIds = recordIds.difference(formControlIds);
    const removedRecordIds = formControlIds.difference(recordIds);

    // Add formGroup for new rows/records
    newRecordIds.forEach(id => {
      const record = records.find(r => r.CrossLinkedInstancesID == id);
      if (record)
        this.formControl.addControl(id.toString(), this.formGroupService.getFormGroupFromFieldsByProperty(record.Fields, 'CollectionFieldsID'), { emitEvent: false });
    });

    // Remove formGroup for removed rows/records
    removedRecordIds.forEach(id => {
      this.formControl.removeControl(id.toString(), { emitEvent: false });
    });
  }
}

/**
 * @deprecated permissions added to Record interface
 */
export interface FormListControlRowState {
  record: Record;
  permissions: FormGridActionPermissions,
}
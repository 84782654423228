import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { LinkedCollectionStorageType } from 'src/models/ts/linked-collection-storage-type.model';
import { AsyncPipe, NgClass } from '@angular/common';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { EMAIL_REGEX } from '../../../../../../shared/constants/email-regex';

/**
 * Represents a control that allows the user to enter an email address.
 * The email address can be opened in the default mail client.
 */
@Component({
  selector: 'bizz-email-control',
  standalone: true,
    imports: [
    ReactiveFormsModule,
    IconComponent,
    AsyncPipe,
    NgClass,
    ComboBoxModule,
    TooltipComponent,
    TranslatePipe
],
  templateUrl: './email-control.component.html',
  styleUrls: ['./email-control.component.scss'],
})
export class EmailControlComponent extends BaseFormControlComponent implements OnInit {
  @ViewChild('input') inputElement: ElementRef;

  public override ngOnInit(): void {
    super.ngOnInit();
    if (this.formControl) {
      this.formControl.addValidators(Validators.pattern(EMAIL_REGEX));
      this.formControl.updateValueAndValidity();
    }
  }

  public openMailClient(): void {
    window.open(`mailto:${this.formControl.value}`, '_blank');
  }

  protected readonly LinkedCollectionStorageType = LinkedCollectionStorageType;
  protected override focus(): void {
      this.inputElement.nativeElement.focus();
  }
}
@if (formFieldSignal(); as formField){
    <div class="group mx-auto flex w-full max-w-lg items-center justify-center">
        <div class="relative z-10 flex w-full cursor-pointer items-center overflow-hidden rounded-[0.6rem] p-[1.5px]">
            <div [ngClass]="{'invisible' : !formField.AiLoading}" class="animation-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#139cd8_20deg,transparent_120deg)]"></div>
            <div class="relative z-20 flex w-full rounded-[0.60rem] bg-white p-[0.1px]"><!--[disabled]="btnLoading || formField.IsReadOnly || readOnlyOverride"-->
                <button class="button form relative" [disabled]="btnLoading || !(hasAiLicense$ | async) || readOnlyOverride || formField.IsReadOnly" (click)="aiButton()">
                    <p>{{ formField.Caption }}</p> <!---->
                    <i class="text-xl fa-solid fa-microchip-ai"></i>
                    @if (!(hasAiLicense$ | async)) {
                        <div class="absolute h-full w-full left-0 z-40 flex justify-center items-center bg-[black]/40">
                            <div kendoTooltip class="flex p-0.5 bg-white rounded-3xl" tooltipClass="!z-[9000]">
                                <bizz-icon
                                        class="size-4 text-blue-500"
                                        title="'You have no license to use AI, please contact a admin.'"
                                        [hover]="false"
                                        iconName="circle-info">
                                </bizz-icon>
                            </div>
                        </div>
                    }
                </button>
            </div>
        </div>
    </div>
}
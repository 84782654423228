<!-- Screen -->
@if (sidebar && !loading) {
<div class="hidden h-full flex-col border-r-2 border-sidebar bg-sidebar font-semibold sm:flex"
         [ngClass]="{'w-[17.5rem]' : (expanded | async)}"
         (mouseleave)="onMouseEvent($event)"
         (mouseenter)="onMouseEvent($event)"
    >
    <!-- Workspace Selector -->
    <bizz-workspace-selector class="w-full px-3 py-2.5" [selectedWorkspace]="selectedWorkspace"
        (selectedWorkspaceChange)="selectedWorkspaceChanged($event)" [showFullButton]="expanded | async">
    </bizz-workspace-selector>
    <!-- Workspace Selector -->
    <!-- Items -->
    <ng-scrollbar class="h-[calc(100vh_-_150px)]" visibility="hover">
        <div class="flex h-full w-full flex-col gap-2.5 px-3" [ngClass]="{'items-center gap-2' : !(expanded | async)} ">
            @for (parent of sidebar.Parents; track $index) {
                @if ((expanded | async)){
                    <bizz-sidebar-block
                        [sidebarListItem]="parent.Parent"
                        [children]="parent.Children"
                        [isMobile]="false"
                        class="flex flex-col">
                    </bizz-sidebar-block>
                }@else{
                    <bizz-sidebar-block
                        [sidebarListItem]="parent.Parent"
                        [children]="parent.Children"
                        [isMobile]="false"
                        class="text-title flex flex-col rounded-lg p-2.5"
                        [ngClass]="{'!p-0 w-12 h-12 justify-center items-center' : !(expanded | async)}"
                        [routerLinkActive]="'bg-sidebar-active-section'"
                        [routerLink]="['dashboard/' + parent.Parent.WorkspaceItemsID]">
                    </bizz-sidebar-block>
                }
            }
        </div>
    </ng-scrollbar>
    <!-- Items -->
    <!-- Expand/Collapse Icons -->
    <bizz-expand-collapse-icon class="mt-auto px-3 py-2.5"></bizz-expand-collapse-icon>
    <!-- Expand/Collapse Icons -->
</div>
}
@else {
<div class="hidden h-full flex-col border-r-2 border-sidebar bg-sidebar font-semibold sm:flex"
    [ngClass]="{'w-[17.5rem]' : (expanded | async)}">
    <!-- Workspace Selector -->
    <div class="flex h-[74px] w-full px-[0.94rem] pb-5 pt-2.5">
        <div
            class="flex h-full w-full flex-row items-center rounded bg-workspace-selector p-2 text-workspace-selector/75">
            <kendo-skeleton shape="circle" animation="wave" class="size-6"></kendo-skeleton>

            <kendo-skeleton shape="text" animation="wave" class="mx-2 flex-1"></kendo-skeleton>
        </div>
    </div>

    <!-- Workspace Selector -->
    <!-- Items -->
    <ng-scrollbar class="h-[calc(100vh_-_180px)]" visibility="hover">
        <bizz-sidebar-block-skeleton class="flex w-full flex-col"></bizz-sidebar-block-skeleton>
    </ng-scrollbar>
    <!-- Items -->
    <!-- Expand/Collapse Icons -->
    <bizz-expand-collapse-icon class="mt-auto px-[0.94rem] py-5"></bizz-expand-collapse-icon>
    <!-- Expand/Collapse Icons -->
</div>
}
<!-- Screen -->

<!-- Mobile -->
@defer (when sidebar) {
<div class="left-0 flex h-full w-full flex-col gap-4 bg-sidebar pb-4 pt-4 font-semibold sm:hidden">
    <!-- Workspace Selector -->
    <div class="mx-[0.9rem] flex pb-4">
        <div class="w-full">
            <bizz-workspace-selector class="w-full" [isMobile]="true" [showFullButton]="true"
                [selectedWorkspace]="selectedWorkspace" (selectedWorkspaceChange)="selectedWorkspaceChanged($event)">
            </bizz-workspace-selector>
        </div>
    </div>
    <!-- Workspace Selector -->
    <div class="no-scrollbar flex h-[calc(100vh_-_200px)] flex-col gap-2.5 overflow-scroll"
        [ngClass]="{'items-center max-w-full' : !(expanded | async)}">
        <!--TODO: Theme color for sidebar active background -->
        @for (parent of sidebar.Parents; track $index) {
        <bizz-sidebar-block [sidebarListItem]="parent.Parent" [children]="parent.Children" [isMobile]="true"
            (mobileRoutingClicked)="toggleExpanded()" class="max-w-full">
        </bizz-sidebar-block>
        }
    </div>
    <!--</ng-scrollbar>-->
    <!-- Items -->
</div>
}
<!-- Mobile -->
    <kendo-treeview #treeview class="h-full"
                    kendoTreeViewExpandable
                    [kendoTreeViewSelectable]="selectableSettings"
                    kendoTreeViewDisable

                    [nodes]="nodes"
                    [textField]="'Name'"
                    [children]="fetchChildren"
                    [hasChildren]="hasChildren"
                    [selectedKeys]="selectedKeys"
                    [disabledKeys]="disabledKeys"
                    [kendoTreeViewDisable]="'Name'"
                    (selectedKeysChange)="onSelectionChange($event)"
                    [selectBy]="selectBy"
                    [expandBy]="'Name'">
        <ng-template kendoTreeViewNodeTemplate let-dataItem class="p-0">
            <span class="relative flex h-full w-full items-center gap-2 rounded px-3 py-0.5" readonly disabled [class]="{'bg-button': isEnabledForSelection(dataItem.ItemType),'cursor-not-allowed': isExcludedUser(dataItem), 'opacity-50': !isEnabledForSelection(dataItem.ItemType) || isExcludedUser(dataItem)}">
                @if (isEnabledForSelection(dataItem.ItemType)) {
                    @if (isSelected(dataItem)) {
                        <bizz-icon iconName="circle-check"
                                   class="size-5 cursor-pointer !text-green-500"></bizz-icon>
                    } @else {
                       
                        <div class="icon relative h-5 w-5 cursor-pointer rounded-full bg-blue-600/30 text-blue-400">
                            <bizz-icon iconName="plus" class="absolute left-1/2 top-1/2 size-3 translate-x-[-51%] translate-y-[-49%] text-center text-blue-500" [class]="isExcludedUser(dataItem)? 'cursor-not-allowed':'cursor-pointer'"></bizz-icon>
                        </div>

                    }
                } @else if (replaceDepartmentsAndFunctionsWithUsers){
                    <div class="icon relative h-5 w-5 cursor-pointer rounded-full bg-blue-600/50 text-blue-300" (click)="selectInnerValues(dataItem)">
                        <bizz-icon iconName="plus" class="absolute left-1/2 top-1/2 size-3 translate-x-[-51%] translate-y-[-49%] text-center text-blue-500"></bizz-icon>
                    </div>
                }
                
                <bizz-icon class="size-4"  [iconName]="getIconForData(dataItem)">
                </bizz-icon>
                <span>{{ dataItem.Name }}</span>
            </span>
        </ng-template>
    </kendo-treeview>


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkedFormControlActionType } from '../../../enums/linked-form-control-action-type.enum';
import { LinkedFormControlAction } from '../../../interfaces/linked-form-control-action';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { LinkedCollectionStorageType } from '../../../../../../../models/ts/linked-collection-storage-type.model';
import { ViewDataSource } from '../../../../../../../models/ts/view-data-source.model';
import { CollectionFormExternalAccessDto } from '../../../../../../../models/ts/collection-form-external-access-dto';
import { UserType } from '../../../../../../../models/ts/user-type.model';
import { FormType } from '../../../../../../../models/ts/form-type.model';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { StrictlyProtectedCollectionTypes } from '../../../../../../shared/constants/strictly-protected-collections';

// TODO: Research unifying the boolean expressions for buttons with LinkedBaseFormControlComponent's booleans
@Component({
  selector: 'bizz-linked-form-control-buttons',
  standalone: true,
  imports: [CommonModule, IconComponent, TooltipsModule, TranslatePipe],
  templateUrl: './linked-form-control-buttons.component.html',
  styleUrls: ['./linked-form-control-buttons.component.scss'],
})
export class LinkedFormControlButtonsComponent implements OnInit {
  @Input() public viewDataSource: ViewDataSource | undefined;
  @Input({ required: true }) public formField: CollectionFormField;
  @Input({ required: true }) public readOnly: boolean;
  @Input() public externalAccess: CollectionFormExternalAccessDto | undefined;
  @Input({required: true}) public userType: UserType;
  @Input() public formType?: FormType;
  @Output() public actionClicked: EventEmitter<LinkedFormControlAction> = new EventEmitter<LinkedFormControlAction>();

  public isProtected = false;
  protected readonly linkedCollectionStorageType = LinkedCollectionStorageType;
  protected readonly formControlLinkedButtonActionType = LinkedFormControlActionType;

  public ngOnInit(): void {
    this.isProtected = StrictlyProtectedCollectionTypes.includes(this.formField.SourceProtectedCollectionType);
  }

  public emitAction(type: LinkedFormControlActionType): void {
    this.actionClicked.emit({ type, formField: this.formField });
  }

  protected readonly UserType = UserType;

  public externalUserCheck(combineWith?: boolean) : boolean {
    if((this.userType != UserType.AnonymousUser && this.userType != UserType.ExternalUser) || (this.formType != null && this.formType != FormType.ExternalForm)) {
      return true;
    }

    if(this.externalAccess == undefined){
      return false;
    }

    return combineWith ?? true;
  }

  protected readonly LinkedCollectionStorageType = LinkedCollectionStorageType;
}
import { CollectionFormService } from 'src/app/features/bizzmine/form/services/collection-form.service';
import { Component, computed, inject,signal, Signal, WritableSignal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LinkedFormControlButtonsComponent } from '../linked-form-control-buttons/linked-form-control-buttons.component';
import { debounceTime, filter, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { ComboBoxComponent, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ViewDataSource } from '../../../../../../../models/ts/view-data-source.model';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import {
  CollectionFormLookupApiService,
  CollectionListSummaryItem
} from '../../../../../../api/bizzmine/collection-form-lookup/collection-form-lookup-api.service';
import { LookupData, LookupService } from '../../../../../../shared/services/lookup/lookup.service';
import { LinkedFormControlAction } from '../../../interfaces/linked-form-control-action';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LinkedFormControlActionType } from '../../../enums/linked-form-control-action-type.enum';
import { formsActions } from '../../../../../../store/features/forms/forms-actions';
import { getLinkedCollectionType } from '../../../../../../shared/functions/helpers/grid-helpers';
import {
  LookupModalData,
  LookupSearchModalComponent
} from 'src/app/features/bizzmine/form/components/lookup-search-modal/lookup-search-modal.component';
import {
  selectForm,
  selectFormViewDataSource,
  selectIntegrityForFormField
} from '../../../../../../store/features/forms/forms-selectors';
import { CollectionListApiService } from '../../../../../../api/bizzmine/collection-list/collection-list-api.service';
import { StoreCollectionForm } from '../../../../../../store/features/forms/forms-state';
import { Dialog } from '@angular/cdk/dialog';
import { CollectionListDataInstance } from 'src/app/shared/interfaces/collection-list-data-instance';
import { LinkedCollectionType } from '../../../../../../../models/ts/linked-collection-type.model';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { ExamUsagesDialogComponent } from './exam-usages-dialog/exam-usages-dialog.component';
import { SkillMismatchDialogComponent } from './skill-mismatch-dialog/skill-mismatch-dialog.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { LinkedCollectionStorageType } from '../../../../../../../models/ts/linked-collection-storage-type.model';
import { LinkedBaseFormControlComponent } from '../../../classes/linked-base-form-control.component';
import { ListDesignGridOptionsDto } from '../../../../../../../models/ts/list-design-grid-options-dto.model';
import { UpdateDeleteState } from '../../../../../../../models/ts/update-delete-state.model';
import { StrictlyProtectedCollectionTypes } from '../../../../../../shared/constants/strictly-protected-collections';
import { ViewDataSourcesInstance } from '../../../../../../../models/ts/view-data-sources-instance.model';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

/**
 * Form Control to represent a linked collection field. (1x1)
 * Fetches lookup data from the API and displays it in a dropdown.
 */
@Component({
  selector: 'bizz-linked-form-control',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, LinkedFormControlButtonsComponent, FormsModule, DropDownsModule, TooltipComponent, TooltipsModule, IconComponent, TranslatePipe],
  templateUrl: './linked-form-control.component.html',
  styleUrls: ['./linked-form-control.component.scss']
})
export class LinkedFormControlComponent extends LinkedBaseFormControlComponent<FormControl> {
  public viewDataSources: Array<ViewDataSource>;
  public fieldViewDataSource: ViewDataSource | undefined;
  public searchResults: Array<CollectionListSummaryItem> = [];
  public search$: Subject<string> = new Subject<string>();
  public form: StoreCollectionForm | undefined;
  public integrity = computed(() => {
    return this.store$.selectSignal(selectIntegrityForFormField(this.formId, this.formFieldSignal()?.Id))();
  })
  @ViewChild('input') public inputElement: ComboBoxComponent;
  public instancesId: WritableSignal<number> = signal(0);
  public versionsId: WritableSignal<number> = signal(0);
  public instance: Signal<ViewDataSourcesInstance | undefined> = computed(() => {
    let field = this.formFieldSignal();
    if (field !== undefined) {
      let vds = this.store$.selectSignal(selectFormViewDataSource(this.formId, field.ViewDataSourcesID))();
      if (vds !== undefined) {
        if (vds.SingleOrMany == LinkedCollectionType.GridRecord) {
          return vds.Instances.find(i => i.ChildInstancesID == this.instancesId() && i.ChildVersionsID == this.versionsId() && i.RowDataDesignCrossID == this.recordId);
        } else {
          return vds.Instances.find(i => i.ChildInstancesID == this.instancesId() && i.ChildVersionsID == this.versionsId());
        }
      } else return undefined;
    } else return undefined;
  });
  protected readonly LinkedCollectionStorageType = LinkedCollectionStorageType;
  protected collectionFormLookupApiService: CollectionFormLookupApiService = inject(CollectionFormLookupApiService);
  protected lookupService: LookupService = inject(LookupService);
  private guidChecksum: string | undefined;
  private collectionListApiService: CollectionListApiService = inject(CollectionListApiService);
  private dialog: Dialog = inject(Dialog);

  public override ngOnInit(): void {
    super.ngOnInit();
    this.store$.select(selectForm(this.formId)).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (form: StoreCollectionForm | undefined) => {
        if (form) {
          this.form = form;
          this.viewDataSources = form.data.ViewDataSources;
          this.fieldViewDataSource = this.viewDataSources.find(vds => vds.ViewDataSourcesID == this.formFieldSignal()?.ViewDataSourcesID);
          this.getExistingInstance();
        }
      }
    });

    this.search$.pipe(
      debounceTime(500),
      takeUntilDestroyed(this.destroyRef),
      map(search => this.performLookup(search))
    ).subscribe();
  }

  public performLookup(search: string): void {
    // show no results when it's disabled to lookup on external form (but this must be enabled to clear the exiting relation)
    // IF Usertype = externaluser && formtype = externalform && islookupallowedextform = false => return an empty array

    // show no results when it's disabled (but this must be enabled to clear the existing relation)
    // ?just disable input field and show the clear button?
    const field = this.formFieldSignal();
    const fieldId = field?.Id;
    if (search !== '' && search !== undefined && fieldId && field) {
      const baseCollectionsId = this.viewDataSources[0].ParentCollectionsID;
      // this must be recalculated because the parentrelation can have new values
      const lookupData = this.lookupService.getHigherLookupData(this.viewDataSources, field.ViewDataSourcesID, this.externalAccess);
      if (lookupData) {
        let request: Observable<any>;
        if (lookupData.linkedLevel === 1) {
          request = this.collectionFormLookupApiService.getSearchByTypeAhead(baseCollectionsId, fieldId, lookupData.viewsId, field.ViewDataSourcesID, search);
        } else {
          request = this.collectionFormLookupApiService.getSearchByTypeAheadDeeperLevel(baseCollectionsId, fieldId, field.ViewDataSourcesID, lookupData.childCollectionsId, lookupData.childOriginalCollectionsId, lookupData.instancesId, search);
        }
        request.subscribe({
          next: (items) => {
            this.searchResults = items;
          }
        });
      }
    } else {
      this.searchResults = [];
    }
  }

  public clearLookup(): void {
    if (this.instancesId() != 0)
      this.formControl.setValue(undefined);
  }

  public showSkillMismatches(): void {
    const integrity = this.integrity();
    if (integrity?.LookupMismatches) {
      this.dialog.open(SkillMismatchDialogComponent, {
        data: {
          lookupMismatches: integrity.LookupMismatches
        }
      }).closed.subscribe();
    }
  }

  public showExamUsages(): void {
    const integrity = this.integrity();
    if (integrity?.ExamUsages) {
      this.dialog.open(ExamUsagesDialogComponent, {
        data: {
          examUsages: integrity.ExamUsages
        }
      }).closed.subscribe();
    }

  }

  public handleLinkedFormControlAction(event: LinkedFormControlAction): void {
    if (this.fieldViewDataSource) {
      switch (event.type) {
        case LinkedFormControlActionType.Create:
          if (this.isInGrid) {
            if (this.externalAccess != null) {
              this.store$.dispatch(formsActions.getExternalGridLinkedForm({
                externalAccess: this.externalAccess,
                formFieldId: event.formField.CollectionFieldsID,
                formId: this.formId,
                gridFieldId: this.gridFieldId,
                recordId: this.recordId,
                relationType: LinkedCollectionType.GridRecord
              }));
            } else {
              this.store$.dispatch(formsActions.getGridLinkedForm({
                formId: this.formId,
                gridFieldId: this.gridFieldId,
                collectionFieldId: this.formFieldSignal()?.CollectionFieldsID,
                recordId: this.recordId,
                relationType: LinkedCollectionType.GridRecord
              }));
            }
          } else {
            if (this.externalAccess != null) {
              this.store$.dispatch(formsActions.getExternalLinkedForm({
                externalAccess: this.externalAccess,
                formFieldId: event.formField.Id,
                formId: this.formId,
                relationType: LinkedCollectionType.SingleRecord
              }));
            } else {
              this.store$.dispatch(formsActions.getLinkedForm({
                formFieldId: event.formField.Id,
                formId: this.formId,
                relationType: LinkedCollectionType.SingleRecord
              }));
            }
          }
          break;
        case LinkedFormControlActionType.View:
        case LinkedFormControlActionType.Edit:
          if (this.fieldViewDataSource.Instances[0] || (this.instancesId && this.versionsId)) {
            if (this.isInGrid)
              this.store$.dispatch(formsActions.getGridLinkedInstance({
                collectionId: this.fieldViewDataSource.ChildCollectionsID,
                instanceId: this.instancesId() ?? this.fieldViewDataSource.Instances[0].ChildInstancesID,
                versionId: this.versionsId() ?? this.fieldViewDataSource.Instances[0].ChildVersionsID,
                read: event.type === LinkedFormControlActionType.View,
                formFieldId: event.formField.CollectionFieldsID,
                formId: this.formId,
                gridFieldId: this.gridFieldId,
                recordId: this.recordId,
                relationType: LinkedCollectionType.GridRecord,
                guidChecksum: this.guidChecksum ?? this.fieldViewDataSource.Instances[0].GuidChecksum
              }));
            else
              this.store$.dispatch(formsActions.getLinkedInstance({
                collectionId: this.fieldViewDataSource.ChildCollectionsID,
                instanceId: this.instancesId() ?? this.fieldViewDataSource.Instances[0].ChildInstancesID,
                versionId: this.versionsId() ?? this.fieldViewDataSource.Instances[0].ChildVersionsID,
                read: event.type === LinkedFormControlActionType.View,
                formFieldId: event.formField.Id,
                formId: this.formId,
                relationType: LinkedCollectionType.SingleRecord,
                guidChecksum: this.guidChecksum ?? this.fieldViewDataSource.Instances[0].GuidChecksum
              }));
          } else
            throw new Error(`Attempted to edit form from field ${event.formField.Id} but form was undefined`);
          break;
        case LinkedFormControlActionType.Search:
          this.openLookupSearchModal();
          break;
      }
    }
  }

  public valueNormalizer(text: Observable<string>): Observable<any> {
    return text.pipe(map(text => {
      return {
        InstancesID: 0,
        OriginalChildInstancesID: 0,
        Text: text,
        VersionsID: 0,
        CrossLinkInstancesID: 0,
        LinkedToParentVersions: []
      };
    }));
  }

  protected override valueChangeDispatcher(value: any) {
    const field = this.formFieldSignal();
    if (field) {
      if (value !== undefined && value.Text !== '') {
        // set instance and versions id's as vds Instances are not updated until saved
        this.instancesId.set(value.InstancesID);
        this.versionsId.set(value.VersionsID);
        this.guidChecksum = value.GuidChecksum;
        // dispatch value change action
        // NMBIL Value update
        if (value.InstancesID == 0 && !this.fieldViewDataSource?.MustBeInList) {
          this.store$.dispatch(formsActions.updateFormFieldValue({
            fieldId: field.Id,
            formId: this.formId,
            value: value.Text
          }));
          this.store$.dispatch(formsActions.lookupCreated({
            formId: this.formId,
            lookupFieldId: field.Id,
            lookupItem: value
          }));
        } else {
          this.store$.dispatch(formsActions.lookupChanged({
            formId: this.formId,
            lookupFieldId: field.Id,
            lookupItem: value
          }));
        }
      } else {
        this.instancesId.set(0);
        this.versionsId.set(0);
        this.guidChecksum = undefined;
        // dispatch clear
        this.store$.dispatch(formsActions.lookupCleared({
          formId: this.formId,
          viewDataSourceId: field.ViewDataSourcesID
        }));
      }
    }
  }

  protected override gridValueChangeDispatcher(value: any) {
    const field = this.formFieldSignal();
    if (field) {
      if (value !== undefined && value.Text !== '') {
        // set instance and versions id's as vds Instances are not updated until saved
        this.instancesId.set(value.InstancesID);
        this.versionsId.set(value.VersionsID);
        this.guidChecksum = value.GuidChecksum;
        // NMBIL Value update
        if (value.InstancesID == 0 && !this.fieldViewDataSource?.MustBeInList) {
          // Lookup Field update
          this.store$.dispatch(formsActions.updateGridFormFieldValue({
            formId: this.formId,
            gridFieldId: this.gridFieldId,
            recordId: this.recordId,
            recordFieldId: field.CollectionFieldsID,
            value: value.Text
          }));
          // Instance update
          this.store$.dispatch(formsActions.gridLookupCreated({
            formId: this.formId,
            gridFieldId: this.gridFieldId,
            recordId: this.recordId,
            lookupFieldId: field.CollectionFieldsID,
            lookupItem: value
          }));
        } else {
          // dispatch value change action
          this.store$.dispatch(formsActions.gridLookupChanged({
            formId: this.formId,
            gridFieldId: this.gridFieldId,
            recordId: this.recordId,
            lookupFieldId: field.CollectionFieldsID,
            lookupItem: value
          }));
        }
      } else {
        this.instancesId.set(0);
        this.versionsId.set(0);
        this.guidChecksum = undefined;
        // dispatch clear
        this.store$.dispatch(formsActions.gridLookupCleared({
          formId: this.formId,
          gridFieldId: this.gridFieldId,
          recordId: this.recordId,
          viewDataSourceId: field.ViewDataSourcesID
        }));
      }
    }
  }

  protected override valueSetter(field: CollectionFormField): void {
    if (!field.Value) {
      this.searchResults = [];
      this.formControl.setValue(undefined, { emitEvent: false });
    } else {
      const summaryMockItem: CollectionListSummaryItem = {
        InstancesID: 0,
        OriginalChildInstancesID: 0,
        Text: field.Value,
        VersionsID: 0,
        CrossLinkInstancesID: 0,
        LinkedToParentVersions: []
      };
      this.searchResults = [summaryMockItem];
      this.formControl.setValue(summaryMockItem, { emitEvent: false });
    }
  }

  protected override focus(): void {
    setTimeout(() => {
      this.inputElement.focus();
    }, 10);
  }

  protected getLookupData(vdsId: number): LookupData | undefined {
    return this.lookupService.getHigherLookupData(this.viewDataSources, vdsId, this.externalAccess);
  }

  protected getListOptions(listId: number): Observable<ListDesignGridOptionsDto> {
    return this.collectionListApiService.getListOptionsByListId(this.externalAccess?.ChildListID ?? listId);
  }

  private getExistingInstance(): void {
    if (this.fieldViewDataSource !== undefined) {
      let foundInstance: ViewDataSourcesInstance | undefined;
      if (this.fieldViewDataSource.SingleOrMany == LinkedCollectionType.GridRecord) {
        foundInstance = this.fieldViewDataSource?.Instances.find(i => i.CrossLinkedInstancesID == this.recordId);
      } else {
        foundInstance = this.fieldViewDataSource?.Instances.find(i => i.State !== UpdateDeleteState.Delete);
      }
      if (foundInstance != undefined) {
        this.instancesId.set(foundInstance.ChildInstancesID);
        this.versionsId.set(foundInstance.ChildVersionsID);
      }
    }
  }

  private openLookupSearchModal(): void {
    const formField = this.formFieldSignal();
    if (formField == undefined)
      throw new Error('LinkedFormControl formField is invalid');
    const lookupData = this.getLookupData(formField.ViewDataSourcesID!);
    let listId = 0;
    if (lookupData && this.form) {
      (this.externalAccess?.ChildListID == null ? this.collectionListApiService.getListIdByViewsId(lookupData.viewsId) : of(this.externalAccess.ChildListID)).pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((id) => {
          listId = id;
          return this.getListOptions(listId);
        }),
        switchMap(listOptions => {
            lookupData.parentFormType = this.form!.data.FormType;
            lookupData.parentFormsId = this.form!.data.CollectionFormId;
            if(lookupData.linkedLevel === 1){
              const vds = this.store$.selectSignal(selectFormViewDataSource(this.formId, formField.ViewDataSourcesID))();
              if(vds){
                lookupData.crossLinkCollectionsID = vds.CrossLinkCollectionsID;
                lookupData.viewDataSourcesID = vds.ViewDataSourcesID;
                lookupData.mustBeUniqueLinkedInstanceType = vds.MustBeUniqueLinkedInstanceType;
              }
            }
            const data: LookupModalData = {
              listId: listId,
              //parentFormType: FormType,
              singleOrMany: getLinkedCollectionType(formField),
              canCreate: (this.externalAccess?.AllowCreateRecord ?? true) && !StrictlyProtectedCollectionTypes.includes(formField.SourceProtectedCollectionType),
              canViewHiddenDocuments: false,
              listOptions: listOptions,
              formFieldId: formField.Id,
              isGridField: CollectionFormService.fieldIsGrid(formField),
              lookupData: lookupData,
              formFieldSourceCollectionId: this.form?.data.ViewDataSources.find(vds => vds.ViewDataSourcesID == formField.ViewDataSourcesID)?.ChildOriginalCollectionsID ?? formField.SourceCollectionFieldsID
            };
            return this.dialog.open<CollectionListDataInstance[]>(LookupSearchModalComponent, {
              data: data
            }).closed;
          }
        ),
        tap(() => this.formControl.markAsDirty()),
        filter((selection: CollectionListDataInstance[] | undefined) => {
          return selection !== undefined && selection[0] !== undefined && !this.isSelectionSameAsCurrent(selection[0]);
        }),
        map((selection: CollectionListDataInstance[] | undefined) => {
          // Selection cannot be undefined due to filter but removing undefined from the chain causes type conflict
          // TODO: fix type to remove undefined => empty selection should be an empty array.
          const instance = selection![0];
          if (this.isInGrid) {
            this.store$.dispatch(formsActions.gridLookupChanged({
              formId: this.formId,
              lookupFieldId: formField.CollectionFieldsID,
              gridFieldId: this.gridFieldId,
              recordId: this.recordId,
              lookupItem: {
                InstancesID: instance.ID,
                OriginalChildInstancesID: instance['OriginalChildInstancesID'] as number,
                Text: '',
                VersionsID: instance.VersionsID,
                CrossLinkInstancesID: instance['CrossLinkInstancesID'] as number,
                LinkedToParentVersions: []
              }
            }));
          } else
            this.store$.dispatch(formsActions.lookupChanged({
              formId: this.formId,
              lookupFieldId: formField.Id,
              lookupItem: {
                InstancesID: instance.ID,
                OriginalChildInstancesID: instance['OriginalChildInstancesID'] as number,
                Text: '',
                VersionsID: instance.VersionsID,
                CrossLinkInstancesID: instance['CrossLinkInstancesID'] as number,
                LinkedToParentVersions: []
              }
            }));
        })
      ).subscribe();
    }
  }

  private isSelectionSameAsCurrent(selection: CollectionListDataInstance): boolean {
    const foundInstance = this.fieldViewDataSource?.Instances.find(i => i.CrossLinkedInstancesID == this.recordId);
    if (foundInstance !== undefined)
      return selection.VersionsID == this.versionsId() && selection.ID == this.instancesId() && foundInstance.State !== UpdateDeleteState.Delete;
    else return selection.VersionsID == this.versionsId() && selection.ID == this.instancesId();
  }
}

import { Component, Inject } from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { EditorModule } from '@progress/kendo-angular-editor';
import { SharedFilterModule } from '@progress/kendo-angular-grid';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { LabelModule } from '@progress/kendo-angular-label';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate/translate.pipe';
import { IconComponent } from '../../../../../../../shared/components/ui/icon/icon.component';
import { KENDO_SLIDER } from '@progress/kendo-angular-inputs';
import { TranslationService } from '../../../../../../../core/services/translation/translation.service';

@Component({
  selector: 'bizz-exam-creator-generate-ai-control',
  standalone: true,
  imports: [
    AsyncPipe,
    EditorModule,
    IconComponent,
    SharedFilterModule,
    TranslatePipe,
    LabelModule,
    NgClass,
    KENDO_SLIDER
  ],
  templateUrl: './exam-creator-generate-ai-control.component.html',
  styleUrl: './exam-creator-generate-ai-control.component.scss'
})
export class ExamCreatorGenerateAiControlComponent {
  public intent: {
    questions: number,
    answersPerQuestion: number
  } = {
    questions: 5,
    answersPerQuestion: 3
  };
  public min = 0;
  public max = 20;

  public isValidInput = false;

  public constructor(private dialogRef: DialogRef, @Inject(DIALOG_DATA) public dialogModel: {
    defaultQuestions: number | null,
    defaultAnswersPerQuestion: number | null
  },
    private translationService: TranslationService) {
    this.setIntent();
    this.dialogRef.disableClose = true;
    this.validate();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public examAmountOfQuestionsField : string | undefined = undefined;
  public numberOfQuestions() : string {
    if(this.examAmountOfQuestionsField == undefined){
      this.examAmountOfQuestionsField = this.translationService.translate('ExamAmountOfQuestionsField') ?? '';
      return this.numberOfQuestions();
    }
    return `${this.examAmountOfQuestionsField} (${this.intent.questions})`;
  }

  public examAmountOfAnswersPerQuestionField : string | undefined = undefined;
  public numberOfAnswers() : string {
    if(this.examAmountOfAnswersPerQuestionField == undefined){
      this.examAmountOfAnswersPerQuestionField = this.translationService.translate('ExamAmountOfAnswersPerQuestionField') ?? '';
      return this.numberOfAnswers();
    }
    return `${this.examAmountOfAnswersPerQuestionField} (${this.intent.answersPerQuestion})`;
  }

  public formatNumber(value: number): number {
    return Math.round(value);
  }

  public validate(): boolean {
    this.isValidInput = true;
    this.isValidInput = this.intent.questions > 0 && this.intent.answersPerQuestion > 0;
    this.isValidInput = this.isValidInput && this.intent.questions % 1 == 0 && this.intent.answersPerQuestion % 1 == 0;
    return this.isValidInput;
  }

  public confirm(): void {
    if (this.validate()) {
      this.dialogRef.close(this.intent);
    }

  }

  private setIntent(): void {
    if (this.dialogModel.defaultQuestions !== null)
      this.intent.questions = this.dialogModel.defaultQuestions > this.max ? this.max : this.dialogModel.defaultQuestions;
    if (this.dialogModel.defaultAnswersPerQuestion !== null)
      this.intent.answersPerQuestion = this.dialogModel.defaultAnswersPerQuestion > this.max ? this.max : this.intent.answersPerQuestion;
  }
}

import { Injectable } from '@angular/core';
import { userSettingsFeature } from '../../../store/features/user-settings/user-settings-feature';
import { Store } from '@ngrx/store';
import { format, parseISO } from 'date-fns';
import { TimeFormatType } from '../../../../models/ts/time-format-type.model';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';

@Injectable({
  providedIn: 'root',
})
export class DateFilterService {
  public constructor(private store$: Store) {}

  private dateTimeFormatType = this.store$.selectSignal(
    userSettingsFeature.selectDateTimeFormatTypeString,
  );
  private timeFormatType = this.store$.selectSignal(
    userSettingsFeature.selectTimeFormatTypeEnum,
  );
  private timeZoneName = this.store$.selectSignal(
    userSettingsFeature.selectTimezoneName,
  );
  private dateFormatType = this.store$.selectSignal(
    userSettingsFeature.selectDateFormatTypeString,
  );
  private thousandSeparator = this.store$.selectSignal(
    userSettingsFeature.selectThousandSeparatorString,
  );
  private commaSeparator = this.store$.selectSignal(
    userSettingsFeature.selectDecimalSeparatorString,
  );
  private commaSeparatorType = this.store$.selectSignal(
    userSettingsFeature.selectDecimalSeparatorType,
  );

  public convertDate(value: string): string {
    if (value == null || value == 'null') return '';
    else {
      return formatInTimeZone(
        parseISO(value),
        this.timeZoneName(),
        this.convertDateMomentFormat(this.dateFormatType()),
      );
    }
  }

  public convertDateTime(value: string): string {
    if (value == null || value == 'null') return '';
    else {
      return formatInTimeZone(
        parseISO(value),
        this.timeZoneName(),
        this.convertDateMomentFormat(this.dateTimeFormatType()),
      );
    }
  }

  public convertTime(value: string): string {
    if (value == null || value == 'null') return '';
    else {
      return formatInTimeZone(
        parseISO(value),
        this.timeZoneName(),
        this.timeFormatType() == TimeFormatType.Twelve ? 'hh:mm a' : 'HH:mm',
      );
    }
  }

  public convertUtcDate(value: string): string {
    if (value == null || value == 'null') return '';
    else {
      return format(
        fromZonedTime(parseISO(value), this.timeZoneName()),
        this.convertDateMomentFormat(this.dateFormatType()),
      );
    }
  }

  public convertUtcDateTime(value: string): string {
    if (value == null || value == 'null') return '';
    else {
      return format(
        fromZonedTime(parseISO(value), this.timeZoneName()),
        this.convertDateMomentFormat(this.dateTimeFormatType()),
      );
    }
  }

  //Todo: RV Change the backend to return the correct format instead of the moment.tz specific one.
  public convertDateMomentFormat(value: string): string {
    //"DD/MM/YYYY"
    //"MM/DD/YYYY"
    //"DD MMM YYYY"
    //"YYYY-MM-DD"
    //replace all
    if (value == null) return value;
    return value
      .replace(new RegExp('D', 'g'), 'd')
      .replace(new RegExp('Y', 'g'), 'y');
  }
}

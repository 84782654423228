import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { PermissionsDataComponent } from '../permissions-data/permissions-data.component';
import { PermissionsParams } from '../../interfaces/permissions-params.interface';
import { take } from 'rxjs';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { InstancePermissionsDto } from 'src/models/ts/instance-permissions-dto.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'bizz-permissions',
  standalone: true,
  imports: [TranslatePipe, IconComponent, PermissionsDataComponent],
  templateUrl: './permissions.component.html',
  styleUrl: './permissions.component.scss'
})
export class PermissionsComponent {
  @Output() public onClose = new EventEmitter<InstancePermissionsDto>();
  @Input() public data: PermissionsParams;

  public constructor(){}

  public onBackClicked(): void {
    this.onClose.emit();
  }

  public save(): void {
    this.onClose.emit(this.data.permissions);
  }
}

import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormHeaderComponent } from '../form-header/form-header.component';
import { FormComponent } from '../form/form.component';
import { ViewStackLoadedComponent } from '../../../../../shared/interfaces/view-stack-loaded-component';
import { CollectionForm } from '../../../../../../models/ts/collection-form.model';

@Component({
  selector: 'bizz-view-stack-form',
  standalone: true,
  imports: [CommonModule, FormComponent, FormHeaderComponent],
  templateUrl: './view-stack-form.component.html',
  styleUrls: ['./view-stack-form.component.scss']
})
export class ViewStackFormComponent implements ViewStackLoadedComponent<CollectionForm> {
  @ViewChild('formComponent') formComponent: FormComponent;
  public id: string;
  public data: CollectionForm;

  public removeFromViewStack(): void {
    if (this.formComponent)
      this.formComponent.dispatchCancel(true);
  }

  public hasUnsavedChanges(): boolean {
    if (this.formComponent)
      return this.formComponent.form.dirty;
    return false;
  }
}

import { Component } from '@angular/core';
import { CellTemplateDirective, ColumnComponent, GridComponent } from '@progress/kendo-angular-grid';
import { ModalComponent } from '../../../../../shared/components/modals/modal/modal.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'bizz-found-accesstoken-confirm',
  standalone: true,
  imports: [
    CellTemplateDirective,
    ColumnComponent,
    GridComponent,
    ModalComponent,
    TranslatePipe
  ],
  templateUrl: './found-accesstoken-confirm.component.html',
  styleUrl: './found-accesstoken-confirm.component.scss'
})
export class FoundAccesstokenConfirmComponent {
  constructor(public dialogRef: DialogRef) {
  }

}

<div class="modal-container justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="" (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
                {{ 'ChooseATheme' | translate }}
            </h1>
        </div>
        <hr class="my-5"/>
        <div class="gutter-stable-both my-4 grid w-full snap-y grid-cols-1 justify-items-center gap-5 overflow-y-auto scroll-smooth px-2.5 hover:overflow-y-auto sm:grid-cols-2 sm:overflow-y-hidden lg:grid-cols-4">
            @if(showFullPreviewIcons) {
                @for(theme of themes; track $index) {
                    <bizz-theme-preview-icon-full class="snap-start scroll-mt-5" [theme]="theme" (themeSelected)="onThemeSelected($event)" 
                    [isSelected]="theme.ID === selectedTheme?.ID">
                    </bizz-theme-preview-icon-full>
                }
            } @else {
                @for(theme of themes; track $index) {
                    <bizz-theme-preview-icon-simple class="snap-start scroll-mt-5" [theme]="theme" (themeSelected)="onThemeSelected($event)" 
                    [isSelected]="theme.ID === selectedTheme?.ID">
                    </bizz-theme-preview-icon-simple>
                }
            }
            
        </div>
        
    </div>
    <div class="modal-buttons">
            <button class="default" type="button" (click)="closeModal($event)">
                {{ 'Cancel' | translate }}
            </button>
            <button class="save min-w-16" type="button" (click)="closeModal($event,selectedTheme)">
                {{ 'Ok' | translate }}
            </button>
    </div>
</div>

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { GridFilterDto } from '../../../../models/ts/grid-filter-dto.model';
import { OperatorDto } from '../../../../models/ts/operator-dto.model';
import { ItemDto } from '../../../../models/ts/item-dto.model';
import { SearchFilterDto } from '../../../../models/ts/search-filter-dto.model';
import { FilterItemType } from '../../../../models/ts/filter-item-type.model';
import { TrnSkillFilterDto } from 'src/app/shared/components/modals/matrix-filter-modal/matrix-filter-modal-data';
import { TypeaheadSearchDto } from '../../../../models/ts/typeahead-search-dto.model';
import { SelectedTypeaheadItemDto } from '../../../../models/ts/selected-typeahead-item-dto.model';
import { DateFilterService } from '../../../shared/services/filter/date-filter-service';
import { userSettingsFeature } from '../../../store/features/user-settings/user-settings-feature';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class FilterApiService {

  public constructor(@Inject(LOCALE_ID) public localeId: string, private store$: Store, private http: HttpClient, public dateFilterService:DateFilterService) {}
  private timeZoneName = this.store$.selectSignal(
    userSettingsFeature.selectTimezoneName
  );

  public getFiltersByViewsID(viewID: number): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/grid/view/${viewID}`);
  }

  public getFiltersByViewWidgetId(viewID: number, widgetID: number): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/grid/view/${viewID}/widget/${widgetID}`)
  }

  public getTaskListFilter(filtersId: number, collectionsId: number, widgetId: number): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/grid/tasklistfilter/${filtersId}/collection/${collectionsId}/widget/${widgetId}`);
  }

  public getFilterData(filterId: number): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/grid/filter/${filterId}`).pipe(map((found: GridFilterDto) => {
      found.Groups.forEach((group) => {
        group.Fields.forEach((field) => {
          field.Values.forEach((value) => {
            if(value.DateTimeValue != null) {
                const dateWithTimezone = new Date(new Date(value.DateTimeValue).toLocaleString('en-US', { timeZone: this.timeZoneName() }));
                if (dateWithTimezone != undefined) {
                  value.DateTimePickerValue =  dateWithTimezone;
                }
              }
          });
        });
      });
      return found;
    }));
  }

  public getFilterOperators(): Observable<OperatorDto> {
    return this.http.get<OperatorDto>(`api/filter/operators`);
  }
  public getFiltergrouptype(): Observable<ItemDto[]> {
    return this.http.get<ItemDto[]>(`api/filter/filtergrouptype`);
  }

  public getFilterList(viewsId: number): Observable<SearchFilterDto[]> {
    return this.http.get<SearchFilterDto[]>(`api/filter/view/${viewsId}/filterList`);
  }

  public getFilterByItemType(filterItemType : FilterItemType): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/${filterItemType}`);
  }

  public getFilterByItemTypeAndCollection(filterItemType : FilterItemType | undefined, collectionsId: number): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/${filterItemType}/collection/${collectionsId}`);
  }

  public getDefaultFilterForUserMatrix(trnAppId: number, skillCollectionId: number): Observable<TrnSkillFilterDto> {
    return this.http.get<TrnSkillFilterDto>(`api/trainingapps/${trnAppId}/defaultuserfilter/${skillCollectionId}`);
  }

  public getDefaultFilterForFunctionMatrix(trnAppId: number, skillCollectionId: number): Observable<TrnSkillFilterDto> {
    return this.http.get<TrnSkillFilterDto>(`api/trainingapps/${trnAppId}/defaultfunctionfilter/${skillCollectionId}`);
  }

  public getTypeAheadFilter(fieldItemType: FilterItemType, typeObject: TypeaheadSearchDto): Observable<Array<SelectedTypeaheadItemDto>> {
    return this.http.post<Array<SelectedTypeaheadItemDto>>(`api/filter/${fieldItemType}/values/search`, typeObject);
  }
}
import { Component, DestroyRef, OnInit } from '@angular/core';
import { TaskApiService } from 'src/app/api/bizzmine/task/task-api.service';
import { GridDataDto } from '../../../../models/ts/grid-data-dto.model';
import { TasksDto } from '../../../../models/ts/tasks-dto.model';
import { TasksSearchDto } from '../../../../models/ts/tasks-search-dto.model';
import { TaskStateFilterType } from '../../../../models/ts/task-state-filter-type.model';
import { GridFilterDto } from '../../../../models/ts/grid-filter-dto.model';
import { NgForOf } from '@angular/common';
import { GridOptions } from 'src/app/shared/classes/list/grid-options';
import { CollectionType } from 'src/models/ts/collection-type.model';
import { DropDownListModule, SharedDirectivesModule, SharedModule } from '@progress/kendo-angular-dropdowns';
import { FilterComponent } from '../../../shared/components/ui/filter-component/filter.component';
import { TranslatePipe } from '../../../shared/pipes/translate/translate.pipe';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { GridGroupSortDto } from 'src/models/ts/grid-group-sort-dto.model';
import { FilterItemType } from 'src/models/ts/filter-item-type.model';
import { IconComponent } from '../../../shared/components/ui/icon/icon.component';
import { GroupDescriptor } from '@progress/kendo-data-query/dist/npm/grouping/group-descriptor.interface';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { CellActionType } from '../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { CellActionData } from '../../../features/bizzmine/widgets/collection-list-widget/interfaces/cell-action-data';
import { TaskService } from '../../../shared/services/tasks/task.service';
import { GridComponent } from '../../../shared/components/grid/grid.component';
import { SearchFilterOutputDto } from '../../../../models/ts/search-filter-output-dto.model';
import { take } from 'rxjs';
import { GridColumnType } from '../../../../models/ts/grid-column-type.model';

@Component({
  selector: 'bizz-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  standalone: true,
  imports: [
    NgForOf,
    GridComponent,
    DropDownListModule,
    SharedDirectivesModule,
    FilterComponent,
    TranslatePipe,
    IconComponent,
    SharedModule
  ]
})
export class TasksComponent implements OnInit {
  public currentPage = 1;
  public pageSizes: Array<number> = [1, 2, 5, 10, 20, 50, 200, 500];
  public searchedTaskDto: TasksDto;
  public tasks: GridDataDto;
  public isLoading: boolean;
  public gridOptions: GridOptions;
  public CollectionType = CollectionType;
  public filterItemType = FilterItemType;
  public gridFilter: GridFilterDto;
  public activeFilter = false;
  public totalRecords: number;
  public taskStateFilterType: TaskStateFilterType = TaskStateFilterType.Open;
  public filterData: GridFilterDto | undefined;
  public searchTerm: string | undefined;

  public constructor(private taskApiService: TaskApiService, private destroyRef: DestroyRef, private taskService: TaskService) {
  }

  public ngOnInit(): void {
    this.getTaskGridOptions();
    this.getTaskSearchFilter();
  }

  public getTaskGridOptions(): void {
    this.isLoading = true;
    this.taskApiService.getTaskOptions(true).subscribe({
      next: (tasks): void => {
        this.searchedTaskDto = tasks;
        this.searchedTaskDto.GridOptions.columns.forEach(col => {
          if(col.field == null || col.field.toLowerCase() == 'state'){
            col.groupable = false;
          }
        });
        this.gridOptions = new GridOptions(this.searchedTaskDto.GridOptions);
        this.fetchSearchData();
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      }
    });
  }

  public getTaskSearchFilter(): void {
    this.taskApiService.getTasksSearchFilter().subscribe({
      next: (gridFilter): void => {
        this.gridFilter = gridFilter;
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      }
    });
  }

  public onTaskFilterStateTypeChanged(type: TaskStateFilterType): void {
    this.taskStateFilterType = type;
    this.currentPage = 1;
    this.fetchSearchData();
  }

  public onPageChange(event: PageChangeEvent): void {
    this.currentPage = (event.skip / event.take) + 1; // Update the current page number
    this.gridOptions.pageSize = event.take;
    this.fetchSearchData();
  }

  public onCellAction(event: { action: CellActionType, data: CellActionData }): void {
    switch (event.action) {
      case CellActionType.OPEN_CONTEXT_MENU:
      case CellActionType.DOWNLOAD_FILE:
      case CellActionType.MAIL_VIEW:
        throw new Error('Invalid cell action for Tasks' + event);
      case CellActionType.TASK_EXECUTE:
        this.taskService.executeTask({
          taskId: event.data.data.ID,
          collectionId: event.data.data.CollectionsID,
          instanceId: event.data.data.InstanceID,
          versionId: event.data.data.VersionsID,
          draftId: event.data.data.DraftsID
        });
        break;
      case CellActionType.MEMO_HYPERLINK_CLICKED:
        this.taskService.executeTask({
          taskId: event.data.data.ID,
          collectionId: event.data.data.CollectionsID,
          instanceId: event.data.data.InstanceID,
          versionId: event.data.data.VersionsID,
          draftId: event.data.data.DraftsID
        });
        break;
      case CellActionType.TASK_FORWARD:
        this.taskService.forwardTask(event.data.data.ID, event.data.data.CollectionsID);
        break;

    }
  }

  //TODO: bv check for duplicate code
  public fetchDataByFilter(filter: SearchFilterOutputDto): void {
    this.searchTerm = filter.Search;
    this.filterData = filter.SearchFilters?.UseDefaultFilter == true? {} as GridFilterDto : filter.SearchFilters;
    this.currentPage = 1;
    this.fetchSearchData();
  }

  public fetchSearchData(): void {
    this.isLoading = true;

    const searchDto: Partial<TasksSearchDto> = {
      TaskStateFilterType: this.taskStateFilterType,
      SearchFilters: this.filterData,
      Everyone: false,
      Page: this.currentPage,
      Records: this.gridOptions.pageSize,
      Group: this.gridOptions.dataSource.group as GridGroupSortDto[],
      Sort: this.gridOptions.dataSource.sort as GridGroupSortDto[]
    };

    if (this.searchTerm){
      searchDto.Search = this.searchTerm;
    }


    this.taskApiService.getTasks(searchDto).pipe(take(1)).subscribe({
      next: (tasks): void => {
        this.tasks = tasks;
        this.totalRecords = tasks.TotalRecords;
        this.activeFilter = tasks.ActiveSearchfilter;
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      }
    });
  }

  public refreshGrid(): void {
    this.fetchSearchData();
  }

  public onGroupChange(groups: Array<GroupDescriptor>): void {
    //ColumnOrder
    this.gridOptions.dataSource.group = groups;
    this.fetchSearchData();
  }

  public onSortChange(sorting: Array<SortDescriptor>): void {
    this.gridOptions.dataSource.sort = sorting;
    this.fetchSearchData();
  }
}

import { Component, DestroyRef, Input, OnInit, signal } from '@angular/core';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { AsyncPipe } from '@angular/common';
import { GridComponent } from '../../../../../../shared/components/grid/grid.component';
import { GridOptions } from '../../../../../../shared/classes/list/grid-options';
import { CollectionFormApiService } from '../../../../../../api/bizzmine/collection-form/collection-form-api.service';
import {
  BehaviorSubject,
  combineLatestWith,
  filter,
  finalize,
  lastValueFrom,
  map,
  of,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { Dialog } from '@angular/cdk/dialog';
import { SelectMailTemplateComponent } from './select-mail-template/select-mail-template.component';
import { Store } from '@ngrx/store';
import {
  selectForm, selectFormField,
  selectFormFields,
  selectFormFieldValidationState,
  selectFormFieldValue
} from '../../../../../../store/features/forms/forms-selectors';
import { StoreCollectionForm } from '../../../../../../store/features/forms/forms-state';
import { CollectionFormService } from '../../../services/collection-form.service';
import { SendMailFromTemplateComponent } from './send-mail-from-template/send-mail-from-template.component';
import { MailForMailTemplateDto } from '../../../../../../../models/ts/mail-for-mail-template-dto';
import { ProtectedFieldType } from '../../../../../../../models/ts/protected-field-type.model';
import { FormFieldType } from '../../../../../../../models/ts/form-field-type.model';
import { CellActionType } from '../../../../widgets/collection-list-widget/classes/cell-action-type';
import { CellActionData } from '../../../../widgets/collection-list-widget/interfaces/cell-action-data';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GridSearchDto } from '../../../../../../../models/ts/grid-search-dto.model';
import { GridGroupSortDto } from '../../../../../../../models/ts/grid-group-sort-dto.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MailingTrackChangesComponent } from './mailing-track-changes/mailing-track-changes.component';
import { DialogModalButtons } from '../../../../../../shared/enums/dialog-modal-buttons';
import { DialogModalComponent } from '../../../../../../shared/components/modals/dialog-modal/dialog-modal.component';
import { formsActions } from '../../../../../../store/features/forms/forms-actions';
import { GridDataDto } from '../../../../../../../models/ts/grid-data-dto.model';
import { TooltipComponent } from "../../../../../../shared/components/ui/tooltip/tooltip.component";
import { CollectionFormValidatorError } from '../../../classes/collection-form-validator-error';

@Component({
  selector: 'bizz-form-mailing-list',
  standalone: true,
  imports: [
    GridComponent,
    AsyncPipe,
    TranslatePipe,
    IconComponent,
    TooltipComponent
],
  templateUrl: './form-mailing-list.component.html',
  styleUrl: './form-mailing-list.component.scss'
})
export class FormMailingListComponent implements OnInit {
  @Input({ required: true }) public element: CollectionFormField;
  @Input({ required: true }) public formId: string;
  public gridOptions: GridOptions;
  public totalItems = 0;
  public isLoading = true;
  public filter$ = new BehaviorSubject<GridSearchDto>({
    Records: 10,
    Page: 1,
    Sort: [],
    Search: '',
    Group: []
  });
  private collectionId: number;
  private versionId: number | null = null;
  private instanceId: number | null = null;
  private formFields: CollectionFormField[] = [];
  public pageRange = signal<{ skip: number, take: number }>({ skip: 0, take: 10 });
  public errorState: CollectionFormValidatorError | undefined;

  private storeSelectedForm$: BehaviorSubject<StoreCollectionForm | undefined> = new BehaviorSubject<StoreCollectionForm | undefined>(undefined);
  
  public formData$ = this.storeSelectedForm$
    .pipe(
      filter(store => store != null),
      map(store => store?.data),
      filter((data) => this.element != null && data != null),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map(data => data!),
      shareReplay(1));

  public gridOptions$ = this.formData$.pipe(
    take(1),
    switchMap(formdata => {
      return this.collectionFormApiService.getMailinglist({
        formsId: formdata.CollectionFormId,
        fieldId: this.element.Id,
        newFlowStarted: formdata?.InstancesID == null || formdata?.InstancesID == 0 || formdata?.VersionsID == null || formdata?.VersionsID == 0
      });
    }), map(found => {
        const gridOptions = new GridOptions(found.GridOptions);
        gridOptions.pageSize = this.filter$.value.Records;
        this.gridOptions = gridOptions;
        return gridOptions;
      }
    ));
  public data$ = this.filter$
    .pipe(
      tap(() => this.isLoading = true),
      combineLatestWith(this.formData$.pipe(take(1))),
      tap(([filter, data]) => {
        this.isLoading = true;
        this.collectionId = data.CollectionsID;
        this.versionId = data.VersionsID;
        this.instanceId = data.InstancesID;
        this.formFields = CollectionFormService.extractFieldsFromForm(data);
        if (this.element.GridOptions == null) return;
      }),
      switchMap(([filter, data]) => {
        if((!!data && !!data.CollectionsID && !!data.VersionsID && !!data.InstancesID)){
          return this.collectionFormApiService.getMailTemplateList({
            collectionsId: data.CollectionsID,
            instancesId: data.InstancesID,
            versionsId: data.VersionsID,
            searchData: {
              Sort: filter.Sort,
              Group: filter.Group,
              Search: filter.Search,
              Page: filter.Page,
              Records: filter.Records,
              State: 1
            }
          }).pipe(combineLatestWith(of(data)))
        }
        return this.store$.select(selectFormField(this.formId, this.element.Id)).pipe(map(field => {
          const value = structuredClone(field?.Value ?? []);
          const result: GridDataDto = {
            Data: value,
            TotalRecords: value.length ?? 0,
            ActiveSearchfilter: false,
            Pages: 1,
            ReportsAvailableForUser: false
          };
          return result;
        }),combineLatestWith(of(data)));
       }),
      tap(([found]) => {
          this.totalItems = found.TotalRecords;
      }),
      map(([found, form]) => {
        const result = found?.Data.slice(this.pageRange().skip, this.pageRange().skip + this.pageRange().take) ?? [];
        
        result.forEach(el => {
          el['IsLockedOrReadonly'] = form.IsLocked || this.element.IsReadOnly;
          el['CanViewChanges'] = this.instanceId != null && this.instanceId > 0 && this.versionId !=null && this.versionId > 0;
        });
        return result;
      }),
      tap(() => this.isLoading = false)
    );
  private mediaIds: Array<number> = [];

  public constructor(private collectionFormApiService: CollectionFormApiService, private dialog: Dialog, private store$: Store, private destroyRef: DestroyRef) {
  }

  public ngOnInit(): void {
    this.store$.select(selectForm(this.formId))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(found => {
        this.storeSelectedForm$.next(found);
      });

    this.store$.select(selectFormFieldValue(this.formId, { collectionId: this.collectionId }, ProtectedFieldType.File))
      .pipe(take(1))
      .subscribe(found => {
        if (found != null) {
          this.mediaIds.push(found as number);
        }
      });

    this.store$.select(selectFormFields(this.formId, {
      formFieldType: FormFieldType.List,
      protectedFieldType: ProtectedFieldType.File
    }))
      .pipe(take(1))
      .subscribe(found => {
        if (found) {
          const fileFields = found.flatMap(_ => _.Records?.map(r => r.Fields))
            .flatMap(_ => _?.filter(f => f.ProtectedFieldType == ProtectedFieldType.File));
          this.mediaIds = this.mediaIds.concat(fileFields?.map(_ => _?.Value) ?? []);
        }
      });
      
    this.store$.select(selectFormFieldValidationState(this.formId, this.element.Id))
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe({
      next: (error: CollectionFormValidatorError | undefined) => {
        this.errorState = error;
      }
    });
  }

  public onCellAction(event: { action: CellActionType, data: CellActionData }): void {
    if (event.action === CellActionType.MAIL_EDIT) {
      this.editMail(event.data.data['ID']);
    } else if (event.action === CellActionType.MAIL_VIEW) {
      this.viewMail(event.data.data['ID']);
    } else if (event.action === CellActionType.MAIL_TRACK_CHANGES) {
      this.dialog.open(MailingTrackChangesComponent, {
        data: {
          collectionId: this.collectionId,
          instanceId: this.instanceId,
          versionId: this.versionId,
          mailId: event.data.data['ID']
        }
      });
    } else if (event.action === CellActionType.MAIL_DELETE_DRAFT) {
      this.dialog.open<DialogModalButtons>(
        DialogModalComponent,
        {
          data: {
            title: 'DeleteItem',
            message: 'AreYouSureYouWantToDeleteThisItem',
            buttons: DialogModalButtons.Delete,
            showCancelButton: true
          }
        }).closed.pipe(take(1)).subscribe(button => {
        if (button && button == DialogModalButtons.Delete) {
          this.collectionFormApiService.deleteMailDraft(event.data.data['ID']).subscribe(() => {
            if(this.instanceId == 0 && this.versionId == 0){
              const found = this.store$.selectSignal(selectFormField(this.formId, this.element.Id))();
              let currentValue:Array<any> = structuredClone(found?.Value ?? []);
              currentValue = currentValue.filter(i => i.ID != event.data.data['ID']);
                this.store$.dispatch(formsActions.updateFormField({
                  formId: this.formId,
                  fieldId: this.element.Id,
                  value: currentValue
                }));


            }
            this.filter$.next(this.filter$.value);
          });
        }
      });
    }
  }

  public onPageChange(event: PageChangeEvent): void {
    this.pageRange.set(event);
    const found = this.filter$.value;
    found.Page = (event.skip / event.take) + 1;
    found.Records = event.take;
    this.gridOptions.pageSize = event.take;
    this.filter$.next(found);
  }

  public onSortChange($event: SortDescriptor[]): void {
    const found = this.filter$.value;
    found.Sort = $event.map(el => (el as GridGroupSortDto));
    this.filter$.next(found);
  }

  public onGroupChange($event: GroupDescriptor[]): void {
    const found = this.filter$.value;
    found.Group = $event.map(el => (el as GridGroupSortDto));
    this.filter$.next(found);
  }

  public selectMailTemplate(): void {
    if (this.collectionId) {
      const ref = this.dialog.open<{ selectedTemplate: number | null } | null>(SelectMailTemplateComponent, {
        data: {
          collectionId: this.collectionId
        }
      });

      lastValueFrom(ref.closed).then((result) => {
        if (result != null) {
          this.createMailFromTemplate(result?.selectedTemplate ?? null);
        }
      });
    }
  }

  public createMailFromTemplate(selectedTemplate: number | null): void {
    if (selectedTemplate == null) {
      this.openCreateMailModal(null);
    } else {
      lastValueFrom(
        this.collectionFormApiService.getSelectedMailTemplate({
          instanceId: this.instanceId!,
          versionId: this.versionId!,
          templateId: selectedTemplate,
          fields: this.formFields
        })
      ).then(found => this.openCreateMailModal(found));
    }
  }

  public viewMail(mailId: number): void {
    lastValueFrom(this.collectionFormApiService.getMailTemplateById({ mailId: mailId })).then(found =>
      this.openCreateMailModal(found, true)
    );
  }

  public editMail(mailId: number): void {
    lastValueFrom(this.collectionFormApiService.getMailTemplateById({ mailId: mailId })).then(found =>
      this.openCreateMailModal(found, false)
    );
  }

  private openCreateMailModal(template: MailForMailTemplateDto | null, viewOnly = false): void {
    const ref = this.dialog.open<any | undefined>(SendMailFromTemplateComponent, {
      data: {
        template: template,
        instanceId: this.instanceId!,
        collectionId: this.collectionId!,
        versionId: this.versionId!,
        mailIds: this.mediaIds,
        viewOnly: viewOnly
      }
    }).closed.pipe(take(1))
      .subscribe((savedItem) => {
        if(this.instanceId == 0 && this.versionId == 0 && savedItem != null){
          const found = this.store$.selectSignal(selectFormField(this.formId, this.element.Id))();
          let currentValue:Array<any> = found?.Value ?? [];
          currentValue = currentValue.filter(i => i.ID != savedItem.ID);
            currentValue.push(savedItem);
            this.store$.dispatch(formsActions.updateFormField({
              formId: this.formId,
              fieldId: this.element.Id,
              value: currentValue
            }));
        }
        this.filter$.next(this.filter$.value); // invokes stream to rerun and thus refresh the grid
      });

  }

}

<!-- Screen & not undocked-->
@if((expanded | async) && !isMobile){
<div class="rounded-lg p-2.5" [routerLinkActive]="'bg-sidebar-active-section'">
    <!--Icon if unpin button not clicked & sliding in/out of sidebar is not enabled-->
    <div class="flex" [ngClass]="{'hidden' : !(expanded | async)}">
        <a class='group flex gap-4 text-sidebar-header hover:text-sidebar-header-hover'
            [routerLink]="sidebarListItem.ItemType == WorkspaceItemType.NewTableViewRecord ?  ['form/collectionsid/' + sidebarListItem.CollectionsID + '/workspaceitemsid/' + sidebarListItem.WorkspaceItemsID] : ['dashboard/' + sidebarListItem.WorkspaceItemsID]"
               routerLinkActive="!text-sidebar-header-hover"
               (click)="openLink(sidebarListItem);slideSidebar()">
                <bizz-icon class="mt-0.5 size-6 min-w-[1.25rem] text-sidebar-icon group-hover:text-sidebar-header-hover sm:size-5"
                            [iconLibrary]="sidebarListItem.Icon.Library"
                            [iconName]="sidebarListItem.Icon.Name"
                            routerLinkActive="!text-sidebar-header-hover"
                            [hover]="true">
                </bizz-icon>
                <p class="text-base no-underline group-hover:text-sidebar-header-hover sm:text-sm"
                   [ngClass]="{'max-h-64': !sidebarListItem.collapsed, 'overflow-hidden': !(expanded | async)}">
                    {{sidebarListItem.Name}}
                </p>
            </a>

            @if (children && children.length > 0) {
                <ng-container>
                        <bizz-icon (click)="sidebarListItem.collapsed = !sidebarListItem.collapsed"
                            [hover]="true"
                            [iconName]="sidebarListItem.collapsed ? 'angle-down' : 'angle-up'"
                            class="ml-auto size-3.5 min-w-3.5 text-sidebar-icon hover:text-sidebar-header-hover">
                        </bizz-icon>
                </ng-container>
            }

    </div>
    <!--Show childeren of sidebar if sidebar is Docked-->
    @if (children && children.length > 0 && !sidebarListItem.collapsed) {
        <div class="ml-[2.27rem] mt-1.5 flex flex-col gap-1.5 font-medium">
            @for (child of children; track $index) {
                <div class="group flex cursor-pointer items-start whitespace-normal text-xs text-sidebar-link no-underline"
                    [routerLink]="child.ItemType == WorkspaceItemType.NewTableViewRecord ?  ['form/collectionsid/' + child.CollectionsID + '/workspaceitemsid/' + child.WorkspaceItemsID] : ['dashboard/' + child.WorkspaceItemsID]"
                    (click)="openLink(child);slideSidebar()" [routerLinkActive]="'!text-sidebar-link-hover'">
                    <bizz-icon
                        class="mr-2.5 mt-0.5 size-3.5 min-w-3.5 resize-none text-sidebar-icon group-hover:text-sidebar-header-hover"
                        [iconLibrary]="child.Icon.Library" [iconName]="child.Icon.Name"
                        [routerLinkActive]="'!text-sidebar-link-hover'" [hover]="true">
                    </bizz-icon>
                    <p class="group-hover:text-sidebar-link-hover">{{child.Name}}</p>
                </div>
        }
        </div>
    }
</div>
}

@else if (isMobile){
<!-- Mobile -->
    <div [routerLinkActive]="'bg-form-control-border'"
         class="text-title mx-4 box-border flex flex-col rounded-lg p-2.5">

    <div class="flex flex-row">
            <a class='group flex flex-row gap-4'
               (click)="openLink(sidebarListItem);slideSidebar()">
                <bizz-icon
                        [hover]="true"
                        [iconLibrary]="sidebarListItem.Icon.Library"
                        [iconName]="sidebarListItem.Icon.Name"
                        class="size-6 min-w-6 text-sidebar-icon group-hover:text-sidebar-header-hover">
                </bizz-icon>
                <p [ngClass]="{'max-h-5': sidebarListItem.collapsed, 'max-h-64': !sidebarListItem.collapsed}"
                   class="text-md max-w-[10.74rem] text-sidebar-header no-underline group-hover:text-sidebar-header-hover">
                    {{sidebarListItem.Name}}
                </p>
            </a>

        @if (children && children.length > 0){
        <ng-container>
                    <bizz-icon (click)="sidebarListItem.collapsed = !sidebarListItem.collapsed"
                        [hover]="true"
                [iconName]="sidebarListItem.collapsed ? 'angle-down' : 'angle-up'"
                class="ml-auto size-5 min-w-5 text-sidebar-header hover:text-sidebar-header-hover">
            </bizz-icon>
        </ng-container>
        }

    </div>

    @for (child of children; track $index){
    <div [ngClass]="{'max-h-0 !mt-0': sidebarListItem.collapsed, 'max-h-64': !sidebarListItem.collapsed}"
        class="mt-2 flex flex-col overflow-hidden font-medium">
        <div [routerLinkActive]="'!text-sidebar-header-hover'"
            [routerLink]="child.ItemType == WorkspaceItemType.NewTableViewRecord ?  ['form/collectionsid/' + child.CollectionsID + '/workspaceitemsid/' + child.WorkspaceItemsID] : ['dashboard/' + child.WorkspaceItemsID]"
            class="group my-1 ml-[2.27rem] box-border flex cursor-pointer flex-row items-start whitespace-normal pr-4 text-xs text-sidebar-link no-underline"
            (click)="openLink(child);slideSidebar()">
            <bizz-icon [hover]="true" [iconLibrary]="child.Icon.Library" [iconName]="child.Icon.Name"
                [routerLinkActive]="'!text-sidebar-header-hover'"
                class="mr-2.5 size-3.5 min-w-[0.875rem] resize-none text-sidebar-icon group-hover:text-sidebar-header-hover">
            </bizz-icon>
            <p class="group-hover:text-sidebar-header-hover">{{child.Name}}</p>
        </div>
    </div>
    }

</div>
}

@else{
<!-- Screen Icon if unpin button clicked & sliding in/out of sidebar gets enabled -->
<bizz-icon class="flex h-6 w-6 text-sidebar-icon hover:text-sidebar-header-hover"
    [iconLibrary]="sidebarListItem.Icon.Library" [iconName]="sidebarListItem.Icon.Name" [hover]="true">
</bizz-icon>
}
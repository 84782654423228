import { Component } from '@angular/core';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { DecimalPipe } from '../../../../pipes/decimal/decimal.pipe';
import { getDisplayStyleForNumber } from 'src/app/shared/functions/helpers/display-style-helpers';

@Component({
  selector: 'bizz-grid-cell-numeric',
  standalone: true,
  templateUrl: './grid-cell-numeric.component.html',
  styleUrl: './grid-cell-numeric.component.scss',
  imports: [DecimalPipe]
})
export class GridCellNumericComponent extends GridCellBaseComponent<string> {

  public backgroundColor: string;
  public foregroundColor: string;
  public hasDisplayStyle = false;

  //If the column doesn't have have display styles we can reduce the padding (for id columns for example)
  public columnHasDisplayStyle = false;

  protected override onDataChanged(): void {
    this.columnHasDisplayStyle = this.column.DisplayStyleValues && this.column.DisplayStyleValues.length > 0;
    if (this.value != null && this.value != '') {
      const numericValue = Number(this.value);
      const displayValue = getDisplayStyleForNumber(this.column.DisplayStyleValues, numericValue);
      if (displayValue) {
        this.backgroundColor = displayValue.BackgroundColor;
        this.foregroundColor = displayValue.ForegroundColor;
        this.hasDisplayStyle = true;
      }
    }
  }
}

import { CollectionFormFieldValue } from "src/models/ts/collection-form-field-value.model";
import { StateType } from "src/models/ts/state-type.model";

/**
 * Filter which will only show active values when field is enabled. 
 * When field is disabled, all past values will be shown (because some old values might still be assigned to the field)
 * @param items The items to filter.
 * @param activeValue The active value.
 * @returns The filtered items.
 */
export function  hideRemovedOptionsWhenFieldEnabled(items: CollectionFormFieldValue[], activeValue: number): CollectionFormFieldValue[] {
    return items.filter(item => {
      return item.State == StateType.Active || item.CollectionFieldValuesID == activeValue;
    });
  }
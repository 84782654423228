import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { catchError, combineLatestWith, exhaustMap, filter, map, mergeMap, of, tap } from 'rxjs';
import { StoreCollectionForm } from '../forms-state';
import { CollectionFormApiService } from '../../../../api/bizzmine/collection-form/collection-form-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CollectionFormEditApiService } from '../../../../api/bizzmine/collection-form-edit/collection-form-edit-api.service';
import { AlertService } from '../../../../features/bizzmine/alerts/alert.service';
import { refreshActions } from '../../refresh/refresh-actions';
import { Store } from '@ngrx/store';
import { selectForm } from '../forms-selectors';
import { CollectionFormJsonService } from '../../../../features/bizzmine/form/services/collection-form-json.service';
import { TaskType } from '../../../../../models/ts/task-type.model';
import { Router } from '@angular/router';
import { SidebarService } from '../../../../shared/services/sidebar/sidebar.service';
import { headerTasksActions } from '../../header-tasks/header-tasks-actions';

/**
 * Effects for forms with a task
 */
@Injectable()
export class FormsTaskEffects {
  private actions$ = inject(Actions);
  private collectionFormApiService = inject(CollectionFormApiService);
  private router = inject(Router);
  private sidebarService = inject(SidebarService);
  public redirectToExam$ = createEffect(() => this.actions$.pipe(
      ofType(formsActions.redirectToExam),
      exhaustMap(request => this.collectionFormApiService.getExamDetails({taskId: request.taskId})),
      tap(data => {
        this.router.navigate([`./trn/${data.TrainingAppID}/exam/${data.ExamSessionVersionsID}`])
          .then();
      }),
  ), {dispatch: false});
  public getTaskForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getTaskForm),
    exhaustMap(({
                  taskId,
                  formId
                }) => this.collectionFormApiService.getFormByTaskID(taskId).pipe(combineLatestWith(of(formId)))
      .pipe(
        map(
          ([data, formId]) =>
          {
            if(data.TaskType == TaskType.TrainingAppExaminationTask) {
              return formsActions.redirectToExam({taskId});
            }
            if(formId != null && formId.trim() != '') {
              return ({
                type: formsActions.formRefreshed.type,
                form: data,
                formId : formId,
              })
            }
            this.sidebarService.collapseSidebar();
            return ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data),
            addToViewStack: true
          })}
        )
      ))
  ));

  public getAnonymousForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getAnonymousForm),
    exhaustMap(({
                  collectionId,
                  formId
                }) => this.collectionFormApiService.getAnonymousForm(collectionId).pipe(combineLatestWith(of(formId)))
      .pipe(
        map(
          ([data, formId]) =>
          {
            if(formId != null && formId.trim() != '') {
              return ({
                type: formsActions.formRefreshed.type,
                form: data,
                formId : formId,
              })
            }
            return ({
              type: formsActions.formFetched.type,
              form: new StoreCollectionForm(data),
              addToViewStack: true
            })}
        )
      ))
  ));
  public getTaskFormOnBehalfOf$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getTaskOnBehalfOf),
    exhaustMap((request) => this.collectionFormApiService.getFormByTaskIdOnBehalfOf(request.taskId, request.collectionsId, request.assignedToId, request.assignedToType).pipe(combineLatestWith(of(request)))
      .pipe(
        map(([data,request]) => {
          if(request.formId != null && request.formId.trim() != '') {
            return ({
              type: formsActions.formRefreshed.type,
              form: data,
              formId : request.formId,
            })
          }
          this.sidebarService.collapseSidebar();
          return ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data),
            addToViewStack: true
          })}
        )
      ))
  ));
  public saveTask$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveTask),
    exhaustMap(({ formId, form, closeForm }) => this.collectionFormEditApiService.saveWithJson(
        form.CollectionsID,
        form.CollectionFormId,
        form.TasksID,
        form.TaskInfo?.ActionsID ?? 0,
        form.TaskType,
        form.MethodType,
        JSON.stringify(CollectionFormJsonService.formToJson(form))
      ).pipe(
        map(data => {
          return ({ type: formsActions.executeTaskSucceeded.type, formId: formId,   versionId: data.VersionID,
            instanceId: data.InstancesID,
            collectionId: data.CollectionsID,
            close: closeForm})}),
        catchError((response: HttpErrorResponse) => of(formsActions.saveTaskFailed({
            formId: formId,
            response: response
          }))
        )
      )
    )
  ));

  private collectionFormEditApiService = inject(CollectionFormEditApiService);
  public executeTask$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.executeTask),
    filter(({ form, preValidate }) => !form.IsExecuteOnBehalfOf && !preValidate),
    exhaustMap(({ formId, form }) => this.collectionFormEditApiService.saveAndCloseStepWithJson(
        form.CollectionsID,
        form.CollectionFormId,
        form.TasksID,
        form.TaskInfo?.ActionsID ?? 0,
        form.TaskType,
        form.MethodType,
        JSON.stringify(CollectionFormJsonService.formToJson(form))
      ).pipe(
        tap(() => {this.sidebarService.resetSidebar()}),
        map(data => ({ type: formsActions.executeTaskSucceeded.type, formId: formId, response: data })),
        catchError((response: HttpErrorResponse) => of(formsActions.executeTaskFailed({
            formId: formId,
            response: response
          }))
        )
      )
    )
  ));

  public preValidateTask$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.executeTask),
    filter(({ form, preValidate }) => !form.IsExecuteOnBehalfOf && preValidate),
    exhaustMap(({formId, form, closeForm}) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      closeForm,
      form
    ).pipe(
      map(data => ({ type: formsActions.executeTask.type, formId, form, closeForm, preValidate: false })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ))

  public preValidateTaskOnBehalfOf$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.executeTask),
    filter(({ form, preValidate }) => !!form.IsExecuteOnBehalfOf && preValidate),
    exhaustMap(({formId, form, closeForm}) => this.collectionFormEditApiService.preValidateFormOnBehalfOf(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      form.AssignedToID,
      closeForm,
      form
    ).pipe(
      map(data => ({ type: formsActions.executeTask.type, formId, form, closeForm, preValidate: false })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ))

  public executeTaskOnBehalfOf$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.executeTask),
    filter(({ form, preValidate }) => !!form.IsExecuteOnBehalfOf && !preValidate),
    exhaustMap(({ formId, form }) => this.collectionFormEditApiService.saveAndCloseStepOnBehalfOfWithJson(
        form.CollectionsID,
        form.CollectionFormId,
        form.TasksID,
        form.TaskInfo?.ActionsID ?? 0,
        form.TaskType,
        form.MethodType,
        form.AssignedToID,
        form.AssignedToType,
        JSON.stringify(CollectionFormJsonService.formToJson(form))
      ).pipe(
        map(data => ({ type: formsActions.executeTaskSucceeded.type, formId: formId, response: data })),
        catchError((response: HttpErrorResponse) => of(formsActions.executeTaskFailed({
            formId: formId,
            response: response
          }))
        )
      )
    )
  ));


  private alertService = inject(AlertService);
  private store$ = inject(Store);
  public executeTaskSucceeded$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.executeTaskSucceeded),
    // TODO: proper alert
    // tap(() => this.alertService.setAlert(SAVED_ALERT)),
    tap((data) => {
      const form = this.store$.selectSignal(selectForm(data.formId))();
        if(form != null) {
          this.store$.dispatch(formsActions.deleteSelfLock({formId: form.id, form: form.data, close: false}));
          this.store$.dispatch(refreshActions.refreshData({formId: form.id, collectionId: form.data.CollectionsID}));
        }
      }

    ),
    filter((data => data.close == null || data.close == true)),
    mergeMap(({formId}) =>
      of(
        ({
          type: formsActions.formClosed.type,
          formId: formId,
          unsavedChanges: false
        }),
       ({ type: headerTasksActions.fetchTasks.type}),
      ) 
    )
  ))
}